import { authHeader } from '../_helpers/authHeader';
import { apiUrl } from '../_constants';
import { handleResponse } from '.';
import 'whatwg-fetch';

function getProfiles() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${apiUrl}/profile`, requestOptions).then(handleResponse);
}

function createProfile(profile) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(profile)
  };

  return fetch(`${apiUrl}/profile/create`, requestOptions).then(handleResponse);
}

function updateProfile(profile) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(profile)
  };

  return fetch(`${apiUrl}/profile/${profile.id}`, requestOptions).then(handleResponse);
}

function deleteProfile(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };

  return fetch(`${apiUrl}/profile/${id}`, requestOptions).then(handleResponse);
}

// eslint-disable-next-line import/prefer-default-export
export const profileService = {
  getProfiles,
  createProfile,
  updateProfile,
  deleteProfile
};
