/* eslint-disable react/jsx-one-expression-per-line */
import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CompanyType } from '../../../_types';

import { good, evil, custom } from '../../../_constants/companies';

import { companyActions } from '../../../_actions';

import {
  Button, Overlay, Tabs, CompanyDeck, Form, FormRow, TextInput, SelectInput, NumberInput, Table
} from '../..';

class EditOverlay extends Component {
  constructor(props) {
    super(props);
    const { company } = this.props;
    this.state = {
      company,
      activeTab: 0,
      submitting: false,
      retiring: false,
      deleting: false,
      detailsFormSubmitted: false
    };
  }

  setActiveTab = (index) => {
    const { company, activeTab } = this.state;

    switch (activeTab) {
      case 0:
        if (company.name === '' || company.race === '') {
          this.setState({
            detailsFormSubmitted: true
          });
          return;
        }
        break;
      default:
        break;
    }

    this.setState({
      detailsFormSubmitted: false,
      activeTab: index
    });
  }

  handleInputChange = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.updateCompanyStat(name, value);
  }

  updateCompanyStat = (name, value) => {
    const { company } = this.state;
    company[name] = value;
    this.setState({
      company
    });
  }

  retireCompany = (e) => {
    const { company, retiring } = this.state;
    if (retiring) {
      return;
    }
    const { retireCompanyAction } = this.props;

    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure you want to retire this company?')) {
      this.setState({
        retiring: true
      });

      retireCompanyAction(company.id, true);
    }
  }

  reEnlistCompany = (e) => {
    const { company, retiring } = this.state;
    if (retiring) {
      return;
    }
    const { retireCompanyAction } = this.props;

    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure you want to re-enlist this company?')) {
      this.setState({
        retiring: true
      });

      retireCompanyAction(company.id, false);
    }
  }

  deleteCompany = (e) => {
    e.preventDefault();
    const { company, deleting } = this.state;
    if (deleting) {
      return;
    }
    const { deleteCompanyAction } = this.props;

    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure you want to delete this company? All associated warriors will also be deleted. This action cannot be undone.')) {
      this.setState({
        deleting: true
      });

      deleteCompanyAction(company.id);
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { company, submitting, activeTab } = this.state;
    if (submitting) {
      return;
    }

    // Do some validation here, return out if it fails and show an error message
    switch (activeTab) {
      case 0:
        if (company.name === '' || company.race === '') {
          this.setState({
            detailsFormSubmitted: true
          });
          return;
        }
        break;
      default:
        break;
    }

    this.setState({
      detailsFormSubmitted: false,
      submitting: true
    });

    const { createCompanyAction, updateCompanyAction, handleClose } = this.props;
    if (company.id) {
      updateCompanyAction(company, handleClose);
    } else {
      createCompanyAction(company);
    }
  }

  render() {
    const { handleClose } = this.props;
    const {
      company, activeTab, submitting, retiring, deleting, detailsFormSubmitted
    } = this.state;
    return (
      <Overlay id="edit-company" title={company.id ? 'Edit Company' : 'Create a Company'} handleClose={handleClose} open>
        <div className={`edit-left ${(submitting || deleting) ? 'submitting' : ''}`}>
          <Tabs
            activeTab={activeTab}
            setActiveTab={this.setActiveTab}
            tabs={[{
              name: 'Details',
              content: (
                <Form buttons={(
                  <Fragment>
                    {!!company.id && (
                      <>
                        {company.retired ? 
                          <Button text="Re-Enlist" constructive type="button" onClick={this.reEnlistCompany} loading={retiring} /> : 
                          <Button text="Retire" constructive type="button" onClick={this.retireCompany} loading={retiring} />
                        }
                      </>
                    )}
                    {!!company.id && <Button text="Delete" destructive type="button" onClick={this.deleteCompany} loading={deleting} />}
                    <Button text="Submit" primary type="button" onClick={this.onSubmit} loading={submitting} />
                  </Fragment>
                )}
                >
                  {!company.id && (<p className="help-text">Enter the details of your company below, then click Submit.<br /><br />The company rating will be automatically calculated as you create your warriors.</p>)}
                  <FormRow>
                    <TextInput name="name" label="Name" value={company.name} onChange={this.handleInputChange} required showError={detailsFormSubmitted && !company.name} />
                  </FormRow>
                  <FormRow>
                    <SelectInput name="race" label="Race" value={company.race} onChange={this.handleInputChange} required showError={detailsFormSubmitted && !company.race}>
                      <option value="">Please Select</option>
                      <optgroup label="Good">
                        {_.map(good, c => <option key={c.name} value={c.name}>{c.name}</option>)}
                      </optgroup>
                      <optgroup label="Evil">
                        {_.map(evil, c => <option key={c.name} value={c.name}>{c.name}</option>)}
                      </optgroup>
                      <optgroup label="Custom">
                        {_.map(custom, c => <option key={c.name} value={c.name}>{c.name}</option>)}
                      </optgroup>
                    </SelectInput>
                  </FormRow>
                  <FormRow>
                    <TextInput name="backstory" label="Backstory" value={company.backstory} onChange={this.handleInputChange} textarea large />
                  </FormRow>
                  <FormRow>
                    <NumberInput name="influence" label="Influence" value={company.influence} min={0} onChange={this.updateCompanyStat} />
                  </FormRow>
                </Form>
              )
            }, {
              name: 'Rating',
              content: (
                <Form>
                  <Table
                    headers={['Name', 'Points']}
                    rows={_.map(company.warriors, warrior => [
                      warrior.name,
                      <strong>{warrior.totalPoints}</strong>
                    ])}
                    overviewTable
                  />
                  <Table rows={[['Total', <strong>{company.rating}</strong>]]} overviewTable />
                </Form>
              )
            }]}
          />
          {(submitting || deleting) && <div className="submit-block" />}
        </div>
        <div className="edit-right">
          <CompanyDeck company={company} />
        </div>
      </Overlay>
    );
  }
}

EditOverlay.propTypes = {
  company: CompanyType.isRequired,
  createCompanyAction: PropTypes.func.isRequired,
  updateCompanyAction: PropTypes.func.isRequired,
  retireCompanyAction: PropTypes.func.isRequired,
  deleteCompanyAction: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default withRouter(connect(null, {
  createCompanyAction: companyActions.createCompany,
  updateCompanyAction: companyActions.updateCompany,
  retireCompanyAction: companyActions.retireCompany,
  deleteCompanyAction: companyActions.deleteCompany,
  getCompaniesAction: companyActions.getCompanies
})(EditOverlay));
