/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import {
  Overlay, Form, Button, TextInput
} from '../..';

class AgainstTheOdds extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      enemyRating: 0
    };
  }

  get bonus() {
    const { companyRating } = this.props;
    const { enemyRating } = this.state;

    const absBonus = (enemyRating - companyRating) / 15;
    const bonus = Math.sign(absBonus) * Math.floor(Math.min(Math.abs(absBonus), 10));

    return bonus;
  }

  handleInputChange = (event) => {
    const { target } = event;
    const value = parseInt(target.value || 0, 10) || 0;

    this.setState({
      enemyRating: value
    });
  }

  render() {
    const { onSubmit, companyRating } = this.props;
    const { enemyRating } = this.state;

    return (
      <Overlay title="Against the Odds" isSmall open>
        <Form buttons={<Button text="Continue" iconName="arrow-right" iconRight primary onClick={() => onSubmit(this.bonus)} />}>
          <p className="help-text">Enter the company rating of the opposing warband below to determine the handicap for this battle.</p>
          <div className="overlay__content__split">
            <div className="overlay__content__left">
              <table className="overlay__table">
                <tbody>
                  <tr>
                    <th>Your Rating</th>
                    <td>{companyRating}</td>
                  </tr>
                  <tr>
                    <th>Opponent Rating</th>
                    <td><TextInput name="enemyRating" value={enemyRating} onChange={this.handleInputChange} clean /></td>
                  </tr>
                  <tr>
                    <th>Difference</th>
                    <td>{Math.abs(companyRating - enemyRating)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="overlay__content__right">
              <h3>{this.bonus < 0 ? 'Your oponent receives' : 'You receive'}</h3>
              <ul>
                <li><span>{Math.abs(this.bonus)} Free re-rolls</span></li>
                <li><span>{Math.abs(this.bonus)} Bonus influence</span></li>
              </ul>
            </div>
          </div>
        </Form>
      </Overlay>
    );
  }
}

AgainstTheOdds.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  companyRating: PropTypes.number.isRequired
};

export default AgainstTheOdds;
