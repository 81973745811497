import { authHeader } from '../_helpers/authHeader';
import { apiUrl } from '../_constants';
import { handleResponse } from '.';
import 'whatwg-fetch';

function getRules() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${apiUrl}/rule`, requestOptions).then(handleResponse);
}

function createRule(rule) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(rule)
  };

  return fetch(`${apiUrl}/rule/create`, requestOptions).then(handleResponse);
}

function updateRule(rule) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(rule)
  };

  return fetch(`${apiUrl}/rule/${rule.id}`, requestOptions).then(handleResponse);
}

function deleteRule(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };

  return fetch(`${apiUrl}/rule/${id}`, requestOptions).then(handleResponse);
}

// eslint-disable-next-line import/prefer-default-export
export const ruleService = {
  getRules,
  createRule,
  updateRule,
  deleteRule
};
