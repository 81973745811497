import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { WarriorsType, WargearType } from '../../../../_types';

import {
  Tabs, Overlay, Icon, Form, FormRow, Button, NumberInput,
  SelectInput, ToggleAccordion
} from '../../..';
import WargearForm from './WargearForm';
import { emptyWargearItem } from '../../../../_constants';

class Result extends Component {
  constructor(props) {
    super(props);

    const { warriors } = this.props;
    const experienceWarriors = _.mapKeys(
      _.map(
        _.filter(warriors, warrior => !warrior.preBattleInjury),
        warrior => ({
          id: warrior.id,
          name: warrior.name,
          bonusExperience: 0
        })
      ), 'id'
    );

    this.state = {
      experienceWarriors,
      result: null,
      influenceEnabled: false,
      influence: 0,
      levelUpEnabled: false,
      levelUp: '',
      experienceEnabled: false,
      itemEnabled: false,
      item: {
        ...emptyWargearItem,
        attackEnabled: false,
        statEnabled: false,
        ruleEnabled: false
      },
      submitted: false
    };
  }

  setResult = (index) => {
    this.setState({
      result: index
    });
  }

  handleInputChange = (event) => {
    const { target } = event;
    const { name, type, checked } = target;
    const value = type === 'checkbox' ? checked : target.value;

    if (name.indexOf('bonusExperience') !== -1) {
      this.updateExperienceValue(name, value);
    } else {
      this.updateValue(name, value);
    }
  }

  updateValue = (name, value) => {
    this.setState({
      [name]: value
    });
  }

  updateExperienceValue = (name, value) => {
    const { experienceWarriors } = this.state;

    const nameParts = name.split('.');
    const warrior = experienceWarriors[nameParts[1]];
    warrior.bonusExperience = value;

    this.setState({
      experienceWarriors: { ...experienceWarriors, [nameParts[1]]: warrior }
    });
  }

  onSubmit = () => {
    const { onSubmit, wargear } = this.props;
    const {
      influenceEnabled,
      influence,
      levelUpEnabled,
      levelUp,
      itemEnabled,
      item
    } = this.state;

    if ((influenceEnabled && !influence)
    || (levelUpEnabled && !levelUp)) {
      this.setState({
        submitted: true
      });
      return;
    }

    if (itemEnabled) {
      if (!item.warriorId || !item.armouryId || (item.armouryId === 'new' && !item.name)) {
        this.setState({
          submitted: true
        });
        return;
      }

      if (item.armouryId && item.armouryId !== 'new') {
        const armouryItem = wargear[item.armouryId];
        item.name = armouryItem.name;
        item.adjustmentStat = armouryItem.adjustmentStat;
        item.adjustmentValue = armouryItem.adjustmentValue;
        item.ruleName = armouryItem.ruleName;
        item.ruleDescription = armouryItem.ruleDescription;
        item.mount = armouryItem.mount;
      }

      item.new = true;
      item.warriorId = parseInt(item.warriorId, 10);
      item.pointsLow = parseInt(item.pointsLow, 10);
      item.pointsHigh = parseInt(item.pointsHigh, 10);
      item.adjustmentValue = parseInt(item.adjustmentValue, 10);
      item.type = 'armoury';
    }

    this.setState({
      submitted: false
    });

    onSubmit(this.state);
  }

  getResultForm = () => {
    const { warriors, wargear } = this.props;
    const {
      experienceWarriors,
      influenceEnabled,
      influence,
      levelUpEnabled,
      levelUp,
      experienceEnabled,
      itemEnabled,
      item,
      submitted
    } = this.state;

    return (
      <Form buttons={<Button text="Continue" primary iconRight iconName="arrow-right" onClick={this.onSubmit} />}>
        <h3>Additional Rewards</h3>
        <ToggleAccordion heading="Bonus Influence" name="influenceEnabled" value={influenceEnabled} onChange={this.handleInputChange}>
          <FormRow>
            <NumberInput name="influence" label="Value" value={influence} onChange={this.updateValue} min={0} showError={submitted && influenceEnabled && !influence} />
          </FormRow>
        </ToggleAccordion>
        <ToggleAccordion heading="Bonus Level Up" name="levelUpEnabled" value={levelUpEnabled} onChange={this.handleInputChange}>
          <FormRow>
            <SelectInput label="Warrior" name="levelUp" value={levelUp} onChange={this.handleInputChange} showError={submitted && levelUpEnabled && !levelUp}>
              <option value="">Please Select</option>
              {_.map(_.filter(warriors, warrior => !warrior.preBattleInjury), warrior => (
                <option key={warrior.id} value={warrior.id}>{warrior.name}</option>
              ))}
            </SelectInput>
          </FormRow>
        </ToggleAccordion>
        <ToggleAccordion heading="Bonus Experience" name="experienceEnabled" value={experienceEnabled} onChange={this.handleInputChange}>
          <FormRow>
            {_.map(experienceWarriors, warrior => (
              <NumberInput
                key={warrior.id}
                name={`experienceWarriors.${warrior.id}.bonusExperience`}
                label={warrior.name}
                value={warrior.bonusExperience}
                onChange={this.updateExperienceValue}
                min={0}
                border
              />
            ))}
          </FormRow>
        </ToggleAccordion>
        <ToggleAccordion heading="Bonus Item" name="itemEnabled" value={itemEnabled} onChange={this.handleInputChange}>
          <WargearForm item={item} warriors={warriors} wargear={wargear} submitted={submitted} />
        </ToggleAccordion>
      </Form>
    );
  }

  render() {
    const { result } = this.state;

    return (
      <Overlay id="result-overlay" title="War's Aftermath" isSmall largeTabs open>
        <Tabs
          activeTab={result}
          setActiveTab={this.setResult}
          stretchTabs
          largeTabs
          tabs={[{
            name: (
              <Fragment>
                <Icon name="win" />
                {' '}
                Win
              </Fragment>),
            content: this.getResultForm()
          }, {
            name: (
              <Fragment>
                <Icon name="draw" />
                {' '}
                Draw
              </Fragment>
            ),
            content: this.getResultForm()
          }, {
            name: (
              <Fragment>
                <Icon name="loss" />
                {' '}
                Loss
              </Fragment>
            ),
            content: this.getResultForm()
          }]}
        />
      </Overlay>
    );
  }
}

Result.propTypes = {
  warriors: WarriorsType.isRequired,
  wargear: PropTypes.objectOf(WargearType.isRequired).isRequired,
  onSubmit: PropTypes.func.isRequired
};
function mapStateToProps({ wargear }) {
  return { wargear };
}

export default connect(mapStateToProps)(Result);
