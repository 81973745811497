import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { SortOptionType } from '../../_types';
import { Icon } from '..';

import './styles.scss';

let timeout;

function Sort({
  sortOption, sortOptions, setSort, storageName
}) {
  const [sortOpen, setSortOpen] = useState(false);

  useEffect(() => () => window.clearTimeout(timeout), []);

  const setStoreOption = (option) => {
    localStorage.setItem(storageName, JSON.stringify(option));
    setSort(option);
  };

  return (
    <div className="sort">
      <div
        className={`btn btn--secondary sort__toggle ${sortOpen ? 'sort__toggle--open' : ''}`}
        onClick={() => setSortOpen(!sortOpen)}
        onKeyDown={() => setSortOpen(!sortOpen)}
        onBlur={() => { timeout = window.setTimeout(() => setSortOpen(false), 250); }}
        tabIndex="0"
        role="button"
      >
        {sortOption.label}
        <Icon name={`sort-${sortOption.mode === 'asc' ? 'asc' : 'desc'}`} />
      </div>
      {sortOpen && (
        <div className="sort__options">
          {sortOptions.map((option) => {
            const { label, value, mode } = option;
            const isSelected = value === sortOption.value && mode === sortOption.mode;
            return (
              <button key={`${value} ${mode}`} type="button" className={`sort__option ${isSelected ? 'sort__option--selected' : ''}`} onClick={() => setStoreOption(option)}>
                {`${label} ${mode}`}
                {(isSelected) && <Icon name="check" />}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
}

Sort.propTypes = {
  sortOption: SortOptionType.isRequired,
  sortOptions: PropTypes.arrayOf(SortOptionType).isRequired,
  setSort: PropTypes.func.isRequired,
  storageName: PropTypes.string.isRequired
};

export default Sort;
