/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { AuthType } from '../_types';
import { ForgotPasswordForm } from '../_components';

class ForgotPasswordPage extends PureComponent {
  render() {
    const { authentication } = this.props;

    return (
      <div className="text-page">
        <div className="intro">
          <div className="intro__text">
            <h3>Battle Companies Manager</h3>
            <h1>Forgotten your password?</h1>
            <p>
              Enter your email address below and we'll send you a password reset link.
            </p>
          </div>
        </div>
        {!authentication.loggedIn && (
          <div className="text-page-forms">
            <div className="text-page-form">
              <h3>Enter your email address</h3>
              <ForgotPasswordForm />
            </div>
          </div>
        )}
      </div>
    );
  }
}

ForgotPasswordPage.propTypes = {
  authentication: AuthType.isRequired
};

function mapStateToProps({ authentication }) {
  return { authentication };
}

export default connect(mapStateToProps)(ForgotPasswordPage);
