import { authHeader } from '../_helpers/authHeader';
import { apiUrl } from '../_constants';
import { handleResponse } from '.';
import 'whatwg-fetch';

function getWarriors(companyId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${apiUrl}/warrior/${companyId}`, requestOptions).then(handleResponse);
}

function createWarrior(warrior, companyId) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(warrior, companyId)
  };

  return fetch(`${apiUrl}/warrior/create`, requestOptions).then(handleResponse);
}

function updateWarrior(warrior, companyId) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(warrior, companyId)
  };

  return fetch(`${apiUrl}/warrior/${warrior.id}`, requestOptions).then(handleResponse);
}

function deleteWarrior(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };

  return fetch(`${apiUrl}/warrior/${id}`, requestOptions).then(handleResponse);
}

function getS3SignedRequest(warriorId, companyId) {
  const fileName = `${warriorId}.jpg`;

  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({ fileName, companyId })
  };

  return fetch(`${apiUrl}/warrior/image`, requestOptions).then(handleResponse);
}

function uploadImage(response, file, croppedImage) {
  const { data: { signedRequest } } = response;

  // eslint-disable-next-line new-cap
  const buf = new Buffer.from(croppedImage.replace(/^data:image\/\w+;base64,/, ''), 'base64');

  const options = {
    method: 'PUT',
    headers: {
      'Content-Encoding': 'base64',
      'Content-Type': 'image/jpeg',
      'Content-Length': buf.length
    },
    body: buf
  };

  return fetch(signedRequest, options).then(handleResponse);
}

// eslint-disable-next-line import/prefer-default-export
export const warriorService = {
  getWarriors,
  createWarrior,
  updateWarrior,
  deleteWarrior,
  getS3SignedRequest,
  uploadImage
};
