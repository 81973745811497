/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/destructuring-assignment */
import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { WargearType, WarriorType } from '../../../_types';

import { emptyWargearItem } from '../../../_constants';

import {
  Button, Accordion, FormRow, FormField, RadioInput,
  TextInput, SelectInput, CheckboxListInput, CharacteristicsTable
} from '../..';
import MultipleOptionFormRow from '../../MultipleOptionsAccordion';

class WargearForm extends Component {
  constructor(props) {
    super(props);
    const { item, isMount } = props;

    let armouryId = '';
    if (item.name && !item.armouryId) {
      armouryId = 'new';
    } else if (item.armouryId) {
      // eslint-disable-next-line prefer-destructuring
      armouryId = item.armouryId;
    }

    this.state = {
      item: {
        id: item.id,
        armouryId,
        type: item.type,
        mount: item.mount || isMount,
        name: item.name,
        pointsLow: item.pointsLow,
        pointsHigh: item.pointsHigh,
        adjustmentStat: item.adjustmentStat || '',
        adjustmentValue: item.adjustmentValue || 0,
        specialRules: item.specialRules || [],
        specialAttacks: item.specialAttacks || [],
        influence: item.influence || 0,
        addToArmoury: false,
        move: item.move || 0,
        fight: item.fight || 0,
        shoot: item.shoot || 0,
        strength: item.strength || 0,
        defence: item.defence || 0,
        attacks: item.attacks || 0,
        wounds: item.wounds || 0,
        courage: item.courage || 0
      },
      submitted: false
    };
  }

  listDataChange = (value, key) => {
    const { item } = this.state;
    this.setState({
      item: {
        ...item,
        [key]: value
      }
    });
  };

  handleInputChange = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    const { item } = this.state;
    this.setState({
      item: {
        ...item,
        [name]: value
      }
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { item } = this.state;
    const {
      onSubmit, profile, wargear, isMount
    } = this.props;

    if (!item.armouryId || (item.armouryId === 'new' && !item.name) || (!profile && !item.type)) {
      this.setState({
        submitted: true
      });
      return;
    }

    // todo: check payload send to api includes rules & attacks
    if (item.armouryId && item.armouryId !== 'new') {
      const armouryItem = wargear[item.armouryId];
      item.name = armouryItem.name;
      item.adjustmentStat = armouryItem.adjustmentStat;
      item.adjustmentValue = armouryItem.adjustmentValue;
      item.ruleName = armouryItem.ruleName;
      item.ruleDescription = armouryItem.ruleDescription;
      item.mount = isMount;
    }

    if (item.id) {
      onSubmit(item);
    } else {
      onSubmit(item);
      this.setState({
        item: {
          ...emptyWargearItem,
          influence: 0,
          addToArmoury: false,
          mount: isMount
        },
        submitted: false
      });
    }
  }

  render() {
    const {
      onSubmit, item, profile, wargear, warrior, isMount
    } = this.props;
    const {
      item: {
        id,
        armouryId,
        type,
        name,
        pointsLow,
        pointsHigh,
        adjustmentStat,
        adjustmentValue,
        move,
        fight,
        shoot,
        strength,
        defence,
        attacks,
        wounds,
        courage,
        influence,
        addToArmoury,
        specialAttacks,
        specialRules
      },
      submitted
    } = this.state;

    let heading = item.name;
    if (!heading) {
      heading = isMount ? 'Add Mount' : 'Add Item';
    }

    return (
      <form onSubmit={this.handleSubmit} id={`${id === 0 ? 'new-wargear-item' : ''}`}>
        <Accordion heading={heading} newItem={id === 0}>
          {!profile && (
          <FormRow>
            <RadioInput
              name="type"
              label="Type"
              value={type}
              options={[{
                label: 'Included in base profile',
                value: 'base'
              }, {
                label: 'From the armoury',
                value: 'armoury'
              }]}
              onChange={this.handleInputChange}
              required
              showError={submitted && !type}
            />
          </FormRow>
          )}
          <FormRow>
            <SelectInput name="armouryId" label={`Load ${isMount ? 'Mount' : 'Item'}`} value={armouryId} onChange={this.handleInputChange} required showError={submitted && !armouryId}>
              <option value="">Please Select</option>
              <option value="new">Create New {isMount ? 'Mount' : 'Item'}</option>
              <optgroup label="Your Armoury">
                {_.map(_.orderBy(_.filter(wargear, { mount: isMount ? 1 : 0 }), ['name']), (w) => {
                  const matchingItem = !!_.find(warrior.wargear, { armouryId: w.id }) && item.armouryId !== w.id;
                  return <option disabled={matchingItem} value={w.id} key={w.id}>{w.name}</option>;
                })}
              </optgroup>
            </SelectInput>
          </FormRow>
          {armouryId === 'new' && (
          <FormRow>
            <TextInput name="name" label="Name" value={name} onChange={this.handleInputChange} required showError={submitted && !name} />
          </FormRow>
          )}
          <FormRow cls="row--points">
            <TextInput name="pointsLow" label="Points" value={pointsLow} onChange={this.handleInputChange} focusSelect small />
            {type === 'armoury' && (
            <Fragment>
              <span>/</span>
              <TextInput name="pointsHigh" label="&nbsp;" value={pointsHigh} onChange={this.handleInputChange} focusSelect small />
            </Fragment>
            )}
          </FormRow>
          {(type === 'armoury' && (!item.id || item.new)) && (
          <FormRow>
            <TextInput name="influence" label="Influence Cost" value={influence} onChange={this.handleInputChange} focusSelect small />
          </FormRow>
          )}
          {(!isMount && armouryId === 'new') && (
          <Fragment>
            <FormRow cls="row--modify-characteristics">
              <SelectInput name="adjustmentValue" label="Characteristics" value={adjustmentValue} onChange={this.handleInputChange}>
                <option value="3">+3</option>
                <option value="2">+2</option>
                <option value="1">+1</option>
                <option value="0">0</option>
                <option value="-1">-1</option>
                <option value="-2">-2</option>
                <option value="-3">-3</option>
              </SelectInput>
              <SelectInput name="adjustmentStat" label="&nbsp;" value={adjustmentStat} onChange={this.handleInputChange}>
                <option value="">Please Select</option>
                <option value="Movement">Movement</option>
                <option value="Fight">Fight</option>
                <option value="Shoot">Shoot</option>
                <option value="Strength">Strength</option>
                <option value="Defence">Defence</option>
                <option value="Attacks">Attacks</option>
                <option value="Wounds">Wounds</option>
                <option value="Courage">Courage</option>
                <option value="Might">Might</option>
                <option value="Will">Will</option>
                <option value="Fate">Fate</option>
              </SelectInput>
            </FormRow>
            <MultipleOptionFormRow
              maxListSize={3}
              items={specialAttacks}
              className="special-attack"
              heading="Special Attack"
              onChange={data => this.listDataChange(data, 'specialAttacks')}
            />
            <MultipleOptionFormRow
              maxListSize={3}
              items={specialRules}
              className="rule"
              heading="Special Rule"
              onChange={data => this.listDataChange(data, 'specialRules')}
            />
          </Fragment>
          )}
          {(isMount && armouryId === 'new') && (
            <Fragment>
              <FormRow cls="row--table">
                <FormField label="Characteristics" name="characteristicsTable">
                  <CharacteristicsTable
                    inputs
                    base={{
                      move: <TextInput name="move" value={move} onChange={this.handleInputChange} clean />,
                      fight: <TextInput name="fight" value={fight} onChange={this.handleInputChange} clean />,
                      shoot: <TextInput name="shoot" value={shoot} onChange={this.handleInputChange} clean />,
                      strength: <TextInput name="strength" value={strength} onChange={this.handleInputChange} clean />,
                      defence: <TextInput name="defence" value={defence} onChange={this.handleInputChange} clean />,
                      attacks: <TextInput name="attacks" value={attacks} onChange={this.handleInputChange} clean />,
                      wounds: <TextInput name="wounds" value={wounds} onChange={this.handleInputChange} clean />,
                      courage: <TextInput name="courage" value={courage} onChange={this.handleInputChange} clean />
                    }}
                    total={{
                      move, fight, shoot, strength, defence, attacks, wounds, courage
                    }}
                  />
                </FormField>
              </FormRow>
              <MultipleOptionFormRow
                maxListSize={3}
                items={specialRules}
                className="rule"
                heading="Special Rule"
                onChange={data => this.listDataChange(data, 'specialRules')}
              />
            </Fragment>
          )}
          <div className="form__inner-footer">
            {(armouryId === 'new' && !id) && (
            <FormRow cls="row--cb-save">
              <CheckboxListInput
                options={[{ name: 'addToArmoury', label: 'Save to My Armoury', value: addToArmoury }]}
                onChange={this.handleInputChange}
              />
            </FormRow>
            )}
            {id !== 0 && (<Button text="Remove" destructive small floatRight type="button" onClick={() => onSubmit(this.state.item, true)} />)}
            <Button text={id ? 'Update' : 'Create'} primary small floatRight type="submit" />
          </div>
        </Accordion>
      </form>
    );
  }
}

WargearForm.propTypes = {
  item: WargearType.isRequired,
  wargear: PropTypes.objectOf(WargearType.isRequired).isRequired,
  warrior: WarriorType.isRequired,
  onSubmit: PropTypes.func.isRequired,
  profile: PropTypes.bool.isRequired,
  isMount: PropTypes.bool
};

WargearForm.defaultProps = {
  isMount: false
};

export default WargearForm;
