import React, { Fragment } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { StatsType } from '../../_types';

import { Table, NumberControl } from '..';

const BattleCell = ({
  id, value, max, onChange
}) => (
  <Fragment>
    <NumberControl className="no-print" id={id} name={id} value={value} onChange={onChange} min={0} max={max} />
    <div className="marker-circles print">{_.times(max, index => <div key={index} className="marker-circle" />)}</div>
  </Fragment>
);

const DetailCell = ({ value }) => (
  <Fragment>
    <span className="no-print">{value}</span>
    <div className="marker-circles print">{_.times(value, index => <div key={index} className="marker-circle" />)}</div>
  </Fragment>
);

const createRow = (stats, label, showLabel, battleMode, remainingTotal, onChange) => {
  const { might, will, fate } = stats;
  let cells;

  if (battleMode && label === 'Total') {
    const { remainingMight, remainingWill, remainingFate } = remainingTotal;
    cells = [
      <BattleCell id="remainingMight" value={remainingMight} max={might} onChange={onChange} />,
      <BattleCell id="remainingWill" value={remainingWill} max={will} onChange={onChange} />,
      <BattleCell id="remainingFate" value={remainingFate} max={fate} onChange={onChange} />
    ];
  } else {
    cells = [
      <DetailCell value={might} />,
      <DetailCell value={will} />,
      <DetailCell value={fate} />
    ];
  }
  if (showLabel) {
    cells.unshift(label);
  }
  return cells;
};

const MWFTable = ({
  base, modified, total, remainingTotal, showLabel, small, battleMode, onChange
}) => {
  const headers = ['Might', 'Will', 'Fate'];
  if (showLabel) {
    headers.unshift('');
  }

  const rows = [];

  if (base) rows.push(createRow(base, 'Base', showLabel));
  if (modified) rows.push(createRow(modified, 'Increase', showLabel));
  if (total) rows.push(createRow(total, 'Total', showLabel, battleMode, remainingTotal, onChange));

  return (
    <Table
      headers={headers}
      rows={rows}
      statsTable
      fixedLayout
      small={small}
    />
  );
};

MWFTable.propTypes = {
  base: StatsType,
  modified: StatsType,
  total: StatsType,
  remainingTotal: StatsType,
  showLabel: PropTypes.bool,
  small: PropTypes.bool,
  battleMode: PropTypes.bool,
  onChange: PropTypes.func
};

MWFTable.defaultProps = {
  base: null,
  modified: null,
  total: null,
  remainingTotal: null,
  showLabel: false,
  small: false,
  battleMode: false,
  onChange: () => {}
};

BattleCell.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};

DetailCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.object]).isRequired
};

export default MWFTable;
