import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';
import { DonationType } from '../../_types';

import ad300x250 from '../../_assets/eg-mesbg-300x250.png';
import ad300x600 from '../../_assets/eg-mesbg-300x600.png';
import ad468x60 from '../../_assets/eg-mesbg-468x60.png';
import ad728x90 from '../../_assets/eg-mesbg-728x90.png';

const desktopAdverts = {
  wide: [{
    img: 'https://elementgames.co.uk/images/affiliate-banners/728x90.jpg',
    url: ''
  }, {
    img: 'https://elementgames.co.uk/images/affiliate-banners/artis-opus-s/728x90.jpg',
    url: '/paints-hobby-and-scenery/artis-opus/artis-opus-brush-set'
  }, {
    img: ad728x90,
    url: '/paints-hobby-and-scenery/games-workshop/MESBG'
  }],
  tall: [{
    img: 'https://elementgames.co.uk/images/affiliate-banners/300x600.jpg',
    url: ''
  }, {
    img: 'https://elementgames.co.uk/images/affiliate-banners/artis-opus-s/300x600.jpg',
    url: '/paints-hobby-and-scenery/artis-opus/artis-opus-brush-set'
  }, {
    img: ad300x600,
    url: '/paints-hobby-and-scenery/games-workshop/MESBG'
  }],
  square: [{
    img: 'https://elementgames.co.uk/images/affiliate-banners/300x250.jpg',
    url: ''
  }, {
    img: 'https://elementgames.co.uk/images/affiliate-banners/artis-opus-s/300x250.jpg',
    url: '/paints-hobby-and-scenery/artis-opus/artis-opus-brush-set'
  }, {
    img: ad300x250,
    url: '/paints-hobby-and-scenery/games-workshop/MESBG'
  }]
};

const mobileAdverts = {
  wide: [{
    img: 'https://elementgames.co.uk/images/affiliate-banners/468x60.jpg',
    url: ''
  }, {
    img: 'https://elementgames.co.uk/images/affiliate-banners/artis-opus-s/468x60.jpg',
    url: '/paints-hobby-and-scenery/artis-opus/artis-opus-brush-set'
  }, {
    img: ad468x60,
    url: '/paints-hobby-and-scenery/games-workshop/MESBG'
  }],
  tall: [{
    img: 'https://elementgames.co.uk/images/affiliate-banners/300x250.jpg',
    url: ''
  }, {
    img: 'https://elementgames.co.uk/images/affiliate-banners/artis-opus-s/300x250.jpg',
    url: '/paints-hobby-and-scenery/artis-opus/artis-opus-brush-set'
  }, {
    img: ad300x250,
    url: '/paints-hobby-and-scenery/games-workshop/MESBG'
  }],
  square: [{
    img: 'https://elementgames.co.uk/images/affiliate-banners/300x250.jpg',
    url: ''
  }, {
    img: 'https://elementgames.co.uk/images/affiliate-banners/artis-opus-s/300x250.jpg',
    url: '/paints-hobby-and-scenery/artis-opus/artis-opus-brush-set'
  }, {
    img: ad300x250,
    url: '/paints-hobby-and-scenery/games-workshop/MESBG'
  }]
};

function getAdvert(isMobile, isTall, isWide) {
  let type = 'square';
  if (isTall) type = 'tall';
  if (isWide) type = 'wide';

  const adverts = isMobile ? mobileAdverts[type] : desktopAdverts[type];
  return adverts[Math.floor(Math.random() * (adverts.length))];
}

function Advert({
  isTall, isWide, donation
}) {
  if (_.isEmpty(donation) || donation.donated) {
    return null;
  }

  const className = `promo${isTall ? ' promo--tall' : ''}${isWide ? ' promo--wide' : ''}`;
  const mobileAd = getAdvert(true, isTall, isWide);
  const desktopAd = getAdvert(false, isTall, isWide);

  return (
    <div className="promo-container">
      <div key="promo-container" className={className}>
        <a href={`https://elementgames.co.uk${mobileAd.url}?d=10673`} title="Element Games - Wargaming Webstore" className="promo-image-mobile" target="_blank" rel="noopener noreferrer">
          <img src={`${mobileAd.img}`} alt="Element Games - Wargaming Webstore" />
        </a>
        <a href={`https://elementgames.co.uk${desktopAd.url}?d=10673`} title="Element Games - Wargaming Webstore" className="promo-image-desktop" target="_blank" rel="noopener noreferrer">
          <img src={`${desktopAd.img}`} alt="Element Games - Wargaming Webstore" />
        </a>
      </div>
      <Link key="promo-link" to="/about">Support us to remove adverts.</Link>
    </div>
  );
}

Advert.propTypes = {
  isTall: PropTypes.bool,
  isWide: PropTypes.bool,
  donation: DonationType.isRequired
};

Advert.defaultProps = {
  isTall: false,
  isWide: false
};

function mapStateToProps({ donation }) {
  return { donation };
}

export default connect(mapStateToProps)(Advert);
