import { ruleService } from '../_services/rule';
import { alertActions } from './alert';

export const ruleConstants = {
  GET_REQUEST: 'RULES_GET_REQUEST',
  GET_SUCCESS: 'RULES_GET_SUCCESS',
  GET_FAILURE: 'RULES_GET_FAILURE',

  CREATE_REQUEST: 'RULE_CREATE_REQUEST',
  CREATE_SUCCESS: 'RULE_CREATE_SUCCESS',
  CREATE_FAILURE: 'RULE_CREATE_FAILURE',

  UPDATE_REQUEST: 'RULE_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'RULE_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'RULE_UPDATE_FAILURE',

  DELETE_REQUEST: 'RULE_DELETE_REQUEST',
  DELETE_SUCCESS: 'RULE_DELETE_SUCCESS',
  DELETE_FAILURE: 'RULE_DELETE_FAILURE'
};

function getRules() {
  function request() { return { type: ruleConstants.GET_REQUEST }; }
  function success(rule) { return { type: ruleConstants.GET_SUCCESS, rule }; }
  function failure(error) { return { type: ruleConstants.GET_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    ruleService.getRules()
      .then(
        rule => dispatch(success(rule)),
        error => dispatch(failure(error.toString()))
      );
  };
}

function createRule(rule, callback) {
  function request(ruleResponse) {
    return { type: ruleConstants.CREATE_REQUEST, ruleResponse };
  }
  function success(ruleResponse) {
    return { type: ruleConstants.CREATE_SUCCESS, ruleResponse };
  }
  function failure(error) { return { type: ruleConstants.CREATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(rule));

    ruleService.createRule(rule)
      .then(
        (ruleResponse) => {
          dispatch(success(ruleResponse));
          if (callback) callback();
          dispatch(alertActions.success(`${ruleResponse.name} created successfully`));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };
}

function updateRule(rule, callback) {
  function request(ruleResponse) {
    return { type: ruleConstants.UPDATE_REQUEST, ruleResponse };
  }
  function success(ruleResponse) {
    return { type: ruleConstants.UPDATE_SUCCESS, ruleResponse };
  }
  function failure(error) { return { type: ruleConstants.UPDATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(rule));

    ruleService.updateRule(rule)
      .then(
        (ruleResponse) => {
          dispatch(success(ruleResponse));
          if (callback) callback();
          dispatch(alertActions.success(`${ruleResponse.name} updated successfully`));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };
}

function deleteRule(id) {
  function request(idResponse) { return { type: ruleConstants.DELETE_REQUEST, idResponse }; }
  function success(idResponse) { return { type: ruleConstants.DELETE_SUCCESS, idResponse }; }
  function failure(idResponse, error) {
    return { type: ruleConstants.DELETE_FAILURE, idResponse, error };
  }

  return (dispatch) => {
    dispatch(request(id));

    ruleService.deleteRule(id)
      .then(
        (responseId) => {
          dispatch(success(responseId));
          dispatch(alertActions.success('Rule deleted successfully'));
        },
        (error) => {
          dispatch(failure(id, error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };
}

// eslint-disable-next-line import/prefer-default-export
export const ruleActions = {
  getRules,
  createRule,
  updateRule,
  deleteRule
};
