import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../..';

import './styles.scss';

const allowedTypes = ['image/jpg', 'image/jpeg', 'image/png'];

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

class FileUpload extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      error: '',
      submitting: false
    };
  }

  clearFile = (e) => {
    e.preventDefault();
    const { onChange } = this.props;
    this.setState({
      file: null
    });
    onChange(null);
  }

  selectFile = async (e) => {
    e.preventDefault();
    const { onChange } = this.props;
    const { files } = e.target;
    if (!files) {
      return;
    }
    const file = files[0];
    if (allowedTypes.indexOf(file.type) === -1) {
      this.setState({
        file: null,
        error: 'Invalid file format, upload a JPG or PNG image.'
      });
      return;
    }
    if (file.size > (10 * 1024 * 1024)) {
      this.setState({
        file: null,
        error: 'File too large. Maximum size 10MB.'
      });
      return;
    }
    this.setState({
      file,
      error: ''
    });

    const imageDataUrl = await readFile(file);

    onChange(imageDataUrl);
  }

  onSubmit = () => {
    const { onSubmit } = this.props;
    const { file } = this.state;
    this.setState({
      submitting: true
    });
    onSubmit(file);
  }

  render() {
    const { name } = this.props;
    const { file, error, submitting } = this.state;
    return (
      <div className="file-upload">
        <label className="btn btn--primary file-upload__change">
          <input id={name} name={name} type="file" onChange={this.selectFile} disabled={submitting} />
          Browse
        </label>
        {!!file && <span className="file-upload__name">{file.name}</span>}
        {!!error && <span className="file-upload__name file-upload__name--error">{error}</span>}
        {(!!file && !submitting) && <Button text="Clear" secondary onClick={this.clearFile} />}
        {!!file && <Button text="Upload" primary iconRight iconName="arrow-right" onClick={this.onSubmit} loading={submitting} />}
      </div>
    );
  }
}

FileUpload.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default FileUpload;
