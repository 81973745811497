import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Icon } from '..';

import './styles.scss';

class Accordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  toggleOpen = () => {
    const { open } = this.state;
    this.setState({
      open: !open
    });
  };

  render() {
    const { heading, children, newItem } = this.props;
    const { open } = this.state;
    return (
      <div className={`accordion${open ? ' accordion--open' : ''}${newItem ? ' accordion--new' : ''}`}>
        <button className="accordion__header" onClick={() => this.toggleOpen()} role="tab" type="button">
          <span className="accordion__header__name">{heading}</span>
          {newItem && (open ? <Icon name="minus-thin" /> : <Icon name="plus-thin" />)}
          {!newItem && <Icon name="chevron-down" />}
        </button>
        {open && (
          <div className="accordion__inner">
            {children}
          </div>
        )}
      </div>
    );
  }
}

Accordion.propTypes = {
  heading: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  newItem: PropTypes.bool
};

Accordion.defaultProps = {
  newItem: false
};

export default Accordion;
