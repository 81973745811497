import _ from 'lodash';
import { graveyardConstants } from '../_actions';
import { calculateWarriorPoints, calculateStatTotals } from '../_helpers/warrior';

function formatWarriors(warriors) {
  if (!warriors) {
    return {};
  }

  const fullWarriors = _.map(warriors, (warrior) => {
    const warriorWithPoints = calculateWarriorPoints(warrior);
    return calculateStatTotals(warriorWithPoints);
  });
  return _.mapKeys(fullWarriors, 'id');
}

function formatWarrior(warrior, state) {
  if (!warrior) {
    return {};
  }
  const newState = { ...state };
  const w = newState[warrior.id];
  w.status = warrior.status;
  return newState;
}

export default function (state = { loading: true }, action) {
  switch (action.type) {
    case graveyardConstants.GET_REQUEST:
      return { loading: true };
    case graveyardConstants.GET_SUCCESS:
      return formatWarriors(action.warriors);
    case graveyardConstants.GET_FAILURE:
      return { error: action.error };

    case graveyardConstants.UPDATE_SUCCESS:
      if (action.graveyardResponse.status !== 'dead') {
        return _.omit(state, action.graveyardResponse.id);
      }
      return formatWarrior(action.graveyardResponse, state);

    case graveyardConstants.DELETE_SUCCESS:
      return _.omit(state, [action.idResponse.id, action.idResponse.creature]);

    default:
      return state;
  }
}
