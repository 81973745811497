/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { isHero } from '../../../../_helpers/warrior';
import { WarriorType, RuleType, HeroicActionType, SpellType } from '../../../../_types';

import {
  Tabs, Table, Form, FormRow, SelectInput, TextInput, ToggleAccordion, Button
} from '../../..';

let timer;

class ExperienceForm extends Component {
  constructor(props) {
    super(props);

    const { warrior } = this.props;

    this.state = {
      warrior,
      chosenPath: '',
      customPath: '',
      reachedNewLevel: warrior.bonusLevel,
      statEnabled: false,
      adjustmentStat: '',
      ruleEnabled: false,
      myRuleId: '',
      ruleName: '',
      ruleDescription: '',
      heroicActionsEnabled: false,
      myHeroicActionsId: '',
      heroicActionName: '',
      heroicActionDescription: '',
      heroicActionBaseCastingValue: '',
      heroicActionId: '',
      spellsEnabled: false,
      mySpellsId: '',
      spellName: '',
      spellDescription: '',
      spellBaseCastingValue: '',
      spellId: '',
      warriorResult: '',
      warriorClass: '',
      timerRunning: false,
      activeTab: 0,
      submitted: false
    };
  }

  setActiveTab = (index) => {
    this.setState({
      activeTab: index
    });
  }

  get experienceTableRows() {
    const { warrior } = this.state;
    const experienceRows = [];
    experienceRows.push(['Wounds Caused', warrior.woundsExperience]);
    experienceRows.push(['Participation', warrior.participationExperience]);
    if (warrior.winExperience) {
      experienceRows.push(['Win Result', warrior.winExperience]);
    }
    if (warrior.bonusExperience) {
      experienceRows.push(['Scenario Bonus', warrior.bonusExperience]);
    }
    return experienceRows;
  }

  get levelTableRows() {
    const { warrior } = this.state;
    const levelRows = [];
    levelRows.push(['Experience', warrior.levelChange]);
    if (warrior.bonusLevel) {
      levelRows.push(['Scenario Bonus', 1]);
    }
    return levelRows;
  }

  handleInputChange = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    if (name === 'statEnabled') {
      this.setState({
        ruleEnabled: false,
        heroicActionsEnabled: false,
        spellsEnabled: false
      });
    }
    if (name === 'ruleEnabled') {
      this.setState({
        statEnabled: false,
        heroicActionsEnabled: false,
        spellsEnabled: false
      });
    }
    if (name === 'heroicActionsEnabled') {
      this.setState({
        statEnabled: false,
        ruleEnabled: false,
        spellsEnabled: false
      });
    }
    if (name === 'spellsEnabled') {
      this.setState({
        statEnabled: false,
        ruleEnabled: false,
        heroicActionsEnabled: false
      });
    }

    this.setState({
      [name]: value
    });
  }

  submitProgression = () => {
    const { rules, spells, heroicActions } = this.props;
    const {
      warrior, statEnabled, adjustmentStat, ruleEnabled, myRuleId, ruleName,
      ruleDescription, heroicActionsEnabled, heroicActionId, myHeroicActionsId, 
      heroicActionName, heroicActionDescription, spellsEnabled, spellId, mySpellsId, 
      spellName, spellDescription, spellBaseCastingValue, warriorResult, warriorClass, 
      chosenPath, customPath
    } = this.state;

    let valid = true;
    if (isHero(warrior)) {
      if ((!warrior.chosenPath && !chosenPath) || (chosenPath === 'Custom' && !customPath)) {
        valid = false;
      }
      if (
        (!statEnabled && !ruleEnabled && !heroicActionsEnabled && !spellsEnabled)
        || (statEnabled && !adjustmentStat)
        || (ruleEnabled && (!myRuleId || (myRuleId === 'new' && !ruleName)))
        || (heroicActionsEnabled && (!myHeroicActionsId || (myHeroicActionsId === 'new' && !heroicActionName)))
        || (spellsEnabled && (!spellId || (spellId === 'new' && (!mySpellsId || !spellBaseCastingValue || (mySpellsId === 'new' && !spellName)))))) {
        valid = false;
      }
    } else if (!warriorResult || (warriorResult === 'promoted' && !warriorClass)) {
      valid = false;
    }

    if (!valid) {
      this.setState({
        submitted: true
      });
      return;
    }

    if (statEnabled && adjustmentStat) {
      warrior.modifyStats[adjustmentStat.toLowerCase()] += 1;
      warrior.totalStats[adjustmentStat.toLowerCase()] += 1;
    }

    if (ruleEnabled) {
      if (myRuleId && myRuleId !== 'new') {
        const selectedRule = rules[myRuleId];
        warrior.specialRules.push({
          id: `${warrior.id}-${selectedRule.name}`,
          warriorId: warrior.id,
          myRuleId,
          name: selectedRule.name,
          description: selectedRule.description,
          type: 'progression',
          new: true
        });
      } else {
        warrior.specialRules.push({
          id: `${warrior.id}-${ruleName}`,
          warriorId: warrior.id,
          name: ruleName,
          description: ruleDescription,
          type: 'progression',
          new: true
        });
      }
    }
    
    if (heroicActionsEnabled) {
      if (heroicActionId && heroicActionId !== 'new') {
        const heroicActionIndex = _.findIndex(warrior.heroicActions, { id: parseInt(heroicActionId, 10) });
        const selectedHeroicAction = warrior.heroicActions[heroicActionIndex];
        selectedHeroicAction.modifyCastingValue += 1;
        warrior.heroicActions[heroicActionIndex] = selectedHeroicAction;
      } else if (myHeroicActionsId && myHeroicActionsId !== 'new') {
        // Add saved heroicAction
        const selectedHeroicAction = heroicActions[myHeroicActionsId];
        warrior.heroicActions.push({
          id: `${warrior.id}-${selectedHeroicAction.name}`,
          warriorId: warrior.id,
          myHeroicActionsId,
          name: selectedHeroicAction.name,
          description: selectedHeroicAction.description,
          type: 'progression',
          new: true
        });
      } else {
        // Add new heroicAction
        warrior.heroicActions.push({
          id: `${warrior.id}-${heroicActionName}`,
          warriorId: warrior.id,
          name: heroicActionName,
          description: heroicActionDescription,
          type: 'progression',
          new: true
        });
      }
    }

    if (spellsEnabled) {
      if (spellId && spellId !== 'new') {
        // Modify casting of existing spell
        const spellIndex = _.findIndex(warrior.spells, { id: parseInt(spellId, 10) });
        const selectedSpell = warrior.spells[spellIndex];
        selectedSpell.modifyCastingValue += 1;
        warrior.spells[spellIndex] = selectedSpell;
      } else if (mySpellsId && mySpellsId !== 'new') {
        // Add saved spell
        const selectedSpell = spells[mySpellsId];
        warrior.spells.push({
          id: `${warrior.id}-${selectedSpell.name}`,
          warriorId: warrior.id,
          mySpellsId,
          name: selectedSpell.name,
          description: selectedSpell.description,
          baseCastingValue: spellBaseCastingValue,
          modifyCastingValue: 0,
          type: 'progression',
          new: true
        });
      } else {
        // Add new spell
        warrior.spells.push({
          id: `${warrior.id}-${spellName}`,
          warriorId: warrior.id,
          name: spellName,
          description: spellDescription,
          baseCastingValue: spellBaseCastingValue,
          modifyCastingValue: 0,
          type: 'progression',
          new: true
        });
      }
    }

    if (warriorResult === 'hero') {
      warrior.rank = 'Hero';
      if (warrior.baseStats.fate === 0) {
        warrior.modifyStats.fate = 1;
        warrior.totalStats.fate = 1;
      }
    }

    if (warriorResult === 'promoted') {
      warrior.baseClass = warriorClass;
    }

    if (chosenPath) {
      warrior.chosenPath = chosenPath;
    }

    if (customPath) {
      warrior.customPath = customPath;
    }

    this.setState({
      warrior,
      chosenPath: '',
      customPath: '',
      statEnabled: false,
      adjustmentStat: '',
      ruleEnabled: false,
      myRuleId: '',
      ruleName: '',
      ruleDescription: '',
      heroicActionsEnabled: false,
      myHeroicActionsId: '',
      heroicActionName: '',
      heroicActionDescription: '',
      heroicActionBaseCastingValue: '',
      heroicActionId: '',
      spellsEnabled: false,
      mySpellsId: '',
      spellName: '',
      spellDescription: '',
      spellBaseCastingValue: '',
      spellId: '',
      warriorResult: '',
      warriorClass: '',
      timerRunning: false,
      reachedNewLevel: false,
      submitted: false
    });
  }

  onSubmit = () => {
    const { onSubmit } = this.props;
    const { warrior } = this.state;
    onSubmit(warrior);
  }

  setCounterTimer = (total, delay) => {
    const { warrior } = this.state;

    timer = window.setTimeout(() => {
      if (warrior.experienceIncrease > 0) {
        warrior.experience += 1;
      }
      warrior.experienceIncrease -= 1;
      const reachedNewLevel = warrior.experience % 5 === 0;
      this.setState({
        timerRunning: !reachedNewLevel,
        reachedNewLevel,
        warrior
      });
      timer = null;
      if (!reachedNewLevel && warrior.experienceIncrease >= 0) {
        this.setCounterTimer(total, 0);
      }
    }, 200 + delay);
  }

  render() {
    const {
      warrior, timerRunning, reachedNewLevel, activeTab
    } = this.state;
    const { active, rules, heroicActions, spells } = this.props;

    if (!active) {
      return <div />;
    }

    if (!timer && !reachedNewLevel && !timerRunning && warrior.experienceIncrease > 0) {
      this.setCounterTimer(warrior.experienceIncrease, 500);
    }

    if (reachedNewLevel) {
      return (
        <Fragment>
          <Form buttons={<Button text="Continue" primary iconName="arrow-right" iconRight onClick={this.submitProgression} />}>
            <h3>Level Gained</h3>
            <PromotionForm {...this.state} handleInputChange={this.handleInputChange} rules={rules} heroicActions={heroicActions} spells={spells} />
          </Form>
          <ExperienceBar experience={warrior.experience} />
        </Fragment>
      );
    }

    if (warrior.experienceIncrease > 0 || (warrior.experienceIncrease === 0 && timerRunning)) {
      return (
        <Fragment>
          <Form>
            <h3>Allocating Experience</h3>
            <span id="experience-counter" className="rosette shrink-in">{warrior.experienceIncrease}</span>
          </Form>
          <ExperienceBar experience={warrior.experience} />
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Form buttons={<Button text="Next" primary iconName="arrow-right" iconRight onClick={this.onSubmit} />}>
          <h3>Summary</h3>
          <div className="grow-in">
            <Tabs
              activeTab={activeTab}
              setActiveTab={this.setActiveTab}
              invertColor
              tabs={[{
                name: 'Experience',
                content: (
                  <Fragment>
                    <Table
                      headers={['Type', '']}
                      rows={this.experienceTableRows}
                      overviewTable
                      block
                    />
                    <Table
                      rows={[
                        ['Total', (warrior.woundsExperience + warrior.participationExperience + warrior.winExperience + (warrior.bonusExperience || 0))]
                      ]}
                      overviewTable
                      block
                    />
                  </Fragment>
                )
              }, {
                name: 'Promotions',
                content: (
                  <Fragment>
                    <Table
                      headers={['Type', '']}
                      rows={this.levelTableRows}
                      overviewTable
                      block
                    />
                    <Table
                      rows={[
                        ['Total', (warrior.levelChange + (warrior.bonusLevel ? 1 : 0))]
                      ]}
                      overviewTable
                      block
                    />
                  </Fragment>
                )
              }]}
            />
          </div>
        </Form>
        <ExperienceBar experience={warrior.experience} />
      </Fragment>
    );
  }
}

const ExperienceBar = ({ experience }) => (
  <div className="card__experience">
    <span className="card__experience__level">{Math.floor(experience / 5) + 1}</span>
    <span className="experience-bar-title">Warrior Level</span>
    <div className={`experience-bar experience-bar--${experience % 5}`}>
      <div className="experience-bar__inner" />
    </div>
  </div>
);

const PromotionForm = ({
  warrior, chosenPath, customPath, statEnabled, adjustmentStat, ruleEnabled, myRuleId, ruleName, ruleDescription,
  heroicActionsEnabled, heroicActionId, myHeroicActionsId, heroicActionName, heroicActionDescription,
  spellsEnabled, spellId, mySpellsId, spellName, spellDescription, spellBaseCastingValue,
  warriorResult, warriorClass, handleInputChange, submitted, rules, heroicActions, spells
}) => {
  if (isHero(warrior)) {
    const {
      fight, shoot, strength, defence, attacks, wounds, courage, might, will, fate
    } = warrior.totalStats;
    return (
      <div className="grow-in">
        <p>
          <strong>{warrior.name}</strong> gained a level.
          {warrior.chosenPath
            ? <Fragment> Roll on the <strong>Path of the {warrior.chosenPath}</strong> and record the result below.</Fragment>
            : <Fragment> Choose a path, and then roll on the table, recording the result below</Fragment>}
        </p>
        {!warrior.chosenPath && (
          <FormRow>
            <SelectInput name="chosenPath" label="Chosen Path" value={chosenPath} onChange={handleInputChange} showError={submitted && !warrior.chosenPath && !chosenPath}>
              <option value="">Please Select</option>
              <option value="Warrior">Warrior</option>
              <option value="Ranger">Ranger</option>
              <option value="General">General</option>
              <option value="Knight">Knight</option>
              <option value="Scout">Scout</option>
              <option value="Adventurer">Adventurer</option>
              <option value="Sorcerer">Sorcerer</option>
              <option value="Beast">Beast</option>
              <option value="Custom">Custom</option>
            </SelectInput>
            {chosenPath === 'Custom' && (
              <TextInput name="customPath" label="Custom Path" value={customPath} onChange={handleInputChange} showError={submitted && chosenPath === 'Custom' && !customPath} />
            )}
          </FormRow>
        )}
        <ToggleAccordion heading="Characteristic Change" name="statEnabled" value={statEnabled} onChange={handleInputChange}>
          <FormRow>
            <SelectInput name="adjustmentStat" label="" value={adjustmentStat} onChange={handleInputChange} showError={submitted && statEnabled && !adjustmentStat}>
              <option value="">Please Select</option>
              <option value="Fight">Fight ({fight}&nbsp;&nbsp;&#8594;&nbsp;&nbsp;{fight + 1})</option>
              <option value="Shoot">Shoot ({shoot}+&nbsp;&nbsp;&#8594;&nbsp;&nbsp;{shoot - 1}+)</option>
              <option value="Strength">Strength ({strength}&nbsp;&nbsp;&#8594;&nbsp;&nbsp;{strength + 1})</option>
              <option value="Defence">Defence ({defence}&nbsp;&nbsp;&#8594;&nbsp;&nbsp;{defence + 1})</option>
              <option value="Attacks">Attacks ({attacks}&nbsp;&nbsp;&#8594;&nbsp;&nbsp;{attacks + 1})</option>
              <option value="Wounds">Wounds ({wounds}&nbsp;&nbsp;&#8594;&nbsp;&nbsp;{wounds + 1})</option>
              <option value="Courage">Courage ({courage}&nbsp;&nbsp;&#8594;&nbsp;&nbsp;{courage + 1})</option>
              <option value="Might">Might ({might}&nbsp;&nbsp;&#8594;&nbsp;&nbsp;{might + 1})</option>
              <option value="Will">Will ({will}&nbsp;&nbsp;&#8594;&nbsp;&nbsp;{will + 1})</option>
              <option value="Fate">Fate ({fate}&nbsp;&nbsp;&#8594;&nbsp;&nbsp;{fate + 1})</option>
            </SelectInput>
          </FormRow>
        </ToggleAccordion>
        <ToggleAccordion heading="New Special Rule" name="ruleEnabled" value={ruleEnabled} onChange={handleInputChange}>
          <FormRow>
            <SelectInput name="myRuleId" label="Select Rule" value={myRuleId} onChange={handleInputChange} required showError={submitted && !myRuleId}>
              <option value="">Please Select</option>
              <option value="new">Create New Rule</option>
              {(!!rules && !!_.size(rules)) && (
              <optgroup label="Your Rules">
                {_.map(_.orderBy(rules, ['name']), (r) => {
                  const matchingRule = !!_.find(warrior.specialRules, { myRuleId: r.id });
                  return <option disabled={matchingRule} value={r.id} key={r.id}>{r.name}</option>;
                })}
              </optgroup>
              )}
            </SelectInput>
          </FormRow>
          {myRuleId === 'new' && (
          <Fragment>
            <FormRow>
              <TextInput name="ruleName" label="Name" value={ruleName} onChange={handleInputChange} showError={submitted && ruleEnabled && !ruleName} />
            </FormRow>
            <FormRow>
              <TextInput name="ruleDescription" label="Description" value={ruleDescription} onChange={handleInputChange} textarea />
            </FormRow>
          </Fragment>
          )}
        </ToggleAccordion>
        <ToggleAccordion heading="New Heroic Action" name="heroicActionsEnabled" value={heroicActionsEnabled} onChange={handleInputChange}>
          <FormRow>
            <SelectInput name="myHeroicActionsId" label="Select Heroic Action" value={myHeroicActionsId} onChange={handleInputChange} required showError={submitted && !myHeroicActionsId}>
              <option value="">Please Select</option>
              <option value="new">Create New Heroic Action</option>
              {(!!heroicActions && !!_.size(heroicActions)) && (
              <optgroup label="Your Heroic Actions">
                {_.map(_.orderBy(heroicActions, ['name']), (r) => {
                  const matchingHeroicAction = !!_.find(warrior.heroicActions, { myHeroicActionsId: r.id });
                  return <option disabled={matchingHeroicAction} value={r.id} key={r.id}>{r.name}</option>;
                })}
              </optgroup>
              )}
            </SelectInput>
          </FormRow>
          {myHeroicActionsId === 'new' && (
          <Fragment>
            <FormRow>
              <TextInput name="heroicActionName" label="Name" value={heroicActionName} onChange={handleInputChange} showError={submitted && heroicActionsEnabled && !heroicActionName} />
            </FormRow>
            <FormRow>
              <TextInput name="heroicActionDescription" label="Description" value={heroicActionDescription} onChange={handleInputChange} textarea />
            </FormRow>
          </Fragment>
          )}
        </ToggleAccordion>
        <ToggleAccordion heading="Spells" name="spellsEnabled" value={spellsEnabled} onChange={handleInputChange}>
          <FormRow>
            <SelectInput name="spellId" label="Select Spell" value={spellId} onChange={handleInputChange} required showError={submitted && !spellId}>
              <option value="">Please Select</option>
              <option value="new">Add New Spell</option>
              {(!!warrior.spells && !!_.size(warrior.spells)) && (
              <optgroup label="Current Spells">
                {_.map(_.orderBy(warrior.spells, ['name']), (s) => {
                  const castingValue = s.baseCastingValue - s.modifyCastingValue;
                  return <option value={s.id} key={s.id}>{s.name} ({castingValue}+&nbsp;&nbsp;&#8594;&nbsp;&nbsp;{castingValue - 1}+)</option>;
                })}
              </optgroup>
              )}
            </SelectInput>
          </FormRow>
          {spellId === 'new' && (
          <Fragment>
            <FormRow>
              <SelectInput name="mySpellsId" label="Load Spell" value={mySpellsId} onChange={handleInputChange} required showError={submitted && !mySpellsId}>
                <option value="">Please Select</option>
                <option value="new">Create New Spell</option>
                {(!!spells && !!_.size(spells)) && (
                <optgroup label="Your Spells">
                  {_.map(_.orderBy(spells, ['name']), (r) => {
                    const matchingSpell = !!_.find(warrior.spells, { mySpellsId: r.id });
                    return <option disabled={matchingSpell} value={r.id} key={r.id}>{r.name}</option>;
                  })}
                </optgroup>
                )}
              </SelectInput>
            </FormRow>
            {mySpellsId === 'new' && (
            <Fragment>
              <FormRow>
                <TextInput name="spellName" label="Name" value={spellName} onChange={handleInputChange} required showError={submitted && !spellName} />
              </FormRow>
              <FormRow>
                <TextInput name="spellDescription" label="Description" value={spellDescription} onChange={handleInputChange} textarea />
              </FormRow>
            </Fragment>
            )}
            <FormRow cls="row--modify-casting-value">
              <SelectInput name="spellBaseCastingValue" label="Casting Value" value={spellBaseCastingValue} onChange={handleInputChange} required showError={submitted && !spellBaseCastingValue}>
                <option value="">Base</option>
                <option value="6">6+</option>
                <option value="5">5+</option>
                <option value="4">4+</option>
                <option value="3">3+</option>
                <option value="2">2+</option>
              </SelectInput>
            </FormRow>
          </Fragment>
          )}
        </ToggleAccordion>
        {(submitted && !statEnabled && !ruleEnabled && !heroicActionsEnabled && !spellsEnabled) && <span className="field-validation-error">Please select a progression perk.</span>}
      </div>
    );
  }
  return (
    <div className="grow-in">
      <p>
        <strong>{warrior.name}</strong> gained a level.
        Roll on the warrior promotion chart and record the result below.
      </p>
      <FormRow>
        <SelectInput name="warriorResult" label="Result" value={warriorResult} onChange={handleInputChange}>
          <option value="">Please Select</option>
          <option value="noChange">No Change</option>
          <option value="promoted">Promoted</option>
          <option value="hero">A Hero in the Making</option>
        </SelectInput>
      </FormRow>
      {warriorResult === 'promoted' && (
        <FormRow>
          <TextInput name="warriorClass" label="New Class" value={warriorClass} onChange={handleInputChange} />
        </FormRow>
      )}
    </div>
  );
};

ExperienceForm.propTypes = {
  warrior: WarriorType.isRequired,
  onSubmit: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  rules: PropTypes.oneOfType([
    PropTypes.objectOf(RuleType),
    PropTypes.objectOf(PropTypes.bool)
  ]).isRequired,
  heroicActions: PropTypes.oneOfType([
    PropTypes.objectOf(HeroicActionType),
    PropTypes.objectOf(PropTypes.bool)
  ]).isRequired,
  spells: PropTypes.oneOfType([
    PropTypes.objectOf(SpellType),
    PropTypes.objectOf(PropTypes.bool)
  ]).isRequired
};

ExperienceBar.propTypes = {
  experience: PropTypes.number.isRequired
};

PromotionForm.propTypes = {
  warrior: WarriorType.isRequired,
  statEnabled: PropTypes.bool.isRequired,
  adjustmentStat: PropTypes.string.isRequired,
  ruleEnabled: PropTypes.bool.isRequired,
  myRuleId: PropTypes.string.isRequired,
  ruleName: PropTypes.string.isRequired,
  ruleDescription: PropTypes.string.isRequired,
  heroicActionsEnabled: PropTypes.bool.isRequired,
  heroicActionId: PropTypes.string.isRequired,
  myHeroicActionsId: PropTypes.string.isRequired,
  heroicActionName: PropTypes.string.isRequired,
  heroicActionDescription: PropTypes.string.isRequired,
  heroicActionBaseCastingValue: PropTypes.string.isRequired,
  spellsEnabled: PropTypes.bool.isRequired,
  spellId: PropTypes.string.isRequired,
  mySpellsId: PropTypes.string.isRequired,
  spellName: PropTypes.string.isRequired,
  spellDescription: PropTypes.string.isRequired,
  spellBaseCastingValue: PropTypes.string.isRequired,
  warriorResult: PropTypes.string.isRequired,
  warriorClass: PropTypes.string.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  submitted: PropTypes.bool.isRequired,
  chosenPath: PropTypes.string.isRequired,
  customPath: PropTypes.string.isRequired,
  rules: PropTypes.oneOfType([
    PropTypes.objectOf(RuleType),
    PropTypes.objectOf(PropTypes.bool)
  ]).isRequired,
  heroicActions: PropTypes.oneOfType([
    PropTypes.objectOf(HeroicActionType),
    PropTypes.objectOf(PropTypes.bool)
  ]).isRequired,
  spells: PropTypes.oneOfType([
    PropTypes.objectOf(SpellType),
    PropTypes.objectOf(PropTypes.bool)
  ]).isRequired
};

export default ExperienceForm;
