import { authHeader } from '../_helpers/authHeader';
import { apiUrl } from '../_constants';
import { handleResponse } from '.';
import 'whatwg-fetch';

function getWarriors() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${apiUrl}/graveyard`, requestOptions).then(handleResponse);
}

function updateGraveyardWarrior(warrior) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(warrior)
  };

  return fetch(`${apiUrl}/graveyard/${warrior.id}`, requestOptions).then(handleResponse);
}

function deleteGraveyardWarrior(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };

  return fetch(`${apiUrl}/graveyard/${id}`, requestOptions).then(handleResponse);
}

// eslint-disable-next-line import/prefer-default-export
export const graveyardService = {
  getWarriors,
  updateGraveyardWarrior,
  deleteGraveyardWarrior
};
