import { companyService } from '../_services/company';
import { alertActions } from './alert';
import { history } from '../_helpers/history';

export const companyConstants = {
  GET_REQUEST: 'COMPANIES_GET_REQUEST',
  GET_SUCCESS: 'COMPANIES_GET_SUCCESS',
  GET_FAILURE: 'COMPANIES_GET_FAILURE',

  GET_SHARED_REQUEST: 'COMPANIES_GET_SHARED_REQUEST',
  GET_SHARED_SUCCESS: 'COMPANIES_GET_SHARED_SUCCESS',
  GET_SHARED_FAILURE: 'COMPANIES_GET_SHARED_FAILURE',

  CREATE_REQUEST: 'COMPANY_CREATE_REQUEST',
  CREATE_SUCCESS: 'COMPANY_CREATE_SUCCESS',
  CREATE_FAILURE: 'COMPANY_CREATE_FAILURE',

  UPDATE_REQUEST: 'COMPANY_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'COMPANY_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'COMPANY_UPDATE_FAILURE',

  RETIRE_REQUEST: 'COMPANY_RETIRE_REQUEST',
  RETIRE_SUCCESS: 'COMPANY_RETIRE_SUCCESS',
  RETIRE_FAILURE: 'COMPANY_RETIRE_FAILURE',

  DELETE_REQUEST: 'COMPANY_DELETE_REQUEST',
  DELETE_SUCCESS: 'COMPANY_DELETE_SUCCESS',
  DELETE_FAILURE: 'COMPANY_DELETE_FAILURE',

  BATTLE_SUCCESS: 'COMPANY_BATTLE_SUCCESS'
};

function getUserCompanies() {
  function request() { return { type: companyConstants.GET_REQUEST }; }
  function success(companies) { return { type: companyConstants.GET_SUCCESS, companies }; }
  function failure(error) { return { type: companyConstants.GET_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    companyService.getUserCompanies()
      .then(
        companies => dispatch(success(companies)),
        error => dispatch(failure(error.toString()))
      );
  };
}

function createCompany(company) {
  function request(companyResponse) {
    return { type: companyConstants.CREATE_REQUEST, companyResponse };
  }
  function success(companyResponse) {
    return { type: companyConstants.CREATE_SUCCESS, companyResponse };
  }
  function failure(error) { return { type: companyConstants.CREATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(company));

    companyService.createCompany(company)
      .then(
        (companyResponse) => {
          dispatch(success(companyResponse));
          history.push(`/company/${companyResponse.id}`);
          dispatch(alertActions.success(`${companyResponse.name} created successfully`));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };
}

function updateCompany(company, callback) {
  function request(companyResponse) {
    return { type: companyConstants.UPDATE_REQUEST, companyResponse };
  }
  function success(companyResponse) {
    return { type: companyConstants.UPDATE_SUCCESS, companyResponse };
  }
  function failure(error) { return { type: companyConstants.UPDATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(company));

    companyService.updateCompany(company)
      .then(
        (companyResponse) => {
          dispatch(success(companyResponse));
          dispatch(alertActions.success(`${companyResponse.name} updated succesfully`));
          if (callback) callback();
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };
}

function retireCompany(id, retire) {
  function request(idResponse) { return { type: companyConstants.RETIRE_REQUEST, idResponse }; }
  function success(idResponse) { return { type: companyConstants.RETIRE_SUCCESS, idResponse }; }
  function failure(idResponse, error) {
    return { type: companyConstants.RETIRE_FAILURE, idResponse, error };
  }

  return (dispatch) => {
    dispatch(request(id));

    companyService.retireCompany(id, retire)
      .then(
        (idResponse) => {
          dispatch(success(idResponse));
          dispatch(getUserCompanies());
          history.push(retire ? '/retired' : '/companies');
          dispatch(alertActions.success('Company retired successfully'));
        },
        error => dispatch(failure(id, error.toString()))
      );
  };
}

function deleteCompany(id) {
  function request(idResponse) { return { type: companyConstants.DELETE_REQUEST, idResponse }; }
  function success(idResponse) { return { type: companyConstants.DELETE_SUCCESS, idResponse }; }
  function failure(idResponse, error) {
    return { type: companyConstants.DELETE_FAILURE, idResponse, error };
  }

  return (dispatch) => {
    dispatch(request(id));

    companyService.deleteCompany(id)
      .then(
        (idResponse) => {
          dispatch(success(idResponse));
          dispatch(getUserCompanies());
          history.push('/companies');
          dispatch(alertActions.success('Company deleted successfully'));
        },
        error => dispatch(failure(id, error.toString()))
      );
  };
}

function saveBattleResult(company, warriors) {
  return (dispatch) => {
    companyService.saveBattleResult(company, warriors)
      .then(
        () => {
          dispatch(getUserCompanies());
          history.push(`/company/${company.id}`);
          dispatch(alertActions.success(`${company.name} updated succesfully`));
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
        }
      );
  };
}

function getSharedCompany(userId, companyId) {
  function request() { return { type: companyConstants.GET_SHARED_REQUEST }; }
  function success(company) { return { type: companyConstants.GET_SHARED_SUCCESS, company }; }
  function failure(error) { return { type: companyConstants.GET_SHARED_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    companyService.getSharedCompany(userId, companyId)
      .then(
        company => dispatch(success(company)),
        error => dispatch(failure(error.toString()))
      );
  };
}

// eslint-disable-next-line import/prefer-default-export
export const companyActions = {
  getUserCompanies,
  createCompany,
  updateCompany,
  retireCompany,
  deleteCompany,
  saveBattleResult,
  getSharedCompany
};
