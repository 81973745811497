import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import './styles.scss';

class Tabs extends Component {
  renderTabControl(name, index) {
    const { activeTab, setActiveTab } = this.props;
    const isActive = activeTab === index;
    return (
      <li className={isActive ? 'active' : ''} key={index}>
        <button onClick={() => setActiveTab(index)} type="button">{name}</button>
      </li>
    );
  }

  renderTabContent() {
    const { activeTab, tabs } = this.props;
    if (activeTab == null) {
      return <div />;
    }
    return (
      <div className="tabs__content tabs__content--active">
        {tabs[activeTab].content}
      </div>
    );
  }

  render() {
    const {
      tabs, invertColor, textPageTabs, stretchTabs, largeTabs
    } = this.props;
    return (
      <div className={`tabs ${invertColor ? 'tabs--invert' : ''} ${textPageTabs ? 'tabs--text' : ''}`}>
        <ul className={`tabs__controls ${stretchTabs ? 'tabs__controls--stretch' : ''} ${largeTabs ? 'tabs__controls--large' : ''}`}>
          {_.map(tabs, (tab, index) => this.renderTabControl(tab.name, index))}
        </ul>
        {this.renderTabContent()}
      </div>
    );
  }
}

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    content: PropTypes.node
  })).isRequired,
  activeTab: PropTypes.number,
  setActiveTab: PropTypes.func.isRequired,
  invertColor: PropTypes.bool,
  textPageTabs: PropTypes.bool,
  stretchTabs: PropTypes.bool,
  largeTabs: PropTypes.bool
};

Tabs.defaultProps = {
  activeTab: null,
  invertColor: false,
  textPageTabs: false,
  stretchTabs: false,
  largeTabs: false
};

export default Tabs;
