/* eslint-disable react/destructuring-assignment */
import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { RuleType, WarriorType } from '../../../_types';

import { emptySpecialRule } from '../../../_constants';

import {
  Button, Accordion, FormRow, RadioInput, TextInput, SelectInput, CheckboxListInput
} from '../..';

class RuleForm extends Component {
  constructor(props) {
    super(props);
    const { rule } = props;

    let myRuleId = '';
    if (rule.name && !rule.myRuleId) {
      myRuleId = 'new';
    } else if (rule.myRuleId) {
      // eslint-disable-next-line prefer-destructuring
      myRuleId = rule.myRuleId;
    }

    this.state = {
      rule: {
        id: rule.id,
        myRuleId,
        type: rule.type,
        name: rule.name,
        description: rule.description,
        addToMyRules: false
      },
      submitted: false
    };
  }

  handleInputChange = (event) => {
    const {
      target: {
        type, name, checked, value
      }
    } = event;
    const val = type === 'checkbox' ? checked : value;

    const { rule } = this.state;
    this.setState({
      rule: {
        ...rule,
        [name]: val
      }
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { onSubmit, profile, rules } = this.props;
    const { rule } = this.state;

    if (!rule.myRuleId || (rule.myRuleId === 'new' && !rule.name) || (!profile && !rule.type)) {
      this.setState({
        submitted: true
      });
      return;
    }

    if (rule.myRuleId && rule.myRuleId !== 'new') {
      const myRule = rules[rule.myRuleId];
      rule.name = myRule.name;
      rule.description = myRule.description;
    }

    if (rule.id) {
      onSubmit(rule);
    } else {
      onSubmit(rule);
      this.setState({
        rule: {
          ...emptySpecialRule,
          addToMyRules: false
        },
        submitted: false
      });
    }
  }

  render() {
    const {
      onSubmit, rule, rules, warrior, profile
    } = this.props;
    const {
      rule: {
        id,
        myRuleId,
        type,
        name,
        description,
        addToMyRules
      },
      submitted
    } = this.state;
    return (
      <form onSubmit={this.handleSubmit} id={`${id === 0 ? 'new-rule' : ''}`}>
        <Accordion heading={rule.name || 'New Rule'} newItem={id === 0}>
          {!profile && (
          <FormRow>
            <RadioInput
              name="type"
              label="Type"
              value={type}
              options={[{
                label: 'Included in base profile',
                value: 'base'
              }, {
                label: 'Gained through progression',
                value: 'progression'
              }]}
              onChange={this.handleInputChange}
              required
              showError={submitted && !type}
            />
          </FormRow>
          )}
          <FormRow>
            <SelectInput name="myRuleId" label="Load Rule" value={myRuleId} onChange={this.handleInputChange} required showError={submitted && !myRuleId}>
              <option value="">Please Select</option>
              <option value="new">Create New Rule</option>
              <optgroup label="Your Rules">
                {_.map(_.orderBy(rules, ['name']), (r) => {
                  const matchingRule = !!_.find(warrior.specialRules, { myRuleId: r.id }) && rule.myRuleId !== r.id;
                  return <option disabled={matchingRule} value={r.id} key={r.id}>{r.name}</option>;
                })}
              </optgroup>
            </SelectInput>
          </FormRow>
          {myRuleId === 'new' && (
          <Fragment>
            <FormRow>
              <TextInput name="name" label="Name" value={name} onChange={this.handleInputChange} required showError={submitted && !name} />
            </FormRow>
            <FormRow>
              <TextInput name="description" label="Description" value={description} onChange={this.handleInputChange} textarea />
            </FormRow>
          </Fragment>
          )}
          <div className="form__inner-footer">
            {(myRuleId === 'new' && !id) && (
            <FormRow cls="row--cb-save">
              <CheckboxListInput
                options={[{ name: 'addToMyRules', label: 'Save to My Rules', value: addToMyRules }]}
                onChange={this.handleInputChange}
              />
            </FormRow>
            )}
            {id !== 0 && (<Button text="Remove" destructive small floatRight type="button" onClick={() => onSubmit(this.state.rule, true)} />)}
            <Button text={id ? 'Update' : 'Create'} primary small floatRight type="submit" />
          </div>
        </Accordion>
      </form>
    );
  }
}

RuleForm.propTypes = {
  rule: RuleType.isRequired,
  rules: PropTypes.objectOf(RuleType.isRequired).isRequired,
  warrior: WarriorType.isRequired,
  onSubmit: PropTypes.func.isRequired,
  profile: PropTypes.bool.isRequired
};

export default RuleForm;
