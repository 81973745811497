export const good = [
  {
    name: 'Minas Tirith',
    image: 'https://battle-companies-manager.s3.eu-west-2.amazonaws.com/races/generic.png'
  },
  {
    name: 'Osgiliath',
    image: 'https://battle-companies-manager.s3.eu-west-2.amazonaws.com/races/generic.png'
  },
  {
    name: 'Fiefdoms',
    image: 'https://battle-companies-manager.s3.eu-west-2.amazonaws.com/races/generic.png'
  },
  {
    name: 'Rohan',
    image: 'https://battle-companies-manager.s3.eu-west-2.amazonaws.com/races/generic.png'
  },
  {
    name: 'Men of the West',
    image: 'https://battle-companies-manager.s3.eu-west-2.amazonaws.com/races/generic.png'
  },
  {
    name: 'Dead of Dunharrow',
    image: 'https://battle-companies-manager.s3.eu-west-2.amazonaws.com/races/generic.png'
  },
  {
    name: 'Arnor',
    image: 'https://battle-companies-manager.s3.eu-west-2.amazonaws.com/races/generic.png'
  },
  {
    name: 'Rangers of the North',
    image: 'https://battle-companies-manager.s3.eu-west-2.amazonaws.com/races/generic.png'
  },
  {
    name: 'Lake-Town',
    image: 'https://battle-companies-manager.s3.eu-west-2.amazonaws.com/races/generic.png'
  },
  {
    name: 'The Last Alliance',
    image: 'https://battle-companies-manager.s3.eu-west-2.amazonaws.com/races/generic.png'
  },
  {
    name: 'Erebor and Dale',
    image: 'https://battle-companies-manager.s3.eu-west-2.amazonaws.com/races/generic.png'
  },
  {
    name: 'Lothlorien',
    image: 'https://battle-companies-manager.s3.eu-west-2.amazonaws.com/races/generic.png'
  },
  {
    name: 'Rivendell',
    image: 'https://battle-companies-manager.s3.eu-west-2.amazonaws.com/races/generic.png'
  },
  {
    name: 'Halls of Thranduil',
    image: 'https://battle-companies-manager.s3.eu-west-2.amazonaws.com/races/generic.png'
  },
  {
    name: 'Iron Hills',
    image: 'https://battle-companies-manager.s3.eu-west-2.amazonaws.com/races/generic.png'
  },
  {
    name: "Durin's Folk",
    image: 'https://battle-companies-manager.s3.eu-west-2.amazonaws.com/races/generic.png'
  },
  {
    name: 'Moria Expedition',
    image: 'https://battle-companies-manager.s3.eu-west-2.amazonaws.com/races/generic.png'
  },
  {
    name: 'The Shire',
    image: 'https://battle-companies-manager.s3.eu-west-2.amazonaws.com/races/generic.png'
  },
  {
    name: 'Wanderers in the Wild',
    image: 'https://battle-companies-manager.s3.eu-west-2.amazonaws.com/races/generic.png'
  }
];

export const evil = [
  {
    name: 'Mordor',
    image: 'https://battle-companies-manager.s3.eu-west-2.amazonaws.com/races/generic.png'
  },
  {
    name: 'Cirith Ungol',
    image: 'https://battle-companies-manager.s3.eu-west-2.amazonaws.com/races/generic.png'
  },
  {
    name: 'Minas Morgul',
    image: 'https://battle-companies-manager.s3.eu-west-2.amazonaws.com/races/generic.png'
  },
  {
    name: 'Isengard',
    image: 'https://battle-companies-manager.s3.eu-west-2.amazonaws.com/races/generic.png'
  },
  {
    name: 'Warg Riders',
    image: 'https://battle-companies-manager.s3.eu-west-2.amazonaws.com/races/generic.png'
  },
  {
    name: 'Dunland',
    image: 'https://battle-companies-manager.s3.eu-west-2.amazonaws.com/races/generic.png'
  },
  {
    name: "Sharkey's Rogues",
    image: 'https://battle-companies-manager.s3.eu-west-2.amazonaws.com/races/generic.png'
  },
  {
    name: 'Moria',
    image: 'https://battle-companies-manager.s3.eu-west-2.amazonaws.com/races/generic.png'
  },
  {
    name: 'Goblin-Town',
    image: 'https://battle-companies-manager.s3.eu-west-2.amazonaws.com/races/generic.png'
  },
  {
    name: 'Angmar',
    image: 'https://battle-companies-manager.s3.eu-west-2.amazonaws.com/races/generic.png'
  },
  {
    name: 'Gundabad',
    image: 'https://battle-companies-manager.s3.eu-west-2.amazonaws.com/races/generic.png'
  },
  {
    name: 'Dol Guldur',
    image: 'https://battle-companies-manager.s3.eu-west-2.amazonaws.com/races/generic.png'
  },
  {
    name: 'Denizens of Mirkwood',
    image: 'https://battle-companies-manager.s3.eu-west-2.amazonaws.com/races/generic.png'
  },
  {
    name: 'Harad',
    image: 'https://battle-companies-manager.s3.eu-west-2.amazonaws.com/races/generic.png'
  },
  {
    name: 'Karna',
    image: 'https://battle-companies-manager.s3.eu-west-2.amazonaws.com/races/generic.png'
  },
  {
    name: 'Far Harad',
    image: 'https://battle-companies-manager.s3.eu-west-2.amazonaws.com/races/generic.png'
  },
  {
    name: 'Corsairs of Umbar',
    image: 'https://battle-companies-manager.s3.eu-west-2.amazonaws.com/races/generic.png'
  },
  {
    name: 'The Easterlings',
    image: 'https://battle-companies-manager.s3.eu-west-2.amazonaws.com/races/generic.png'
  },
  {
    name: 'Black Dragons',
    image: 'https://battle-companies-manager.s3.eu-west-2.amazonaws.com/races/generic.png'
  },
  {
    name: 'Khand',
    image: 'https://battle-companies-manager.s3.eu-west-2.amazonaws.com/races/generic.png'
  }
];

export const custom = [
  {
    name: 'Custom',
    image: 'https://battle-companies-manager.s3.eu-west-2.amazonaws.com/races/generic.png'
  }
]