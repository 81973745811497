/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-restricted-globals */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import ProfileCard from './ProfileCard';
import RuleCard from './RuleCard';
import SpellCard from './SpellCard';
import HeroicActionCard from './HeroicActionCard';
import WargearCard from './WargearCard';
import WarriorCard from './WarriorCard';

import './styles.scss';
import blood from '../../_assets/blood.png';

class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      overlayOpen: false
    };
  }

  openOverlay = (e) => {
    const { clickable } = this.props;
    const { target } = e;
    const isRulesItem = target.classList.contains('card__rules__item');
    const isRulesOverlay = !!target.closest('#wargear-details');
    if (!clickable || isRulesItem || isRulesOverlay) return;
    this.setState({
      overlayOpen: true
    });
  }

  closeOverlay = (hasEdited) => {
    if (hasEdited === true) {
      if (confirm(`You have unsaved changes, all unsaved changes will be lost. Are you sure you wish to continue?`)) {
        this.setState({
          overlayOpen: false
        });
      }
    } else {
      this.setState({
        overlayOpen: false
      });
    }
  }

  render() {
    const {
      id, title, tab, tabClass, printStatus, points, backText,
      showBlood, overlay, clickable, className, children
    } = this.props;
    const { overlayOpen } = this.state;

    return (
      <Fragment>
        <div id={`card-${id}`} className={`card ${className}${clickable ? ' card--clickable' : ''}`} onClick={this.openOverlay}>
          <div className="card__inner">
            {tab && <span className={`card__rank${tabClass ? ` card__rank--${tabClass}` : ''}`}>{tab}</span>}
            {printStatus}
            <div className="card__front">
              <div className="card__header">
                <span className="card__header__name">{title}</span>
                {points && (
                <span className="card__header__points">
                  {points}
                  <small>pts</small>
                </span>
                )}
              </div>
              {children}
            </div>
            <div className="card__back">
              <span>{title}</span>
              {showBlood && <img src={blood} className="blood" alt="Blood" />}
              {!!backText && <span>{backText}</span>}
            </div>
          </div>
        </div>
        {overlayOpen && React.cloneElement(overlay, { handleClose: this.closeOverlay })}
      </Fragment>
    );
  }
}

Card.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  tab: PropTypes.string,
  tabClass: PropTypes.string,
  printStatus: PropTypes.node,
  points: PropTypes.number,
  backText: PropTypes.string,
  showBlood: PropTypes.bool,
  overlay: PropTypes.node.isRequired,
  clickable: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

Card.defaultProps = {
  tab: '',
  tabClass: '',
  printStatus: null,
  points: null,
  backText: '',
  showBlood: false,
  clickable: false,
  className: ''
};

export {
  Card,
  ProfileCard,
  RuleCard,
  SpellCard,
  HeroicActionCard,
  WargearCard,
  WarriorCard
};
