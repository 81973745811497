import React, { Fragment } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { WarriorsType, SortOptionType } from '../../_types';
import { emptyWarrior } from '../../_constants';

import EditWarriorOverlay from '../Overlay/EditWarriorOverlay';
import {
  Grid,
  WarriorCard,
  NewCard,
  Advert
} from '..';

import warriorIcon from '../../_assets/warrior.svg';

const WarriorList = ({
  warriors, companyId, showNewCard, clickable, editable, battleMode, isGraveyard, alterStat, sortOption
}) => {
  let orderedWariors;
  const rankedWarriors = _.map(warriors, (warrior) => {
    const w = { ...warrior };
    switch (warrior.rank) {
      case 'Leader': w.sortRank = 1; break;
      case 'Sergeant': w.sortRank = 2; break;
      case 'Hero': w.sortRank = 3; break;
      case 'Warrior': w.sortRank = 4; break;
      case 'Wanderer': w.sortRank = 5; break;
      default: w.sortRank = 6;
    }
    return w;
  });

  if (battleMode || sortOption.value === 'default') {
    orderedWariors = _.orderBy(rankedWarriors, ['sortRank', 'id'], ['asc', 'asc']);
  } else {
    orderedWariors = _.orderBy(rankedWarriors, [sortOption.value, o => o.name.toLowerCase()], [sortOption.mode, 'desc']);
  }

  const cId = parseInt(companyId, 10);
  let i = 0;

  return (
    <Grid className="warrior-grid">
      {_.map(orderedWariors, warrior => (
        <Fragment key={warrior.id}>
          <WarriorCard
            warrior={_.clone(warrior)}
            companyId={cId}
            flipped={(warrior.status === 'injured' || warrior.status === 'lost' || warrior.wounded || warrior.fled || warrior.preBattleInjury) && !isGraveyard}
            clickable={clickable}
            editable={editable}
            battleMode={battleMode}
            alterStat={alterStat}
            isGraveyard={isGraveyard}
          />
          {(!battleMode && (i++ % 4 === 0)) && <Advert isTall />}
        </Fragment>
      ))}
      {showNewCard && (
        <NewCard
          key="new"
          title="New Warrior"
          icon={warriorIcon}
          overlay={(
            <EditWarriorOverlay
              warrior={{ ..._.clone(emptyWarrior), companyId: cId }}
              companyId={cId}
              editable={editable}
            />
          )}
        />
      )}
    </Grid>
  );
};

WarriorList.propTypes = {
  warriors: WarriorsType.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  showNewCard: PropTypes.bool,
  battleMode: PropTypes.bool,
  isGraveyard: PropTypes.bool,
  alterStat: PropTypes.func,
  clickable: PropTypes.bool,
  editable: PropTypes.bool,
  sortOption: SortOptionType.isRequired
};

WarriorList.defaultProps = {
  showNewCard: false,
  battleMode: false,
  isGraveyard: false,
  clickable: false,
  editable: false,
  alterStat: () => {}
};

export default WarriorList;
