import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';

import { CompaniesType, WarriorsType } from '../_types';
import { warriorActions } from '../_actions';

import { Loader } from '../_components';
import BattleScreen from '../_components/BattleScreen';

class Battle extends Component {
  constructor(props) {
    super(props);
    const { match } = this.props;
    const { companyId } = match.params;
    this.getWarriors(companyId);
  }

  getWarriors(id) {
    const { getWarriorsAction, warriors } = this.props;
    if (warriors.loading) {
      getWarriorsAction(id);
    }
  }

  render() {
    const { companies, match, warriors } = this.props;
    const { companyId } = match.params;

    if (companies.loading || warriors.loading) {
      return <Loader />;
    }

    return (
      <BattleScreen warriors={warriors} company={companies[companyId]} />
    );
  }
}

Battle.propTypes = {
  companies: CompaniesType.isRequired,
  warriors: WarriorsType.isRequired,
  getWarriorsAction: PropTypes.func.isRequired,
  match: ReactRouterPropTypes.match.isRequired
};

function mapStateToProps({ companies, warriors }) {
  return { companies, warriors };
}

export default withRouter(connect(mapStateToProps,
  { getWarriorsAction: warriorActions.getWarriors })(Battle));
