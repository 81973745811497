import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { RulesItem, RulesItemType } from './RulesItem';

import './styles.scss';

const RulesList = ({ title, rules, clickable }) => {
  const cls = title.toLowerCase().replace(' ', '-');
  return (
    <div className={`card__rules card__rules--${cls}`}>
      <span className="card__rules__header">
        {title}
      </span>
      <div className="card__rules__inner">
        {rules.length
          ? _.map(rules, (item, index) => <RulesItem key={`${item.name}${index}`} item={item} clickable={clickable} />)
          : <span>&nbsp;</span>}
      </div>
    </div>
  );
};

RulesList.propTypes = {
  title: PropTypes.string.isRequired,
  rules: PropTypes.arrayOf(RulesItemType).isRequired,
  clickable: PropTypes.bool
};

RulesList.defaultProps = {
  clickable: false
};

export default RulesList;
