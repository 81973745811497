import React, { Fragment, useState } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { emptyProfile } from '../_constants';
import { WarriorsType } from '../_types';
import { useStoredSort } from '../_hooks/useStoredSort';

import EditWarriorOverlay from '../_components/Overlay/EditWarriorOverlay';
import {
  ProfileCard, NewCard, Grid, Loader, Advert, PageHeader
} from '../_components';

import warriorIcon from '../_assets/warrior.svg';

const storageName = 'sort-profiles';
const sortOptions = [
  { label: 'Name', value: o => o.baseClass.toLowerCase(), mode: 'asc' },
  { label: 'Name', value: o => o.baseClass.toLowerCase(), mode: 'desc' },
  { label: 'Points', value: 'totalPoints', mode: 'asc' },
  { label: 'Points', value: 'totalPoints', mode: 'desc' },
  { label: 'Race', value: 'race', mode: 'asc' },
  { label: 'Race', value: 'race', mode: 'desc' }
];

function Profiles({ profiles }) {
  const [sort, setSort] = useState(useStoredSort(sortOptions, 4, storageName));

  if (profiles.loading) {
    return <Loader />;
  }

  const orderedProfiles = _.orderBy(profiles, [sort.value, o => o.baseClass.toLowerCase()], [sort.mode, 'asc']);

  let i = 0;

  return (
    <Fragment>
      <Advert isWide />
      <PageHeader title="My Profiles" sortOption={sort} setSort={setSort} sortOptions={sortOptions} storageName={storageName} />
      <Grid className="profile-grid">
        {_.map(orderedProfiles, profile => (
          <Fragment key={profile.id}>
            <ProfileCard profile={_.clone(profile)} clickable />
            {(i++ % 4 === 0) && <Advert isTall />}
          </Fragment>
        ))}
        <NewCard
          key="new"
          title="New Profile"
          icon={warriorIcon}
          overlay={(
            <EditWarriorOverlay
              warrior={_.clone(emptyProfile)}
              editable={true}
              profile
            />
          )}
        />
      </Grid>
    </Fragment>
  );
}

Profiles.propTypes = {
  profiles: WarriorsType.isRequired
};

function mapStateToProps({ profiles }) {
  return { profiles };
}

export default withRouter(connect(mapStateToProps)(Profiles));
