/* eslint-disable react/destructuring-assignment */
import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { HeroicActionType, WarriorType } from '../../../_types';

import { emptyHeroicAction } from '../../../_constants';

import {
  Button, Accordion, FormRow, RadioInput, TextInput, SelectInput, CheckboxListInput
} from '../..';

class HeroicActionForm extends Component {
  constructor(props) {
    super(props);
    const { heroicAction } = props;

    let myHeroicActionsId = '';
    if (heroicAction.name && !heroicAction.myHeroicActionsId) {
      myHeroicActionsId = 'new';
    } else if (heroicAction.myHeroicActionsId) {
      // eslint-disable-next-line prefer-destructuring
      myHeroicActionsId = heroicAction.myHeroicActionsId;
    }

    this.state = {
      heroicAction: {
        id: heroicAction.id,
        myHeroicActionsId,
        type: heroicAction.type,
        name: heroicAction.name,
        description: heroicAction.description,
        addToMyHeroicActions: false
      },
      submitted: false
    };
  }

  handleInputChange = (event) => {
    const {
      target: {
        type, name, checked, value
      }
    } = event;
    const val = type === 'checkbox' ? checked : value;

    const { heroicAction } = this.state;
    this.setState({
      heroicAction: {
        ...heroicAction,
        [name]: val
      }
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { onSubmit, profile, heroicActions } = this.props;
    const { heroicAction } = this.state;

    if (!heroicAction.myHeroicActionsId || (heroicAction.myHeroicActionsId === 'new' && !heroicAction.name) || (!profile && !heroicAction.type)) {
      this.setState({
        submitted: true
      });
      return;
    }

    if (heroicAction.myHeroicActionsId && heroicAction.myHeroicActionsId !== 'new') {
      const myHeroicAction = heroicActions[heroicAction.myHeroicActionsId];
      heroicAction.name = myHeroicAction.name;
      heroicAction.description = myHeroicAction.description;
    }

    if (heroicAction.id) {
      onSubmit(heroicAction);
    } else {
      onSubmit(heroicAction);
      this.setState({
        heroicAction: {
          ...emptyHeroicAction,
          addToMyHeroicActions: false
        },
        submitted: false
      });
    }
  }

  render() {
    const {
      onSubmit, heroicAction, heroicActions, warrior, profile
    } = this.props;
    const {
      heroicAction: {
        id,
        myHeroicActionsId,
        type,
        name,
        description,
        addToMyHeroicActions
      },
      submitted
    } = this.state;
    return (
      <form onSubmit={this.handleSubmit} id={`${id === 0 ? 'new-heroic-action' : ''}`}>
        <Accordion heading={heroicAction.name ? heroicAction.name : 'New Heroic Action'} newItem={id === 0}>
          {!profile && (
          <FormRow>
            <RadioInput
              name="type"
              label="Type"
              value={type}
              options={[{
                label: 'Included in base profile',
                value: 'base'
              }, {
                label: 'Gained through progression',
                value: 'progression'
              }]}
              onChange={this.handleInputChange}
              required
              showError={submitted && !type}
            />
          </FormRow>
          )}
          <FormRow>
            <SelectInput name="myHeroicActionsId" label="Load Heroic Action" value={myHeroicActionsId} onChange={this.handleInputChange} required showError={submitted && !myHeroicActionsId}>
              <option value="">Please Select</option>
              <option value="new">Create New Heroic Action</option>
              <optgroup label="Your Heroic Actions">
                {_.map(_.orderBy(heroicActions, ['name']), (r) => {
                  const matchingHeroicAction = !!_.find(warrior.heroicActions, { myHeroicActionsId: r.id }) && heroicAction.myHeroicActionsId !== r.id;
                  return <option disabled={matchingHeroicAction} value={r.id} key={r.id}>{r.name}</option>;
                })}
              </optgroup>
            </SelectInput>
          </FormRow>
          {myHeroicActionsId === 'new' && (
          <Fragment>
            <FormRow>
              <TextInput name="name" label="Name" value={name} onChange={this.handleInputChange} required showError={submitted && !name} />
            </FormRow>
            <FormRow>
              <TextInput name="description" label="Description" value={description} onChange={this.handleInputChange} textarea />
            </FormRow>
          </Fragment>
          )}
          <div className="form__inner-footer">
            {(myHeroicActionsId === 'new' && !id) && (
            <FormRow cls="row--cb-save">
              <CheckboxListInput
                options={[{ name: 'addToMyHeroicActions', label: 'Save to My Heroic Actions', value: addToMyHeroicActions }]}
                onChange={this.handleInputChange}
              />
            </FormRow>
            )}
            {id !== 0 && (<Button text="Remove" destructive small floatRight type="button" onClick={() => onSubmit(this.state.heroicAction, true)} />)}
            <Button text={id ? 'Update' : 'Create'} primary small floatRight type="submit" />
          </div>
        </Accordion>
      </form>
    );
  }
}

HeroicActionForm.propTypes = {
  heroicAction: HeroicActionType.isRequired,
  heroicActions: PropTypes.objectOf(HeroicActionType.isRequired).isRequired,
  warrior: WarriorType.isRequired,
  onSubmit: PropTypes.func.isRequired,
  profile: PropTypes.bool.isRequired
};

export default HeroicActionForm;
