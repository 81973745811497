import React from 'react';
import PropTypes from 'prop-types';

import { SortOptionType } from '../../_types';

import { Sort } from '..';

function PageHeader({
  title, sortOption, setSort, sortOptions, storageName
}) {
  return (
    <div className="header">
      <div className="header__top">
        <div className="header__top__inner">
          <span className="header__name">{title}</span>
        </div>
        <div className="header__sort">
          <Sort sortOption={sortOption} setSort={setSort} sortOptions={sortOptions} storageName={storageName} />
        </div>
      </div>
    </div>
  );
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  sortOption: SortOptionType.isRequired,
  setSort: PropTypes.func.isRequired,
  sortOptions: PropTypes.arrayOf(SortOptionType).isRequired,
  storageName: PropTypes.string.isRequired
};

export default PageHeader;
