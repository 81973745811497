import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import CharacteristicsTable from './CharacteristicsTable';
import MWFTable from './MWFTable';

import './styles.scss';

class Table extends Component {
  generateTableClass() {
    const {
      fixedLayout, statsTable, overviewTable, className, small, overlay
    } = this.props;
    const cls = ['table'];
    if (fixedLayout) cls.push('table--fixed');
    if (statsTable) cls.push('table--stats');
    if (overviewTable) cls.push('table--overview');
    if (small) cls.push('table--small');
    if (overlay) cls.push('overlay__table');
    if (className) cls.push(className);
    return cls.join(' ');
  }

  render() {
    const { headers, rows } = this.props;
    return (
      <table className={this.generateTableClass(this.props)} cellPadding="0" cellSpacing="0">
        {!!headers && (
          <thead>
            <tr>
              {_.map(headers, (header, index) => <th key={index}>{header}</th>)}
            </tr>
          </thead>
        )}
        <tbody>
          {_.map(rows, (row, index) => (
            <tr key={index} className={row[0] === 'Total' ? 'total' : ''}>
              {_.map(row, (cell, cellIndex) => <td key={cellIndex}>{cell}</td>)}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

Table.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string),
  rows: PropTypes.arrayOf(PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.node])
  )).isRequired,
  className: PropTypes.string,
  fixedLayout: PropTypes.bool,
  statsTable: PropTypes.bool,
  overviewTable: PropTypes.bool,
  small: PropTypes.bool,
  overlay: PropTypes.bool
};

Table.defaultProps = {
  headers: [],
  className: '',
  fixedLayout: false,
  statsTable: false,
  overviewTable: false,
  small: false,
  overlay: false
};

export {
  Table,
  CharacteristicsTable,
  MWFTable
};
