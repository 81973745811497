/* eslint-disable react/jsx-one-expression-per-line */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { WarriorType, CompanyType } from '../../../_types';

import { emptyStats } from '../../../_constants';
import { isHero } from '../../../_helpers/warrior';

import {
  Tabs, Table, CharacteristicsTable, MWFTable, Button, Form, FormRow, TextInput
} from '../..';

const getCharacteristicsPoints = ({
  move, fight, shoot, strength, defence, attacks, wounds, courage
}) => {
  const characteristics = [];
  if (move > 0) characteristics.push([`+${move} Move`, <strong>{move * 5}</strong>]);
  if (fight > 0) characteristics.push([`+${fight} Fight`, <strong>{fight * 5}</strong>]);
  if (shoot > 0) characteristics.push([`+${shoot} Shoot`, <strong>0</strong>]);
  if (strength > 0) characteristics.push([`+${strength} Strength`, <strong>{strength * 5}</strong>]);
  if (defence > 0) characteristics.push([`+${defence} Defence`, <strong>{defence * 5}</strong>]);
  if (attacks > 0) characteristics.push([`+${attacks} Attacks`, <strong>{attacks * 10}</strong>]);
  if (wounds > 0) characteristics.push([`+${wounds} Wounds`, <strong>{wounds * 10}</strong>]);
  if (courage > 0) characteristics.push([`+${courage} Courage`, <strong>{courage * 5}</strong>]);
  return characteristics;
};

const getMWFPoints = ({ might, will, fate }) => {
  const characteristics = [];
  if (might > 0) characteristics.push([`+${might} Might`, <strong>{might * 5}</strong>]);
  if (will > 0) characteristics.push([`+${will} Will`, <strong>{will * 5}</strong>]);
  if (fate > 0) characteristics.push([`+${fate} Fate`, <strong>{fate * 5}</strong>]);
  return characteristics;
};

const getWargearPoints = (wargear, pointsHigh) => {
  const wargearRows = [];
  _.map(wargear, (item) => {
    let value;
    if (!item.pointsLow || item.remove) {
      return;
    }
    if (item.type === 'base') {
      value = <strong>{item.pointsLow}</strong>;
    } else {
      value = pointsHigh ? (
        <Fragment>{item.pointsLow}/<strong>{item.pointsHigh}</strong></Fragment>
      ) : (
        <Fragment><strong>{item.pointsLow}</strong>/{item.pointsHigh}</Fragment>
      );
    }
    wargearRows.push([
      item.name,
      value
    ]);
  });
  return wargearRows;
};

const getRulePoints = (rules) => {
  const ruleRows = [];
  _.map(rules, (rule) => {
    if (rule.type === 'progression') {
      ruleRows.push([rule.name, <strong>5</strong>]);
    }
  });
  return ruleRows;
};

class OverviewForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0
    };
  }

  setActiveTab = (index) => {
    this.setState({
      activeTab: index
    });
  }

  render() {
    const {
      warrior, company, onSubmit, setActiveTab, submitting, profile, prevTab, editable, handleInputChange
    } = this.props;
    const { activeTab } = this.state;

    const warriorCost = parseInt(warrior.influence, 10);
    const wargearCost = _.reduce(warrior.wargear, (result, item) => result + item.influence, 0);
    const totalCost = warriorCost + wargearCost;

    const tabs = [{
      name: 'Points',
      content: <PointsBreakdown warrior={warrior} profile={profile} />
    }, {
      name: 'Characteristics',
      content: <CharacteristicsBreakdown warrior={warrior} />
    }, {
      name: 'Biography',
      content: (
        <Fragment>
          <p>Add an optional biography to keep track of your warrior.</p>
          <FormRow>
            <TextInput name="biography" value={warrior.biography} onChange={handleInputChange} textarea large />
          </FormRow>
        </Fragment>
      )
    }];

    if (totalCost) {
      tabs.unshift({
        name: 'Influence',
        content: (
          <InfluenceBreakdown
            warrior={warrior}
            warriorCost={warriorCost}
            wargearCost={wargearCost}
            companyInfluence={company ? company.influence : 0}
          />
        )
      });
    }

    return (
      <Form
        buttons={editable && (
          <Fragment>
            <Button text="Previous" secondary type="button" iconName="arrow-left" iconLeft onClick={() => setActiveTab(prevTab)} />
            <Button text="Finish" primary type="button" onClick={onSubmit} loading={submitting} />
          </Fragment>
        )}
      >
        {!warrior.id && (
        <p className="help-text">
          In this section you can see a breakdown of the warrior&apos;s points cost, as well as see how their characteristics were calculated. Once you are happy, click Finish to create your warrior.
        </p>
        )}
        <Tabs
          activeTab={activeTab}
          setActiveTab={this.setActiveTab}
          tabs={tabs}
          invertColor
        />
      </Form>
    );
  }
}

const InfluenceBreakdown = ({
  warrior, warriorCost, wargearCost, companyInfluence
}) => {
  const totalCost = warriorCost + wargearCost;
  return (
    <Fragment>
      <p>
        Submitting these changes will cost your battle company <strong>{totalCost}</strong>
        {' '}influence points. You currently have <strong>{companyInfluence}</strong>
        {' '}influence points available.
      </p>
      <Table
        headers={['Details', '']}
        rows={[['Reinforcement Cost', <strong>{warriorCost}</strong>]]}
        overviewTable
      />
      {wargearCost > 0 && (
      <Table
        headers={['Wargear', '']}
        rows={_.map(warrior.wargear, item => [item.name, <strong>{item.influence}</strong>])}
        overviewTable
      />
      )}
      <Table
        rows={[['Total', <strong>{totalCost}</strong>]]}
        overviewTable
      />
    </Fragment>
  );
};

const PointsBreakdown = ({ warrior, profile }) => {
  let pointsHigh = false;
  let characteristicsChanges = 0;
  let mwfChanges = 0;

  if (!profile) {
    pointsHigh = (
      warrior.baseStats.attacks + warrior.baseStats.wounds
      + warrior.modifyStats.attacks + warrior.modifyStats.wounds
    ) > 2;

    characteristicsChanges = warrior.modifyStats.move
      + warrior.modifyStats.fight + warrior.modifyStats.shoot + warrior.modifyStats.strength
      + warrior.modifyStats.defence + warrior.modifyStats.attacks + warrior.modifyStats.wounds
      + warrior.modifyStats.courage;

    mwfChanges = warrior.modifyStats.might + warrior.modifyStats.will
      + warrior.modifyStats.fate;
  }

  return (
    <Fragment>
      <p>Review the breakdown of the points cost below.</p>
      <Table
        headers={['Details', '']}
        rows={[['Base Points', <strong>{warrior.basePoints}</strong>]]}
        overviewTable
      />
      {!!characteristicsChanges && (
      <Table
        headers={['Characteristics', '']}
        rows={getCharacteristicsPoints(warrior.modifyStats)}
        overviewTable
      />
      )}
      {!!mwfChanges && (
      <Table
        headers={['Might, Will and Fate', '']}
        rows={getMWFPoints(warrior.modifyStats)}
        overviewTable
      />
      )}
      {!!_.reduce(warrior.wargear, (sum, item) => sum + item.pointsLow + item.pointsHigh, 0) && (
      <Table
        headers={['Wargear', '']}
        rows={getWargearPoints(warrior.wargear, pointsHigh)}
        overviewTable
      />
      )}
      {!!_.filter(warrior.specialRules, { type: 'progression' }).length && (
      <Table
        headers={['Special Rules', '']}
        rows={getRulePoints(warrior.specialRules)}
        overviewTable
      />
      )}
      <Table
        rows={[['Total', warrior.totalPoints]]}
        overviewTable
      />
    </Fragment>
  );
};

const CharacteristicsBreakdown = ({ warrior }) => {
  const wargear = [];

  if (warrior.legWound) {
    const woundStats = _.clone(emptyStats);
    woundStats.move = -1;
    wargear.push({ name: 'Leg Wound', stats: woundStats });
  }

  _.map(_.filter(warrior.wargear, ({ remove }) => !remove), (item) => {
    if (item.adjustmentStat) {
      const itemStats = _.clone(emptyStats);
      itemStats[item.adjustmentStat.toLowerCase()] = item.adjustmentValue;
      wargear.push({ name: item.name, stats: itemStats });
    }
  });

  return (
    <Fragment>
      <p>Review the breakdown of the characteristics below.</p>
      <CharacteristicsTable
        base={warrior.baseStats}
        modified={isHero(warrior) ? warrior.modifyStats : null}
        wargear={wargear}
        total={warrior.totalStats}
        showLabel
        fixedLayout
      />
      {(warrior.rank !== '' && warrior.rank !== 'Warrior' && warrior.rank !== 'Creature') && (
      <MWFTable
        base={warrior.baseStats}
        modified={warrior.rank !== 'Wanderer' ? warrior.modifyStats : null}
        total={warrior.totalStats}
        showLabel
        small
        fixedLayout
      />
      )}
    </Fragment>
  );
};

OverviewForm.propTypes = {
  warrior: WarriorType.isRequired,
  company: CompanyType,
  handleInputChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  prevTab: PropTypes.number.isRequired,
  submitting: PropTypes.bool.isRequired,
  profile: PropTypes.bool.isRequired,
  editable: PropTypes.bool
};

OverviewForm.defaultProps = {
  company: null,
  editable: false
};

InfluenceBreakdown.propTypes = WarriorType.isRequired;

PointsBreakdown.propTypes = {
  warrior: WarriorType.isRequired,
  profile: PropTypes.bool.isRequired
};

CharacteristicsBreakdown.propTypes = {
  warrior: WarriorType.isRequired
};

export default OverviewForm;
