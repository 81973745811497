/* eslint-disable react/jsx-one-expression-per-line */
import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  WarriorCard, Overlay, Form, Button
} from '../../..';
import InjuryForm from './InjuryForm';
import { WarriorsType } from '../../../../_types';

class ResolveInjuries extends Component {
  constructor(props) {
    super(props);

    const { warriors } = this.props;

    const woundedWarriors = _.map(_.filter(warriors, { wounded: true }), (warrior, index) => ({
      ...warrior,
      index,
      injuryResult: '',
      healWound: '',
      woundsInfluence: 0,
      injuryResolved: false,
      introClosed: false
    }));

    this.state = {
      warriors: woundedWarriors
    };
  }

  onSubmit = (warrior) => {
    const { recordInjury } = this.props;
    const { warriors } = this.state;
    const editedWarrior = { ...warrior };

    if (warrior.woundsInfluence && warrior.injuryResult !== 'wounds') {
      editedWarrior.woundsInfluence = 0;
    }

    if (warrior.injuryResult === 'protected') {
      if (warrior.totalStats.fate < 3) {
        editedWarrior.modifyStats.fate += 1;
        editedWarrior.totalStats.fate += 1;
        editedWarrior.totalPoints += 5;
      }
    }

    switch (warrior.injuryResult) {
      case 'dead':
        editedWarrior.status = 'dead';
        break;
      case 'lost':
        editedWarrior.status = 'lost';
        break;
      case 'injured':
        editedWarrior.status = 'injured';
        break;
      case 'arm':
        editedWarrior.armWound = 1;
        break;
      case 'leg':
        editedWarrior.legWound = 1;
        break;
      case 'old':
        editedWarrior.oldWound += 1;
        break;
      default:
        if (warrior.healWound) {
          editedWarrior[warrior.healWound] -= 1;
        }
        break;
    }

    editedWarrior.wounded = false;

    warriors[warrior.index].animateOut = true;
    this.setState({
      warriors
    });

    window.setTimeout(() => {
      warriors[warrior.index] = editedWarrior;
      this.setState({
        warriors
      });
      recordInjury(editedWarrior);
    }, 500);
  }

  render() {
    const { warriors, introClosed } = this.state;
    const filteredWarriors = _.filter(warriors, { wounded: true });

    if (!introClosed) {
      return (
        <Overlay title="Resolve Injuries" isSmall open>
          <Form buttons={<Button text="Continue" iconName="arrow-right" iconRight primary onClick={() => { this.setState({ introClosed: true }); }} />}>
            <p>
              You have <strong>{filteredWarriors.length}</strong> injured warrior{filteredWarriors.length !== 1 ? 's' : '' }.
              Click Continue to resolve their wounds.
            </p>
          </Form>
        </Overlay>
      );
    }

    return (
      <div className="card-overlay">
      {_.map(filteredWarriors, warrior => (
        <WarriorCard
          key={warrior.id}
          warrior={warrior}
          companyId={warrior.companyId}
          overlay
          form={(
            <InjuryForm
              warrior={{...warrior}}
              onSubmit={this.onSubmit}
            />
          )}
        />
      ))}
      </div>
    );
  }
}

ResolveInjuries.propTypes = {
  warriors: WarriorsType.isRequired,
  recordInjury: PropTypes.func.isRequired
};

export default ResolveInjuries;
