// eslint-disable-next-line import/prefer-default-export
export function useStoredSort(sortOptions, initialSort, storageName) {
  let sortIndex = initialSort;
  const storageSort = localStorage.getItem(storageName);

  if (storageSort) {
    const parsedOption = JSON.parse(storageSort);
    const storedIndex = sortOptions.findIndex(o => o.value === parsedOption.value && o.mode === parsedOption.mode);
    if (storedIndex > -1) {
      sortIndex = storedIndex;
    }
  }

  return sortOptions[sortIndex];
}
