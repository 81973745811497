/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { AuthType } from '../_types';

import './styles.scss';

import { LoginForm } from '../_components';

class ContactPage extends PureComponent {
  render() {
    const { authentication } = this.props;

    return (
      <div className="text-page">
        <div className="intro">
          <div className="intro__text">
            <h3>Battle Companies Manager</h3>
            <h1>Contact</h1>
            <p>For help, support or feature requests please email <a href="mailto:battlecompaniesmanager@gmail.com">battlecompaniesmanager@gmail.com</a> or post a question on the <a href="https://www.facebook.com/Battle-Companies-Manager-112213840144097" target="_blank" rel="noopener noreferrer">Battle Companies Manager Facebook page</a>.</p>
          </div>
          {!authentication.loggedIn && (
          <div className="login-form text-page-form">
            <h3>Login</h3>
            <LoginForm />
          </div>
          )}
        </div>
      </div>
    );
  }
}

ContactPage.propTypes = {
  authentication: AuthType.isRequired
};

function mapStateToProps({ authentication }) {
  return { authentication };
}

export default connect(mapStateToProps)(ContactPage);
