import React, { PureComponent } from 'react';
import EditRuleOverlay from '../Overlay/EditRuleOverlay';
import { RuleType } from '../../_types';
import { Card } from '..';

class RuleCard extends PureComponent {
  render() {
    const { rule } = this.props;

    return (
      <Card
        id={rule.id}
        title={rule.name}
        className="card--rule"
        overlay={<EditRuleOverlay rule={{ ...rule }} />}
        clickable
      >
        <div className="card__rules card__rules--special-rules">
          <span className="card__rules__header">
            Special Rule
          </span>
          <div className="card__rules__inner">
            {rule.description}
          </div>
        </div>
      </Card>
    );
  }
}

RuleCard.propTypes = {
  rule: RuleType.isRequired
};

export default RuleCard;
