import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { userActions } from '../../_actions';
import {
  Button, Form, FormRow, TextInput
} from '..';

class ChangePasswordPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        password: '',
        newPassword: '',
        confirmPassword: ''
      },
      submitted: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value
      }
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { changePassword } = this.props;
    const { user } = this.state;

    this.setState({ submitted: true });

    if (!user.password
      || !user.newPassword
      || !user.confirmPassword
      || user.newPassword !== user.confirmPassword) {
      return;
    }
    changePassword(user);
  }

  render() {
    const { changingPassword } = this.props;
    const { user, submitted } = this.state;
    return (
      <form id="change-password-form" name="change-password-form" onSubmit={this.handleSubmit}>
        <Form buttons={<Button text="Submit" primary iconName="arrow-right" iconRight type="submit" loading={changingPassword} />}>
          <FormRow>
            <TextInput name="password" placeholder="Current Password" value={user.password} onChange={this.handleChange} type="password" required showError={submitted && !user.password} />
          </FormRow>
          <FormRow>
            <TextInput name="newPassword" placeholder="New Password" value={user.newPassword} onChange={this.handleChange} type="password" required showError={submitted && !user.newPassword} />
          </FormRow>
          <FormRow>
            <TextInput name="confirmPassword" placeholder="Confirm Password" value={user.confirmPassword} onChange={this.handleChange} type="password" required showError={submitted && !user.confirmPassword} />
            {(submitted && user.newPassword !== user.confirmPassword) && (
              <span className="field-validation-error">Your passwords do not match.</span>
            )}
          </FormRow>
        </Form>
      </form>
    );
  }
}

ChangePasswordPage.propTypes = {
  changePassword: PropTypes.func.isRequired,
  changingPassword: PropTypes.bool
};

ChangePasswordPage.defaultProps = {
  changingPassword: false
};

function mapStateToProps({ changePassword }) {
  const { changingPassword } = changePassword;
  return { changingPassword };
}

export default connect(mapStateToProps, {
  changePassword: userActions.changePassword
})(ChangePasswordPage);
