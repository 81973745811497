/* eslint-disable react/jsx-one-expression-per-line */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { WarriorType, ProfileType } from '../../../_types';
import { good, evil, custom } from '../../../_constants/companies';

import {
  Button, Form, FormRow, TextInput, NumberInput,
  RadioInput, SelectInput, FileInput
} from '../..';

const DetailsForm = ({
  warrior, handleInputChange, selectProfile, updateWarriorStat, setActiveTab,
  deleteWarrior, deleting, uploadImage, submitted, profile, profiles, selectedProfile, warriors
}) => (
  <Form
    buttons={(
      <Fragment>
        {!!warrior.id && <Button text="Delete" destructive type="button" onClick={deleteWarrior} loading={deleting} />}
        <Button text="Next" primary type="button" iconName="arrow-right" iconRight onClick={() => setActiveTab(1)} />
      </Fragment>
      )}
  >
    {!profile && !warrior.id && (
    <p className="help-text">
      Fill in the details below to build your warrior card. You can store profiles in the My Profiles section, then select them here to speed up the process.
      <br /><br />
      The points cost of your warrior is automatically calculated as you proceed.
      <br /><br />
      Once you have selected an image you can zoom and crop it on the card before clicking upload.
    </p>
    )}
    {!!profile && !warrior.id && (
    <p className="help-text">
      Fill in the details below to build your profile.<br /><br />You can select multiple races for the profile, or select &apos;Any&apos; to make this profile avilable to all races.
    </p>
    )}
    {!profile && (
      <FormRow>
        <SelectInput name="selectedProfile" label="Load Profile" value={selectedProfile} onChange={selectProfile}>
          <option value="">Please Select</option>
          {_.map(_.orderBy(profiles, ['baseClass']), p => <option key={p.id} value={p.id}>{p.baseClass}</option>)}
        </SelectInput>
      </FormRow>
    )}
    {!profile && (
      <FormRow>
        <TextInput name="name" label="Name" value={warrior.name} onChange={handleInputChange} required showError={submitted && !warrior.name} />
      </FormRow>
    )}
    {!profile && (
      <FormRow>
        {warrior.imageUploaded ? (
          <TextInput
            name="image"
            label="Image"
            value="Image uploaded successfully"
            onChange={() => {}}
            disabled
            readonly
          />
        ) : (
          <Fragment>
            <FileInput
              name="image"
              label="Image"
              value=""
              onChange={(file) => {
                updateWarriorStat('image', '');
                updateWarriorStat('tempImage', file);
              }}
              onSubmit={uploadImage}
              tempImageSelected={submitted && !!warrior.tempImage}
            />
          </Fragment>
        )}
      </FormRow>
    )}
    <FormRow>
      <TextInput name="baseClass" label={profile ? 'Name' : 'Base Class'} value={warrior.baseClass} onChange={handleInputChange} required showError={submitted && profile && !warrior.baseClass} />
    </FormRow>
    {profile && (
      <FormRow>
        <SelectInput name="race" label="Races" value={warrior.race.split(',')} onChange={handleInputChange} multiple>
          <option value="">Any</option>
          <optgroup label="Good">
            {_.map(good, c => <option key={c.name} value={c.name}>{c.name}</option>)}
          </optgroup>
          <optgroup label="Evil">
            {_.map(evil, c => <option key={c.name} value={c.name}>{c.name}</option>)}
          </optgroup>
          <optgroup label="Custom">
            {_.map(custom, c => <option key={c.name} value={c.name}>{c.name}</option>)}
          </optgroup>
        </SelectInput>
      </FormRow>
    )}
    {!profile && (
      <FormRow>
        <RadioInput
          name="rank"
          label="Rank"
          value={warrior.rank}
          onChange={handleInputChange}
          options={[
            { label: 'Leader', value: 'Leader' },
            { label: 'Sergeant', value: 'Sergeant' },
            { label: 'Hero', value: 'Hero' },
            { label: 'Warrior', value: 'Warrior' },
            { label: 'Creature', value: 'Creature' },
            { label: 'Wanderer', value: 'Wanderer' }
          ]}
          required
          showError={submitted && !warrior.rank}
        />
      </FormRow>
    )}
    {warrior.rank === 'Creature' && (
      <FormRow>
        <SelectInput name="owner" label="Owner" value={warrior.owner || ''} onChange={handleInputChange}>
          <option value="">Please Select</option>
          {_.map(
            _.filter(
              _.orderBy(warriors, ['rank']), 
              warrior => warrior.rank === 'Leader' || warrior.rank === 'Sergeant'
            ), w => <option key={w.id} value={w.id}>{w.name}</option>
          )}
        </SelectInput>
      </FormRow>
    )}
    {!profile && (warrior.rank === 'Leader' || warrior.rank === 'Sergeant' || warrior.rank === 'Hero') && (
      <FormRow>
        <SelectInput name="chosenPath" label="Chosen Path" value={warrior.chosenPath} onChange={handleInputChange}>
          <option value="">Please Select</option>
          <option value="Warrior">Warrior</option>
          <option value="Ranger">Ranger</option>
          <option value="General">General</option>
          <option value="Knight">Knight</option>
          <option value="Scout">Scout</option>
          <option value="Adventurer">Adventurer</option>
          <option value="Sorcerer">Sorcerer</option>
          <option value="Beast">Beast</option>
          <option value="Custom">Custom</option>
        </SelectInput>
        {warrior.chosenPath === 'Custom' && (
          <TextInput name="customPath" label="Custom Path" value={warrior.customPath} onChange={handleInputChange} showError={submitted && warrior.chosenPath === 'Custom' && !warrior.customPath} />
        )}
      </FormRow>
    )}
    <FormRow>
      <NumberInput name="basePoints" label="Base Points" value={warrior.basePoints} min={0} onChange={updateWarriorStat} />
    </FormRow>
    {(!profile && warrior.rank !== 'Wanderer' && warrior.rank !== 'Creature') && (
      <FormRow>
        <NumberInput name="experience" label="Experience" value={warrior.experience} min={0} onChange={updateWarriorStat} />
      </FormRow>
    )}
    {!profile && !warrior.id && (
      <FormRow>
        <NumberInput name="influence" label="Influence Cost" value={warrior.influence} min={0} onChange={updateWarriorStat} />
      </FormRow>
    )}
  </Form>
);

DetailsForm.propTypes = {
  warrior: WarriorType.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  updateWarriorStat: PropTypes.func.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  selectProfile: PropTypes.func.isRequired,
  deleteWarrior: PropTypes.func.isRequired,
  uploadImage: PropTypes.func.isRequired,
  deleting: PropTypes.bool.isRequired,
  submitted: PropTypes.bool.isRequired,
  profile: PropTypes.bool.isRequired,
  profiles: PropTypes.arrayOf(ProfileType).isRequired,
  selectedProfile: PropTypes.string.isRequired
};

function mapStateToProps({ warriors }) {
  return { warriors };
}

export default connect(mapStateToProps)(DetailsForm);
