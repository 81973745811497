import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { CompanyType } from '../../_types';
import { good, evil, custom } from '../../_constants/companies';

import './styles.scss';

const CompanyDeck = ({ company, clickable }) => {
  const { id, race } = company;

  const matchingRace = _.find(good.concat(evil).concat(custom), { name: race });
  const image = matchingRace ? matchingRace.image : '';

  if (clickable) {
    return (
      <div className="deck__outer">
        <Link to={`/company/${id}`} className="deck">
          <DeckInner company={company} image={image} />
        </Link>
      </div>
    );
  }
  return (
    <div className="deck">
      <DeckInner company={company} image={image} />
    </div>
  );
};

const DeckInner = ({
  company: {
    name, race, rating, influence, warriors
  }, image
}) => (
  <Fragment>
    <div className="deck__container">
      <div className="deck__inner">
        <div className="deck__top">
          <span className="deck__name">{name}</span>
          <span className="deck__race">{race}</span>
        </div>
        <img src={image} alt={race} />
        <div className="deck__bottom">
          <span className="deck__warrior-count">
            <span>Warriors:</span>
            {' '}
            <strong>{_.filter(warriors, warrior => warrior.warriorRank !== 'Creature').length}</strong>
          </span>
          <span className="deck__rating">
            <span>Company rating:</span>
            {' '}
            <strong>{rating}</strong>
          </span>
          <span className="deck__influence">
            <span>Influence:</span>
            {' '}
            <strong>{influence}</strong>
          </span>
        </div>
      </div>
    </div>
    <div className="deck__cards" />
  </Fragment>
);

CompanyDeck.propTypes = {
  company: CompanyType.isRequired,
  clickable: PropTypes.bool
};

CompanyDeck.defaultProps = {
  clickable: false
};

DeckInner.propTypes = {
  company: CompanyType.isRequired,
  image: PropTypes.string.isRequired
};

export default CompanyDeck;
