import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { userActions } from '../../_actions';
import {
  Button, Form, FormRow, TextInput
} from '..';

class UpdateUserForm extends React.Component {
  constructor(props) {
    super(props);

    const { user } = this.props;
    this.state = {
      user,
      submitted: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value
      }
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { update } = this.props;
    const { user } = this.state;

    this.setState({ submitted: true });

    if (!user.name || !user.email) {
      return;
    }
    update(user);
  }

  render() {
    const { updating } = this.props;
    const { user, submitted } = this.state;
    return (
      <form id="update-user-form" name="update-user-form" onSubmit={this.handleSubmit}>
        <Form buttons={<Button text="Submit" primary iconName="arrow-right" iconRight type="submit" loading={updating} />}>
          <FormRow>
            <TextInput name="name" placeholder="Name" value={user.name} onChange={this.handleChange} required showError={submitted && !user.name} />
          </FormRow>
          <FormRow>
            <TextInput name="email" placeholder="Email" value={user.email} onChange={this.handleChange} type="email" required showError={submitted && !user.email} />
          </FormRow>
        </Form>
      </form>
    );
  }
}

UpdateUserForm.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string
  }).isRequired,
  update: PropTypes.func.isRequired,
  updating: PropTypes.bool
};

UpdateUserForm.defaultProps = {
  updating: false
};

function mapStateToProps({ authentication }) {
  const { user, updating } = authentication;
  return { user, updating };
}

export default connect(mapStateToProps, {
  update: userActions.update
})(UpdateUserForm);
