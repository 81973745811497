import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { Prompt } from 'react-router-dom';
import { WarriorsType, CompanyType } from '../../_types';

import { WarriorList, CompanyHeader } from '..';
import OldBattleWoundsOverlay from './OldBattleWounds';
import AgainstTheOdds from './AgainstTheOdds';
import PostBattle from './PostBattle';
import LoadState from './LoadState';

class BattleScreen extends Component {
  constructor () {
    super()

    this.state = {
      warriors: {},
      company: {},
      bonus: 0,
      oldBattleWoundsShown: false,
      againstTheOddsShown: false,
      battleEnded: false
    }
  }
  
  loadState = (useSavedState) => {
    const { warriors, company } = this.props;
    
    if (useSavedState) {
      const savedState = window.sessionStorage.getItem(company.id)
      this.setState(JSON.parse(savedState))
      return
    }

    window.sessionStorage.removeItem(company.id)

    const battleWarriors = _.mapKeys(_.map(_.clone(warriors), warrior => ({
      ...warrior,
      wounded: 0,
      fled: 0,
      remainingWounds: warrior.totalStats.wounds,
      remainingMight: warrior.totalStats.might,
      remainingWill: warrior.totalStats.will,
      remainingFate: warrior.totalStats.fate,
      woundsInflicted: 0,
      preBattleInjury: warrior.status === 'injured' || warrior.status === 'lost'
    })), 'id');

    this.setState({
      warriors: battleWarriors,
      company: _.clone(company),
      bonus: 0,
      oldBattleWoundsShown: false,
      againstTheOddsShown: false,
      battleEnded: false
    })
  }

  componentDidUpdate = () => {
    // eslint-disable-next-line no-restricted-globals
    window.onbeforeunload = () => 'Progress has been saved. Are you sure you want to continue?';
  }

  submitBattleWounds = (woundedWarriors) => {
    const { warriors } = this.state;

    this.saveState({
      ...this.state,
      warriors: { ...warriors, ...woundedWarriors },
      oldBattleWoundsShown: true
    });
  }

  submitAgainstTheOdds = (bonus) => {
    this.saveState({
      ...this.state,
      bonus: Math.max(0, bonus),
      againstTheOddsShown: true
    });
  }

  endBattle = () => {
    this.saveState({
      ...this.state,
      battleEnded: true
    });
  }

  alterStat = (event, name, change, warriorId) => {
    const { warriors } = this.state;
    const warrior = warriors[warriorId];
    
    warrior[name] += change;
    warrior.wounded = warrior.remainingWounds === 0;

    this.saveState({ 
      ...this.state,
      warriors: {
        ...warriors,
        [warriorId]: warrior
      }
    });
  }

  saveState = (newState) => {
    const { company } = this.props;

    window.sessionStorage.setItem(company.id, JSON.stringify(newState))

    this.setState(newState);
  }

  render() {
    if (_.isEmpty(this.state.warriors)) {
      const savedState = window.sessionStorage.getItem(this.props.company.id)

      if (!!savedState) {
        return <LoadState onSubmit={this.loadState} />
      } else {
        this.loadState(false)
      }
    }

    const {
      warriors, company, bonus, oldBattleWoundsShown, againstTheOddsShown, battleEnded
    } = this.state;

    const oldWounds = _.filter(warriors, warrior => (
      warrior.oldWound > 0 && !warrior.preBattleInjury
    ));
    if (!oldBattleWoundsShown && oldWounds.length) {
      return (
        <OldBattleWoundsOverlay
          warriors={_.mapKeys(oldWounds, 'id')}
          onSubmit={this.submitBattleWounds}
        />
      );
    }

    const battleRating = _.reduce(warriors, (sum, warrior) => sum + (
      !warrior.preBattleInjury ? warrior.totalPoints : 0
    ), 0);

    if (!againstTheOddsShown) {
      return <AgainstTheOdds companyRating={battleRating} onSubmit={this.submitAgainstTheOdds} />;
    }

    return (
      <Fragment>
        <Prompt when message="All progress from this battle will be lost. Are you sure you wish to continue?" />
        <CompanyHeader
          companies={{ [company.id]: { ...company, warriors } }}
          companyId={company.id}
          bonus={bonus}
          endBattle={this.endBattle}
          battleMode
          battleRating={battleRating}
        />
        <WarriorList
          warriors={warriors}
          companyId={company.id}
          alterStat={this.alterStat}
          sortOption={{ value: 'default' }}
          battleMode
        />
        {battleEnded && (<div className="post-battle"><PostBattle company={company} warriors={warriors} bonus={bonus} /></div>)}
      </Fragment>
    );
  }
}

BattleScreen.propTypes = {
  warriors: WarriorsType.isRequired,
  company: CompanyType.isRequired
};

export default BattleScreen;
