import _ from 'lodash';
import { warriorConstants } from '../_actions';
import { calculateWarriorPoints, calculateStatTotals } from '../_helpers/warrior';

export function formatWarriors(warriors) {
  if (!warriors) {
    return {};
  }

  const fullWarriors = _.map(warriors, (warrior) => {
    const warriorWithPoints = calculateWarriorPoints(warrior);
    return calculateStatTotals(warriorWithPoints);
  });
  return _.mapKeys(fullWarriors, 'id');
}

function formatWarrior(warrior, state) {
  if (!warrior) {
    return {};
  }
  const warriorWithPoints = calculateWarriorPoints(warrior);
  const newState = { ...state };
  newState[warrior.id] = calculateStatTotals(warriorWithPoints);
  return newState;
}

export default function (state = { loading: true }, action) {
  switch (action.type) {
    case warriorConstants.GET_REQUEST:
      return { loading: true };
    case warriorConstants.GET_SUCCESS:
      return formatWarriors(action.warriors);
    case warriorConstants.GET_FAILURE:
      return { error: action.error };

    case warriorConstants.CREATE_SUCCESS:
      return formatWarrior(action.warriorResponse, state);

    case warriorConstants.UPDATE_SUCCESS:
      return formatWarrior(action.warriorResponse, state);

    case warriorConstants.DELETE_SUCCESS:
      return _.omit(state, [action.idResponse.id, action.idResponse.creature]);

    default:
      return state;
  }
}
