export const alertConstants = {
  SUCCESS: 'ALERT_SUCCESS',
  ERROR: 'ALERT_ERROR',
  CLEAR: 'ALERT_CLEAR'
};

function clear() {
  return { type: alertConstants.CLEAR };
}

function success(message) {
  function show() { return { type: alertConstants.SUCCESS, message }; }
  return (dispatch) => {
    dispatch(show());
    window.setTimeout(() => {
      dispatch(clear());
    }, 5000);
  };
}

function error(message) {
  function show() { return { type: alertConstants.ERROR, message }; }
  return (dispatch) => {
    dispatch(show());
    window.setTimeout(() => {
      dispatch(clear());
    }, 5000);
  };
}

// eslint-disable-next-line import/prefer-default-export
export const alertActions = {
  success,
  error,
  clear
};
