import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Icon, Loader } from '..';

import './styles.scss';

const generateButtonClass = ({
  primary, secondary, destructive, constructive, iconLeft, iconRight, block, floatRight, small, className
}) => {
  const cls = [className, 'btn'];
  if (primary) cls.push('btn--primary');
  if (secondary) cls.push('btn--secondary');
  if (destructive) cls.push('btn--destructive');
  if (constructive) cls.push('btn--constructive');
  if (iconLeft) cls.push('btn--icon-left');
  if (iconRight) cls.push('btn--icon-right');
  if (block) cls.push('btn--block');
  if (floatRight) cls.push('btn--right');
  if (small) cls.push('btn--small');
  return cls.join(' ');
};

export const Button = (props) => {
  const {
    text, type, onClick, iconName, loading, disabled
  } = props;
  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    }
  };

  return (
    // eslint-disable-next-line react/button-has-type
    <button onClick={handleClick} className={generateButtonClass(props)} type={type || 'button'} disabled={disabled || loading}>
      {loading ? (
        <Loader grey />
      ) : (
        <Fragment>
          {text}
          {iconName && <Icon name={iconName} />}
        </Fragment>
      )}
    </button>
  );
};

export const LinkButton = (props) => {
  const { text, to, iconName } = props;
  return (
    <Link to={to} className={generateButtonClass(props)}>
      {text}
      {iconName && <Icon name={iconName} />}
    </Link>
  );
};

const ButtonProps = {
  text: PropTypes.string.isRequired,
  iconName: PropTypes.string,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  destructive: PropTypes.bool,
  iconLeft: PropTypes.bool,
  iconRight: PropTypes.bool,
  block: PropTypes.bool,
  floatRight: PropTypes.bool,
  small: PropTypes.bool
};

const ButtonDefaultProps = {
  iconName: null,
  primary: false,
  secondary: false,
  destructive: false,
  iconLeft: false,
  iconRight: false,
  block: false,
  floatRight: false,
  small: false
};

Button.propTypes = {
  ...ButtonProps,
  type: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool
};

Button.defaultProps = {
  ...ButtonDefaultProps,
  onClick: null,
  type: 'button',
  loading: false,
  disabled: false
};

LinkButton.propTypes = {
  ...ButtonProps,
  to: PropTypes.string.isRequired
};

LinkButton.defaultProps = {
  ...ButtonDefaultProps
};
