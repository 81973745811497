import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ruleActions } from '../../../_actions';
import { RuleType } from '../../../_types';

import {
  Button, Overlay, Form, FormRow, TextInput
} from '../..';

class EditRuleOverlay extends Component {
  constructor(props) {
    super(props);
    const { rule } = this.props;
    this.state = {
      rule,
      submitting: false,
      deleting: false,
      submitted: false
    };
  }

  handleInputChange = (event) => {
    const { target } = event;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState({
      [name]: value
    });
  }

  updateRuleStat = (event) => {
    const { rule } = this.state;
    const {
      target: {
        name, type, checked, value
      }
    } = event;
    const val = type === 'checkbox' ? checked : value;

    const nameParts = name.split('.');
    if (nameParts.length > 1) {
      rule[nameParts[0]][nameParts[1]] = val;
    } else {
      rule[name] = val;
    }

    this.setState({
      rule
    });
  }

  deleteRule = (e) => {
    e.preventDefault();
    const { rule, deleting } = this.state;
    if (deleting) {
      return;
    }
    const { deleteRuleAction } = this.props;

    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure you want to delete this rule? This action cannot be undone.')) {
      this.setState({
        deleting: true
      });

      deleteRuleAction(rule.id);
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { rule, submitting } = this.state;
    if (submitting) {
      return;
    }

    if (rule.name === '') {
      this.setState({
        submitted: true
      });
      return;
    }

    this.setState({
      submitted: false,
      submitting: true
    });

    const submitRule = {
      id: rule.id,
      name: rule.name,
      description: rule.description
    };

    const { createRuleAction, updateRuleAction, handleClose } = this.props;
    if (rule.id) {
      updateRuleAction(submitRule, handleClose);
    } else {
      createRuleAction(submitRule, handleClose);
    }
  }

  render() {
    const { handleClose } = this.props;
    const {
      rule, submitting, deleting, submitted
    } = this.state;
    const { name, description } = rule;
    return (
      <Overlay id="edit-rule" title={rule.id ? 'Edit Rule' : 'Create Rule'} handleClose={handleClose} open>
        <div className={`edit-left ${(submitting || deleting) ? 'submitting' : ''}`}>
          <Form buttons={(
            <Fragment>
              {!!rule.id && <Button text="Delete" destructive type="button" onClick={this.deleteRule} loading={deleting} />}
              <Button text="Submit" primary type="button" onClick={this.onSubmit} loading={submitting} />
            </Fragment>
          )}
          >
            {!rule.id && (
            <p className="help-text">
              Rules can be created here, and then re-used on your warriors and profiles.
            </p>
            )}
            <FormRow>
              <TextInput name="name" label="Name" value={name} onChange={this.updateRuleStat} required showError={submitted && !name} />
            </FormRow>
            <FormRow>
              <TextInput name="description" label="Description" value={description} onChange={this.updateRuleStat} textarea />
            </FormRow>
          </Form>
          {(submitting || deleting) && <div className="submit-block" />}
        </div>
      </Overlay>
    );
  }
}

EditRuleOverlay.propTypes = {
  rule: RuleType.isRequired,
  createRuleAction: PropTypes.func.isRequired,
  updateRuleAction: PropTypes.func.isRequired,
  deleteRuleAction: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default withRouter(connect(null, {
  createRuleAction: ruleActions.createRule,
  updateRuleAction: ruleActions.updateRule,
  deleteRuleAction: ruleActions.deleteRule
})(EditRuleOverlay));
