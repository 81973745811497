import { graveyardService } from '../_services/graveyard';
import { alertActions } from './alert';

export const graveyardConstants = {
  GET_REQUEST: 'GRAVEYARD_GET_REQUEST',
  GET_SUCCESS: 'GRAVEYARD_GET_SUCCESS',
  GET_FAILURE: 'GRAVEYARD_GET_FAILURE',

  UPDATE_REQUEST: 'GRAVEYARD_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'GRAVEYARD_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'GRAVEYARD_UPDATE_FAILURE',

  DELETE_REQUEST: 'GRAVEYARD_DELETE_REQUEST',
  DELETE_SUCCESS: 'GRAVEYARD_DELETE_SUCCESS',
  DELETE_FAILURE: 'GRAVEYARD_DELETE_FAILURE'
};

function getGraveyardWarriors(companyId) {
  function request() { return { type: graveyardConstants.GET_REQUEST }; }
  function success(warriors) { return { type: graveyardConstants.GET_SUCCESS, warriors }; }
  function failure(error) { return { type: graveyardConstants.GET_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    graveyardService.getWarriors(companyId)
      .then(
        warriors => dispatch(success(warriors)),
        error => dispatch(failure(error.toString()))
      );
  };
}

function updateGraveyardWarrior(warrior, callback) {
  function request(graveyardResponse) {
    return { type: graveyardConstants.UPDATE_REQUEST, graveyardResponse };
  }
  function success(graveyardResponse) {
    return { type: graveyardConstants.UPDATE_SUCCESS, graveyardResponse };
  }
  function failure(error) { return { type: graveyardConstants.UPDATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(warrior));

    graveyardService.updateGraveyardWarrior(warrior)
      .then(
        (graveyardResponse) => {
          dispatch(success(graveyardResponse));
          if (callback) callback();
          dispatch(alertActions.success(`${warrior.name} updated successfully`));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };
}

function deleteGraveyardWarrior(id) {
  function request(idResponse) { return { type: graveyardConstants.DELETE_REQUEST, idResponse }; }
  function success(idResponse) { return { type: graveyardConstants.DELETE_SUCCESS, idResponse }; }
  function failure(idResponse, error) {
    return { type: graveyardConstants.DELETE_FAILURE, idResponse, error };
  }

  return (dispatch) => {
    dispatch(request(id));

    graveyardService.deleteGraveyardWarrior(id)
      .then(
        (responseId) => {
          dispatch(success(responseId));
          dispatch(alertActions.success('Warrior deleted successfully'));
        },
        (error) => {
          dispatch(failure(id, error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };
}

// eslint-disable-next-line import/prefer-default-export
export const graveyardActions = {
  getGraveyardWarriors,
  updateGraveyardWarrior,
  deleteGraveyardWarrior
};
