import React from "react"

import './styles.scss';

function ClimbChart() {
  return (
    <div className="simple-chart">
      <table className="simple-chart__table">
        <thead>
          <tr>
            <th>D6</th>
            <th>Result</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>1</th>
            <td>
              <strong>Fall</strong><br/>
              The model slips and falls to the ground, and becomes Prone (see page 32) at the bottom of the surface it attempted to climb, place a Prone marker next to it. If the model began its climb at ground level, it suffers no further damage, but if it was higher than this it may suffer falling damage (see opposite).
            </td>
          </tr>
          <tr>
            <th>2-5</th>
            <td>
              <strong>Continue to Climb</strong><br/>
              The model climbs until it has moved its maximum Move distance, or reaches the top, after which it may move no further this Move phase.
            </td>
          </tr>
          <tr>
            <th>6</th>
            <td>
              <strong>Swift Ascent</strong><br/>
              The model climbs until it has moved its maximum Move distance, or reaches the top. It may even complete its move up to its maximum Move distance after it reaches the top (minus the distance it has already used climbing up, of course).
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default ClimbChart