/* eslint-disable react/jsx-one-expression-per-line */
import React, { Fragment, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { CompaniesType, WarriorsType } from '../_types';
import { warriorActions } from '../_actions';
import { useStoredSort } from '../_hooks/useStoredSort';

import {
  WarriorList, CompanyHeader, Loader, Advert, CharacteristicsTable
} from '../_components';

const storageName = 'sort-warriors';
const sortOptions = [
  { label: 'Default', value: 'default', mode: '' },
  { label: 'Level', value: 'experience', mode: 'asc' },
  { label: 'Level', value: 'experience', mode: 'desc' },
  { label: 'Name', value: o => o.name.toLowerCase(), mode: 'asc' },
  { label: 'Name', value: o => o.name.toLowerCase(), mode: 'desc' },
  { label: 'Points', value: 'totalPoints', mode: 'asc' },
  { label: 'Points', value: 'totalPoints', mode: 'desc' },
  { label: 'Rank', value: 'sortRank', mode: 'asc' },
  { label: 'Rank', value: 'sortRank', mode: 'desc' }
];

function CompanyDetails({
  warriors, companies, match, getWarriorsAction
}) {
  const { companyId } = match.params;

  const [sort, setSort] = useState(useStoredSort(sortOptions, 0, storageName));

  useEffect(() => {
    getWarriorsAction(companyId);
  }, [getWarriorsAction, companyId]);

  const renderCompanyWarriors = () => {
    if (warriors.error) {
      return <h2 style={{ color: '#fff', textAlign: 'center' }}>{warriors.error}</h2>;
    }

    if (warriors.loading) {
      return <Loader />;
    }

    return <WarriorList warriors={warriors} companyId={companyId} sortOption={sort} editable clickable showNewCard />;
  };

  const renderCompanyRules = () => {
    if (warriors.loading || warriors.error) {
      return null;
    }

    const rules = _.uniqBy(_.reduce(warriors, (result, warrior) => [...result, ...warrior.specialRules], []), 'name');
    const spells = _.uniqBy(_.reduce(warriors, (result, warrior) => [...result, ...warrior.spells], []), 'name');

    const mounts = _.uniqBy(_.reduce(warriors, (result, warrior) => {
      const mount = warrior.wargear.find(item => item.mount === 1);
      if (!mount) {
        return result;
      }
      return [...result, mount];
    }, []), 'name');

    const all = _.orderBy([...rules, ...spells], o => o.name.toLowerCase());
    return (
      <div className="company-rules print">
        <h2>Rules and Spells</h2>
        {_.map(all, (rule, index) => <p key={index}><strong>{rule.name}</strong>: {rule.description}</p>)}
        <br />
        <h2>Mounts</h2>
        {_.map(mounts, (mount, index) => (
          <div className="mounts" key={index}>
            <p><strong>{mount.name}</strong></p>
            <CharacteristicsTable total={{
              move: mount.move,
              fight: mount.fight,
              shoot: mount.shoot,
              strength: mount.strength,
              defence: mount.defence,
              attacks: mount.attacks,
              wounds: mount.wounds,
              courage: mount.courage,
              might: mount.might,
              will: mount.will,
              fate: mount.fate
            }}
            />
            {_.map(mount.specialRules, (rule, i) => <p key={i}><strong>{rule.name}</strong>: {rule.description}</p>)}
          </div>
        ))}
      </div>
    );
  };

  return (
    <Fragment>
      <Advert isWide />
      <CompanyHeader companies={companies} companyId={companyId} sortOption={sort} sortOptions={sortOptions} setSort={setSort} storageName={storageName} clickable />
      {renderCompanyWarriors()}
      {renderCompanyRules()}
    </Fragment>
  );
}


CompanyDetails.propTypes = {
  companies: CompaniesType.isRequired,
  warriors: WarriorsType.isRequired,
  getWarriorsAction: PropTypes.func.isRequired,
  match: ReactRouterPropTypes.match.isRequired
};

function mapStateToProps({ companies, warriors }) {
  return { companies, warriors };
}

export default withRouter(connect(mapStateToProps,
  { getWarriorsAction: warriorActions.getWarriors })(CompanyDetails));
