import PropTypes from 'prop-types';

export const StatsType = PropTypes.shape({
  move: PropTypes.oneOfType([PropTypes.number, PropTypes.node]),
  fight: PropTypes.oneOfType([PropTypes.number, PropTypes.node]),
  shoot: PropTypes.oneOfType([PropTypes.number, PropTypes.node]),
  strength: PropTypes.oneOfType([PropTypes.number, PropTypes.node]),
  defence: PropTypes.oneOfType([PropTypes.number, PropTypes.node]),
  attacks: PropTypes.oneOfType([PropTypes.number, PropTypes.node]),
  wounds: PropTypes.oneOfType([PropTypes.number, PropTypes.node]),
  courage: PropTypes.oneOfType([PropTypes.number, PropTypes.node]),
  might: PropTypes.oneOfType([PropTypes.number, PropTypes.node]),
  will: PropTypes.oneOfType([PropTypes.number, PropTypes.node]),
  fate: PropTypes.oneOfType([PropTypes.number, PropTypes.node])
});

export const SpecialRuleType = PropTypes.shape({
  name: PropTypes.string,
  description: PropTypes.string
});

export const WargearType = PropTypes.shape({
  id: PropTypes.number,
  warriorId: PropTypes.number,
  type: PropTypes.string,
  name: PropTypes.string,
  pointsLow: PropTypes.number,
  pointsHigh: PropTypes.number,
  adjustmentStat: PropTypes.string,
  adjustmentValue: PropTypes.number,
  specialAttacks: PropTypes.arrayOf(SpecialRuleType),
  specialRules: PropTypes.arrayOf(SpecialRuleType)
});

export const RuleType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  warriorId: PropTypes.number,
  wargearId: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string
});

export const HeroicActionType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  warriorId: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string
});

export const SpellType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  warriorId: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  baseCastingValue: PropTypes.number,
  modifyCastingValue: PropTypes.number
});

export const WarriorType = PropTypes.shape({
  id: PropTypes.number,
  companyId: PropTypes.number,
  name: PropTypes.string,
  image: PropTypes.string,
  rank: PropTypes.string,
  experience: PropTypes.number,
  injured: PropTypes.number,
  baseClass: PropTypes.string,
  basePoints: PropTypes.number,
  totalPoints: PropTypes.number,
  chosenPath: PropTypes.string,
  customPath: PropTypes.string,
  baseStats: StatsType,
  modifyStats: StatsType,
  totalStats: StatsType,
  wargear: PropTypes.arrayOf(WargearType),
  specialRules: PropTypes.arrayOf(RuleType),
  spells: PropTypes.arrayOf(SpellType),
  heroicActions: PropTypes.arrayOf(HeroicActionType),
  owner: PropTypes.string
});

export const WarriorsType = PropTypes.oneOfType([
  PropTypes.objectOf(WarriorType),
  PropTypes.objectOf(PropTypes.bool)
]);

export const CompanyType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  race: PropTypes.string,
  backstory: PropTypes.string,
  influence: PropTypes.number,
  rating: PropTypes.number,
  currentRating: PropTypes.number,
  warriors: PropTypes.objectOf(WarriorType),
  retired: PropTypes.bool
});

export const CompaniesType = PropTypes.oneOfType([
  PropTypes.objectOf(CompanyType),
  PropTypes.objectOf(PropTypes.bool)
]);

export const MatchType = PropTypes.shape({
  params: PropTypes.shape({
    field1: PropTypes.number,
    field2: PropTypes.string
  })
});

export const AuthType = PropTypes.shape({
  loggedIn: PropTypes.bool,
  user: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    token: PropTypes.sting
  })
});

export const ProfileType = PropTypes.shape({
  id: PropTypes.number,
  race: PropTypes.string,
  baseClass: PropTypes.string,
  basePoints: PropTypes.number,
  totalPoints: PropTypes.number,
  chosenPath: PropTypes.string,
  baseStats: StatsType,
  totalStats: StatsType,
  wargear: PropTypes.arrayOf(WargearType),
  specialRules: PropTypes.arrayOf(RuleType)
});

export const SortOptionType = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  mode: PropTypes.string
});

export const DonationType = PropTypes.shape({
  donated: PropTypes.bool
});
