/* eslint-disable react/jsx-one-expression-per-line */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { WarriorType } from '../../../_types';
import { isHero } from '../../../_helpers/warrior';

import {
  Button, CharacteristicsTable, MWFTable, Form, TextInput
} from '../..';

const CharacteristicsForm = ({ warrior, handleInputChange, setActiveTab }) => (
  <Form
    buttons={(
      <Fragment>
        <Button text="Previous" secondary type="button" iconName="arrow-left" iconLeft onClick={() => setActiveTab(0)} />
        <Button text="Next" primary type="button" iconName="arrow-right" iconRight onClick={() => setActiveTab(2)} />
      </Fragment>
  )}
  >
    {!warrior.id && (
    <p className="help-text">
      Enter the base characteristics for the warrior, followed by any increases caused by progressions.<br /><br />Do not include wargear increases in this section.
    </p>
    )}
    <CharacteristicsTable
      showLabel
      inputs
      base={{
        move: <TextInput name="baseStats.move" value={warrior.baseStats.move} onChange={handleInputChange} clean />,
        fight: <TextInput name="baseStats.fight" value={warrior.baseStats.fight} onChange={handleInputChange} clean />,
        shoot: <TextInput name="baseStats.shoot" value={warrior.baseStats.shoot} onChange={handleInputChange} clean />,
        strength: <TextInput name="baseStats.strength" value={warrior.baseStats.strength} onChange={handleInputChange} clean />,
        defence: <TextInput name="baseStats.defence" value={warrior.baseStats.defence} onChange={handleInputChange} clean />,
        attacks: <TextInput name="baseStats.attacks" value={warrior.baseStats.attacks} onChange={handleInputChange} clean />,
        wounds: <TextInput name="baseStats.wounds" value={warrior.baseStats.wounds} onChange={handleInputChange} clean />,
        courage: <TextInput name="baseStats.courage" value={warrior.baseStats.courage} onChange={handleInputChange} clean />
      }}
      modified={isHero(warrior) ? {
        move: <TextInput name="modifyStats.move" value={warrior.modifyStats.move} onChange={handleInputChange} clean />,
        fight: <TextInput name="modifyStats.fight" value={warrior.modifyStats.fight} onChange={handleInputChange} clean />,
        shoot: <TextInput name="modifyStats.shoot" value={warrior.modifyStats.shoot} onChange={handleInputChange} clean />,
        strength: <TextInput name="modifyStats.strength" value={warrior.modifyStats.strength} onChange={handleInputChange} clean />,
        defence: <TextInput name="modifyStats.defence" value={warrior.modifyStats.defence} onChange={handleInputChange} clean />,
        attacks: <TextInput name="modifyStats.attacks" value={warrior.modifyStats.attacks} onChange={handleInputChange} clean />,
        wounds: <TextInput name="modifyStats.wounds" value={warrior.modifyStats.wounds} onChange={handleInputChange} clean />,
        courage: <TextInput name="modifyStats.courage" value={warrior.modifyStats.courage} onChange={handleInputChange} clean />
      } : null}
      total={warrior.subTotalStats}
    />
    {(warrior.rank !== '' && warrior.rank !== 'Warrior' && warrior.rank !== 'Creature') && (
    <MWFTable
      showLabel
      small
      base={{
        might: <TextInput name="baseStats.might" value={warrior.baseStats.might} onChange={handleInputChange} clean />,
        will: <TextInput name="baseStats.will" value={warrior.baseStats.will} onChange={handleInputChange} clean />,
        fate: <TextInput name="baseStats.fate" value={warrior.baseStats.fate} onChange={handleInputChange} clean />
      }}
      modified={isHero(warrior) ? {
        might: <TextInput name="modifyStats.might" value={warrior.modifyStats.might} onChange={handleInputChange} clean />,
        will: <TextInput name="modifyStats.will" value={warrior.modifyStats.will} onChange={handleInputChange} clean />,
        fate: <TextInput name="modifyStats.fate" value={warrior.modifyStats.fate} onChange={handleInputChange} clean />
      } : null}
      total={warrior.subTotalStats}
    />
    )}
  </Form>
);

CharacteristicsForm.propTypes = {
  warrior: WarriorType.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  setActiveTab: PropTypes.func.isRequired
};

export default CharacteristicsForm;
