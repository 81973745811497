import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

class RadioButtons extends PureComponent {
  render() {
    const {
      name, value, onChange, options
    } = this.props;
    return (
      <div className="radio-list">
        {options.map(option => (
          <label key={option.value} className="radio">
            <input name={name} type="radio" value={option.value} checked={option.value === value} onChange={onChange} />
            <span className="radio__input" />
            {option.label}
          </label>
        ))}
      </div>
    );
  }
}

RadioButtons.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  })).isRequired
};

export default RadioButtons;
