/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { CompaniesType, SortOptionType } from '../../_types';

import './styles.scss';
import EditCompanyOverlay from '../Overlay/EditCompanyOverlay';
import {
  Button, Icon, LinkButton, Loader, Overlay, Sort
} from '..';
import { TextInput } from '../Forms';
import ToolsOverlay from '../Overlay/ToolsOverlay';

class Header extends Component {
  constructor(props) {
    super(props);
    const { bonus } = props;
    this.state = {
      overlayOpen: false,
      shareOverlayOpen: false,
      toolsOverlayOpen: false,
      remainingBonus: bonus
    };
  }

  openOverlay = () => {
    this.setState({
      overlayOpen: true
    });
  }

  closeOverlay = () => {
    this.setState({
      overlayOpen: false
    });
  }

  openShareOverlay = () => {
    this.setState({
      shareOverlayOpen: true
    });
  }

  closeShareOverlay = () => {
    this.setState({
      shareOverlayOpen: false
    });
  }

  openToolsOverlay = () => {
    this.setState({
      toolsOverlayOpen: true
    });
  }

  closeToolsOverlay = () => {
    this.setState({
      toolsOverlayOpen: false
    });
  }

  adjustBonus = (event, amount) => {
    const { remainingBonus } = this.state;
    this.setState({
      remainingBonus: remainingBonus + amount
    });
  }

  abandonBattle = () => {
    const { history, companyId } = this.props;
    history.push(`/company/${companyId}`);
  }

  render() {
    const {
      companies, companyId, clickable, battleMode, bonus, endBattle, battleRating, sortOption, sortOptions, setSort, storageName
    } = this.props;
    const { overlayOpen, shareOverlayOpen, toolsOverlayOpen, remainingBonus } = this.state;

    if (companies.loading) {
      return <div className="header header--loading"><div className="header__top__inner"><Loader small /></div></div>;
    }

    const company = companies[companyId];
    const {
      name, race, rating, currentRating, influence, warriors
    } = company;

    let startingWarriors;
    let woundedWarriors;
    let breakPoint;
    let quarteredPoint;

    if (battleMode) {
      startingWarriors = _.filter(company.warriors, warrior => (!warrior.preBattleInjury && warrior.rank !== 'Creature'));
      woundedWarriors = _.filter(startingWarriors, warrior => (warrior.wounded || warrior.fled)).length;
      breakPoint = Math.ceil(startingWarriors.length * 0.501);
      quarteredPoint = Math.ceil(startingWarriors.length * 0.751);
    }

    return (
      <Fragment>
        {battleMode && (
          <div className="header no-print">
            <div className="header__top">
              <div className="header__top__inner">
                <HeaderTop
                  name={name}
                  race={race}
                  endBattle={endBattle}
                  battleMode
                />
              </div>
              <div className="header__buttons">
                <Button text="Quit" secondary block iconName="exit" onClick={this.abandonBattle} />
                <Button text="Tools" secondary block iconName="tools" onClick={this.openToolsOverlay} />
                <Button text="End Battle" primary block iconName="arrow-right" iconRight onClick={endBattle} />
              </div>
            </div>
            <div className="header__bottom">
              <HeaderBottom warriors={startingWarriors.length} rating={battleRating} currentRating={battleRating} influence={influence} />
              <div className="header__right">
                <span className="header__value">
                  <strong>{woundedWarriors >= breakPoint ? 'Broken' : `${woundedWarriors}/${breakPoint}`}</strong> Break Point
                </span>
                <span className="header__value">
                  <strong>{woundedWarriors >= quarteredPoint ? 'Quartered' : `${woundedWarriors}/${quarteredPoint}`}</strong> Quartered Point
                </span>
              </div>
              {bonus > 0 && (
                <div className="header__right">
                  <span className="header__value">Bonus Influence: <strong>{bonus}</strong></span>
                  <span className="header__value">
                    Remaining Re-rolls:
                    <div className="header-counter">
                      <span className="header-counter__val">{remainingBonus}</span>
                      <button
                        className="header-counter__dec"
                        disabled={remainingBonus <= 0}
                        onClick={e => this.adjustBonus(e, -1)}
                        type="button"
                      >
                        <Icon name="minus" />
                      </button>
                      <button
                        className="header-counter__inc"
                        disabled={remainingBonus >= bonus}
                        onClick={e => this.adjustBonus(e, 1)}
                        type="button"
                      >
                        <Icon name="plus" />
                      </button>
                    </div>
                  </span>
                </div>
              )}
            </div>
            {toolsOverlayOpen && (
              <ToolsOverlay handleClose={this.closeToolsOverlay} />
            )}
          </div>
        )}
        {!battleMode && (
          <div className="header no-print">
            <div className="header__top">
              {clickable ? (
                <button className="header__top__inner" type="button" onClick={this.openOverlay}>
                  <HeaderTop {...company} />
                </button>
              ) : (
                <div className="header__top__inner">
                  <HeaderTop {...company} />
                </div>
              )}
              <div className="header__buttons">
                {clickable && <Button text="Edit" secondary block iconName="feather" onClick={this.openOverlay} />}
                <Button text="Print" secondary block iconName="print" onClick={() => { window.print(); }} />
                {clickable && <Button text="Share" secondary block iconName="share" onClick={this.openShareOverlay} />}
                <Sort sortOption={sortOption} setSort={setSort} sortOptions={sortOptions} storageName={storageName} />
                {clickable && <LinkButton to={`/company/${companyId}/battle`} text="Start Battle" primary block iconName="arrow-right" iconRight />}
              </div>
            </div>
            <div className="header__bottom">
              <HeaderBottom warriors={_.map(warriors, warrior => warrior.warriorRank || warrior.rank).filter(rank => rank !== 'Creature').length} rating={rating} currentRating={currentRating} influence={influence} />
            </div>
            {(clickable && overlayOpen) && (
              <EditCompanyOverlay company={{ ...company }} handleClose={this.closeOverlay} />
            )}
            {(clickable && shareOverlayOpen) && (
              <Overlay id="share-company" title="Share your company" handleClose={this.closeShareOverlay} open isSmall>
                <div className="form__container">
                  <div className="form__inner">
                    <p>To share your company with your friends, simply send them the link below. They will be able to view and print your company, but not make any changes.</p>
                    <TextInput name="companyUrl" value={company.shareUrl} readOnly focusSelect onChange={() => {}} />
                  </div>
                </div>
              </Overlay>
            )}
          </div>
        )}
      </Fragment>
    );
  }
}

const HeaderTop = ({
  name, race
}) => (
  <div className="header__left">
    <span className="header__name">{name}</span>
    <span className="header__race">{race}</span>
  </div>
);

const HeaderBottom = ({
  warriors, rating, currentRating, influence
}) => (
  <div className="header__left">
    <span className="header__value">
      <strong>{warriors}</strong>
      Warriors
    </span>
    <span className="header__value">
      <strong>
        {rating}
        {currentRating !== rating ? ` (${currentRating})` : ''}
      </strong>
      Rating
    </span>
    <span className="header__value">
      <strong>{influence}</strong>
      Influence
    </span>
  </div>
);

Header.propTypes = {
  companies: CompaniesType.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  battleMode: PropTypes.bool,
  bonus: PropTypes.number,
  endBattle: PropTypes.func,
  battleRating: PropTypes.number,
  history: ReactRouterPropTypes.history.isRequired,
  clickable: PropTypes.bool,
  sortOption: SortOptionType,
  sortOptions: PropTypes.arrayOf(SortOptionType),
  setSort: PropTypes.func,
  storageName: PropTypes.string
};

Header.defaultProps = {
  battleMode: false,
  bonus: 0,
  endBattle: () => {},
  battleRating: null,
  clickable: false,
  sortOption: null,
  sortOptions: [],
  setSort: null,
  storageName: null
};

HeaderTop.propTypes = {
  name: PropTypes.string.isRequired,
  race: PropTypes.string.isRequired
};

HeaderBottom.propTypes = {
  rating: PropTypes.number.isRequired,
  currentRating: PropTypes.number.isRequired,
  influence: PropTypes.number.isRequired,
  warriors: PropTypes.number.isRequired
};

export default withRouter(Header);
