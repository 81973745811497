import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Icon } from '..';
import Modal from './OverlayPortal';

import './styles.scss';

class Overlay extends Component {
  generateOverlayClass() {
    const { isSmall } = this.props;
    const cls = ['overlay overlay--open'];
    if (isSmall) cls.push('overlay--small');
    return cls.join(' ');
  }

  render() {
    const {
      title, children, handleClose, id
    } = this.props;
    return (
      <Modal>
        <div className={this.generateOverlayClass()} id={id}>
          <div className="overlay__inner">
            {handleClose && (
            <button className="overlay__close" onClick={handleClose} type="button">
              <Icon name="cross" />
            </button>
            )}
            <h1>{title}</h1>
            <div className="overlay__content">
              {children}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

Overlay.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  handleClose: PropTypes.func,
  isSmall: PropTypes.bool
};

Overlay.defaultProps = {
  id: null,
  isSmall: false,
  handleClose: null
};

export default Overlay;
