import { userConstants } from '../_actions';

export default function (state = {}, action) {
  switch (action.type) {
    case userConstants.CHECK_DONATION_SUCCESS:
      return {
        donated: !!action.response.donated
      };
    default:
      return state;
  }
}
