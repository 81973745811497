/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { AuthType } from '../_types';
import { LoginForm } from '../_components';

import './styles.scss';
import patroenButton from '../_assets/patreon.png';
import paypalButton from '../_assets/paypal.png';

class AboutPage extends PureComponent {
  render() {
    const { authentication } = this.props;

    return (
      <div className="text-page">
        <div className="intro">
          <div className="intro__text">
            <h3>Battle Companies Manager</h3>
            <h1>About</h1>
            <div className="right">
              <a href="https://www.patreon.com/bePatron?u=17808468" className="donate" target="_blank" rel="noopener noreferrer">
                <img src={patroenButton} alt="Patreon" />
              </a>
              <strong>Or</strong>
              <form action="https://www.paypal.com/donate" method="post" target="_top" className="donate">
                <input type="hidden" name="hosted_button_id" value="QZ46U9CJF7EFE" />
                <input type="image" src={paypalButton} border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                <img alt="" border="0" src="https://www.paypal.com/en_GB/i/scr/pixel.gif" width="1" height="1" />
              </form>
            </div>
            <p>Battle Companies Manager is developed and hosted by Pat Gaunt, a player and fan of the game, with contributions from Marcel Hollink.</p>
            <p>To create the best experience possible, I avoided adding adverts, however as the user base has increased, so have the costs. If you find the app useful, please consider donating to help support the continued development, and as a thank you I will disable adverts for your account (Minimum donation of £1 due to fees). Alternatively, any purchases you make from Element Games after clicking on the adverts provides me with a small kick back.</p>
            <p>Please note, removing adverts is currently a manual process, so the adverts will not disappear immediately. If you are still seeing adverts 24 hours after your donation please contact me through the Facebook group.</p>
          </div>
          {!authentication.loggedIn && (
          <div className="login-form text-page-form">
            <h3>Login</h3>
            <LoginForm />
          </div>
          )}
        </div>
        <div>
          <h2>Roadmap</h2>
          <p>There&apos;s still some way to go until the app is complete, below are a few features that I plan to add over time.</p>
          <ul className="styled">
            <li><s><span><strong>Custom Sorting</strong> - allow you to select the order items and warriors are shown in on the various pages</span></s></li>
            <li><span><strong>Groups</strong> - add ability to create groups with your friends so it&apos;s easier to track progress through campaigns.</span></li>
            <li><span><strong>Battle History</strong> - store past battles with their results, so you can track the progression of your battle company.</span></li>
            <li><span><strong>Map Based Campaigns</strong> - better support for map based campaign rewards.</span></li>
          </ul>
        </div>
      </div>
    );
  }
}

AboutPage.propTypes = {
  authentication: AuthType.isRequired
};

function mapStateToProps({ authentication }) {
  return { authentication };
}

export default connect(mapStateToProps)(AboutPage);
