/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import Cropper from 'react-easy-crop';
import { WarriorType } from '../../_types';
import { isHero } from '../../_helpers/warrior';

import {
  Card, CharacteristicsTable, MWFTable, Icon, RulesList, Button
} from '..';
import EditWarriorOverlay from '../Overlay/EditWarriorOverlay';

import blood from '../../_assets/blood.png';

class WarriorCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      crop: { x: 0, y: 0 },
      rotation: 0,
      zoom: 1
    };
  }

  get cardDetails() {
    const {
      warrior, battleMode, alterStat
    } = this.props;
    const {
      id,
      rank,
      totalStats,
      wargear,
      specialRules,
      heroicActions,
      spells,
      experience,
      remainingWounds,
      remainingMight,
      remainingWill,
      remainingFate,
      woundsInflicted
    } = warrior;

    const filteredHeroicActions = _.filter(heroicActions, ({ remove }) => !remove);
    const filteredSpells = _.filter(spells, ({ remove }) => !remove);
    const showRightContainer = (!!filteredHeroicActions && !!filteredHeroicActions.length) || (!!filteredSpells && !!filteredSpells.length)

    return (
      <Fragment>
        <CharacteristicsTable total={totalStats} />
        {!!(isHero(warrior) || (warrior.rank === 'Wanderer' && (totalStats.might || totalStats.will || totalStats.fate))) && (
          <MWFTable
            total={totalStats}
            remainingTotal={{ remainingMight, remainingWill, remainingFate }}
            battleMode={battleMode && !warrior.preBattleInjury}
            onChange={this.alterMWF}
          />
        )}
        <div className="card__rules__container">
          <div className="card__rules__container__left">
            <RulesList title="Wargear" rules={_.filter(wargear, ({ remove }) => !remove)} clickable />
            <RulesList
              title="Special Rules"
              rules={_.filter(specialRules, (rule) => {
                let wargearItem;
                if (rule.wargearId) {
                  wargearItem = _.find(warrior.wargear, { id: rule.wargearId });
                }
                return !rule.remove && !(wargearItem && wargearItem.remove);
              })}
              clickable
            />
          </div>
          {showRightContainer && (
            <div className="card__rules__container__right">
              {!!filteredHeroicActions && !!filteredHeroicActions.length && (
                <RulesList
                  title="Heroic Actions"
                  rules={_.map(filteredHeroicActions, heroicAction => ({
                    id: heroicAction.id,
                    name: heroicAction.name,
                    description: heroicAction.description
                  }))}
                  clickable
                />
              )}
              {!!filteredSpells && !!filteredSpells.length && (
                <RulesList
                  title="Spells"
                  rules={_.map(filteredSpells, spell => ({
                    id: spell.id,
                    name: `${spell.name} (${spell.baseCastingValue - spell.modifyCastingValue}+)`,
                    description: spell.description
                  }))}
                  clickable
                />
              )}
            </div>
          )}
        </div>
        {(rank !== 'Wanderer' && rank !== 'Creature') && (
        <Fragment>
          <div className="print marker-circles marker-circles__experience">
            <small>Experience</small><br/>
            {_.times(105, index => <div key={index} className={`marker-circle ${index < experience ? 'marker-circle--filled' : ''}`} />)}
          </div>
          <div className="card__experience no-print">
            <span className="card__experience__level">{Math.floor(experience / 5) + 1}</span>
            <span className="experience-bar-title">{isHero(warrior) ? 'Hero' : 'Warrior'} Level</span>
            <div className={`experience-bar experience-bar--${experience % 5}`}>
              <div className="experience-bar__inner" />
            </div>
          </div>
        </Fragment>
        )}
        <div className="card__bottom print">
          <div className="card__counters">
            <div className="card-counter">
              <span className="card-counter__title">Wounds Inflicted</span>
              <Icon name="swords" />
              <div className="marker-circles marker-circles__wounds-inflicted">
                {_.times(10, index => <div key={index} className="marker-circle" />)}
              </div>
            </div>
            <div className="card-counter">
              <span className="card-counter__title">Wounds Remaining</span>
              <Icon name="shield" />
              <div className="marker-circles">
                {_.times(totalStats.wounds, index => <div key={index} className="marker-circle" />)}
              </div>
            </div>
          </div>
        </div>
        {(battleMode && !warrior.preBattleInjury) && (
          <div className="card__bottom no-print">
            <div className="card__counters">
              <div className="card-counter">
                <span className="card-counter__title">Wounds Inflicted</span>
                <Icon name="swords" />
                <span className="card-counter__val" id="inflicted">{woundsInflicted}</span>
                <div className="card-counter__controls">
                  <button className="card-counter__dec" type="button" onClick={e => alterStat(e, 'woundsInflicted', -1, id)} disabled={woundsInflicted <= 0}>
                    <Icon name="minus" />
                  </button>
                  <button className="card-counter__inc" type="button" onClick={e => alterStat(e, 'woundsInflicted', 1, id)}>
                    <Icon name="plus" />
                  </button>
                </div>
              </div>
              <div className="flee-container">
                <Button text="Flee" iconName="flee" onClick={e => alterStat(e, 'fled', 1, id)} />
              </div>
              <div className="card-counter">
                <span className="card-counter__title">Wounds Remaining</span>
                <Icon name="shield" />
                <span className="card-counter__val" id="remaining">{remainingWounds}</span>
                <div className="card-counter__controls">
                  <button className="card-counter__dec" type="button" onClick={e => alterStat(e, 'remainingWounds', -1, id)} disabled={remainingWounds <= 0}>
                    <Icon name="minus" />
                  </button>
                  <button className="card-counter__inc" type="button" onClick={e => alterStat(e, 'remainingWounds', 1, id)} disabled={remainingWounds >= totalStats.wounds}>
                    <Icon name="plus" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }

  onCropChange = (crop) => {
    this.setState({ crop });
  }

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  }

  changeRotation = () => {
    const { rotation } = this.state;
    let r = rotation + 90;
    if (r === 360) {
      r = 0;
    }
    this.setState({ rotation: r });
  }

  onRotationChange = (rotation) => {
    this.setState({ rotation });
  }

  alterMWF = (name, value) => {
    const { warrior, alterStat } = this.props;
    const change = value - warrior[name];
    alterStat(null, name, change, warrior.id);
  }

  generateCardClass() {
    const {
      flipped, overlay, warrior
    } = this.props;
    const cls = [];
    if (flipped) cls.push('card--flip');
    if (overlay) cls.push('card--overlay');
    if (warrior.animateOut) cls.push('card--animate-out');
    return cls.join(' ');
  }

  render() {
    const {
      companyId, warrior, clickable, editable, form, onCropComplete, isGraveyard, graveyard, warriors
    } = this.props;
    const {
      crop, zoom, rotation
    } = this.state;
    const {
      id,
      rank,
      name,
      totalPoints,
      image,
      tempImage,
      baseClass,
      chosenPath,
      customPath,
      legWound,
      armWound,
      oldWound,
      status,
      wounded,
      fled,
      preBattleInjury,
      owner
    } = warrior;

    let backText = '';
    let ownerName = '';
    let pathText = '';
    if (status === 'lost') backText = 'Lost in Battle';
    if (status === 'injured') backText = 'Injured';
    if (wounded) backText = 'Wounded';
    if (fled) backText = 'Fled';
    if (preBattleInjury) backText = 'Took no part';

    if (chosenPath) {
      pathText = chosenPath !== 'Custom' ? `Path of the ${chosenPath}` : customPath ? `Path of the ${customPath}` : ''
    } else if (owner) {
      pathText = `Owned by ${ownerName}`
    }

    if (owner) {
      const ownerProfile = isGraveyard ? graveyard[owner] : warriors[owner]
      ownerName = ownerProfile ? ownerProfile.name : ''
    }

    return (
      <Card
        id={id}
        title={(
          <>
            {name}
            <div className="injuries print">
              {!!legWound && <span className="injury" title="Leg Wound"><Icon name="injury-leg" /></span>}
              {!!armWound && <span className="injury" title="Arm Wound"><Icon name="injury-arm" /></span>}
              {!!oldWound && <span className="injury" title="Old Battle Wound"><Icon name="injury-old" /></span>}
            </div>
          </>
        )}
        points={totalPoints}
        tab={rank}
        printStatus={(
          <Fragment>
            {status === 'lost' && <span className="injury-status print">Lost in Battle</span>}
            {status === 'injured' && <span className="injury-status print">Injured</span>}
          </Fragment>
        )}
        tabClass={(rank || '').toLowerCase()}
        backText={backText}
        showBlood={status === 'injured'}
        className={this.generateCardClass()}
        overlay={(
          <EditWarriorOverlay
            warrior={{ ...warrior }}
            companyId={companyId}
            isGraveyard={isGraveyard}
            editable={editable}
          />
        )}
        clickable={clickable}
      >
        <div className="card__image" style={image && !tempImage ? { backgroundImage: `url('${image}')` } : {}}>
          {tempImage && (
            <Fragment>
              <Cropper
                image={tempImage}
                crop={crop}
                zoom={zoom}
                zoomSpeed={0.25}
                rotation={rotation}
                aspect={800 / 480}
                cropSize={{ width: 300, height: 180 }}
                onCropChange={this.onCropChange}
                onCropComplete={(croppedArea, croppedAreaPixels) => onCropComplete(
                  croppedArea, croppedAreaPixels, rotation
                )
                }
                onZoomChange={this.onZoomChange}
              />
              <Button text="Rotate" iconName="rotate" primary iconRight onClick={this.changeRotation} />
            </Fragment>
          )}
          <span className="injuries">
            {!!legWound && <span className="injury" title="Leg Wound"><Icon name="injury-leg" /></span>}
            {!!armWound && <span className="injury" title="Arm Wound"><Icon name="injury-arm" /></span>}
            {!!oldWound && <span className="injury" title="Old Battle Wound"><Icon name="injury-old" /></span>}
            {status === 'lost' && <span className="injury" title="Lost in Battle"><Icon name="injury-lost" /></span>}
          </span>
          {status === 'injured' && <img src={blood} className="blood" alt="Blood" />}
        </div>
        <div className="card__details">
          <span className="card__details__class">{baseClass || '-'}</span>
          <span className="card__details__path">
            {pathText}
          </span>
        </div>
        {form || this.cardDetails}
      </Card>
    );
  }
}

WarriorCard.propTypes = {
  companyId: PropTypes.number.isRequired,
  warrior: WarriorType.isRequired,
  battleMode: PropTypes.bool,
  clickable: PropTypes.bool,
  editable: PropTypes.bool,
  flipped: PropTypes.bool,
  overlay: PropTypes.bool,
  isGraveyard: PropTypes.bool,
  alterStat: PropTypes.func,
  form: PropTypes.node,
  onCropComplete: PropTypes.func
};

WarriorCard.defaultProps = {
  battleMode: false,
  clickable: false,
  editable: false,
  flipped: false,
  overlay: false,
  isGraveyard: false,
  alterStat: () => {},
  form: null,
  onCropComplete: () => {}
};

function mapStateToProps({ warriors, graveyard }) {
  return { warriors, graveyard };
}

export default connect(mapStateToProps)(WarriorCard);
