import _ from 'lodash';
import { spellConstants } from '../_actions';

function formatItems(spells) {
  if (!spells) {
    return {};
  }

  return _.mapKeys(spells, 'id');
}

function formatItem(spell, state) {
  if (!spell) {
    return {};
  }
  const newState = { ...state };
  newState[spell.id] = spell;
  return newState;
}

export default function (state = { loading: true }, action) {
  switch (action.type) {
    case spellConstants.GET_REQUEST:
      return { loading: true };
    case spellConstants.GET_SUCCESS:
      return formatItems(action.spell);
    case spellConstants.GET_FAILURE:
      return { error: action.error };

    case spellConstants.CREATE_SUCCESS:
      return formatItem(action.spellResponse, state);

    case spellConstants.UPDATE_SUCCESS:
      return formatItem(action.spellResponse, state);

    case spellConstants.DELETE_SUCCESS:
      return _.omit(state, action.idResponse.id);

    default:
      return state;
  }
}
