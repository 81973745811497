/* eslint-disable react/jsx-one-expression-per-line */
import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import EditWargearOverlay from '../Overlay/EditWargearOverlay';
import { WargearType } from '../../_types';
import { Card, CharacteristicsTable } from '..';
import { RulesItem } from '../RulesList/RulesItem';

class WargearCard extends Component {
  ruleList = (title, value, className) => (
    <div className={`card__rules card__rules--${className}`}>
      <span className="card__rules__header">
        {title}
      </span>
      <div className="card__rules__inner">
        {value}
      </div>
    </div>
  )

  getRuleItems = items => _.map(_.filter(items, 'name'), (item, index) => (<RulesItem key={index} item={item} clickable={!!item.description} />))

  specialAttacks = () => {
    const { item: { specialAttacks: attacks } } = this.props;
    return this.getRuleItems(attacks);
  }

  specialRules = () => {
    const { item: { specialRules: rules } } = this.props;
    return this.getRuleItems(rules);
  }


  render() {
    const { item } = this.props;
    return (
      <Card
        id={item.id}
        title={item.name}
        className="card--wargear"
        overlay={<EditWargearOverlay item={{ ...item }} />}
        clickable
      >
        {!!item.mount && (
          <CharacteristicsTable
            total={{
              move: item.move,
              fight: item.fight,
              shoot: item.shoot,
              strength: item.strength,
              defence: item.defence,
              attacks: item.attacks,
              wounds: item.wounds,
              courage: item.courage
            }}
          />
        )}
        {!!item.adjustmentValue && this.ruleList('Characteristics', `+${item.adjustmentValue} ${item.adjustmentStat}`, 'wargear')}
        {!!item.specialAttacks.length && this.ruleList('Attacks', <Fragment>{this.specialAttacks()}</Fragment>, 'attacks')}
        {!!item.specialRules.length && this.ruleList('Special Rules', <Fragment>{this.specialRules()}</Fragment>, 'special-rules')}
      </Card>
    );
  }
}

WargearCard.propTypes = {
  item: WargearType.isRequired
};

export default WargearCard;
