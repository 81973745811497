/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { PureComponent } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  Overlay, Form, Button, SwitchInput
} from '../..';
import { WarriorsType } from '../../../_types';

class OldBattleWounds extends PureComponent {
  constructor(props) {
    super(props);
    const { warriors } = this.props;
    this.state = {
      warriors
    };
  }

  handleInputChange = (event, warriorId) => {
    const { target } = event;
    const value = target.checked;

    const { warriors } = this.state;
    const warrior = warriors[warriorId];

    warrior.preBattleInjury = !value;

    this.setState({
      warriors: { ...warriors, [warriorId]: warrior }
    });
  }

  render() {
    const { onSubmit } = this.props;
    const { warriors } = this.state;

    const woundedWarriors = { ...warriors };
    const count = _.size(woundedWarriors);

    return (
      <Overlay title="Old Battle Wounds" isSmall open>
        <Form buttons={<Button text="Continue" iconName="arrow-right" iconRight primary onClick={() => onSubmit(woundedWarriors)} />}>
          <p className="help-text">You have {count} hero{count !== 1 ? 'es' : ''} with old battle wounds, roll for their injuries and record the result below.</p>
          {_.map(warriors, warrior => (
            <div className="battle-wound" key={warrior.id}>
              <span className="battle-wound__name">{warrior.name}</span>
              <SwitchInput name="preBattleInjury" onLabel="Ready" offLabel="Injured" value={!warrior.preBattleInjury} onChange={e => this.handleInputChange(e, warrior.id)} />
            </div>
          ))}
        </Form>
      </Overlay>
    );
  }
}

OldBattleWounds.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  warriors: WarriorsType.isRequired
};

export default OldBattleWounds;
