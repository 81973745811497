import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { CompaniesType } from '../_types';
import { emptyCompany } from '../_constants';
import { useStoredSort } from '../_hooks/useStoredSort';

import {
  Grid, CompanyDeck, NewCompanyDeck, Loader, Advert, PageHeader
} from '../_components';

const storageName = 'sort-companies';
const sortOptions = [
  { label: 'Created', value: 'id', mode: 'asc' },
  { label: 'Created', value: 'id', mode: 'desc' },
  { label: 'Name', value: o => o.name.toLowerCase(), mode: 'asc' },
  { label: 'Name', value: o => o.name.toLowerCase(), mode: 'desc' },
  { label: 'Rating', value: 'rating', mode: 'asc' },
  { label: 'Rating', value: 'rating', mode: 'desc' }
];

function Companies({ companies }) {
  const [sort, setSort] = useState(useStoredSort(sortOptions, 0, storageName));

  let i = 0;

  if (companies.loading) {
    return <Loader />;
  }

  const orderedCompanies = _.orderBy(_.filter(companies, c => !c.retired), [sort.value], [sort.mode]);

  return (
    <Fragment>
      <Advert isWide />
      <PageHeader title="My Companies" sortOption={sort} setSort={setSort} sortOptions={sortOptions} storageName={storageName} />
      <Grid className="company-grid">
        {_.map(orderedCompanies, company => (
          <Fragment key={company.id}>
            <CompanyDeck company={company} clickable />
            {(i++ % 4 === 0) && <Advert />}
          </Fragment>
        ))}
        <NewCompanyDeck key="new" company={{ ...emptyCompany }} />
      </Grid>
    </Fragment>
  );
}

Companies.propTypes = {
  companies: CompaniesType.isRequired
};

function mapStateToProps(state) {
  return { companies: state.companies };
}

export default connect(mapStateToProps)(Companies);
