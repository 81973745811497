import _ from 'lodash';

export function isHero(warrior) {
  return warrior.rank === 'Hero' || warrior.rank === 'Leader' || warrior.rank === 'Sergeant';
}

export function calculateWarriorPoints(warrior) {
  const { baseStats } = warrior;
  const { modifyStats } = warrior;
  const highPoints = (
    parseInt(baseStats.attacks, 10)
    + parseInt(modifyStats.attacks, 10)
    + parseInt(baseStats.wounds, 10)
    + parseInt(modifyStats.wounds, 10)
  ) > 2;

  // Base points
  const points = warrior.basePoints;

  // Stat increases
  const mainStats = _.reduce([
    modifyStats.move,
    modifyStats.fight,
    modifyStats.strength,
    modifyStats.defence,
    modifyStats.courage,
    modifyStats.might,
    modifyStats.will,
    modifyStats.fate
  ], (sum, n) => sum + (n * 5), 0);

  // Attack/Wound increases
  const attacksWounds = _.reduce([
    modifyStats.attacks, modifyStats.wounds
  ], (sum, n) => sum + (n * 10), 0);

  const wargear = _.reduce(_.map(warrior.wargear, (item) => {
    if (item.remove) {
      return 0;
    }
    if (item.type === 'base') {
      return parseInt(item.pointsLow, 10);
    }
    return highPoints ? parseInt(item.pointsHigh, 10) : parseInt(item.pointsLow, 10);
  }), (sum, n) => (sum + n), 0);

  // Rules
  const rulePoints = (_.filter(warrior.specialRules, rule => rule.type === 'progression' && !rule.remove).length * 5);

  const totalPoints = points + mainStats + attacksWounds + wargear + rulePoints;

  return {
    ...warrior,
    totalPoints
  };
}

function calculateStat(statName, warrior) {
  const base = warrior.baseStats[statName.toLowerCase()];
  const modified = warrior.modifyStats[statName.toLowerCase()];

  if (statName === 'Shoot') {
    return parseInt(base || 0, 10) - parseInt(modified || 0, 10);
  }
  return parseInt(base || 0, 10) + parseInt(modified || 0, 10);
}

function calculateStatWithWargear(statName, warrior, subTotalStats) {
  const activeWargear = _.filter(warrior.wargear, ({ remove }) => !remove);
  const filteredWargear = _.filter(activeWargear, { adjustmentStat: statName });
  const wargear = _.reduce(filteredWargear,
    (sum, item) => sum + parseInt(item.adjustmentValue, 10), 0);

  if (statName === 'Shoot') {
    return subTotalStats[statName.toLowerCase()] - parseInt(wargear || 0, 10);
  }
  return subTotalStats[statName.toLowerCase()] + parseInt(wargear || 0, 10);
}

export function calculateStatTotals(warrior) {
  const subTotalStats = {
    move: calculateStat('Move', warrior),
    fight: calculateStat('Fight', warrior),
    shoot: calculateStat('Shoot', warrior),
    strength: calculateStat('Strength', warrior),
    defence: calculateStat('Defence', warrior),
    attacks: calculateStat('Attacks', warrior),
    wounds: calculateStat('Wounds', warrior),
    courage: calculateStat('Courage', warrior),
    might: calculateStat('Might', warrior),
    will: calculateStat('Will', warrior),
    fate: calculateStat('Fate', warrior)
  };
  const totalStats = {
    move: calculateStatWithWargear('Move', warrior, subTotalStats),
    fight: calculateStatWithWargear('Fight', warrior, subTotalStats),
    shoot: calculateStatWithWargear('Shoot', warrior, subTotalStats),
    strength: calculateStatWithWargear('Strength', warrior, subTotalStats),
    defence: calculateStatWithWargear('Defence', warrior, subTotalStats),
    attacks: calculateStatWithWargear('Attacks', warrior, subTotalStats),
    wounds: calculateStatWithWargear('Wounds', warrior, subTotalStats),
    courage: calculateStatWithWargear('Courage', warrior, subTotalStats),
    might: calculateStatWithWargear('Might', warrior, subTotalStats),
    will: calculateStatWithWargear('Will', warrior, subTotalStats),
    fate: calculateStatWithWargear('Fate', warrior, subTotalStats)
  };

  if (warrior.legWound) {
    totalStats.move -= 1;
  }

  return {
    ...warrior,
    totalStats,
    subTotalStats
  };
}
