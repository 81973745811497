/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-restricted-globals */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  WarriorType, CompaniesType, ProfileType, WargearType, RuleType, SpellType, HeroicActionType
} from '../../../_types';
import {
  emptySpecialRule, emptyHeroicAction, emptySpell, emptyWargearItem, emptyWarrior
} from '../../../_constants';
import {
  warriorActions, profileActions, graveyardActions, ruleActions, wargearActions, heroicActionActions, spellActions
} from '../../../_actions';
import {
  calculateWarriorPoints, calculateStatTotals, calculateProfilePoints, calculateProfileStatTotals
} from '../../../_helpers';
import {
  Form, Button, Overlay, Tabs, WarriorCard, ProfileCard
} from '../..';

import DetailsForm from './DetailsForm';
import CharacteristicsForm from './CharacteristicsForm';
import RuleForm from './RuleForm';
import HeroicActionForm from './HeroicActionForm';
import SpellForm from './SpellForm';
import WargearForm from './WargearForm';
import InjuriesForm from './InjuriesForm';
import OverviewForm from './OverviewForm';

const errorMessage = 'You have unsaved changes on this page, are you sure you want to continue? Unsaved changes will be lost.'

const createImage = url => new Promise((resolve, reject) => {
  const image = new Image();
  image.addEventListener('load', () => resolve(image));
  image.addEventListener('error', error => reject(error));
  image.src = url;
});

async function getCroppedImg(imageSrc, pixelCrop) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  ctx.preserveDrawingBuffer = true;

  // if no rotation, return cropped and resized image
  if (pixelCrop.rotation === 0) {
    canvas.width = 800;
    canvas.height = 480;
    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      800,
      480
    );

    return canvas.toDataURL('image/jpeg');
  }

  // set width to double image size to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = image.width * 2;
  canvas.height = image.height * 2;

  // translate canvas context to a central location to allow rotating around the center.
  ctx.translate(image.width, image.height);
  ctx.rotate((pixelCrop.rotation * Math.PI) / 180);
  ctx.translate(-image.width, -image.height);

  // draw rotated image and store data.
  ctx.drawImage(image, image.width / 2, image.height / 2);
  const data = ctx.getImageData(0, 0, image.width * 2, image.height * 2);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    0 - image.width / 2 - pixelCrop.x,
    0 - image.height / 2 - pixelCrop.y
  );

  const newCanvas = document.createElement('canvas');
  const newCtx = newCanvas.getContext('2d');

  newCanvas.width = 800;
  newCanvas.height = 480;
  newCtx.drawImage(canvas, 0, 0, 800, 480);

  // As Base64 string
  return newCanvas.toDataURL('image/jpeg');

  // As a blob
  // return new Promise((resolve) => {
  //   canvas.toBlob((file) => {
  //     resolve(file);
  //   }, 'image/jpeg');
  // });
}

class EditOverlay extends Component {
  constructor(props) {
    super(props);
    const { warrior } = this.props;
    this.state = {
      warrior: {
        id: warrior.id,
        companyId: warrior.companyId,
        name: warrior.name,
        biography: warrior.biography,
        race: warrior.race,
        image: warrior.image,
        rank: warrior.rank,
        experience: warrior.experience,
        status: warrior.status,
        legWound: warrior.legWound,
        armWound: warrior.armWound,
        oldWound: warrior.oldWound,
        baseClass: warrior.baseClass,
        owner: warrior.owner,
        basePoints: warrior.basePoints,
        totalPoints: warrior.totalPoints,
        chosenPath: warrior.chosenPath,
        customPath: warrior.customPath,
        baseStats: _.clone(warrior.baseStats),
        modifyStats: _.clone(warrior.modifyStats),
        subTotalStats: _.clone(warrior.subTotalStats),
        totalStats: _.clone(warrior.totalStats),
        wargear: _.clone(warrior.wargear),
        specialRules: _.clone(warrior.specialRules),
        heroicActions: _.clone(warrior.heroicActions),
        spells: _.clone(warrior.spells),
        influence: 0
      },
      activeTab: 0,
      submitting: false,
      detailsFormSubmitted: false,
      deleting: false,
      selectedProfile: '',
      crop: {},
      hasEdited: false
    };
  }

  setActiveTab = (index) => {
    const { profile, isGraveyard } = this.props;
    const { warrior, activeTab } = this.state;

    if (!isGraveyard) {
      const tabs = this.getTabs()
      const tabName = tabs[activeTab].name

      switch (tabName) {
        case 'Details':
          if ((!profile && (warrior.name === '' || warrior.rank === '' || (warrior.chosenPath === 'Custom' && !warrior.customPath))) || (profile && warrior.baseClass === '') || (!profile && warrior.tempImage)) {
            this.setState({
              detailsFormSubmitted: true
            });
            return;
          }
          break;
        case 'Wargear':
          const itemName = document.querySelector('#new-wargear-item #name');
          const armouryItem = document.querySelector('#new-wargear-item #armouryId');
          if ((itemName && itemName.value) || (armouryItem && armouryItem.value !== '' && armouryItem.value !== 'new')) {
            if (!confirm(errorMessage)) {
              return;
            }
          }
          break;
        case 'Rules':
          const ruleName = document.querySelector('#new-rule #name');
          const ruleItem = document.querySelector('#new-rule #myRuleId');          
          if ((ruleName && ruleName.value) || (ruleItem && ruleItem.value !== '' && ruleItem.value !== 'new')) {
            if (!confirm(errorMessage)) {
              return;
            }
          }
          break;
        case 'Heroic Actions':
          const heroicName = document.querySelector('#new-heroic-action #name');
          const heroicItem = document.querySelector('#new-heroic-action #myHeroicActionsId');          
          if ((heroicName && heroicName.value) || (heroicItem && heroicItem.value !== '' && heroicItem.value !== 'new')) {
            if (!confirm(errorMessage)) {
              return;
            }
          }
          break;
        case 'Spells':
          const spellName = document.querySelector('#new-spell #name');
          const spellItem = document.querySelector('#new-spell #mySpellsId');          
          if ((spellName && spellName.value) || (spellItem && spellItem.value !== '' && spellItem.value !== 'new')) {
            if (!confirm(errorMessage)) {
              return;
            }
          }
          break;
        default:
          break;
      }
    }

    this.setState({
      detailsFormSubmitted: false,
      activeTab: index
    });
  }

  selectProfile = (event) => {
    const { profiles } = this.props;
    const { warrior } = this.state;
    const { target: { value } } = event;

    const profileWarrior = { ...emptyWarrior, ...profiles[value] };

    const specialRules = _.map(profileWarrior.specialRules, (rule, index) => ({
      ...rule,
      id: parseInt(`${new Date().valueOf()}${index}`, 10),
      new: true,
      profileId: null
    }));

    _.map(warrior.specialRules, (rule) => {
      if (rule.type === 'progression') {
        specialRules.push(rule);
      } else {
        specialRules.push({ ...rule, remove: true });
      }
    });

    const wargear = _.map(profileWarrior.wargear, (item, index) => ({
      ...item,
      id: parseInt(`${new Date().valueOf()}${index}`, 10),
      new: true,
      profileId: null
    }));

    _.map(warrior.wargear, (item) => {
      if (item.type === 'armoury') {
        wargear.push(item);
      } else {
        wargear.push({ ...item, remove: true });
      }
      if (item.ruleName) {
        specialRules.push({
          id: `wargear${item.id}`,
          name: item.ruleName,
          description: item.ruleDescription,
          type: 'wargear'
        });
      }
    });

    const modifyStats = _.clone(warrior.modifyStats);
    if (profileWarrior.baseStats.might || profileWarrior.baseStats.will || profileWarrior.baseStats.fate) {
      modifyStats.might = 0;
      modifyStats.will = 0;
      modifyStats.fate = 0;
    }

    let loadedWarrior = {
      ...profileWarrior,
      id: warrior.id,
      companyId: warrior.companyId,
      name: warrior.name,
      image: warrior.image,
      rank: warrior.rank,
      experience: warrior.experience,
      status: warrior.status,
      legWound: warrior.legWound,
      armWound: warrior.armWound,
      oldWound: warrior.oldWound,
      chosenPath: warrior.chosenPath,
      customPath: warrior.customPath,
      modifyStats,
      wargear,
      specialRules,
      heroicActions: [],
      spells: [],
      influence: 0
    };

    loadedWarrior = calculateWarriorPoints(loadedWarrior);
    loadedWarrior = calculateStatTotals(loadedWarrior);

    this.setState({
      selectedProfile: value,
      warrior: loadedWarrior
    });
  }

  handleInputChange = (event) => {
    const { target } = event;
    let value;
    if (target.multiple) {
      value = _.map(target.selectedOptions, option => (option.value)).join(',');
    } else if (target.type === 'checkbox') {
      value = target.checked ? 1 : 0;
    } else {
      // eslint-disable-next-line prefer-destructuring
      value = target.value;
    }
    const { name } = target;

    this.updateWarriorStat(name, value);
  }

  updateWarriorStat = (name, value) => {
    const { warrior } = this.state;
    const nameParts = name.split('.');

    if (nameParts.length > 1) {
      warrior[nameParts[0]][nameParts[1]] = value;
    } else {
      warrior[name] = value;
    }

    if (name === 'rank') {
      // eslint-disable-next-line react/destructuring-assignment
      const { modifyStats } = this.props.warrior;

      if (value !== 'Creature') {
        warrior.owner = ''
      }

      if (value === 'Warrior') {
        warrior.modifyStats.might = 0;
        warrior.modifyStats.will = 0;
        warrior.modifyStats.fate = 0;
      } else {
        const currentTotal = modifyStats.might + modifyStats.will + modifyStats.fate;
        if (currentTotal === 0) {
          switch (value) {
            case 'Leader':
            case 'Sergeant':
              warrior.modifyStats.might = warrior.baseStats.might ? 0 : 1;
              warrior.modifyStats.will = warrior.baseStats.will ? 0 : 1;
              warrior.modifyStats.fate = warrior.baseStats.fate ? 0 : 1;
              break;
            case 'Hero':
              warrior.modifyStats.might = 0;
              warrior.modifyStats.will = 0;
              warrior.modifyStats.fate = warrior.baseStats.fate ? 0 : 1;
              break;
            case 'Wanderer':
              warrior.modifyStats.might = 0;
              warrior.modifyStats.will = 0;
              warrior.modifyStats.fate = 0;
              break;
            default:
              break;
          }
        } else {
          warrior.modifyStats.might = modifyStats.might;
          warrior.modifyStats.will = modifyStats.will;
          warrior.modifyStats.fate = modifyStats.fate;
        }
      }
    }

    this.updateWarrior();
  }

  updateRule = (rule, remove) => {
    const { warrior } = this.state;
    const newRule = { ...rule };

    if (remove) {
      newRule.remove = true;
    }

    newRule.warriorId = warrior.id;

    if (!rule.type) {
      newRule.type = 'base';
    }
    if (newRule.type === 'wargear') {
      warrior.specialRules.push(newRule);
    } else if (newRule.id) {
      const ruleIndex = _.findIndex(warrior.specialRules, { id: newRule.id });
      warrior.specialRules[ruleIndex] = newRule;
    } else {
      newRule.new = true;
      newRule.id = new Date().valueOf();
      warrior.specialRules.push(newRule);
    }
    this.updateWarrior();
  }

  updateHeroicAction = (heroicAction, remove) => {
    const { warrior } = this.state;
    const newHeroicAction = { ...heroicAction };

    if (remove) {
      newHeroicAction.remove = true;
    }

    newHeroicAction.warriorId = warrior.id;

    if (!heroicAction.type) {
      newHeroicAction.type = 'base';
    }

    if (newHeroicAction.id) {
      const heroicActionIndex = _.findIndex(warrior.heroicActions, { id: newHeroicAction.id });
      warrior.heroicActions[heroicActionIndex] = newHeroicAction;
    } else {
      newHeroicAction.new = true;
      newHeroicAction.id = new Date().valueOf();
      warrior.heroicActions.push(newHeroicAction);
    }
    this.updateWarrior();
  }

  updateSpell = (spell, remove) => {
    const { warrior } = this.state;
    const newSpell = { ...spell };

    if (remove) {
      newSpell.remove = true;
    }

    newSpell.warriorId = warrior.id;

    if (!spell.type) {
      newSpell.type = 'base';
    }

    if (newSpell.id) {
      const spellIndex = _.findIndex(warrior.spells, { id: newSpell.id });
      warrior.spells[spellIndex] = newSpell;
    } else {
      newSpell.new = true;
      newSpell.id = new Date().valueOf();
      warrior.spells.push(newSpell);
    }
    this.updateWarrior();
  }

  updateWargear = (item, remove) => {
    const { warrior } = this.state;

    const isArmouryItem = item.armouryId && item.armouryId !== 'new';
    const setStat = (isArmouryItem && item.adjustmentValue);

    const modifiedItem = {
      id: item.id,
      armouryId: item.armouryId,
      type: item.type || 'base',
      name: item.name,
      mount: item.mount,
      pointsLow: parseInt(item.pointsLow || 0, 10),
      pointsHigh: parseInt(item.pointsHigh || 0, 10),
      adjustmentStat: setStat ? item.adjustmentStat : '',
      adjustmentValue: setStat ? parseInt(item.adjustmentValue || 0, 10) : 0,
      influence: parseInt(item.influence, 10),
      addToArmoury: item.addToArmoury,
      move: item.move,
      fight: item.fight,
      shoot: item.shoot,
      strength: item.strength,
      defence: item.defence,
      attacks: item.attacks,
      wounds: item.wounds,
      courage: item.courage,
      specialAttacks: item.specialAttacks,
      specialRules: item.specialRules
    };

    if (remove) {
      modifiedItem.remove = true;
      _.remove(warrior.specialRules, rule => (rule.id === `wargear${item.id}`));
    } else {
      _.forEach(item.specialRules, (rule) => {
        this.updateRule({
          id: `wargear${item.id}`, name: rule.name, description: rule.description, type: 'wargear'
        });
      });
    }

    if (modifiedItem.id) {
      const itemIndex = _.findIndex(warrior.wargear, { id: modifiedItem.id });
      warrior.wargear[itemIndex] = modifiedItem;
    } else {
      modifiedItem.new = true;
      modifiedItem.id = new Date().valueOf();
      warrior.wargear.push(modifiedItem);
    }

    this.updateWarrior();
  }

  updateWarrior = () => {
    const { profile } = this.props;
    let { warrior } = this.state;

    if (profile) {
      warrior = calculateProfilePoints(warrior);
      warrior = calculateProfileStatTotals(warrior);
    } else {
      warrior = calculateWarriorPoints(warrior);
      warrior = calculateStatTotals(warrior);
    }

    this.setState({
      warrior,
      hasEdited: true
    });
  }

  deleteWarrior = (e) => {
    e.preventDefault();
    const { profile, isGraveyard } = this.props;
    const { warrior, deleting } = this.state;
    if (deleting) {
      return;
    }
    const {
      deleteWarriorAction, deleteProfileAction, deleteGraveyardAction, companyId
    } = this.props;

    if (confirm('Are you sure you want to delete this warrior? All associated wargear and creatures will also be deleted. This action cannot be undone.')) {
      this.setState({
        deleting: true
      });

      if (isGraveyard) {
        deleteGraveyardAction(warrior.id);
      } else if (profile) {
        deleteProfileAction(warrior.id);
      } else {
        deleteWarriorAction(warrior.id, companyId);
      }
    }
  }

  onCropComplete = (croppedArea, croppedAreaPixels, rotation) => {
    this.setState({
      crop: { ...croppedAreaPixels, rotation }
    });
  }

  uploadImage = async (file) => {
    const { crop, warrior } = this.state;
    const { uploadImageAction, companyId } = this.props;

    const croppedImage = await getCroppedImg(
      warrior.tempImage,
      crop
    );

    const timeStamp = new Date().valueOf();

    uploadImageAction(file, croppedImage, warrior.id || timeStamp, companyId, (newUrl) => {
      warrior.image = `${newUrl}?v=${timeStamp}`;
      warrior.tempImage = null;
      warrior.imageUploaded = true;
      this.setState({ warrior });
    });
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { profile, isGraveyard } = this.props;
    const { submitting, warrior } = this.state;
    if (submitting) {
      return;
    }
    const {
      updateWarriorAction, createWarriorAction, updateProfileAction,
      createProfileAction, updateGraveyardAction, handleClose
    } = this.props;

    this.setState({
      submitting: true
    });

    if (isGraveyard) {
      updateGraveyardAction(warrior, handleClose);
    } else if (profile) {
      if (warrior.id) {
        updateProfileAction(warrior, this.submitCallback);
      } else {
        createProfileAction(warrior, this.submitCallback);
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (warrior.id) {
        updateWarriorAction(warrior, this.submitCallback);
      } else {
        createWarriorAction(warrior, this.submitCallback);
      }
    }
  }

  submitCallback = () => {
    const { handleClose, getWargearAction, getRulesAction, getHeroicActionsAction, getSpellsAction } = this.props;
    handleClose(false);
    getWargearAction();
    getRulesAction();
    getHeroicActionsAction()
    getSpellsAction();
  }

  getTabs = () => {
    const {
      companyId, companies, profile, profiles, wargear, rules, heroicActions, spells, isGraveyard, editable
    } = this.props;
    const {
      warrior, submitting, deleting, detailsFormSubmitted, selectedProfile
    } = this.state;
    const company = companies[companyId];
    const filteredProfiles = _.filter(profiles, p => (company && (((p.race || '').split(',').indexOf(company.race) !== -1) || p.race === '')));
    const tabs = [];

    if (!isGraveyard && editable) {
      tabs.push({
        name: 'Details',
        content: (
          <DetailsForm
            warrior={warrior}
            handleInputChange={this.handleInputChange}
            updateWarriorStat={this.updateWarriorStat}
            selectProfile={this.selectProfile}
            setActiveTab={this.setActiveTab}
            deleteWarrior={this.deleteWarrior}
            uploadImage={this.uploadImage}
            companyId={companyId}
            deleting={deleting}
            submitted={detailsFormSubmitted}
            profiles={filteredProfiles}
            profile={profile}
            selectedProfile={selectedProfile}
          />
        )
      }, {
        name: 'Characteristics',
        content: (
          <CharacteristicsForm
            warrior={warrior}
            handleInputChange={this.handleInputChange}
            setActiveTab={this.setActiveTab}
          />
        )
      }, {
        name: 'Wargear',
        content: (
          <Form
            buttons={(
              <Fragment>
                <Button text="Previous" secondary type="button" iconName="arrow-left" iconLeft onClick={() => this.setActiveTab(1)} />
                <Button text="Next" primary type="button" iconName="arrow-right" iconRight onClick={() => this.setActiveTab(3)} />
              </Fragment>
            )}
          >
            {!warrior.id && (
            <p className="help-text">
              Add all of the wargear items your warrior carries. Include items from the warrior&apos;s base profile, as well as equipment purchased from The Armoury.
              <br /><br />
              Items can be created in the My Armoury section, and then selected here to save time.
            </p>
            )}
            {_.map(warrior.wargear, (item, index) => {
              if (item.remove) {
                return null;
              }
              return (
                <WargearForm
                  key={index}
                  item={{ ...item }}
                  onSubmit={this.updateWargear}
                  profile={profile}
                  wargear={wargear}
                  warrior={warrior}
                  isMount={!!item.mount}
                />
              );
            })}
            <WargearForm key="newItem" item={{ ...emptyWargearItem }} onSubmit={this.updateWargear} warrior={warrior} profile={profile} wargear={wargear} />
            <WargearForm key="newMount" item={{ ...emptyWargearItem }} onSubmit={this.updateWargear} warrior={warrior} profile={profile} wargear={wargear} isMount />
          </Form>
        )
      });
    }

    if (!isGraveyard && editable) {
      const prevTab = 2;
      const nextTab = prevTab + 2;
      tabs.push({
        name: 'Rules',
        content: (
          <Form
            buttons={(
              <Fragment>
                <Button text="Previous" secondary type="button" iconName="arrow-left" iconLeft onClick={() => this.setActiveTab(prevTab)} />
                <Button text="Next" primary type="button" iconName="arrow-right" iconRight onClick={() => this.setActiveTab(nextTab)} />
              </Fragment>
            )}
          >
            {!warrior.id && (
            <p className="help-text">
              Add all of the abilities of your warrior. During a battle you can click on a rule to see its description.
              <br /><br />
              You can store abilities in the My Rules section and select them here to save time.
            </p>
            )}
            {_.map(warrior.specialRules, (rule, index) => {
              if (rule.type === 'wargear' || rule.remove) {
                return null;
              }
              return (
                <RuleForm
                  key={index}
                  rule={rule}
                  rules={rules}
                  warrior={warrior}
                  onSubmit={this.updateRule}
                  profile={profile}
                />
              );
            })}
            <RuleForm key="newRule" rule={{ ...emptySpecialRule }} rules={rules} warrior={warrior} onSubmit={this.updateRule} profile={profile} />
          </Form>
        )
      });
    }

    if (!isGraveyard && editable && !profile && warrior.rank !== 'Creature' && warrior.rank !== 'Warrior') {
      tabs.push({
        name: 'Heroic Actions',
        content: (
          <Form
            buttons={(
              <Fragment>
                <Button text="Previous" secondary type="button" iconName="arrow-left" iconLeft onClick={() => this.setActiveTab(3)} />
                <Button text="Next" primary type="button" iconName="arrow-right" iconRight onClick={() => this.setActiveTab(5)} />
              </Fragment>
            )}
          >
            {!warrior.id && (
            <p className="help-text">
              Add all of the heroic actions for your warrior. During a battle you can click on a heroic action to see its description.
              <br /><br />
              You can store heroic actions in the My Heroic Actions section and select them here to save time.
            </p>
            )}
            {_.map(warrior.heroicActions, (heroicAction, index) => {
              if (heroicAction.remove) {
                return null;
              }
              return (
                <HeroicActionForm
                  key={index}
                  heroicAction={heroicAction}
                  heroicActions={heroicActions}
                  warrior={warrior}
                  onSubmit={this.updateHeroicAction}
                  profile={profile}
                />
              );
            })}
            <HeroicActionForm key="newHeroicAction" heroicAction={{ ...emptyHeroicAction }} heroicActions={heroicActions} warrior={warrior} onSubmit={this.updateHeroicAction} profile={profile} />
          </Form>
        )
      }, {
        name: 'Spells',
        content: (
          <Form
            buttons={(
              <Fragment>
                <Button text="Previous" secondary type="button" iconName="arrow-left" iconLeft onClick={() => this.setActiveTab(4)} />
                <Button text="Next" primary type="button" iconName="arrow-right" iconRight onClick={() => this.setActiveTab(6)} />
              </Fragment>
            )}
          >
            {!warrior.id && (
            <p className="help-text">
              Add all of the spells for your warrior. During a battle you can click on a spell to see its description.
              <br /><br />
              You can store spells in the My Spells section and select them here to save time.
            </p>
            )}
            {_.map(warrior.spells, (spell, index) => {
              if (spell.remove) {
                return null;
              }
              return (
                <SpellForm
                  key={index}
                  spell={spell}
                  spells={spells}
                  warrior={warrior}
                  onSubmit={this.updateSpell}
                  profile={profile}
                />
              );
            })}
            <SpellForm key="newSpell" spell={{ ...emptySpell }} spells={spells} warrior={warrior} onSubmit={this.updateSpell} profile={profile} />
          </Form>
        )
      });
    }

    if (!profile && editable) {
      const prevTab = warrior.rank === 'Creature' || warrior.rank === 'Warrior' ? 3 : 5;
      let nextTab = prevTab + 2;
      if (isGraveyard) {
        nextTab = 1;
      }
      tabs.push({
        name: 'Injuries',
        content: (
          <InjuriesForm
            warrior={warrior}
            handleInputChange={this.handleInputChange}
            updateWarriorStat={this.updateWarriorStat}
            setActiveTab={this.setActiveTab}
            deleteWarrior={this.deleteWarrior}
            deleting={deleting}
            isGraveyard={isGraveyard || false}
            prevTab={prevTab}
            nextTab={nextTab}
          />
        )
      });
    }

    let prevTab = warrior.rank === 'Creature' || warrior.rank === 'Warrior' ? 4 : 6;
    if (profile) {
      prevTab = 3;
    }
    if (isGraveyard) {
      prevTab = 0;
    }

    tabs.push({
      name: 'Overview',
      content: (
        <OverviewForm
          warrior={warrior}
          company={company}
          onSubmit={this.onSubmit}
          handleInputChange={this.handleInputChange}
          setActiveTab={this.setActiveTab}
          submitting={submitting}
          profile={profile}
          prevTab={prevTab}
          editable={editable}
        />
      )
    });

    return tabs;
  }

  render() {
    const { handleClose, companyId, profile, editable } = this.props;
    const {
      warrior, activeTab, submitting, deleting, hasEdited
    } = this.state;

    const titleAction = warrior.id ? editable ? 'Edit' : 'View' : 'Create a';
    const titleType = profile ? 'Profile' : 'Warrior';

    return (
      <Overlay id="edit-warrior" title={`${titleAction} ${titleType}`} handleClose={() => handleClose(hasEdited)} open>
        <div className={`edit-left ${(submitting || deleting) ? 'submitting' : ''}`}>
          <Tabs
            activeTab={activeTab}
            setActiveTab={this.setActiveTab}
            tabs={this.getTabs()}
          />
          {(submitting || deleting) && <div className="submit-block" />}
        </div>
        <div className="edit-right">
          {profile
            ? <ProfileCard profile={warrior} />
            : (
              <WarriorCard
                warrior={warrior}
                companyId={companyId}
                onCropComplete={this.onCropComplete}
              />
            )
          }
        </div>
      </Overlay>
    );
  }
}

EditOverlay.propTypes = {
  warrior: WarriorType.isRequired,
  companyId: PropTypes.number,
  companies: CompaniesType,
  createWarriorAction: PropTypes.func.isRequired,
  updateWarriorAction: PropTypes.func.isRequired,
  deleteWarriorAction: PropTypes.func.isRequired,
  createProfileAction: PropTypes.func.isRequired,
  updateProfileAction: PropTypes.func.isRequired,
  deleteProfileAction: PropTypes.func.isRequired,
  updateGraveyardAction: PropTypes.func.isRequired,
  deleteGraveyardAction: PropTypes.func.isRequired,
  uploadImageAction: PropTypes.func.isRequired,
  getWargearAction: PropTypes.func.isRequired,
  getRulesAction: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  profile: PropTypes.bool,
  profiles: PropTypes.objectOf(ProfileType).isRequired,
  wargear: PropTypes.oneOfType([
    PropTypes.objectOf(WargearType),
    PropTypes.objectOf(PropTypes.bool)
  ]).isRequired,
  rules: PropTypes.oneOfType([
    PropTypes.objectOf(RuleType),
    PropTypes.objectOf(PropTypes.bool)
  ]).isRequired,
  heroicActions: PropTypes.oneOfType([
    PropTypes.objectOf(HeroicActionType),
    PropTypes.objectOf(PropTypes.bool)
  ]).isRequired,
  spells: PropTypes.oneOfType([
    PropTypes.objectOf(SpellType),
    PropTypes.objectOf(PropTypes.bool)
  ]).isRequired,
  isGraveyard: PropTypes.bool,
  editable: PropTypes.bool
};

EditOverlay.defaultProps = {
  companyId: null,
  companies: [],
  editable: false,
  profile: false,
  isGraveyard: false
};

function mapStateToProps({
  companies, profiles, wargear, rules, heroicActions, spells
}) {
  return {
    companies, profiles, wargear, rules, heroicActions, spells
  };
}

export default connect(mapStateToProps, {
  createWarriorAction: warriorActions.createWarrior,
  updateWarriorAction: warriorActions.updateWarrior,
  deleteWarriorAction: warriorActions.deleteWarrior,
  createProfileAction: profileActions.createProfile,
  updateProfileAction: profileActions.updateProfile,
  deleteProfileAction: profileActions.deleteProfile,
  updateGraveyardAction: graveyardActions.updateGraveyardWarrior,
  deleteGraveyardAction: graveyardActions.deleteGraveyardWarrior,
  uploadImageAction: warriorActions.uploadImage,
  getWargearAction: wargearActions.getWargear,
  getRulesAction: ruleActions.getRules,
  getHeroicActionsAction: heroicActionActions.getHeroicActions,
  getSpellsAction: spellActions.getSpells
})(EditOverlay);
