import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

class ToggleAccordion extends PureComponent {
  render() {
    const {
      heading, name, children, value, onChange
    } = this.props;
    const open = !!value;

    return (
      <div className={`toggle-accordion${open ? ' toggle-accordion--open' : ''}`}>
        <label className="toggle-accordion__header">
          <span className="toggle-accordion__header__name">{heading}</span>
          <span className="toggle-control">
            <input type="checkbox" checked={open} name={name} onChange={onChange} />
            <span className="toggle-control__inner" />
          </span>
        </label>
        {open && (
          <div className="toggle-accordion__inner">
            {children}
          </div>
        )}
      </div>
    );
  }
}

ToggleAccordion.propTypes = {
  heading: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ToggleAccordion;
