/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { userActions } from '../../_actions';
import {
  Button, Form, FormRow, TextInput
} from '..';

class ForgotPasswordForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      submitted: false,
      emailSent: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { sendPasswordReset } = this.props;
    const { email } = this.state;
    if (email) {
      sendPasswordReset(email, () => {
        this.setState({
          emailSent: true
        });
      });
    }
  }

  render() {
    const { changingPassword } = this.props;
    const { email, submitted, emailSent } = this.state;

    if (emailSent) {
      return <p>We have sent you an email with a link to reset your password. Please check your inbox</p>;
    }

    return (
      <form id="forgot-password-form" name="forgot-password-form" onSubmit={this.handleSubmit}>
        <Form buttons={<Button text="Submit" primary iconName="arrow-right" iconRight type="submit" loading={changingPassword} />}>
          <FormRow>
            <TextInput name="email" value={email} onChange={this.handleChange} placeholder="Email address" type="email" required showError={submitted && !email} />
          </FormRow>
        </Form>
      </form>
    );
  }
}

ForgotPasswordForm.propTypes = {
  changingPassword: PropTypes.bool,
  sendPasswordReset: PropTypes.func.isRequired
};

ForgotPasswordForm.defaultProps = {
  changingPassword: false
};

function mapStateToProps({ changePassword }) {
  const { changingPassword } = changePassword;
  return { changingPassword };
}

export default connect(mapStateToProps, ({
  sendPasswordReset: userActions.sendPasswordReset
}))(ForgotPasswordForm);
