/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/destructuring-assignment */
import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { WargearType, WarriorsType } from '../../../../_types';

import {
  ToggleAccordion, FormRow, FormField, RadioInput,
  TextInput, SelectInput, CharacteristicsTable
} from '../../..';

class WargearForm extends Component {
  constructor(props) {
    super(props);
    const { item } = this.props;
    this.state = {
      item
    };
  }

  handleInputChange = (event) => {
    const { item } = this.state;
    const {
      target: {
        name, type, checked, value
      }
    } = event;
    const val = type === 'checkbox' ? checked : value;

    const nameParts = name.split('.');
    if (nameParts.length > 1) {
      item[nameParts[0]][nameParts[1]] = val;
    } else if (name === 'mount') {
      item[name] = parseInt(value, 10);
    } else {
      item[name] = val;
    }

    this.setState({
      item
    });
  }

  render() {
    const { wargear, warriors, submitted } = this.props;
    const {
      item: {
        warriorId,
        armouryId,
        name,
        pointsLow,
        pointsHigh,
        adjustmentStat,
        adjustmentValue,
        attackDescription,
        attackName,
        ruleDescription,
        ruleName,
        mount,
        move,
        fight,
        shoot,
        strength,
        defence,
        attacks,
        wounds,
        courage,
        statEnabled,
        attackEnabled,
        ruleEnabled
      }
    } = this.state;

    return (
      <Fragment>
        <FormRow>
          <SelectInput name="warriorId" label="Warrior" value={warriorId} onChange={this.handleInputChange} required showError={submitted && !warriorId}>
            <option value="">Please Select</option>
            {_.map(_.orderBy(warriors, ['name']), w => <option value={w.id} key={w.id}>{w.name}</option>)}
          </SelectInput>
        </FormRow>
        <FormRow>
          <RadioInput
            name="mount"
            label="Item Type"
            value={mount}
            options={[{
              label: 'Item',
              value: 0
            }, {
              label: 'Mount',
              value: 1
            }]}
            onChange={this.handleInputChange}
            required
          />
        </FormRow>
        <FormRow>
          <SelectInput name="armouryId" label={`Load ${mount ? 'Mount' : 'Item'}`} value={armouryId} onChange={this.handleInputChange} required showError={submitted && !armouryId}>
            <option value="">Please Select</option>
            <option value="new">Create New {mount ? 'Mount' : 'Item'}</option>
            <optgroup label="Your Armoury">
              {_.map(_.orderBy(_.filter(wargear, { mount: mount ? 1 : 0 }), ['name']), w => <option value={w.id} key={w.id}>{w.name}</option>)}
            </optgroup>
          </SelectInput>
        </FormRow>
        {armouryId === 'new' && (
          <FormRow>
            <TextInput name="name" label="Name" value={name} onChange={this.handleInputChange} required showError={submitted && !name} />
          </FormRow>
        )}
        <FormRow cls="row--points">
          <TextInput name="pointsLow" label="Points" value={pointsLow} onChange={this.handleInputChange} small />
          <span>/</span>
          <TextInput name="pointsHigh" label="&nbsp;" value={pointsHigh} onChange={this.handleInputChange} small />
        </FormRow>
        {(!mount && armouryId === 'new') && (
          <Fragment>
            <ToggleAccordion heading="Characteristics" name="statEnabled" value={statEnabled} onChange={this.handleInputChange}>
              <FormRow cls="row--modify-characteristics">
                <SelectInput name="adjustmentValue" label="Adjustment" value={adjustmentValue} onChange={this.handleInputChange}>
                  <option value="3">+3</option>
                  <option value="2">+2</option>
                  <option value="1">+1</option>
                  <option value="0">0</option>
                  <option value="-1">-1</option>
                  <option value="-2">-2</option>
                  <option value="-3">-3</option>
                </SelectInput>
                <SelectInput name="adjustmentStat" label="&nbsp;" value={adjustmentStat} onChange={this.handleInputChange}>
                  <option value="">Please Select</option>
                  <option value="Movement">Movement</option>
                  <option value="Fight">Fight</option>
                  <option value="Shoot">Shoot</option>
                  <option value="Strength">Strength</option>
                  <option value="Defence">Defence</option>
                  <option value="Attacks">Attacks</option>
                  <option value="Wounds">Wounds</option>
                  <option value="Courage">Courage</option>
                  <option value="Might">Might</option>
                  <option value="Will">Will</option>
                  <option value="Fate">Fate</option>
                </SelectInput>
              </FormRow>
            </ToggleAccordion>
            <ToggleAccordion heading="Special Attack" name="attackEnabled" value={attackEnabled} onChange={this.handleInputChange}>
              <FormRow>
                <TextInput name="attackName" label="Name" value={attackName} onChange={this.handleInputChange} />
              </FormRow>
              <FormRow>
                <TextInput name="attackDescription" label="Description" value={attackDescription} onChange={this.handleInputChange} textarea />
              </FormRow>
            </ToggleAccordion>
            <ToggleAccordion heading="Special Rule" name="ruleEnabled" value={ruleEnabled} onChange={this.handleInputChange}>
              <FormRow>
                <TextInput name="ruleName" label="Name" value={ruleName} onChange={this.handleInputChange} />
              </FormRow>
              <FormRow>
                <TextInput name="ruleDescription" label="Description" value={ruleDescription} onChange={this.handleInputChange} textarea />
              </FormRow>
            </ToggleAccordion>
          </Fragment>
        )}
        {(!!mount && armouryId === 'new') && (
        <FormRow cls="row--table">
          <FormField label="Characteristics">
            <CharacteristicsTable
              inputs
              base={{
                move: <TextInput name="move" value={move} onChange={this.handleInputChange} clean />,
                fight: <TextInput name="fight" value={fight} onChange={this.handleInputChange} clean />,
                shoot: <TextInput name="shoot" value={shoot} onChange={this.handleInputChange} clean />,
                strength: <TextInput name="strength" value={strength} onChange={this.handleInputChange} clean />,
                defence: <TextInput name="defence" value={defence} onChange={this.handleInputChange} clean />,
                attacks: <TextInput name="attacks" value={attacks} onChange={this.handleInputChange} clean />,
                wounds: <TextInput name="wounds" value={wounds} onChange={this.handleInputChange} clean />,
                courage: <TextInput name="courage" value={courage} onChange={this.handleInputChange} clean />
              }}
              total={{
                move, fight, shoot, strength, defence, attacks, wounds, courage
              }}
            />
          </FormField>
        </FormRow>
        )}
      </Fragment>
    );
  }
}

WargearForm.propTypes = {
  item: WargearType.isRequired,
  wargear: PropTypes.objectOf(WargearType.isRequired).isRequired,
  warriors: WarriorsType.isRequired,
  submitted: PropTypes.bool.isRequired
};

export default WargearForm;
