/* eslint-disable max-len */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { wargearActions } from '../../../_actions';
import { WargearType } from '../../../_types';

import {
  Button, CharacteristicsTable, Form, FormField, FormRow, Overlay, SelectInput, TextInput
} from '../..';
import MultipleOptionFormRow from '../../MultipleOptionsAccordion';

class EditWargearOverlay extends Component {
  constructor(props) {
    super(props);
    const { item } = this.props;
    this.state = {
      item,
      submitting: false,
      deleting: false,
      submitted: false
    };
  }

  listDataChange = (value, key) => {
    const { item } = this.state;
    this.setState({
      item: {
        ...item,
        [key]: value
      }
    });
  };

  handleInputChange = (event) => {
    const { target } = event;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState({
      [name]: value
    });
  }

  updateItemStat = (event) => {
    const { item } = this.state;
    const {
      target: {
        name, type, checked, value
      }
    } = event;
    const val = type === 'checkbox' ? checked : value;

    const nameParts = name.split('.');
    if (nameParts.length > 1) {
      item[nameParts[0]][nameParts[1]] = val;
    } else if (name === 'mount') {
      item[name] = parseInt(value, 10);
    } else {
      item[name] = val;
    }

    this.setState({
      item
    });
  }

  deleteItem = (e) => {
    e.preventDefault();
    const { item, deleting } = this.state;
    if (deleting) {
      return;
    }
    const { deleteWargearItemAction } = this.props;

    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure you want to delete this item? This action cannot be undone.')) {
      this.setState({
        deleting: true
      });

      deleteWargearItemAction(item.id);
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { item, submitting } = this.state;
    if (submitting) {
      return;
    }

    if (item.name === '') {
      this.setState({ submitted: true });
      return;
    }

    this.setState({
      submitted: false,
      submitting: true
    });

    const submitItem = {
      id: item.id,
      name: item.name,
      adjustmentStat: item.adjustmentStat || '',
      adjustmentValue: item.adjustmentValue || 0,
      mount: item.mount,
      move: item.move,
      fight: item.fight,
      shoot: item.shoot,
      strength: item.strength,
      defence: item.defence,
      attacks: item.attacks,
      wounds: item.wounds,
      courage: item.courage,
      specialAttacks: item.specialAttacks,
      specialRules: item.specialRules
    };

    const { createWargearItemAction, updateWargearItemAction, handleClose } = this.props;
    if (item.id) {
      updateWargearItemAction(submitItem, handleClose);
    } else {
      createWargearItemAction(submitItem, handleClose);
    }
  }

  render() {
    const { handleClose } = this.props;
    const {
      item: {
        adjustmentStat, adjustmentValue, attacks, courage, defence, fight, id, mount, move, name, shoot, specialAttacks, specialRules, strength, wounds
      },
      submitting, deleting, submitted
    } = this.state;
    const operation = id ? 'Edit' : 'Create';
    const type = mount ? 'Mount' : 'Item';
    return (
      <Overlay id="edit-wargear" title={`${operation} ${type}`} handleClose={handleClose} open>
        <div className={`edit-left ${(submitting || deleting) ? 'submitting' : ''}`}>
          <Form buttons={(
            <Fragment>
              {!!id && <Button text="Delete" destructive type="button" onClick={this.deleteItem} loading={deleting} />}
              <Button text="Submit" primary type="button" onClick={this.onSubmit} loading={submitting} />
            </Fragment>
          )}
          >
            {!id && (
            <p className="help-text">
              You can create both items and mounts from this form. Expand the relevent sections to enter all of the details for your item.
            </p>
            )}
            <FormRow>
              <TextInput name="name" label="Name" value={name} onChange={this.updateItemStat} required showError={submitted && !name} />
            </FormRow>
            {!mount && (
            <Fragment>
              <FormRow cls="row--modify-characteristics">
                <SelectInput name="adjustmentValue" label="Characteristics" value={adjustmentValue} onChange={this.updateItemStat}>
                  <option value="3">+3</option>
                  <option value="2">+2</option>
                  <option value="1">+1</option>
                  <option value="0">0</option>
                  <option value="-1">-1</option>
                  <option value="-2">-2</option>
                  <option value="-3">-3</option>
                </SelectInput>
                <SelectInput name="adjustmentStat" label="&nbsp;" value={adjustmentStat} onChange={this.updateItemStat}>
                  <option value="">Please Select</option>
                  <option value="Movement">Movement</option>
                  <option value="Fight">Fight</option>
                  <option value="Shoot">Shoot</option>
                  <option value="Strength">Strength</option>
                  <option value="Defence">Defence</option>
                  <option value="Attacks">Attacks</option>
                  <option value="Wounds">Wounds</option>
                  <option value="Courage">Courage</option>
                  <option value="Might">Might</option>
                  <option value="Will">Will</option>
                  <option value="Fate">Fate</option>
                </SelectInput>
              </FormRow>
              <MultipleOptionFormRow
                maxListSize={3}
                items={specialAttacks}
                className="special-attack"
                heading="Special Attack"
                onChange={data => this.listDataChange(data, 'specialAttacks')}
              />
            </Fragment>
            )}
            {!!mount && (
            <FormRow cls="row--table">
              <FormField label="Characteristics" name="characteristics">
                <CharacteristicsTable
                  inputs
                  base={{
                    move: <TextInput name="move" value={move} onChange={this.updateItemStat} clean />,
                    fight: <TextInput name="fight" value={fight} onChange={this.updateItemStat} clean />,
                    shoot: <TextInput name="shoot" value={shoot} onChange={this.updateItemStat} clean />,
                    strength: <TextInput name="strength" value={strength} onChange={this.updateItemStat} clean />,
                    defence: <TextInput name="defence" value={defence} onChange={this.updateItemStat} clean />,
                    attacks: <TextInput name="attacks" value={attacks} onChange={this.updateItemStat} clean />,
                    wounds: <TextInput name="wounds" value={wounds} onChange={this.updateItemStat} clean />,
                    courage: <TextInput name="courage" value={courage} onChange={this.updateItemStat} clean />
                  }}
                  total={{
                    move, fight, shoot, strength, defence, attacks, wounds, courage
                  }}
                />
              </FormField>
            </FormRow>
            )}
            <MultipleOptionFormRow
              maxListSize={3}
              items={specialRules}
              className="rule"
              heading="Special Rule"
              onChange={data => this.listDataChange(data, 'specialRules')}
            />
          </Form>
          {(submitting || deleting) && <div className="submit-block" />}
        </div>
      </Overlay>
    );
  }
}

EditWargearOverlay.propTypes = {
  item: WargearType.isRequired,
  createWargearItemAction: PropTypes.func.isRequired,
  updateWargearItemAction: PropTypes.func.isRequired,
  deleteWargearItemAction: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default withRouter(connect(null, {
  createWargearItemAction: wargearActions.createWargearItem,
  updateWargearItemAction: wargearActions.updateWargearItem,
  deleteWargearItemAction: wargearActions.deleteWargearItem
})(EditWargearOverlay));
