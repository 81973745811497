/* eslint-disable react/jsx-one-expression-per-line */
import React, { Fragment, useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { RuleType } from '../_types';
import { emptySpecialRule } from '../_constants';
import { ruleActions } from '../_actions';
import { useStoredSort } from '../_hooks/useStoredSort';

import {
  Grid, Loader, RuleCard, NewCard, Advert, PageHeader
} from '../_components';
import EditRuleOverlay from '../_components/Overlay/EditRuleOverlay';

const storageName = 'sort-rules';
const sortOptions = [
  { label: 'Name', value: o => o.name.toLowerCase(), mode: 'asc' },
  { label: 'Name', value: o => o.name.toLowerCase(), mode: 'desc' }
];

function Rules({ rules, getRulesAction }) {
  const [sort, setSort] = useState(useStoredSort(sortOptions, 0, storageName));

  useEffect(() => {
    getRulesAction();
  }, [getRulesAction]);

  if (rules.loading) {
    return <Loader />;
  }

  let i = 0;

  const orderedRules = _.orderBy(rules, [sort.value], [sort.mode]);

  return (
    <Fragment>
      <Advert isWide />
      <PageHeader title="My Rules" sortOption={sort} setSort={setSort} sortOptions={sortOptions} storageName={storageName} />
      <Grid>
        {_.map(orderedRules, rule => (
          <Fragment key={rule.id}>
            <RuleCard rule={rule} />
            {(i++ % 4 === 0) && <Advert />}
          </Fragment>
        ))}
        <NewCard
          key="new"
          title="New Rule"
          overlay={<EditRuleOverlay rule={_.clone(emptySpecialRule)} />}
          small
        />
      </Grid>
    </Fragment>
  );
}

Rules.propTypes = {
  rules: PropTypes.oneOfType([
    PropTypes.arrayOf(RuleType),
    PropTypes.object
  ]).isRequired,
  getRulesAction: PropTypes.func.isRequired
};

function mapStateToProps({ rules }) {
  return { rules };
}

export default withRouter(connect(mapStateToProps, {
  getRulesAction: ruleActions.getRules
})(Rules));
