import { userService } from '../_services/user';
import { alertActions } from './alert';
import { history } from '../_helpers/history';

import { companyActions } from './company';
import { profileActions } from './profile';
import { wargearActions } from './wargear';
import { ruleActions } from './rule';

export const userConstants = {
  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  UPDATE_REQUEST: 'USERS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'USERS_UPDATE_FAILURE',

  CHANGE_PASSWORD_REQUEST: 'USERS_CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'USERS_CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'USERS_CHANGE_PASSWORD_FAILURE',

  RESET_PASSWORD_REQUEST: 'USERS_RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'USERS_RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'USERS_RESET_PASSWORD_FAILURE',

  FORGOT_PASSWORD_REQUEST: 'USERS_FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'USERS_FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE: 'USERS_FORGOT_PASSWORD_FAILURE',

  LOGOUT: 'USERS_LOGOUT',

  DELETE_REQUEST: 'USERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'USERS_DELETE_FAILURE',

  CHECK_DONATION_SUCCESS: 'USERS_CHECK_DONATION_SUCCESS'
};

function checkDonation() {
  function success(response) {
    return { type: userConstants.CHECK_DONATION_SUCCESS, response };
  }

  return (dispatch) => {
    userService.checkDonation()
      .then(
        (response) => {
          dispatch(success(response));
        }
      );
  };
}

function login(email, password) {
  function request(user) { return { type: userConstants.LOGIN_REQUEST, user }; }
  function success(user) { return { type: userConstants.LOGIN_SUCCESS, user }; }
  function failure(error) { return { type: userConstants.LOGIN_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request({ email }));

    userService.login(email, password)
      .then(
        (user) => {
          dispatch(success(user));
          dispatch(companyActions.getUserCompanies());
          dispatch(profileActions.getProfiles());
          dispatch(wargearActions.getWargear());
          dispatch(ruleActions.getRules());
          dispatch(checkDonation());
          history.push('/companies');
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };
}

function logout() {
  function request() { return { type: userConstants.LOGOUT }; }

  userService.logout();
  history.push('/');

  return (dispatch) => {
    dispatch(request());
  };
}

function register(user, callback) {
  function request(userResponse) { return { type: userConstants.REGISTER_REQUEST, userResponse }; }
  function success(userResponse) { return { type: userConstants.REGISTER_SUCCESS, userResponse }; }
  function failure(error) { return { type: userConstants.REGISTER_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(user));

    userService.register(user)
      .then(
        (userResponse) => {
          dispatch(success(userResponse));
          callback();
          dispatch(alertActions.success('Registration successful'));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };
}

function update(user) {
  function request(userResponse) { return { type: userConstants.UPDATE_REQUEST, userResponse }; }
  function success(userResponse) { return { type: userConstants.UPDATE_SUCCESS, userResponse }; }
  function failure(error) { return { type: userConstants.UPDATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(user));
    userService.update(user)
      .then(
        (userResponse) => {
          dispatch(success(userResponse));
          dispatch(alertActions.success('Details updated successfully'));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };
}

function changePassword(user) {
  function request(userResponse) {
    return { type: userConstants.CHANGE_PASSWORD_REQUEST, userResponse };
  }
  function success(userResponse) {
    return { type: userConstants.CHANGE_PASSWORD_SUCCESS, userResponse };
  }
  function failure(error) { return { type: userConstants.CHANGE_PASSWORD_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(user));
    userService.changePassword(user)
      .then(
        (userResponse) => {
          dispatch(success(userResponse));
          dispatch(alertActions.success('Password changed successfully'));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };
}

function sendPasswordReset(email, callback) {
  function request(response) {
    return { type: userConstants.FORGOT_PASSWORD_REQUEST, response };
  }
  function success(response) {
    return { type: userConstants.FORGOT_PASSWORD_SUCCESS, response };
  }
  function failure(error) { return { type: userConstants.FORGOT_PASSWORD_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(email));
    userService.sendPasswordReset(email)
      .then(
        (response) => {
          dispatch(success(response));
          callback();
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };
}

function resetPassword(passwordObject, callback) {
  function request(response) {
    return { type: userConstants.RESET_PASSWORD_REQUEST, response };
  }
  function success(response) {
    return { type: userConstants.RESET_PASSWORD_SUCCESS, response };
  }
  function failure(error) { return { type: userConstants.RESET_PASSWORD_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(passwordObject));
    userService.resetPassword(passwordObject)
      .then(
        (response) => {
          dispatch(success(response));
          callback();
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };
}

// eslint-disable-next-line import/prefer-default-export
export const userActions = {
  login,
  logout,
  register,
  update,
  changePassword,
  sendPasswordReset,
  resetPassword,
  checkDonation
};
