import { companyConstants } from '../_actions';
import { formatWarriors } from './warriors';

export default function (state = { loading: true }, action) {
  switch (action.type) {
    case companyConstants.GET_SHARED_REQUEST:
      return { loading: true };
    case companyConstants.GET_SHARED_SUCCESS:
      return { ...action.company, warriors: formatWarriors(action.company.warriors) };
    case companyConstants.GET_SHARED_FAILURE:
      return { error: action.error };

    default:
      return state;
  }
}
