/* eslint-disable react/jsx-one-expression-per-line */
import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { WarriorType } from '../../../../_types';
import { isHero } from '../../../../_helpers/warrior';

import {
  Form, FormRow, NumberInput, SelectInput, RadioInput, Button
} from '../../..';

class InjuryForm extends Component {
  constructor(props) {
    super(props);

    const { warrior } = this.props;

    this.state = {
      warrior,
      submitted: false
    };
  }

  handleInjuryResult = (name, value) => {
    const { warrior } = this.state;
    warrior[name] = value;
    this.setState({
      warrior
    });
  }

  onSubmit = () => {
    const { onSubmit } = this.props;
    const { warrior } = this.state;

    this.setState({
      submitted: true
    });

    if (!warrior.injuryResult || (warrior.injuryResult === 'wounds' && !warrior.woundsInfluence)) {
      return;
    }

    this.setState({
      submitted: false
    });

    onSubmit(warrior);
  }

  render() {
    const { submitted, warrior } = this.state;

    const {
      totalStats, injuryResult, healWound, woundsInfluence, wounded
    } = warrior;

    if (!wounded) {
      return <Form><span className="resolved" /></Form>;
    }

    const treatAsHero = isHero(warrior)
    || (totalStats.might + totalStats.will + totalStats.fate) > 0;
    const tableName = treatAsHero ? 'Hero' : 'Warrior';

    let options;
    if (treatAsHero) {
      options = [{
        name: 'Dead',
        value: 'dead',
        roll: '2'
      }, {
        name: 'Lost in Battle',
        value: 'lost',
        roll: '3'
      }, {
        name: 'Arm Wound',
        value: 'arm',
        roll: '4'
      }, {
        name: 'Leg Wound',
        value: 'leg',
        roll: '5'
      }, {
        name: 'Full Recovery',
        value: 'recovered',
        roll: '6-8'
      }, {
        name: 'It\'s just a Scratch',
        value: 'injured',
        roll: '9'
      }, {
        name: 'Old Battle Wound',
        value: 'old',
        roll: '10'
      }, {
        name: 'Wounds of a Hero',
        value: 'wounds',
        roll: '11'
      }, {
        name: 'Protected by the Valar',
        value: 'protected',
        roll: '12'
      }];
    } else {
      options = [{
        name: 'Dead',
        value: 'dead',
        roll: '2-3'
      }, {
        name: 'Injured',
        value: 'injured',
        roll: '4-5'
      }, {
        name: 'Full Recovery',
        value: 'recovered',
        roll: '6+ '
      }];
    }

    const existingWounds = [];
    if (warrior.legWound) {
      existingWounds.push({ value: 'legWound', label: 'Leg Wound' });
    }
    if (warrior.armWound) {
      existingWounds.push({ value: 'armWound', label: 'Arm Wound' });
    }
    if (warrior.oldWound) {
      existingWounds.push({ value: 'oldWound', label: 'Old Battle Wound' });
    }
    if (!warrior.healWound && existingWounds.length) {
      warrior.healWound = warrior.healWound || existingWounds[0].value;
    }

    return (
      <Form buttons={<Button text="Submit" primary onClick={this.onSubmit} />}>
        <h3>Resolve Injuries</h3>
        <p>Roll on the <strong>{tableName}</strong> injury table and record the result below.</p>
        <FormRow>
          <SelectInput label="Result" name="injuryResult" value={injuryResult} onChange={e => this.handleInjuryResult(e.target.name, e.target.value)} required showError={submitted && !injuryResult}>
            <option value="">Please Select</option>
            {_.map(options, option => (
              <option key={option.value} value={option.value}>{option.roll}&nbsp;&nbsp;&#8594;&nbsp;&nbsp;{option.name}</option>
            ))}
          </SelectInput>
        </FormRow>
        {((injuryResult === 'recovered' || injuryResult === 'wounds' || injuryResult === 'protected') && !!existingWounds.length) && (
          <Fragment>
            <p>You may choose one existing wound to be healed.</p>
            <FormRow>
              <RadioInput label="Wounds" name="healWound" value={healWound} options={existingWounds} onChange={e => this.handleInjuryResult(e.target.name, e.target.value)} />
            </FormRow>
          </Fragment>
        )}
        {injuryResult === 'wounds' && (
          <Fragment>
            <p>Your company gains D6 influence, please roll and record the result below.</p>
            <FormRow>
              <NumberInput label="Influence" name="woundsInfluence" value={woundsInfluence} min={0} max={6} onChange={(name, value) => this.handleInjuryResult(name, value)} showError={submitted && !woundsInfluence} />
            </FormRow>
          </Fragment>
        )}
        {injuryResult === 'protected' && (
          warrior.totalStats.fate < 3
            ? <p><strong>You will gain 1 additional Fate point.</strong></p>
            : <p><strong>You are already at the maximum number of Fate points.</strong></p>
        )}
        {(injuryResult === 'leg' && !!warrior.legWound) && <p><strong>You already have a leg wound, your warrior must retire from combat. Please select the &apos;Dead&apos; option above.</strong></p>}
        {(injuryResult === 'arm' && !!warrior.armWound) && <p><strong>You already have an arm wound, your warrior must retire from combat. Please select the &apos;Dead&apos; option above.</strong></p>}
      </Form>
    );
  }
}

InjuryForm.propTypes = {
  warrior: WarriorType.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default InjuryForm;
