/* eslint-disable max-len */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { AuthType } from '../_types';
import { LoginForm, Tabs } from '../_components';

import './styles.scss';
import card from '../_assets/card.png';
import battleCard from '../_assets/card-battle.png';
import aftermathCard from '../_assets/card-aftermath.png';

class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0
    };
  }

  setActiveTab = (index) => {
    this.setState({
      activeTab: index
    });
  }

  render() {
    const { authentication } = this.props;
    const { activeTab } = this.state;

    return (
      <div className="homepage text-page">
        <div className="intro">
          <div className="intro__text">
            <h3>Welcome to</h3>
            <h1>Battle Companies Manager</h1>
            <p>Battle Companies Manager is your unofficial companion for Games Workshop’s Middle Earth Strategy Battle Game. This app will help you to keep track of your Battle Companies as they travel through Middle Earth.</p>
            <p>Ditch the cumbersome PDF and try out the easy way of managing your Battle Company for free today.</p>
          </div>
          {!authentication.loggedIn && (
          <div className="login-form text-page-form">
            <h3>Login</h3>
            <LoginForm />
          </div>
          )}
        </div>
        <Tabs
          activeTab={activeTab}
          setActiveTab={this.setActiveTab}
          textPageTabs
          tabs={[{
            name: 'Company Details',
            content: (
              <div className="section">
                <div className="image">
                  <img src={card} alt="Example Card" />
                </div>
                <div className="text">
                  <h2>Company Details</h2>
                  <p>The company details screen gives you an overview of each of your warriors. From here you can add, edit or remove warriors, as well as tweaking your influence points.</p>
                  <p>The warrior cards are fully customisable, giving you the freedom to create your perfect warband.</p>
                  <ul className="styled">
                    <li><span>Quickly identify your key characters from their rank tab.</span></li>
                    <li><span>The points for each warrior are automatically calculated based on their characteristics and wargear, saving you time and hassle.</span></li>
                    <li><span>Upload your own images to help you remember who each warrior is as your company grows.</span></li>
                    <li><span>Keep track of the warriors original class and their chosen path once they&apos;re promoted to a hero.</span></li>
                    <li><span>Easily calculate your warriors characteristics using the edit warrior overlay.</span></li>
                    <li><span>Record any wargear and special rules the warrior has, then simply click the item during a battle to get an overview of what the item gives - no more digging through rule books!</span></li>
                    <li><span>Automatically calculate the experience and warrior level as your company progresses.</span></li>
                  </ul>
                </div>
              </div>
            )
          }, {
            name: 'Battle Screen',
            content: (
              <div className="section">
                <div className="image">
                  <img src={battleCard} alt="Example Battle Card" />
                </div>
                <div className="text">
                  <h2>Battle Screen</h2>
                  <p>The battle screen is the perfect companion to help you efficiently keep track of your Battle Company during a battle.</p>
                  <p>Keep track of each warrior with individual Might, Will and Fate counters, as well as kill and wound tracking.</p>
                  <ul className="styled">
                    <li><span>Easily identify each warrior by their photo, saving the need for names on their bases.</span></li>
                    <li><span>Track the Might, Will and Fate of each warrior during the battle.</span></li>
                    <li><span>View all of the characteristics of your warrior in one place.</span></li>
                    <li><span>Track the number of wounds inflicted by each warrior.</span></li>
                    <li><span>Click on a wargear item or special rule to open an overlay with it's details, saving you time digging through the rule book.</span></li>
                    <li><span>Record the number of remaining wounds of each warrior. When they reach 0 the card is flipped over, allowing you to easily see who is still active in the battle.</span></li>
                  </ul>
                </div>
              </div>
            )
          }, {
            name: 'War\'s Aftermath',
            content: (
              <div className="section">
                <div className="image">
                  <img src={aftermathCard} alt="Example Aftermath Card" />
                </div>
                <div className="text">
                  <h2>War's Aftermath</h2>
                  <p>One of the most complicated parts of Battle Companies is War's Aftermath - resolving the affects of the battle on each warrior.</p>
                  <p>Battle Companies Manager leads you through this process, avoiding potential errors and saving you time.</p>
                  <ul className="styled">
                    <li><span>Track the battle result - Win, Draw or Loss, along with any bonuses the scenario rewards.</span></li>
                    <li><span>Resolve any wounds for your warriors, with tips on which table to roll on.</span></li>
                    <li><span>Automatically award experience to each warrior based on their contribution, and resolve any progressions in real-time.</span></li>
                    <li><span>All forms are shown directly on the warrior card, making it easy to keep track of who you are dealing with.</span></li>
                    <li><span>Automatically calculate the influence for your company, based on the result of the battle and any bonuses.</span></li>
                    <li><span>All changes instantly update your company, allowing you to instantly jump into another game.</span></li>
                  </ul>
                </div>
              </div>
            )
          }]}
        />
        <span data-ccpa-link="1" />
      </div>
    );
  }
}

Homepage.propTypes = {
  authentication: AuthType.isRequired
};

function mapStateToProps({ authentication }) {
  return { authentication };
}

export default connect(mapStateToProps)(Homepage);
