import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { StatsType } from '../../_types';

import { Table } from '..';

const generateValue = (value, suffix, inputs) => {
  if (!inputs && value === 0) {
    return '-';
  }
  return inputs ? value : `${value}${suffix || ''}`;
};

const createRow = (stats, label, showLabel, inputs) => {
  const {
    move, fight, shoot, strength, defence, attacks, wounds, courage
  } = stats;
  const cells = [
    generateValue(move, '"', inputs),
    <Fragment>
      {generateValue(fight, '', inputs)}
/
      {generateValue(shoot, '+', inputs)}
    </Fragment>,
    generateValue(strength, '', inputs),
    generateValue(defence, '', inputs),
    generateValue(attacks, '', inputs),
    generateValue(wounds, '', inputs),
    generateValue(courage, '', inputs)
  ];
  if (showLabel) {
    cells.unshift(label);
  }
  return cells;
};

const CharacteristicsTable = ({
  base, modified, wargear, total, showLabel, inputs, thLabel
}) => {
  const headers = ['Mv', 'F', 'S', 'D', 'A', 'W', 'C'];
  if (showLabel) {
    headers.unshift(thLabel);
  }

  const rows = [];

  if (base) rows.push(createRow(base, 'Base', showLabel, inputs));
  if (modified) rows.push(createRow(modified, 'Increase', showLabel, inputs));
  if (wargear) {
    _.map(wargear, (item) => {
      rows.push(createRow(item.stats, item.name, showLabel));
    });
  }
  if (total) rows.push(createRow(total, 'Total', showLabel));

  return (
    <Table
      headers={headers}
      rows={rows}
      statsTable
      fixedLayout={!inputs}
    />
  );
};

CharacteristicsTable.propTypes = {
  base: StatsType,
  modified: StatsType,
  total: StatsType,
  wargear: PropTypes.arrayOf(StatsType),
  showLabel: PropTypes.bool,
  inputs: PropTypes.bool,
  thLabel: PropTypes.string
};

CharacteristicsTable.defaultProps = {
  base: null,
  modified: null,
  total: null,
  wargear: null,
  showLabel: false,
  inputs: false,
  thLabel: ''
};

export default CharacteristicsTable;
