import { warriorService } from '../_services/warrior';
import { alertActions } from './alert';
import { companyActions } from './company';

export const warriorConstants = {
  GET_REQUEST: 'WARRIORS_GET_REQUEST',
  GET_SUCCESS: 'WARRIORS_GET_SUCCESS',
  GET_FAILURE: 'WARRIORS_GET_FAILURE',

  CREATE_REQUEST: 'WARRIOR_CREATE_REQUEST',
  CREATE_SUCCESS: 'WARRIOR_CREATE_SUCCESS',
  CREATE_FAILURE: 'WARRIOR_CREATE_FAILURE',

  UPDATE_REQUEST: 'WARRIOR_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'WARRIOR_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'WARRIOR_UPDATE_FAILURE',

  DELETE_REQUEST: 'WARRIOR_DELETE_REQUEST',
  DELETE_SUCCESS: 'WARRIOR_DELETE_SUCCESS',
  DELETE_FAILURE: 'WARRIOR_DELETE_FAILURE',

  IMAGE_UPLOAD_REQUEST: 'WARRIOR_IMAGE_UPLOAD_REQUEST',
  IMAGE_UPLOAD_SUCCESS: 'WARRIOR_IMAGE_UPLOAD_SUCCESS',
  IMAGE_UPLOAD_FAILURE: 'WARRIOR_IMAGE_UPLOAD_FAILURE'
};

function getWarriors(companyId) {
  function request() { return { type: warriorConstants.GET_REQUEST }; }
  function success(warriors) { return { type: warriorConstants.GET_SUCCESS, warriors }; }
  function failure(error) { return { type: warriorConstants.GET_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    warriorService.getWarriors(companyId)
      .then(
        warriors => dispatch(success(warriors)),
        error => dispatch(failure(error.toString()))
      );
  };
}

function createWarrior(warrior, callback) {
  function request(warriorResponse) {
    return { type: warriorConstants.CREATE_REQUEST, warriorResponse };
  }
  function success(warriorResponse) {
    return { type: warriorConstants.CREATE_SUCCESS, warriorResponse };
  }
  function failure(error) { return { type: warriorConstants.CREATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(warrior));

    warriorService.createWarrior(warrior)
      .then(
        (warriorResponse) => {
          dispatch(success(warriorResponse));
          dispatch(companyActions.getUserCompanies());
          if (callback) callback();
          dispatch(alertActions.success(`${warriorResponse.name} created successfully`));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };
}

function updateWarrior(warrior, callback) {
  function request(warriorResponse) {
    return { type: warriorConstants.UPDATE_REQUEST, warriorResponse };
  }
  function success(warriorResponse) {
    return { type: warriorConstants.UPDATE_SUCCESS, warriorResponse };
  }
  function failure(error) { return { type: warriorConstants.UPDATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(warrior));

    warriorService.updateWarrior(warrior)
      .then(
        (warriorResponse) => {
          dispatch(success(warriorResponse));
          dispatch(companyActions.getUserCompanies());
          if (callback) callback();
          dispatch(alertActions.success(`${warriorResponse.name} updated successfully`));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };
}

function deleteWarrior(id) {
  function request(idResponse) { return { type: warriorConstants.DELETE_REQUEST, idResponse }; }
  function success(idResponse) { return { type: warriorConstants.DELETE_SUCCESS, idResponse }; }
  function failure(idResponse, error) {
    return { type: warriorConstants.DELETE_FAILURE, idResponse, error };
  }

  return (dispatch) => {
    dispatch(request(id));

    warriorService.deleteWarrior(id)
      .then(
        (responseId) => {
          dispatch(success(responseId));
          dispatch(companyActions.getUserCompanies());
          dispatch(alertActions.success('Warrior deleted successfully'));
        },
        (error) => {
          dispatch(failure(id, error.toString()));
          dispatch(alertActions.success(error.toString()));
        }
      );
  };
}

function uploadImage(image, croppedImage, warriorId, companyId, callback) {
  return (dispatch) => {
    warriorService.getS3SignedRequest(warriorId, companyId)
      .then(
        (response) => {
          warriorService.uploadImage(response, image, croppedImage)
            .then(() => {
              if (callback) {
                callback(response.data.url);
              }
            },
            (error) => {
              dispatch(alertActions.error(error.toString()));
            });
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
        }
      );
  };
}

// eslint-disable-next-line import/prefer-default-export
export const warriorActions = {
  getWarriors,
  createWarrior,
  updateWarrior,
  deleteWarrior,
  uploadImage
};
