import _ from 'lodash';
import { emptyStats } from '.';

export const emptyProfile = {
  id: 0,
  baseClass: '',
  race: '',
  basePoints: 0,
  baseStats: _.clone(emptyStats),
  totalStats: _.clone(emptyStats),
  wargear: [],
  specialRules: []
};
