/* eslint-disable react/destructuring-assignment */
import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { SpellType, WarriorType } from '../../../_types';

import { emptySpell } from '../../../_constants';

import {
  Button, Accordion, FormRow, RadioInput, TextInput, SelectInput, CheckboxListInput
} from '../..';

class SpellForm extends Component {
  constructor(props) {
    super(props);
    const { spell } = props;

    let mySpellsId = '';
    if (spell.name && !spell.mySpellsId) {
      mySpellsId = 'new';
    } else if (spell.mySpellsId) {
      // eslint-disable-next-line prefer-destructuring
      mySpellsId = spell.mySpellsId;
    }

    this.state = {
      spell: {
        id: spell.id,
        mySpellsId,
        type: spell.type,
        name: spell.name,
        description: spell.description,
        baseCastingValue: spell.baseCastingValue,
        modifyCastingValue: spell.modifyCastingValue,
        addToMySpells: false
      },
      submitted: false
    };
  }

  handleInputChange = (event) => {
    const {
      target: {
        type, name, checked, value
      }
    } = event;
    const val = type === 'checkbox' ? checked : value;

    const { spell } = this.state;
    this.setState({
      spell: {
        ...spell,
        [name]: val
      }
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { onSubmit, profile, spells } = this.props;
    const { spell } = this.state;

    if (!spell.mySpellsId || (spell.mySpellsId === 'new' && !spell.name) || (!profile && !spell.type)) {
      this.setState({
        submitted: true
      });
      return;
    }

    if (spell.mySpellsId && spell.mySpellsId !== 'new') {
      const mySpell = spells[spell.mySpellsId];
      spell.name = mySpell.name;
      spell.description = mySpell.description;
    }

    spell.baseCastingValue = parseInt(spell.baseCastingValue, 10);
    spell.modifyCastingValue = parseInt(spell.modifyCastingValue, 10);

    if (spell.id) {
      onSubmit(spell);
    } else {
      onSubmit(spell);
      this.setState({
        spell: {
          ...emptySpell,
          addToMySpells: false
        },
        submitted: false
      });
    }
  }

  render() {
    const {
      onSubmit, spell, spells, warrior, profile
    } = this.props;
    const {
      spell: {
        id,
        mySpellsId,
        type,
        name,
        description,
        baseCastingValue,
        modifyCastingValue,
        addToMySpells
      },
      submitted
    } = this.state;
    return (
      <form onSubmit={this.handleSubmit} id={`${id === 0 ? 'new-spell' : ''}`}>
        <Accordion heading={spell.name ? `${spell.name} (${spell.baseCastingValue - spell.modifyCastingValue}+)` : 'New Spell'} newItem={id === 0}>
          {!profile && (
          <FormRow>
            <RadioInput
              name="type"
              label="Type"
              value={type}
              options={[{
                label: 'Included in base profile',
                value: 'base'
              }, {
                label: 'Gained through progression',
                value: 'progression'
              }]}
              onChange={this.handleInputChange}
              required
              showError={submitted && !type}
            />
          </FormRow>
          )}
          <FormRow>
            <SelectInput name="mySpellsId" label="Load Spell" value={mySpellsId} onChange={this.handleInputChange} required showError={submitted && !mySpellsId}>
              <option value="">Please Select</option>
              <option value="new">Create New Spell</option>
              <optgroup label="Your Spells">
                {_.map(_.orderBy(spells, ['name']), (r) => {
                  const matchingSpell = !!_.find(warrior.spells, { mySpellsId: r.id }) && spell.mySpellsId !== r.id;
                  return <option disabled={matchingSpell} value={r.id} key={r.id}>{r.name}</option>;
                })}
              </optgroup>
            </SelectInput>
          </FormRow>
          {mySpellsId === 'new' && (
          <Fragment>
            <FormRow>
              <TextInput name="name" label="Name" value={name} onChange={this.handleInputChange} required showError={submitted && !name} />
            </FormRow>
            <FormRow>
              <TextInput name="description" label="Description" value={description} onChange={this.handleInputChange} textarea />
            </FormRow>
          </Fragment>
          )}
          <FormRow cls="row--modify-casting-value">
            <SelectInput name="baseCastingValue" label="Casting Value" value={baseCastingValue} onChange={this.handleInputChange}>
              <option value="">Base</option>
              <option value="6">6+</option>
              <option value="5">5+</option>
              <option value="4">4+</option>
              <option value="3">3+</option>
              <option value="2">2+</option>
            </SelectInput>
            <SelectInput name="modifyCastingValue" label="&nbsp;" value={modifyCastingValue} onChange={this.handleInputChange}>
              <option value="0">Modifier</option>
              <option value="1">-1</option>
              <option value="2">-2</option>
              <option value="3">-3</option>
            </SelectInput>
          </FormRow>
          <div className="form__inner-footer">
            {(mySpellsId === 'new' && !id) && (
            <FormRow cls="row--cb-save">
              <CheckboxListInput
                options={[{ name: 'addToMySpells', label: 'Save to My Spells', value: addToMySpells }]}
                onChange={this.handleInputChange}
              />
            </FormRow>
            )}
            {id !== 0 && (<Button text="Remove" destructive small floatRight type="button" onClick={() => onSubmit(this.state.spell, true)} />)}
            <Button text={id ? 'Update' : 'Create'} primary small floatRight type="submit" />
          </div>
        </Accordion>
      </form>
    );
  }
}

SpellForm.propTypes = {
  spell: SpellType.isRequired,
  spells: PropTypes.objectOf(SpellType.isRequired).isRequired,
  warrior: WarriorType.isRequired,
  onSubmit: PropTypes.func.isRequired,
  profile: PropTypes.bool.isRequired
};

export default SpellForm;
