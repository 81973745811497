import { authHeader } from '../_helpers/authHeader';
import { apiUrl } from '../_constants';
import { handleResponse } from '.';
import 'whatwg-fetch';

function getHeroicActions() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${apiUrl}/heroicAction`, requestOptions).then(handleResponse);
}

function createHeroicAction(heroicAction) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(heroicAction)
  };

  return fetch(`${apiUrl}/heroicAction/create`, requestOptions).then(handleResponse);
}

function updateHeroicAction(heroicAction) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(heroicAction)
  };

  return fetch(`${apiUrl}/heroicAction/${heroicAction.id}`, requestOptions).then(handleResponse);
}

function deleteHeroicAction(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };

  return fetch(`${apiUrl}/heroicAction/${id}`, requestOptions).then(handleResponse);
}

// eslint-disable-next-line import/prefer-default-export
export const heroicActionService = {
  getHeroicActions,
  createHeroicAction,
  updateHeroicAction,
  deleteHeroicAction
};
