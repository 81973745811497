/* eslint-disable react/jsx-one-expression-per-line */
import React, { Fragment, useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { SpellType } from '../_types';
import { emptySpell } from '../_constants';
import { spellActions } from '../_actions';
import { useStoredSort } from '../_hooks/useStoredSort';

import {
  Grid, Loader, SpellCard, NewCard, Advert, PageHeader
} from '../_components';
import EditSpellOverlay from '../_components/Overlay/EditSpellOverlay';

const storageName = 'sort-spells';
const sortOptions = [
  { label: 'Name', value: o => o.name.toLowerCase(), mode: 'asc' },
  { label: 'Name', value: o => o.name.toLowerCase(), mode: 'desc' }
];

function Spells({ spells, getSpellsAction }) {
  const [sort, setSort] = useState(useStoredSort(sortOptions, 0, storageName));

  useEffect(() => {
    getSpellsAction();
  }, [getSpellsAction]);

  if (spells.loading) {
    return <Loader />;
  }

  let i = 0;

  const orderedSpells = _.orderBy(spells, [sort.value], [sort.mode]);

  return (
    <Fragment>
      <Advert isWide />
      <PageHeader title="My Spells" sortOption={sort} setSort={setSort} sortOptions={sortOptions} storageName={storageName} />
      <Grid>
        {_.map(orderedSpells, spell => (
          <Fragment key={spell.id}>
            <SpellCard spell={spell} />
            {(i++ % 4 === 0) && <Advert />}
          </Fragment>
        ))}
        <NewCard
          key="new"
          title="New Spell"
          overlay={<EditSpellOverlay spell={_.clone(emptySpell)} />}
          small
        />
      </Grid>
    </Fragment>
  );
}

Spells.propTypes = {
  spells: PropTypes.oneOfType([
    PropTypes.arrayOf(SpellType),
    PropTypes.object
  ]).isRequired,
  getSpellsAction: PropTypes.func.isRequired
};

function mapStateToProps({ spells }) {
  return { spells };
}

export default withRouter(connect(mapStateToProps, {
  getSpellsAction: spellActions.getSpells
})(Spells));
