import _ from 'lodash';
import { heroicActionConstants } from '../_actions';

function formatItems(heroicActions) {
  if (!heroicActions) {
    return {};
  }

  return _.mapKeys(heroicActions, 'id');
}

function formatItem(heroicAction, state) {
  if (!heroicAction) {
    return {};
  }
  const newState = { ...state };
  newState[heroicAction.id] = heroicAction;
  return newState;
}

export default function (state = { loading: true }, action) {
  switch (action.type) {
    case heroicActionConstants.GET_REQUEST:
      return { loading: true };
    case heroicActionConstants.GET_SUCCESS:
      return formatItems(action.heroicAction);
    case heroicActionConstants.GET_FAILURE:
      return { error: action.error };

    case heroicActionConstants.CREATE_SUCCESS:
      return formatItem(action.heroicActionResponse, state);

    case heroicActionConstants.UPDATE_SUCCESS:
      return formatItem(action.heroicActionResponse, state);

    case heroicActionConstants.DELETE_SUCCESS:
      return _.omit(state, action.idResponse.id);

    default:
      return state;
  }
}
