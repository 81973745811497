import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { PropTypes } from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import { userActions } from '../../_actions';
import { CompaniesType, AuthType } from '../../_types';
// eslint-disable-next-line import/no-cycle
import { Icon, Button } from '..';

import logo from '../../_assets/logo.svg';
import logoMobile from '../../_assets/logo-mobile.svg';
import './styles.scss';

class Navigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navOpen: false
    };
  }

  toggleNav = () => {
    const { navOpen } = this.state;
    this.setState({
      navOpen: !navOpen
    });
  }

  closeNav = () => {
    this.setState({
      navOpen: false
    });
  }

  render() {
    const { navOpen } = this.state;
    const {
      history, authentication, companies, logout
    } = this.props;
    const activeCompanyId = history.location.pathname.replace('/company/', '').split('/')[0];

    return (
      <header id="header-main" className={navOpen ? 'nav-open' : ''}>
        <div className="container">
          <Link to="/" onClick={this.closeNav} className="logo"><img src={logo} alt="Battle Companies Manager" /></Link>
          <Link to="/" onClick={this.closeNav} className="logo-mobile"><img src={logoMobile} alt="Battle Companies Manager" /></Link>
          <nav id="main-nav">
            <button className="nav-caller" onClick={this.toggleNav} type="button">
              <span className="line" />
              <span className="line" />
              <span className="line" />
            </button>
            <div className="sliding-nav">
              <ul>
                {!authentication.loggedIn && (
                  <li><Link to="/login" onClick={this.closeNav}>Login / Register</Link></li>
                )}
                {authentication.loggedIn && !companies.loading && (
                <Fragment>
                  <li><Link to="/companies" onClick={this.closeNav}>My Companies</Link></li>
                  {_.map(_.filter(companies, c => !c.retired), ({ id, name, warriors }) => (
                    <CompanyNav
                      key={id}
                      id={id}
                      name={name}
                      warriors={warriors}
                      activeCompanyId={activeCompanyId}
                      closeNav={this.closeNav}
                    />
                  ))}
                </Fragment>
                )}
                {authentication.loggedIn && (
                <Fragment>
                  <li><Link to="/profiles" onClick={this.closeNav}>My Profiles</Link></li>
                  <li><Link to="/armoury" onClick={this.closeNav}>My Armoury</Link></li>
                  <li><Link to="/rules" onClick={this.closeNav}>My Rules</Link></li>
                  <li><Link to="/spells" onClick={this.closeNav}>My Spells</Link></li>
                  <li><Link to="/heroics" onClick={this.closeNav}>My Heroic Actions</Link></li>
                  <li><Link to="/retired" onClick={this.closeNav}>Retired Companies</Link></li>
                  <li><Link to="/graveyard" onClick={this.closeNav}>Graveyard</Link></li>
                  <li><Link to="/account" onClick={this.closeNav}>Account Details</Link></li>
                </Fragment>
                )}
                <li><Link to="/about" onClick={this.closeNav}>About</Link></li>
                <li><Link to="/contact" onClick={this.closeNav}>Contact</Link></li>
                {authentication.loggedIn && (
                  <li><Button text="Logout" onClick={() => logout()} /></li>
                )}
              </ul>
            </div>
          </nav>
        </div>
      </header>
    );
  }
}

const CompanyNav = ({
  id, name, warriors, activeCompanyId, closeNav
}) => {
  const active = activeCompanyId === id.toString();
  const orderedWariors = _.sortBy(warriors, (warrior) => {
    switch (warrior.warriorRank) {
      case 'Leader': return 1;
      case 'Sergeant': return 2;
      case 'Hero': return 3;
      case 'Warrior': return 4;
      case 'Wanderer': return 5;
      default: return 6;
    }
  });

  return (
    <li className={`company ${active ? 'active' : ''}`}>
      <Link to={`/company/${id}`} onClick={closeNav}>
        {active ? <Icon name="bookmark-filled" /> : <Icon name="bookmark" />}
        {name}
      </Link>
      {warriors && (
        <ul>
          {_.map(orderedWariors, warrior => (
            <WarriorNav
              key={warrior.id}
              id={warrior.id}
              name={warrior.name}
              rank={warrior.warriorRank}
              closeNav={closeNav}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

const WarriorNav = ({
  id, name, rank, closeNav
}) => (
  <li className={rank.toLowerCase()}>
    <Link
      to={`#card-${id}`}
      onClick={(e) => {
        e.preventDefault();
        closeNav();
        window.scrollTo(0, document.getElementById(`card-${id}`).offsetTop - 20);
      }}
    >
      <Icon name="user" />
      {name}
    </Link>
  </li>
);

Navigation.propTypes = {
  companies: PropTypes.oneOfType([CompaniesType, PropTypes.objectOf(PropTypes.bool)]).isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  authentication: AuthType.isRequired,
  logout: PropTypes.func.isRequired
};

CompanyNav.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  warriors: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    warriorRank: PropTypes.string.isRequired
  })).isRequired,
  closeNav: PropTypes.func.isRequired,
  activeCompanyId: PropTypes.string
};

CompanyNav.defaultProps = {
  activeCompanyId: ''
};

WarriorNav.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  rank: PropTypes.string.isRequired,
  closeNav: PropTypes.func.isRequired
};

function mapStateToProps({ companies, authentication }) {
  return { companies, authentication };
}

export default withRouter(connect(mapStateToProps, {
  logout: userActions.logout
})(Navigation));
