import React, { Fragment, useState, useEffect } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { WarriorsType } from '../_types';
import { graveyardActions } from '../_actions';

import {
  WarriorList, Loader, Icon, PageHeader
} from '../_components';
import { useStoredSort } from '../_hooks/useStoredSort';

const storageName = 'sort-graveyard';
const sortOptions = [
  { label: 'Default', value: 'default', mode: '' },
  { label: 'Level', value: 'experience', mode: 'asc' },
  { label: 'Level', value: 'experience', mode: 'desc' },
  { label: 'Name', value: o => o.name.toLowerCase(), mode: 'asc' },
  { label: 'Name', value: o => o.name.toLowerCase(), mode: 'desc' },
  { label: 'Points', value: 'totalPoints', mode: 'asc' },
  { label: 'Points', value: 'totalPoints', mode: 'desc' },
  { label: 'Rank', value: 'sortRank', mode: 'asc' },
  { label: 'Rank', value: 'sortRank', mode: 'desc' }
];

function Graveyard({ graveyard, getWarriorsAction }) {
  const [sort, setSort] = useState(useStoredSort(sortOptions, 0, storageName));

  useEffect(() => {
    getWarriorsAction();
  }, [getWarriorsAction]);

  if (graveyard.loading) {
    return <Loader />;
  }

  const groupedWarriors = _.groupBy(graveyard, 'companyId');

  return (
    <Fragment>
      <PageHeader title="Graveyard" sortOption={sort} setSort={setSort} sortOptions={sortOptions} storageName={storageName} />
      {_.map(groupedWarriors, (group, index) => (
        <Fragment key={index}>
          <h2>
            <Icon name="bookmark" />
            {' '}
            {group[0].companyName}
          </h2>
          <WarriorList warriors={_.mapKeys(group, 'id')} companyId={group[0].companyId} sortOption={sort} editable clickable isGraveyard />
        </Fragment>
      ))}
    </Fragment>
  );
}

Graveyard.propTypes = {
  graveyard: WarriorsType.isRequired,
  getWarriorsAction: PropTypes.func.isRequired
};

function mapStateToProps({ graveyard }) {
  return { graveyard };
}

export default withRouter(connect(mapStateToProps,
  { getWarriorsAction: graveyardActions.getGraveyardWarriors })(Graveyard));
