import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import AlertsReducer from './alerts';
import AuthenticationReducer from './authentication';
import ChangePasswordReducer from './changePassword';
import CompaniesReducer from './companies';
import DonationReducer from './donation';
import GraveyardReducer from './graveyard';
import HeroicActionsReducer from './heroicActions';
import ProfilesReducer from './profiles';
import RegistrationReducer from './registration';
import ResetPasswordReducer from './resetPassword';
import RulesReducer from './rules';
import SharedCompanyReducer from './sharedCompany';
import SpellsReducer from './spells';
import WargearReducer from './wargear';
import WarriorsReducer from './warriors';

const rootReducer = combineReducers({
  alerts: AlertsReducer,
  authentication: AuthenticationReducer,
  companies: CompaniesReducer,
  donation: DonationReducer,
  graveyard: GraveyardReducer,
  heroicActions: HeroicActionsReducer,
  profiles: ProfilesReducer,
  registration: RegistrationReducer,
  changePassword: ChangePasswordReducer,
  resetPassword: ResetPasswordReducer,
  rules: RulesReducer,
  sharedCompany: SharedCompanyReducer,
  spells: SpellsReducer,
  wargear: WargearReducer,
  warriors: WarriorsReducer,
  form: formReducer
});

export default rootReducer;
