// eslint-disable-next-line import/prefer-default-export
export const emptyCompany = {
  id: 0,
  name: '',
  race: '',
  backstory: '',
  influence: 0,
  rating: 0,
  currentRating: 0
};
