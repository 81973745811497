import { heroicActionService } from '../_services/heroicAction';
import { alertActions } from './alert';

export const heroicActionConstants = {
  GET_REQUEST: 'HEROIC_ACTIONS_GET_REQUEST',
  GET_SUCCESS: 'HEROIC_ACTIONS_GET_SUCCESS',
  GET_FAILURE: 'HEROIC_ACTIONS_GET_FAILURE',

  CREATE_REQUEST: 'HEROIC_ACTION_CREATE_REQUEST',
  CREATE_SUCCESS: 'HEROIC_ACTION_CREATE_SUCCESS',
  CREATE_FAILURE: 'HEROIC_ACTION_CREATE_FAILURE',

  UPDATE_REQUEST: 'HEROIC_ACTION_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'HEROIC_ACTION_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'HEROIC_ACTION_UPDATE_FAILURE',

  DELETE_REQUEST: 'HEROIC_ACTION_DELETE_REQUEST',
  DELETE_SUCCESS: 'HEROIC_ACTION_DELETE_SUCCESS',
  DELETE_FAILURE: 'HEROIC_ACTION_DELETE_FAILURE'
};

function getHeroicActions() {
  function request() { return { type: heroicActionConstants.GET_REQUEST }; }
  function success(heroicAction) { return { type: heroicActionConstants.GET_SUCCESS, heroicAction }; }
  function failure(error) { return { type: heroicActionConstants.GET_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    heroicActionService.getHeroicActions()
      .then(
        heroicActions => dispatch(success(heroicActions)),
        error => dispatch(failure(error.toString()))
      );
  };
}

function createHeroicAction(heroicAction, callback) {
  function request(heroicActionResponse) {
    return { type: heroicActionConstants.CREATE_REQUEST, heroicActionResponse };
  }
  function success(heroicActionResponse) {
    return { type: heroicActionConstants.CREATE_SUCCESS, heroicActionResponse };
  }
  function failure(error) { return { type: heroicActionConstants.CREATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(heroicAction));

    heroicActionService.createHeroicAction(heroicAction)
      .then(
        (heroicActionResponse) => {
          dispatch(success(heroicActionResponse));
          if (callback) callback();
          dispatch(alertActions.success(`${heroicActionResponse.name} created successfully`));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };
}

function updateHeroicAction(heroicAction, callback) {
  function request(heroicActionResponse) {
    return { type: heroicActionConstants.UPDATE_REQUEST, heroicActionResponse };
  }
  function success(heroicActionResponse) {
    return { type: heroicActionConstants.UPDATE_SUCCESS, heroicActionResponse };
  }
  function failure(error) { return { type: heroicActionConstants.UPDATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(heroicAction));

    heroicActionService.updateHeroicAction(heroicAction)
      .then(
        (heroicActionResponse) => {
          dispatch(success(heroicActionResponse));
          if (callback) callback();
          dispatch(alertActions.success(`${heroicActionResponse.name} updated successfully`));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };
}

function deleteHeroicAction(id) {
  function request(idResponse) { return { type: heroicActionConstants.DELETE_REQUEST, idResponse }; }
  function success(idResponse) { return { type: heroicActionConstants.DELETE_SUCCESS, idResponse }; }
  function failure(idResponse, error) {
    return { type: heroicActionConstants.DELETE_FAILURE, idResponse, error };
  }

  return (dispatch) => {
    dispatch(request(id));

    heroicActionService.deleteHeroicAction(id)
      .then(
        (responseId) => {
          dispatch(success(responseId));
          dispatch(alertActions.success('HeroicAction deleted successfully'));
        },
        (error) => {
          dispatch(failure(id, error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };
}

// eslint-disable-next-line import/prefer-default-export
export const heroicActionActions = {
  getHeroicActions,
  createHeroicAction,
  updateHeroicAction,
  deleteHeroicAction
};
