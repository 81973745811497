import React from 'react';
import PropTypes from 'prop-types';

import symbols from '../../_assets/svg-symbols.svg';

import './styles.scss';

const Icon = ({ name }) => {
  switch (name) {
    case 'arrow-left':
      return (
        <svg className="icon-arrow-left" viewBox="0 0 18.5 18.5">
          <polygon points="8.8,0.5 10.2,2 2.9,9.3 10.2,16.5 8.8,18 0,9.3" />
          <rect x="1.5" y="8.3" width="17" height="2" />
        </svg>
      );
    case 'arrow-right':
      return (
        <svg className="icon-arrow-right" viewBox="0 0 18.5 18.5">
          <polygon points="9.8,18 8.3,16.5 15.6,9.3 8.3,2 9.8,0.5 18.5,9.3" />
          <rect x="0" y="8.3" width="17" height="2" />
        </svg>
      );
    case 'bookmark-filled':
      return (
        <svg className="icon-bookmark-filled" viewBox="0 0 11.7 11.7">
          <path d="M1.4,0.1h9v11.5L9.6,11L5.9,8.2L2.2,11l-0.8,0.6C1.4,11.6,1.4,0.1,1.4,0.1z" />
        </svg>
      );
    case 'bookmark':
      return (
        <svg className="icon-bookmark" viewBox="0 0 11.7 11.7">
          <path d="M1.4,0.1h9v11.5L9.6,11L5.9,8.2L2.2,11l-0.8,0.6V0.1z M2.4,1.1v8.5l3.2-2.4L5.9,7l0.3,0.2l3.2,2.4V1.1H2.4z" />
        </svg>
      );
    case 'check':
      return (
        <svg className="icon-check" viewBox="0 0 768 768">
          <path d="M695.166 97.837l-439.165 439.165-183.167-183.166c-16.683-16.683-43.69-16.683-60.331 0s-16.683 43.69 0 60.331l213.332 213.332c16.683 16.683 43.691 16.683 60.331 0l469.33-469.33c16.683-16.683 16.683-43.69 0-60.331s-43.691-16.683-60.331 0z" />
        </svg>
      );
    case 'chevron-down':
      return (
        <svg className="icon-chevron-down" viewBox="0 0 16.2 16.2">
          <path d="M8.1,12.5c-0.3,0-0.5-0.1-0.7-0.3L0.5,5.4c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l6.2,6.2l6.2-6.2c0.4-0.4,1-0.4,1.4,0
            s0.4,1,0,1.4l-6.9,6.9C8.6,12.4,8.4,12.5,8.1,12.5z"
          />
        </svg>
      );
    case 'chevron-up':
      return (
        <svg className="icon-chevron-up" viewBox="0 0 16.2 16.2">
          <path d="M8.1,3.7c0.3,0,0.5,0.1,0.7,0.3l6.9,6.9c0.4,0.4,0.4,1,0,1.4s-1,0.4-1.4,0L8.1,6.1l-6.2,6.2c-0.4,0.4-1,0.4-1.4,0
            s-0.4-1,0-1.4l6.9-6.9C7.6,3.8,7.8,3.7,8.1,3.7z"
          />
        </svg>
      );
    case 'cross':
      return (
        <svg className="icon-cross" viewBox="0 0 768 768">
          <path d="M687.499 141.608l-242.393 242.392 242.393 242.393-61.106 61.106-242.392-242.392-242.392 242.393-61.106-61.106 242.392-242.392-242.392-242.392 61.106-61.106 242.393 242.393 242.393-242.393z" />
        </svg>
      );
    case 'draw':
      return (
        <svg className="icon-draw" viewBox="0 0 32 32">
          <path d="M6.275 28.377c-0.666 0-1.428-0.285-1.904-0.762-1.047-1.047-1.047-2.761 0-3.808l4.094-4.094 0.857 0.857-4.094 4.094c-0.571 0.571-0.571 1.619 0 2.19s1.619 0.571 2.19 0l4.094-4.094 0.857 0.857-4.094 4.094c-0.571 0.381-1.333 0.666-2 0.666z" />
          <path d="M13.797 24.95c-0.476 0-0.952-0.19-1.333-0.571l-4.856-4.856c-0.381-0.381-0.571-0.857-0.571-1.333s0.19-0.952 0.571-1.333c0.762-0.762 1.999-0.762 2.666 0l4.856 4.856c0.381 0.381 0.571 0.857 0.571 1.333s-0.19 0.952-0.571 1.333c-0.381 0.381-0.857 0.571-1.333 0.571zM13.225 23.617c0.286 0.285 0.762 0.285 1.047 0 0.095-0.095 0.19-0.285 0.19-0.571 0-0.19-0.095-0.381-0.19-0.571l-4.856-4.856c-0.286-0.285-0.762-0.285-1.047 0-0.095 0.095-0.19 0.285-0.19 0.571s0.095 0.381 0.19 0.571l4.856 4.856z" />
          <path d="M17.7 22.379l-10.378-10.378c-2.19-2.19-3.618-5.046-3.713-7.712v-0.666h0.666c2.571 0.285 5.427 1.619 7.617 3.808l10.378 10.378-4.57 4.57zM4.847 4.956c0.285 2.19 1.523 4.475 3.332 6.284l9.521 9.521 2.951-2.951-9.521-9.521c-1.904-1.809-4.189-3.047-6.284-3.332z" />
          <path d="M19.509 20.284c-0.19 0-0.285-0.095-0.381-0.19l-9.711-9.711c-0.19-0.19-0.19-0.571 0-0.857 0.19-0.19 0.571-0.19 0.857 0l9.711 9.711c0.19 0.19 0.19 0.571 0 0.857-0.19 0.095-0.285 0.19-0.476 0.19z" />
          <path d="M25.507 28.377c-0.666 0-1.428-0.285-1.904-0.762l-4.094-4.094 0.857-0.857 4.094 4.094c0.571 0.571 1.619 0.571 2.19 0s0.571-1.619 0-2.19l-4.094-4.094 0.857-0.857 4.094 4.094c1.047 1.047 1.047 2.761 0 3.808-0.571 0.571-1.238 0.857-2 0.857z" />
          <path d="M18.081 24.95c-0.476 0-0.952-0.19-1.333-0.571s-0.571-0.857-0.571-1.333 0.19-0.952 0.571-1.333l4.856-4.856c0.762-0.762 2-0.762 2.666 0 0.381 0.381 0.571 0.857 0.571 1.333s-0.19 0.952-0.571 1.333l-4.856 4.856c-0.381 0.381-0.857 0.571-1.333 0.571zM22.937 17.428c-0.19 0-0.381 0.095-0.571 0.19l-4.856 4.856c-0.095 0.095-0.19 0.285-0.19 0.571 0 0.19 0.095 0.381 0.19 0.571 0.285 0.285 0.762 0.285 1.047 0l4.856-4.856c0.095-0.095 0.19-0.285 0.19-0.571s-0.095-0.381-0.19-0.571c-0.095-0.095-0.285-0.19-0.476-0.19z" />
          <path d="M15.415 19.523l-1.238 1.238-2.951-2.951 1.238-1.238-0.762-0.857-2.095 2.095 4.57 4.57 2.095-2.095z" />
          <path d="M27.602 3.718c-2.666 0.19-5.522 1.523-7.712 3.713l-4.475 4.475 0.857 0.857 4.475-4.475c1.809-1.809 4.094-3.047 6.284-3.332-0.285 2.19-1.523 4.475-3.332 6.284l-4.475 4.475 0.857 0.857 4.475-4.475c2.19-2.19 3.618-5.046 3.713-7.712v-0.666h-0.666z" />
          <path d="M13.511 17.619l-1.619 1.619c-0.19 0.19-0.19 0.571 0 0.857 0.095 0.095 0.286 0.19 0.381 0.19s0.286-0.095 0.381-0.19l1.619-1.619-0.762-0.857z" />
          <path d="M22.461 9.526c-0.19-0.19-0.571-0.19-0.857 0l-4.094 4.094 0.857 0.857 4.094-4.094c0.19-0.286 0.19-0.666 0-0.857z" />
        </svg>
      );
    case 'experience':
      return (
        <svg className="icon-experience" viewBox="0 0 21.4 21.4">
          <path d="M10.7,20.9C5.1,19.1,1.2,13,1.2,6V0.5h19V6C20.2,13,16.3,19.1,10.7,20.9z" />
          <path d="M19.7,1v5c0,6.7-3.7,12.6-9,14.4c-5.3-1.8-9-7.7-9-14.4V1H19.7 M20.7,0h-20v6c0,7.4,4.3,13.7,10,15.4
            c5.7-1.8,10-8,10-15.4V0L20.7,0z"
          />
        </svg>
      );
    case 'exit':
      return (
        <svg className="icon-exit" viewBox="0 0 640 640">
          <path d="M32 320l192 160v-96h224v-128h-224v-96l-192 160zM544 544h-256v64h256c35.2 0 64-28.8 64-64v-448c0-35.2-28.8-64-64-64h-256v64h256v448z" />
        </svg>
      );
    case 'feather':
      return (
        <svg className="icon-feather" viewBox="0 0 640 640">
          <path d="M136.128 626.144c9.824-31.424 24.64-75.648 44.512-139.584 86.624-13.728 122.464 10.912 177.472-87.328-44.64
            13.664-98.464-25.344-95.584-42.272 2.912-16.896 125.216 12.192 205.312-101.536-100.96 22.272-133.248-26.752-120.224-34.144
            30.048-17.088 119.232-7.104 166.784-53.408 24.512-23.84 36-81.792 26.016-102.464-11.968-24.992-84.992-62.272-125.248-58.752-40.256
            3.488-103.392 153.28-122.144 152.128-18.688-1.184-22.496-67.136 10.208-128.416-34.464 15.264-97.632 62.688-117.44 103.232-36.896
            75.424 3.456 248.512-9.472 254.656-12.96 6.176-56.512-79.392-69.504-118.208-17.76 59.488-18.176 119.072 33.696 198.208-19.552 51.936-30.24
            111.712-31.872 142.112-0.768 24.288 23.168 29.504 27.488 15.776z"
          />
        </svg>
      );
    case 'flee':
      return (
        <svg className="icon-flee" viewBox="0 0 768 768">
          <path d="M316.5 619.5l-223.5-43.5 12-64.5 157.5 31.5 51-259.5-58.5 22.5v109.5h-63v-150l166.5-70.5q4.5 0 12.75-1.5t12.75-1.5q31.5 0 54 31.5l31.5 51q43.5 76.5 138 76.5v64.5q-106.5 0-175.5-79.5l-19.5 96 67.5 63v240h-64.5v-192l-67.5-63zM432 175.5q-25.5 0-45-19.5t-19.5-45 19.5-44.25 45-18.75 44.25 18.75 18.75 44.25-18.75 45-44.25 19.5z" />
        </svg>
      );
    case 'horseshoe':
      return (
        <svg className="icon-horseshoe" viewBox="0 0 798.58 798.58">
          <path d="M101,106.1V12c38.1,0,75.6-1.5,112.8,0.5c25.5,1.4,50.1,37.6,51.9,69.9c1.5,28.2-9.4,52.7-21.9,76.7
            c-20.1,38.4-43.2,75.5-60.2,115.2c-23.5,54.9-36.4,112.4-29.1,173.5c11.8,99.4,84.9,184,182.9,205.2
            c107.4,23.3,195.9-10.1,265.6-94.4c41.6-50.3,55.6-108.3,50-172.7c-6.4-73.3-38.8-136-77-196.7c-18.6-29.6-37.4-59.5-52.1-91.1
            c-11.4-24.4-5.8-49.9,14.2-69.3c7.1-6.9,17.6-13.8,26.8-14.2c40.1-1.5,80.2-0.7,121.9-0.7V106c-11.9,0-21.8-0.2-31.6,0
            c-15.4,0.4-20,7.7-12,21c8.3,13.6,18.3,26.1,27.5,39.2c34.3,48.6,69.6,97.1,85.8,155.1c33.2,118.9,18.1,229.6-62.1,327.6
            c-38.4,46.9-82.8,86.1-138.7,111.3c-41.4,18.7-85.2,27.1-130,30.2c-75.4,5.1-147.7-6.7-213.1-46.9C151,705.7,98.3,658.7,64.4,593.2
            c-23.8-45.9-28.5-95.5-30.6-146c-3.2-78.8,20-150.5,56.6-218.7c17.3-32.1,36.2-63.4,54.7-94.8c10.3-17.4,6.3-26.1-13.9-27.5
            C121.8,105.6,112.2,106.1,101,106.1z M400.4,748.9c51.3,0.1,101.8-11.9,147.7-34.8c11.7-6,22.6-13.3,32.5-21.9
            c7.6-6.6,10.5-16.6,3.7-25.9c-6.8-9.3-15.8-6.8-24.5-2.3c-2.8,1.5-5.9,2.5-8.8,3.8c-69.3,31.3-141.7,41.8-216.3,26.5
            c-31-6.3-60.8-18.6-91.1-28.2c-7.7-2.5-17.1-7.8-22.2,1c-3.8,6.5-4.6,16.5-2.9,24c1.4,5.9,7.8,12.3,13.6,15.3
            C283.7,732.3,338.4,746.7,400.4,748.9z M151.8,632.3c7.5-3.1,19.7-15.9,22.2-20.1c2.8-4.7,0.3-14.9-3-20.8
            c-10.3-18.3-22.2-35.7-33.3-53.5c-7.4-11.9-16.1-14.4-28.2-7.7c-13.9,7.7-18.5,16.9-11.7,28.7c7.6,13.2,15.7,26.2,23.9,39
            c6.9,10.8,12.7,22.9,21.9,31.8c1.2,1.3,2.6,2.3,4.2,3.1C149.2,633.2,150.6,633,151.8,632.3z M704.4,552c0.3-10.6-8.3-21.2-19.3-24.1
            c-9-2.4-16.5-1.2-22.1,8.1c-11.9,19.9-24.9,39.2-37.1,59c-6.6,10.6-3.5,20.4,8.4,29c10.8,7.8,20.4,6.8,28.2-4.3
            c13.3-18.9,25.5-38.5,38.6-57.5C703.2,559.2,704.3,555.7,704.4,552z M731.9,427.8c0.2-10.8-0.1-21.7-1-32.8
            c-1.1-13.9-11.7-16.2-22.8-15.6c-10.4,0.5-20.1,2.8-20.7,16.5c-1,23.6-2.5,47.3-3.4,70.9c-0.5,13.4,4.4,18.2,18.6,19.6
            c16.3,1.6,24.7-3.3,26.2-16.4C730.6,455.7,731.6,441.8,731.9,427.8L731.9,427.8z M71.1,435C71,447,71.6,459,72.8,471
            c1.2,11.7,11.1,18.3,24.9,17.1s20.4-8,19.8-20.6c-1.1-23.2-2.2-46.4-3.4-69.6c-0.6-11.8-7.4-18.4-20.7-18.2
            c-10.4,0.2-20.1,2.9-20.9,16.4C71.8,409.2,71.2,422.1,71.1,435L71.1,435z M721.8,320.8c0-1-0.1-2-0.2-3.1c-2-12.9-9.9-27-15.9-38.4
            c-6.3-11.8-13.6-23.6-19.7-35.1c-5.3-9.9-16.1-7.4-24.4-2.8c-8.3,4.5-15.2,10.3-10.4,21.9c9.5,22.7,18.7,45.6,27.4,68.5
            c4.4,11.7,13,11.5,22.3,8.9C711.1,338,721.8,332.1,721.8,320.8L721.8,320.8z M117.3,342.5c5.4-1.6,10.1-5.6,12.5-12.4
            c8.1-22.7,14.3-46,21.5-69c3.9-12.5-4.1-17.9-13.3-21.7c-9.6-4-19.2-4.2-24,7.6c-8.6,21-17.4,42-24.9,63.4c-3.2,9-2.8,19,4.7,25.7
            C100.8,342.2,109.8,344.7,117.3,342.5z"
          />
        </svg>
      );
    case 'injury-arm':
      return (
        <svg className="icon-injury-arm" viewBox="0 0 99.3 99.4">
          <path d="M51.2,52.8c-4.2,0-8.1-2.4-11.1-6.7c-0.7-1.1-1.1-2.3-1.1-3.6v-5.2c-2.3-0.8-4-4.2-4-7c0-2.4,1.3-3.7,2.2-4.3
            c-0.4-2.8-0.6-10.8,4.2-14.6c2.7-2.1,5.2-3.1,7.4-4c1.7-0.7,3.2-1.3,4.3-2.2l2.2-2l-0.6,2.9c-0.1,0.3-0.1,0.7-0.2,1
            c0.9-0.9,1.8-1.8,2-2.5l1.1-2.9l0.8,3C58.8,5.9,59,7,59,8c0.1-0.1,0.1-0.2,0.2-0.4l0.8-2l1,1.9c0.2,0.3,0.4,0.6,0.6,1
            c1.7,2.8,5,8.1,3.7,17.5c0.9,0.6,2.2,2,2.2,4.4c0,2.8-1.8,6.2-4,7v5.2c0,1.3-0.4,2.5-1.1,3.6C59.4,50.5,55.4,52.8,51.2,52.8z
              M52.3,8c-0.9,0.4-1.8,0.8-2.8,1.2c-2.1,0.8-4.5,1.8-6.9,3.7c-4.2,3.3-3.9,11.4-3.4,13.3l0.2,0.8l-0.7,0.3
            c-0.1,0-1.7,0.8-1.7,2.9c0,2.5,1.7,5.2,3,5.2h1v7c0,0.9,0.3,1.7,0.8,2.4c2.6,3.8,6,5.9,9.5,5.9s6.9-2.1,9.5-5.9
            c0.5-0.7,0.8-1.6,0.8-2.4v-7h1c1.2,0,3-2.7,3-5.2c0-2.1-1.7-2.9-1.7-2.9L63,27.1l0.1-0.8c1.4-8.7-1.4-13.6-3.1-16.4
            c-0.5,0.7-1.2,1.3-1.9,2l-3.1,3.1l1.4-4.1c0.4-1,0.5-2.2,0.4-3.4c-1.3,1.4-3,2.7-3.3,2.9l-1.9,1.4l0.4-2.4
            C52.1,9.4,52.2,8.8,52.3,8z"
          />
          <rect x="-0.5" y="70" transform="matrix(4.394577e-02 -0.999 0.999 4.394577e-02 -46.8531 93.1077)" width="51.4" height="2" />
          <rect x="45.1" y="40.4" transform="matrix(0.9372 -0.3488 0.3488 0.9372 -21.336 20.4533)" width="2" height="58.1" />
          <rect x="10.6" y="79.7" transform="matrix(0.7042 -0.71 0.71 0.7042 -51.6477 37.4918)" width="17.2" height="2" />
          <path d="M59.3,97.7H24.1c-3,0-7.2-0.6-9.8-3.4c-1.7-1.9-2.5-4.4-2.2-7.6c0.5-6,2.2-21.1,3-28.4l0.1-1.3c1-8.6,5.6-10.9,10.7-12.7
            c4.9-1.7,13.6-4.9,13.6-4.9l0.7,1.9c-0.1,0-8.8,3.2-13.7,4.9c-5,1.7-8.6,3.5-9.4,11l-0.1,1.3c-0.8,7.3-2.5,22.4-3,28.3
            c-0.2,2.6,0.4,4.7,1.7,6.1c2.1,2.2,5.7,2.7,8.3,2.7h35.2c3.9,0,7-3.1,7-7s-3.1-7-7-7h-8.9v-2h8.9c5,0,9,4,9,9
            S64.2,97.7,59.3,97.7z"
          />
          <path d="M87.2,91.7h-2V58.4c0-7.8-5-10.6-9.4-12.1c-4.9-1.7-13.6-4.9-13.7-4.9l0.7-1.9c0.1,0,8.7,3.2,13.6,4.9
            c4.6,1.6,10.7,4.8,10.7,14V91.7z"
          />
          <rect x="71.1" y="62" width="2" height="34.7" />
        </svg>
      );
    case 'injury-leg':
      return (
        <svg className="icon-injury-leg" viewBox="0 0 107.9 108.1">
          <path d="M45.1,54.1c-0.2-2.1-1.1-5.1-1.7-6c-0.9-1.3-2.2-4.4-2.6-6.2c-0.4-2.2,0.3-4.3,2.3-6.4l1.4,1.4c-2,2.1-2,3.5-1.8,4.6
            c0.3,1.5,1.5,4.4,2.2,5.4c1,1.4,1.9,5,2.1,7L45.1,54.1z"
          />
          <path d="M47.7,29.9l-1.9-0.7c2.8-6.9,6.4-11.3,9.1-14.5c0.4-0.5,0.8-0.9,1.1-1.3l1.6,1.3c-0.3,0.4-0.7,0.9-1.1,1.4
            C53.9,19,50.4,23.3,47.7,29.9z"
          />
          <path d="M66.4,56.4l-2-0.1c0.3-5.6-2.5-10.5-3.5-11.6c-0.6-0.6-0.9-1.4-0.8-2.2c0.1-1.6,1.6-2.8,2.4-3.4c0.4-0.3,1-0.6,2-1.2
            c2.9-1.7,8.3-4.8,13.6-9.5c7.1-6.2,9.7-9.4,12.4-15.1l1.8,0.8c-2.8,5.9-5.6,9.5-12.9,15.8c-5.5,4.8-11,8-13.9,9.7
            c-0.8,0.5-1.5,0.9-1.8,1.1c-0.9,0.7-1.5,1.5-1.6,2c0,0.2,0,0.4,0.3,0.7C63.8,44.8,66.8,50.2,66.4,56.4z"
          />
          <path d="M40.6,104.2c-2.5,0-4.5,0-5.6-0.1l-0.9-0.1v-0.9c0-0.2,0-0.4,0-0.6c-0.1-1.5-0.3-4.3,3.6-5.3c5.2-1.4,8.9-2.8,11.4-4.1
            c3.4-1.9,5.7-3.6,4.3-8.9c-0.6-2.3-1.8-5.4-3.3-9.1c-2.3-5.8-5.1-13-6.4-19.7l-0.2-1l1-0.2c7.1-1.4,14.6-0.9,22.5,1.5l0.8,0.2
            l0,0.8c-0.1,1.5-0.2,3-0.3,4.5c-0.4,5-0.7,9.7-0.4,13.7c0.4,4.8,0.9,12.2,2.8,15.3c2.2,3.6,3.4,8.8,2.9,12l-0.1,0.7l-0.7,0.1
            C69.5,103.6,50.8,104.2,40.6,104.2z M36.1,102.2c6.7,0.3,29.9-0.5,34.7-0.9c0.2-2.8-1-7.1-2.7-9.9C66,87.8,65.4,80.5,65,75.1
            c-0.3-4.2,0-9,0.4-14c0.1-1.2,0.2-2.4,0.3-3.7c-6.9-2-13.5-2.5-19.7-1.4c1.3,6.2,4,12.9,6.1,18.3c1.4,3.7,2.7,6.9,3.3,9.3
            c1.9,7.2-2.4,9.6-5.3,11.1c-2.6,1.5-6.5,2.9-11.9,4.3C36,99.7,36,100.8,36.1,102.2z"
          />
          <path d="M65.5,66.9c-4.9-3.3-12.4-4.7-19.7-5.9l0.3-2c7.5,1.2,15.3,2.7,20.5,6.2L65.5,66.9z" />
          <path d="M64.9,71.4c-4.9-2.3-12.4-4.2-17.5-5.1l0.3-2c5.3,0.9,12.9,2.9,18,5.3L64.9,71.4z" />
          <path d="M50.7,73.4l-0.8-1.8c3.1-1.4,7.9-3,11.8-3.5l0.3,2C58.3,70.6,53.6,72.1,50.7,73.4z" />
          <path d="M52.7,78.2l-1-1.7c4.4-2.7,9-4,13.7-4.1l0,2C61,74.4,56.7,75.7,52.7,78.2z" />
          <path d="M53.4,62.3l-0.7-1.9c4.6-1.9,9-2.9,13.4-3.1l0.1,2C61.9,59.5,57.9,60.5,53.4,62.3z" />
          <path d="M54.6,83.3l-1.1-1.7c1.8-1.2,6.6-4,12.5-4.6l0.2,2C60.7,79.5,56.2,82.1,54.6,83.3z" />
          <path d="M66.5,85.6c-2-1.4-5.5-2.5-10.7-3.4l0.3-2c5.5,1,9.2,2.2,11.5,3.8L66.5,85.6z" />
          <path d="M68.4,91.6c-3.7-2.4-8.2-3.8-13.6-4.4l0.2-2c5.8,0.6,10.5,2.1,14.5,4.7L68.4,91.6z" />
          <path d="M48.6,41.3l-1.9-0.6c1.4-4.7,3.5-8.5,6.6-12.5l1.6,1.2C51.8,33.2,49.9,36.9,48.6,41.3z" />
          <rect x="23.8" y="2.4" transform="matrix(0.4843 -0.8749 0.8749 0.4843 -6.3086 32.93)" width="2" height="38.8" />
          <polygon points="48.8,38.2 34.9,36.2 40.8,30.7 42.3,22.8 51.4,33.4 49.9,34.7 43.5,27.3 42.7,31.7 39.3,34.8 49.1,36.3" />
          <polygon points="11.1,21.3 10.5,19.4 17,17.5 15.2,11 17.1,10.4 19.5,18.9" />
          <polygon points="5.8,18.4 5.2,16.5 11.8,14.6 9.9,8.1 11.8,7.5 14.3,16" />
          <polygon points="0.6,15.5 0,13.6 6.5,11.7 4.7,5.2 6.6,4.6 9,13.1" />
        </svg>
      );
    case 'injury-lost':
      return (
        <svg className="icon-injury-lost" viewBox="0 0 102.6 102.9">
          <path d="M51.5,55.1c-4.2,0-8.1-2.4-11.1-6.7c-0.7-1.1-1.1-2.3-1.1-3.6v-5.2c-2.3-0.8-4-4.2-4-7c0-2.4,1.3-3.7,2.2-4.3
            c-0.4-2.8-0.6-10.8,4.2-14.6c2.7-2.1,5.2-3.1,7.4-4c1.7-0.7,3.2-1.3,4.3-2.2l2.2-2l-0.6,2.9c-0.1,0.3-0.1,0.7-0.2,1
            c0.9-0.9,1.8-1.8,2-2.5L58,4l0.8,3c0.3,1.1,0.5,2.2,0.5,3.2c0.1-0.1,0.1-0.2,0.2-0.4l0.8-2l1,1.9c0.2,0.3,0.4,0.6,0.6,1
            c1.7,2.8,5,8.1,3.7,17.5c0.9,0.6,2.2,2,2.2,4.4c0,2.8-1.8,6.2-4,7v5.2c0,1.3-0.4,2.5-1.1,3.6C59.7,52.7,55.7,55.1,51.5,55.1z
            M52.7,10.2c-0.9,0.4-1.8,0.8-2.8,1.2c-2.1,0.8-4.5,1.8-6.9,3.7c-4.2,3.3-3.9,11.4-3.4,13.3l0.2,0.8L39,29.6
            c-0.1,0-1.7,0.8-1.7,2.9c0,2.5,1.7,5.2,3,5.2h1v7c0,0.9,0.3,1.7,0.8,2.4c2.6,3.8,6,5.9,9.5,5.9c3.5,0,6.9-2.1,9.5-5.9
            c0.5-0.7,0.8-1.6,0.8-2.4v-7h1c1.2,0,3-2.7,3-5.2c0-2.1-1.7-2.9-1.7-2.9l-0.7-0.3l0.1-0.8c1.4-8.7-1.4-13.6-3.1-16.4
            c-0.5,0.7-1.2,1.3-1.9,2l-3.1,3.1l1.4-4.1c0.4-1,0.5-2.2,0.4-3.4c-1.3,1.4-3,2.7-3.3,2.9L52.1,14l0.4-2.4
            C52.4,11.6,52.5,11,52.7,10.2z"
          />
          <path d="M87.6,94h-2V60.6c0-7.8-5-10.6-9.4-12.1c-4.9-1.7-13.6-4.9-13.7-4.9l0.7-1.9c0.1,0,8.7,3.2,13.6,4.9
            c4.6,1.6,10.8,4.8,10.8,14V94z"
          />
          <rect x="71.5" y="64.3" width="2" height="34.7" />
          <path d="M17.5,94h-2V60.6c0-9.2,6.1-12.4,10.7-14c4.9-1.7,13.6-4.9,13.6-4.9l0.7,1.9c-0.1,0-8.8,3.2-13.7,4.9
            c-4.4,1.5-9.4,4.3-9.4,12.1V94z"
          />
          <rect x="29.6" y="64.3" width="2" height="34.7" />
          <path d="M54.1,71.5c0-2.2-1.5-3.3-3.6-3.3c-2,0-3.4,1-4.8,2.4l-4.3-3.9c2.5-2.8,6-4.8,10.1-4.8c5.7,0,10,2.8,10,9.1
            c0,6.5-8,8.5-7.3,14.8h-6.8C46.4,78.5,54.1,75.5,54.1,71.5z M46.2,93.8c0-2.8,2-4.9,4.7-4.9c2.7,0,4.7,2.1,4.7,4.9
            c0,2.8-2,4.9-4.7,4.9C48.2,98.7,46.2,96.5,46.2,93.8z"
          />
        </svg>
      );
    case 'injury-old':
      return (
        <svg className="icon-injury-old" viewBox="0 0 105.8 105.9">
          <path d="M52.8,102.6c-8.4,0-16.3-4.8-22.4-13.6c-1.4-2-2.1-4.4-2.1-6.9V48.5l9.3-17l15.3,7.6l16.4-6.5l8,15.9v33.7
            c0,2.5-0.7,4.8-2.1,6.9C69.2,97.8,61.2,102.6,52.8,102.6z M30.3,49v33.2c0,2.1,0.6,4,1.8,5.7c5.7,8.2,13,12.7,20.7,12.7
            c7.7,0,15.1-4.5,20.7-12.7c1.2-1.7,1.8-3.7,1.8-5.7V48.9l-7-13.9l-15.6,6.2l-14.3-7.1L30.3,49z"
          />
          <path d="M76.3,70.6v-2c3.4,0,7.4-6.3,7.4-11.9c0-4.6-3.3-6.6-4.1-7h-3.3v-2h3.7l0.2,0.1c0.2,0.1,5.4,2.5,5.4,8.9
            C85.7,62.9,81.3,70.6,76.3,70.6z"
          />
          <path d="M29.4,70.6c-5,0-9.4-7.8-9.4-13.9c0-6.4,5.2-8.8,5.4-8.9l0.2-0.1h3.7v2h-3.3c-0.8,0.4-4.1,2.5-4.1,7
            c0,5.6,3.9,11.9,7.4,11.9V70.6z"
          />
          <path d="M24.9,48.9c-1.2-5.3-1.6-23.2,8.2-31c5.4-4.3,10.6-6.4,15.2-8.2c3.7-1.5,6.8-2.7,9.2-4.8l2.2-2l-0.6,2.9
            c-0.3,1.7-0.7,3.7-0.9,5.2c2.5-2,6-5.2,6.9-7.6l1.1-2.9l0.8,3c1,3.7,1.3,7,0.9,10c1.1-1.3,2-2.5,2.6-3.9l0.8-2l1,1.9
            c0.3,0.6,0.8,1.3,1.3,2.2c3.7,5.9,10.4,17,7.3,37.1l-2-0.3c3-19.4-3.5-30.1-7-35.8c-0.1-0.2-0.3-0.4-0.4-0.6
            c-1.2,1.9-2.8,3.6-4.7,5.5l-3.1,3.1l1.4-4.1c1.1-3,1.2-6.2,0.5-10c-2.7,3.7-7.9,7.5-8.2,7.6l-1.9,1.4l0.4-2.4
            c0,0,0.4-2.5,0.9-5.2c-2.2,1.4-4.8,2.5-7.7,3.6c-4.5,1.8-9.5,3.8-14.7,7.9c-9.1,7.2-8.5,24.7-7.5,29L24.9,48.9z"
          />
          <polygon points="58.2,58 57.4,56.2 69.9,50.6 72.9,53.1 71.6,54.6 69.6,52.9" />
          <path d="M58.1,60.9L57.5,59l0.4-0.1c6.3-1.7,10.5-2.8,14.8-0.6l-0.9,1.8c-3.7-1.9-7.1-1-13.4,0.7L58.1,60.9z" />
          <path d="M64.3,63.8c-1.7,0-3-1.3-3-3c0-1.7,1.3-3,3-3c1.7,0,3,1.3,3,3C67.3,62.4,66,63.8,64.3,63.8z M64.3,59.8c-0.6,0-1,0.5-1,1
            s0.5,1,1,1s1-0.5,1-1S64.9,59.8,64.3,59.8z"
          />
          <path d="M42.8,69c-0.9,0-1.9-0.1-2.8-0.4c-7-2.2-10.4-7.8-8.7-14.2c0.4-1.5,2.3-2.2,5.8-2.2c4.9,0,14.4,1.9,15.5,6.2
            c0.6,2.6-0.5,5.6-2.9,7.8C47.7,68,45.3,69,42.8,69z M33.2,54.8c-1.4,5.4,1.4,9.9,7.3,11.8c2.7,0.8,5.6,0.1,7.8-2
            c1.8-1.7,2.7-4,2.3-5.9c-0.5-1.8-5-3.8-10.4-4.5C35.8,53.7,33.5,54.4,33.2,54.8z"
          />
          <path d="M45.4,55.1l-1.1-1.7c8.3-5.2,18-9.6,28-12.8l0.6,1.9C63.2,45.6,53.6,50,45.4,55.1z" />
          <path d="M30.1,67.2l-1.5-1.4c1.2-1.3,2.6-2.6,4.1-3.9l1.3,1.5C32.5,64.7,31.2,66,30.1,67.2z" />
          <path d="M52.8,77c-3.6,0-6.4-2.1-6.4-4.7h2c0,1.5,2,2.7,4.4,2.7c2.4,0,4.4-1.2,4.4-2.7h2C59.3,74.9,56.5,77,52.8,77z" />
          <rect x="46.5" y="83.5" width="12.6" height="2" />
          <rect x="51.1" y="87.8" width="3.5" height="2" />
          <polygon points="39.5,53.3 38.2,46.2 43.1,35.4 44.9,36.2 40.3,46.5 41.5,52.9" />
          <rect x="33.4" y="70.6" transform="matrix(0.2871 -0.9579 0.9579 0.2871 -41.53 87.371)" width="9.1" height="2" />
        </svg>
      );
    case 'loss':
      return (
        <svg className="icon-loss" viewBox="0 0 33.2 33.2">
          <path d="M13.5,11.8L8.3,17c-1.9,1.9-4.3,3.2-6.6,3.5c0.3-2.3,1.6-4.7,3.5-6.6l3.5-3.5V8.6l-4.4,4.4c-2.3,2.3-3.8,5.3-3.9,8.1
            l0,0.7l0.7,0c2.8-0.2,5.8-1.6,8.1-3.9l6-6H13.5z"
          />
          <path d="M10.8,10.6l-4.2,4.2c-0.2,0.2-0.2,0.6,0,0.9c0.2,0.2,0.6,0.2,0.9,0l3.4-3.4V10.6z" />
          <path d="M31.9,24.6L28,20.7l0.6-0.6c0.4-0.4,0.6-0.9,0.6-1.4s-0.2-1-0.6-1.4c-0.8-0.8-2.1-0.8-2.8,0l-0.2,0.2l-6-6h-1.7l6.8,6.8
            l-1.1,1.1l-5.8-5.8v1.7l5,5l-1.1,1.1l-4.5-4.5h-1.7l5.4,5.4l-0.2,0.2c-0.4,0.4-0.6,0.9-0.6,1.4s0.2,1,0.6,1.4
            c0.4,0.4,0.9,0.6,1.4,0.6c0.5,0,1-0.2,1.4-0.6l0.6-0.6l3.9,3.9c0.6,0.6,1.3,0.8,2,0.8c0.7,0,1.5-0.3,2-0.8
            C33,27.5,33,25.7,31.9,24.6z M22.6,24.4c-0.3,0.3-0.8,0.3-1.1,0c-0.1-0.1-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6l2.6-2.6c0,0,0,0,0,0
            s0,0,0,0l2.6-2.6c0.2-0.2,0.4-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.1,0.1,0.2,0.3,0.2,0.6c0,0.2-0.1,0.4-0.2,0.6L22.6,24.4z M31.1,27.7
            c-0.6,0.6-1.7,0.6-2.3,0l-3.9-3.9l2.3-2.3l3.9,3.9C31.7,26.1,31.7,27.1,31.1,27.7z"
          />
          <polygon points="12.8,17.6 9.8,20.6 9.8,22.3 12.8,19.3" />
          <polygon points="13.7,6.8 10.7,3.8 8.9,3.8 11.9,6.8" />
          <polygon points="16.8,8.7 19.8,5.7 19.8,4 16.8,7" />
        </svg>
      );
    case 'minus-thin':
      return (
        <svg className="icon-minus-thin" viewBox="0 0 24 24">
          <rect y="11" x="4" width="16" height="2" />
        </svg>
      );
    case 'minus':
      return (
        <svg className="icon-minus" viewBox="0 0 8 8">
          <rect y="3" width="8" height="2" />
        </svg>
      );
    case 'plus-thin':
      return (
        <svg className="icon-plus-thin" viewBox="0 0 24 24">
          <path d="M5 13h6v6c0 0.552 0.448 1 1 1s1-0.448 1-1v-6h6c0.552 0 1-0.448 1-1s-0.448-1-1-1h-6v-6c0-0.552-0.448-1-1-1s-1 0.448-1 1v6h-6c-0.552 0-1 0.448-1 1s0.448 1 1 1z" />
        </svg>
      );
    case 'plus':
      return (
        <svg className="icon-plus" viewBox="0 0 8 8">
          <rect y="3" width="8" height="2" />
          <rect x="3" y="0" width="2" height="8" />
        </svg>
      );
    case 'print':
      return (
        <svg className="icon-print" viewBox="0 0 768 768">
          <path d="M544 256h-320v-160h320zM160 608v96c0 17.664 14.336 32 32 32h384c17.664 0 32-14.336 32-32v-96h32c26.496 0 50.56-10.784 67.872-28.128s28.128-41.376 28.128-67.872v-160c0-26.496-10.784-50.56-28.128-67.872s-41.376-28.128-67.872-28.128h-32v-192c0-17.664-14.336-32-32-32h-384c-17.664 0-32 14.336-32 32v192h-32c-26.496 0-50.56 10.784-67.872 28.128s-28.128 41.376-28.128 67.872v160c0 26.496 10.784 50.56 28.128 67.872s41.376 28.128 67.872 28.128zM192 416c-17.664 0-32 14.336-32 32v96h-32c-8.832 0-16.8-3.552-22.624-9.376s-9.376-13.792-9.376-22.624v-160c0-8.832 3.552-16.8 9.376-22.624s13.792-9.376 22.624-9.376h512c8.832 0 16.8 3.552 22.624 9.376s9.376 13.792 9.376 22.624v160c0 8.832-3.552 16.8-9.376 22.624s-13.792 9.376-22.624 9.376h-32v-96c0-17.664-14.336-32-32-32zM224 480h320v192h-320z" />
        </svg>
      );
    case 'rotate':
      return (
        <svg className="icon-rotate" viewBox="0 0 768 768">
          <path d="M625.504 469.344c-23.552 66.656-71.84 117.44-131.008 145.696s-128.992 33.952-195.648 10.4-117.44-71.84-145.696-131.008-33.952-128.992-10.4-195.648 71.84-117.44 131.008-145.696 128.992-33.952 195.648-10.4c38.016 13.44 70.688 34.816 95.584 60.224l90.368 85.088h-111.36c-17.664 0-32 14.336-32 32s14.336 32 32 32h191.904c0.128 0 0.288 0 0.416 0 4.384-0.032 8.544-0.96 12.352-2.624 3.808-1.632 7.328-4.032 10.368-7.2 0.384-0.384 0.736-0.8 1.088-1.216 2.432-2.784 4.288-5.888 5.6-9.184 1.312-3.264 2.080-6.816 2.208-10.496 0.064-0.416 0.064-0.832 0.064-1.28v-192c0-17.664-14.336-32-32-32s-32 14.336-32 32v117.92l-93.312-87.84c-31.776-32.384-72.768-59.072-119.936-75.712-83.328-29.472-170.752-22.336-244.608 12.96s-134.304 98.816-163.744 182.144-22.272 170.72 12.992 244.576 98.816 134.304 182.144 163.744 170.72 22.304 244.576-12.992 134.304-98.816 163.744-182.144c5.888-16.672-2.848-34.944-19.52-40.832s-34.944 2.848-40.832 19.52z" />
        </svg>
      );
    case 'share':
      return (
        <svg className="icon-rotate" viewBox="0 0 512 512">
          <path d="M399 334c-18.346 0-34.905 7.613-46.724 19.837l-175.095-87.548c0.534-3.352 0.819-6.787 0.819-10.29s-0.285-6.938-0.819-10.288l175.095-87.547c11.819 12.224 28.377 19.836 46.724 19.836 35.899 0 65-29.101 65-65s-29.101-65-65-65-65 29.102-65 65c0 3.502 0.286 6.938 0.819 10.288l-175.095 87.548c-11.819-12.224-28.377-19.837-46.724-19.837-35.898 0-65 29.102-65 65 0 35.899 29.102 65 65 65 18.346 0 34.905-7.613 46.724-19.836l175.095 87.548c-0.533 3.351-0.819 6.786-0.819 10.288 0 35.899 29.101 65 65 65s65-29.101 65-65c0-35.897-29.101-65-65-65z" />
        </svg>
      );
    case 'shield':
      return (
        <svg className="icon-shield" viewBox="0 0 20 21.4">
          <path d="M19,1v5c0,6.7-3.7,12.6-9,14.4C4.7,18.6,1,12.7,1,6V1H19 M20,0H0v6c0,7.4,4.3,13.7,10,15.4
            c5.7-1.8,10-8,10-15.4V0L20,0z"
          />
          <polygon points="4,15.9 3,15.5 6.3,7.8 10.3,11.8 12.8,3.3 16.9,7.3 16.1,8.1 13.2,5.1 10.7,13.6 6.7,9.6" />
        </svg>
      );
    case 'sort-asc':
      return (
        <svg className="icon-sort-asc" viewBox="0 0 16 15">
          <path d="M15.1,7.3H7.6c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h7.6c0.3,0,0.5,0.2,0.5,0.5S15.4,7.3,15.1,7.3z"/>
          <path d="M12.3,10.1H7.6c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h4.8c0.3,0,0.5,0.2,0.5,0.5S12.6,10.1,12.3,10.1z"/>
          <path d="M10.4,12.9H7.6c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h2.9c0.3,0,0.5,0.2,0.5,0.5S10.7,12.9,10.4,12.9z"/>
          <path d="M4.1,2.1v12.4c0,0.3-0.3,0.5-0.5,0.5C3.3,15,3,14.8,3,14.5V2.1L0.9,4.2c-0.2,0.2-0.6,0.2-0.8,0c-0.2-0.2-0.2-0.6,0-0.8 L3.6,0l0.8,0.8l0,0L7,3.4C7.2,3.7,7.2,4,7,4.2c-0.2,0.2-0.6,0.2-0.8,0L4.1,2.1z"/>
        </svg>
      );
    case 'sort-desc':
      return (
        <svg className="icon-sort-desc" viewBox="0 0 16 15">
          <path d="M15.1,3.3H7.6c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h7.6c0.3,0,0.5,0.2,0.5,0.5S15.4,3.3,15.1,3.3z"/>
          <path d="M12.3,6.1H7.6c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h4.8c0.3,0,0.5,0.2,0.5,0.5S12.6,6.1,12.3,6.1z"/>
          <path d="M10.4,8.9H7.6c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5h2.9c0.3,0,0.5,0.2,0.5,0.5C10.9,8.7,10.7,8.9,10.4,8.9z"/>
          <path d="M3,12.9V0.5C3,0.2,3.3,0,3.6,0c0.3,0,0.5,0.2,0.5,0.5v12.4l2.1-2.1c0.2-0.2,0.6-0.2,0.8,0c0.2,0.2,0.2,0.6,0,0.8L3.6,15 l-0.8-0.8l0,0l-2.7-2.7c-0.2-0.2-0.2-0.6,0-0.8c0.2-0.2,0.6-0.2,0.8,0L3,12.9z"/>
        </svg>
      );
    case 'swords':
      return (
        <svg className="icon-swords" viewBox="0 0 27.4 27.5">
          <path d="M3.6,26.7c-0.7,0-1.5-0.3-2-0.8c-1.1-1.1-1.1-2.9,0-4l4.3-4.3l0.9,0.9l-4.3,4.3c-0.6,0.6-0.6,1.7,0,2.3
            c0.6,0.6,1.7,0.6,2.3,0l4.3-4.3l0.9,0.9l-4.3,4.3C5.1,26.4,4.3,26.7,3.6,26.7z"
          />
          <path d="M11.5,23.1c-0.5,0-1-0.2-1.4-0.6l-5.1-5.1C4.6,17,4.4,16.5,4.4,16s0.2-1,0.6-1.4c0.8-0.8,2.1-0.8,2.8,0l5.1,5.1
            c0.4,0.4,0.6,0.9,0.6,1.4s-0.2,1-0.6,1.4C12.5,22.9,12,23.1,11.5,23.1z M10.9,21.7c0.3,0.3,0.8,0.3,1.1,0c0.1-0.1,0.2-0.3,0.2-0.6
            c0-0.2-0.1-0.4-0.2-0.6l-5.1-5.1c-0.3-0.3-0.8-0.3-1.1,0c-0.1,0.1-0.2,0.3-0.2,0.6s0.1,0.4,0.2,0.6L10.9,21.7z"
          />
          <path d="M15.6,20.4L4.7,9.5C2.4,7.2,0.9,4.2,0.8,1.4l0-0.7l0.7,0C4.2,1,7.2,2.4,9.5,4.7l10.9,10.9L15.6,20.4z M2.1,2.1
            c0.3,2.3,1.6,4.7,3.5,6.6l10,10l3.1-3.1l-10-10C6.7,3.7,4.3,2.4,2.1,2.1z"
          />
          <line x1="17.5" y1="17.6" x2="7.3" y2="7.3" />
          <path d="M17.5,18.2c-0.2,0-0.3-0.1-0.4-0.2L6.9,7.8c-0.2-0.2-0.2-0.6,0-0.9c0.2-0.2,0.6-0.2,0.9,0L18,17.1c0.2,0.2,0.2,0.6,0,0.9
            C17.8,18.1,17.7,18.2,17.5,18.2z"
          />
          <path d="M23.8,26.7c-0.7,0-1.5-0.3-2-0.8l-4.3-4.3l0.9-0.9l4.3,4.3c0.6,0.6,1.7,0.6,2.3,0c0.6-0.6,0.6-1.7,0-2.3l-4.3-4.3
            l0.9-0.9l4.3,4.3c1.1,1.1,1.1,2.9,0,4C25.3,26.4,24.6,26.7,23.8,26.7z"
          />
          <path d="M16,23.1c-0.5,0-1-0.2-1.4-0.6c-0.4-0.4-0.6-0.9-0.6-1.4s0.2-1,0.6-1.4l5.1-5.1c0.8-0.8,2.1-0.8,2.8,0
            c0.4,0.4,0.6,0.9,0.6,1.4s-0.2,1-0.6,1.4l-5.1,5.1C17,22.9,16.5,23.1,16,23.1z M21.1,15.2c-0.2,0-0.4,0.1-0.6,0.2l-5.1,5.1
            c-0.1,0.1-0.2,0.3-0.2,0.6c0,0.2,0.1,0.4,0.2,0.6c0.3,0.3,0.8,0.3,1.1,0l5.1-5.1c0.1-0.1,0.2-0.3,0.2-0.6s-0.1-0.4-0.2-0.6
            C21.5,15.3,21.3,15.2,21.1,15.2z"
          />
          <polygon points="13.2,17.4 11.9,18.7 8.8,15.6 10.1,14.3 9.3,13.4 7.1,15.6 11.9,20.4 14.1,18.2" />
          <path d="M26,0.8c-2.8,0.2-5.8,1.6-8.1,3.9l-4.7,4.7l0.9,0.9l4.7-4.7c1.9-1.9,4.3-3.2,6.6-3.5c-0.3,2.3-1.6,4.7-3.5,6.6l-4.7,4.7
            l0.9,0.9l4.7-4.7c2.3-2.3,3.8-5.3,3.9-8.1l0-0.7L26,0.8z"
          />
          <path d="M11.2,15.4l-1.7,1.7c-0.2,0.2-0.2,0.6,0,0.9c0.1,0.1,0.3,0.2,0.4,0.2s0.3-0.1,0.4-0.2l1.7-1.7L11.2,15.4z" />
          <path d="M20.6,6.9c-0.2-0.2-0.6-0.2-0.9,0l-4.3,4.3l0.9,0.9l4.3-4.3C20.8,7.5,20.8,7.1,20.6,6.9z" />
        </svg>
      );
    case 'tools':
      return (
        <svg className="icon-tools" viewBox="0 0 675 675">
          <path d="M 62.910305,586.86333 L 125.86516,650.60806 L 354.66003,422.80608 L 582.79529,652.32059 L 649.12836,585.39956 L 
            419.06211,356.15214 L 538.31924,239.08217 L 656.12237,360.43632 L 672.92355,183.72974 L 632.91916,143.04888 L 643.3629,132.19791 L 
            578.91235,67.944477 L 566.45233,81.085597 L 489.93282,4.363831 L 390.62711,103.14697 L 467.4617,180.34954 L 355.60522,292.86066 L 
            249.67092,189.78284 L 335.40773,104.38653 L 238.02361,7.697965 L 153.1742,92.253519 L 144.09633,82.235117 L 78.425241,147.87971 L 
            87.464971,157.23449 L 4.1797765,239.7581 L 102.32623,337.47278 L 186.38467,253.2337 L 289.76593,358.64588 L 62.910305,586.86333 z"
          />
        </svg>
      )
    case 'user':
      return (
        <svg className="icon-user" viewBox="0 0 8.8 8.8">
          <path d="M4.4,5.5c0.4,0,0.8,0,1.3,0.1s1,0.2,1.4,0.4c0.5,0.2,0.8,0.4,1.2,0.7C8.6,7,8.7,7.3,8.7,7.7v1.1H0.1V7.7
            c0-0.4,0.2-0.7,0.5-0.9S1.2,6.2,1.7,6c0.4-0.2,0.9-0.3,1.4-0.4S4,5.5,4.4,5.5z M4.4,0.1c0.3,0,0.6,0.1,0.8,0.2s0.5,0.3,0.7,0.5
            s0.3,0.4,0.5,0.7s0.2,0.5,0.2,0.9c0,0.3-0.1,0.6-0.2,0.8S6.1,3.6,5.9,3.8S5.5,4.1,5.2,4.2S4.7,4.4,4.4,4.4S3.8,4.3,3.5,4.2
            S3.1,4,2.9,3.8S2.5,3.3,2.4,3.1S2.2,2.5,2.2,2.2c0-0.3,0.1-0.6,0.2-0.9s0.3-0.5,0.5-0.7s0.4-0.3,0.7-0.5S4.1,0.1,4.4,0.1z"
          />
        </svg>
      );
    case 'win':
      return (
        <svg className="icon-win" viewBox="0 0 41.1 41.1">
          <polygon points="33.1,39.5 22.3,31.7 23,30.7 30.8,36.4 26.9,24.3 37.2,16.8 24.9,16.8 24.9,15.6 40.8,15.6
            28.3,24.7"
          />
          <path d="M17.1,26l0-15.4c0-3.3,1.1-6.4,2.9-8.5l0.5-0.5L21,2.1c1.9,2.1,2.9,5.2,2.9,8.5V26H17.1z M20.5,3.5
            c-1.4,1.8-2.2,4.4-2.2,7.1l0,14.2h4.4V10.6C22.7,7.9,21.9,5.4,20.5,3.5z"
          />
          <line x1="20.5" y1="25.4" x2="20.5" y2="10.9" />
          <path d="M20.1,25.8c-0.1-0.1-0.2-0.3-0.2-0.4l0-14.5c0-0.3,0.3-0.6,0.6-0.6s0.6,0.3,0.6,0.6l0,14.5
            c0,0.3-0.3,0.6-0.6,0.6C20.3,26,20.2,26,20.1,25.8z"
          />
          <path d="M18.5,36.3c-0.5-0.5-0.8-1.2-0.8-2v-6.1h1.2v6.1c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6l0-6.1h1.2
            l0,6.1c0,1.6-1.3,2.8-2.8,2.8C19.7,37.2,19,36.9,18.5,36.3z"
          />
          <path d="M15.5,28.2c-0.4-0.4-0.6-0.9-0.6-1.4c0-0.5,0.2-1,0.6-1.4c0.4-0.4,0.9-0.6,1.4-0.6l7.2,0c1.1,0,2,0.9,2,2
            c0,0.5-0.2,1-0.6,1.4c-0.4,0.4-0.9,0.6-1.4,0.6l-7.2,0C16.3,28.8,15.8,28.6,15.5,28.2z M24.7,26.3c-0.1-0.1-0.3-0.2-0.6-0.2
            l-7.2,0c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.1-0.2,0.3-0.2,0.6c0,0.4,0.4,0.8,0.8,0.8l7.2,0c0.2,0,0.4-0.1,0.6-0.2
            c0.1-0.1,0.2-0.3,0.2-0.6C24.9,26.6,24.8,26.4,24.7,26.3z"
          />
          <polygon points="8,39.5 12.8,24.7 0.2,15.6 16.2,15.6 16.2,16.8 3.9,16.8 14.2,24.3 10.3,36.4 18,30.7 18.7,31.7" />
        </svg>
      );
    default:
      return <svg className={`icon-${name}`}><use xlinkHref={`${symbols}#icon-${name}`} /></svg>;
  }
};

Icon.propTypes = {
  name: PropTypes.string.isRequired
};

export default Icon;
