/* eslint-disable no-restricted-globals */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Icon } from '..';

import './styles.scss';

class NewCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      overlayOpen: false
    };
  }

  openOverlay = () => {
    this.setState({
      overlayOpen: true
    });
  }

  closeOverlay = (hasEdited) => {
    if (hasEdited === true) {
      if (confirm(`You have unsaved changes, all unsaved changes will be lost. Are you sure you wish to continue?`)) {
        this.setState({
          overlayOpen: false
        });
      }
    } else {
      this.setState({
        overlayOpen: false
      });
    }
  }

  render() {
    const {
      overlay, title, icon, small
    } = this.props;
    const { overlayOpen } = this.state;

    return (
      <Fragment>
        <button className={`card ${small ? 'card--small' : ''}`} onClick={() => this.openOverlay()} type="button">
          <div className="new-card">
            <div className="new-card__inner">
              {icon && <img src={icon} alt={title} />}
              <span>{title}</span>
              <Icon name="plus" />
            </div>
          </div>
        </button>
        {(overlayOpen) && React.cloneElement(overlay, { handleClose: this.closeOverlay })}
      </Fragment>
    );
  }
}

NewCard.propTypes = {
  overlay: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  small: PropTypes.bool
};

NewCard.defaultProps = {
  icon: '',
  small: false
};

export default NewCard;
