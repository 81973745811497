import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { userActions } from '../../_actions';
import {
  Button, Form, FormRow, TextInput
} from '..';

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        name: '',
        email: '',
        confirmEmail: '',
        password: '',
        confirmPassword: ''
      },
      submitted: false,
      registrationSuccessful: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value
      }
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({ submitted: true });
    const { register } = this.props;
    const { user } = this.state;

    if (!user.name
      || !user.email
      || !user.confirmEmail
      || !user.password
      || !user.confirmPassword) {
      return;
    }

    if ((user.password !== user.confirmPassword) || (user.email !== user.confirmEmail)) {
      return;
    }

    if (user.name && user.email && user.password) {
      register(user, () => {
        this.setState({
          registrationSuccessful: true
        });
      });
    }
  }

  render() {
    const { registering } = this.props;
    const { user, submitted, registrationSuccessful } = this.state;

    if (registrationSuccessful) {
      return <p>Registration successful, you can now log in to your new account.</p>;
    }

    return (
      <form id="register-form" name="register-form" onSubmit={this.handleSubmit}>
        <Form buttons={<Button text="Register" primary iconName="arrow-right" iconRight type="submit" loading={registering} />}>
          <FormRow>
            <TextInput id="register-name" name="name" placeholder="Name" value={user.name} onChange={this.handleChange} required showError={submitted && !user.name} />
          </FormRow>
          <FormRow>
            <TextInput id="register-email" name="email" placeholder="Email" value={user.email} onChange={this.handleChange} type="email" required showError={submitted && !user.email} />
          </FormRow>
          <FormRow>
            <TextInput id="register-confirmEmail" name="confirmEmail" placeholder="Confirm Email" value={user.confirmEmail} onChange={this.handleChange} type="email" required showError={submitted && !user.confirmEmail} />
            {(submitted && user.email !== user.confirmEmail) && (
              <span className="field-validation-error">Your email addresses do not match.</span>
            )}
          </FormRow>
          <FormRow>
            <TextInput id="register-password" name="password" placeholder="Password" value={user.password} onChange={this.handleChange} type="password" showError={submitted && !user.password} />
          </FormRow>
          <FormRow>
            <TextInput id="register-confirmPassword" name="confirmPassword" placeholder="Confirm Password" value={user.confirmPassword} onChange={this.handleChange} type="password" showError={submitted && !user.confirmPassword} />
            {(submitted && user.password !== user.confirmPassword) && (
              <span className="field-validation-error">Your passwords do not match.</span>
            )}
          </FormRow>
        </Form>
      </form>
    );
  }
}

RegisterPage.propTypes = {
  register: PropTypes.func.isRequired,
  registering: PropTypes.bool
};

RegisterPage.defaultProps = {
  registering: false
};

function mapStateToProps({ registration }) {
  const { registering } = registration;
  return { registering };
}

export default connect(mapStateToProps, { register: userActions.register })(RegisterPage);
