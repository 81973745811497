import React from "react"

import './styles.scss';

function SwimChart() {
  return (
    <div className="simple-chart">
      <table className="simple-chart__table">
        <thead>
          <tr>
            <th>D6</th>
            <th>Result</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>1</th>
            <td>
              <strong>Sink</strong><br/>
              The model is overcome by the weight of its wargear (or complete inability to swim) and drowns. Remove the model as a casualty.
            </td>
          </tr>
          <tr>
            <th>2-5</th>
            <td>
              <strong>Swim</strong><br/>
              The model may move up to half its remaining Move allowance through the water.
            </td>
          </tr>
          <tr>
            <th>6</th>
            <td>
              <strong>Swim Strongly</strong><br/>
              The model may move its remaining Move allowance through the water
            </td>
          </tr>
        </tbody>
      </table>

      <table className="simple-chart__table">
        <thead>
          <tr>
            <th colSpan={2}>
              Modifiers
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>+1</th>
            <td>The model is mounted</td>
          </tr>
          <tr>
            <th>-1</th>
            <td>The model is equipped with heavy armour, Dwarf armour or Heavy Dwarf armour</td>
          </tr>
          <tr>
            <th>-1</th>
            <td>The model is carrying a shield</td>
          </tr>
          <tr>
            <th>-1</th>
            <td>The model is carrying a banner</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default SwimChart