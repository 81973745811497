/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import {
  Overlay, Form, Button
} from '../..';

class LoadState extends PureComponent {
  render() {
    const { onSubmit } = this.props;

    return (
      <Overlay title="Load Previous Battle" isSmall open>
        <Form buttons={
          <>
            <Button text="Continue" iconName="arrow-right" iconRight primary onClick={() => onSubmit(true)} />
            <Button text="Discard" destructive onClick={() => onSubmit(false)} />
          </>
        }>
          <p className="help-text">Saved battle data found, would you like to continue your previous battle?</p>
        </Form>
      </Overlay>
    );
  }
}

LoadState.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default LoadState;
