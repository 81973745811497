import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { spellActions } from '../../../_actions';
import { SpellType } from '../../../_types';

import {
  Button, Overlay, Form, FormRow, TextInput
} from '../..';

class EditSpellOverlay extends Component {
  constructor(props) {
    super(props);
    const { spell } = this.props;
    this.state = {
      spell,
      submitting: false,
      deleting: false,
      submitted: false
    };
  }

  handleInputChange = (event) => {
    const { target } = event;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState({
      [name]: value
    });
  }

  updateSpellStat = (event) => {
    const { spell } = this.state;
    const {
      target: {
        name, type, checked, value
      }
    } = event;
    const val = type === 'checkbox' ? checked : value;

    const nameParts = name.split('.');
    if (nameParts.length > 1) {
      spell[nameParts[0]][nameParts[1]] = val;
    } else {
      spell[name] = val;
    }

    this.setState({
      spell
    });
  }

  deleteSpell = (e) => {
    e.preventDefault();
    const { spell, deleting } = this.state;
    if (deleting) {
      return;
    }
    const { deleteSpellAction } = this.props;

    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure you want to delete this spell? This action cannot be undone.')) {
      this.setState({
        deleting: true
      });

      deleteSpellAction(spell.id);
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { spell, submitting } = this.state;
    if (submitting) {
      return;
    }

    if (spell.name === '') {
      this.setState({
        submitted: true
      });
      return;
    }

    this.setState({
      submitted: false,
      submitting: true
    });

    const submitSpell = {
      id: spell.id,
      name: spell.name,
      description: spell.description
    };

    const { createSpellAction, updateSpellAction, handleClose } = this.props;
    if (spell.id) {
      updateSpellAction(submitSpell, handleClose);
    } else {
      createSpellAction(submitSpell, handleClose);
    }
  }

  render() {
    const { handleClose } = this.props;
    const {
      spell, submitting, deleting, submitted
    } = this.state;
    const { name, description } = spell;
    return (
      <Overlay id="edit-spell" title={spell.id ? 'Edit Spell' : 'Create Spell'} handleClose={handleClose} open>
        <div className={`edit-left ${(submitting || deleting) ? 'submitting' : ''}`}>
          <Form buttons={(
            <Fragment>
              {!!spell.id && <Button text="Delete" destructive type="button" onClick={this.deleteSpell} loading={deleting} />}
              <Button text="Submit" primary type="button" onClick={this.onSubmit} loading={submitting} />
            </Fragment>
          )}
          >
            {!spell.id && (
            <p className="help-text">
              Spells can be created here, and then quickly selected on your warriors.
            </p>
            )}
            <FormRow>
              <TextInput name="name" label="Name" value={name} onChange={this.updateSpellStat} required showError={submitted && !name} />
            </FormRow>
            <FormRow>
              <TextInput name="description" label="Description" value={description} onChange={this.updateSpellStat} textarea />
            </FormRow>
          </Form>
          {(submitting || deleting) && <div className="submit-block" />}
        </div>
      </Overlay>
    );
  }
}

EditSpellOverlay.propTypes = {
  spell: SpellType.isRequired,
  createSpellAction: PropTypes.func.isRequired,
  updateSpellAction: PropTypes.func.isRequired,
  deleteSpellAction: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default withRouter(connect(null, {
  createSpellAction: spellActions.createSpell,
  updateSpellAction: spellActions.updateSpell,
  deleteSpellAction: spellActions.deleteSpell
})(EditSpellOverlay));
