import { userConstants } from '../_actions';

export default function (state = {}, action) {
  switch (action.type) {
    case userConstants.CHANGE_PASSWORD_REQUEST:
      return { changingPassword: true };
    case userConstants.CHANGE_PASSWORD_SUCCESS:
      return {};
    case userConstants.CHANGE_PASSWORD_FAILURE:
      return {};
    default:
      return state;
  }
}
