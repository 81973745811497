/* eslint-disable react/jsx-one-expression-per-line */
import React, { Fragment, useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { WargearType } from '../_types';
import { emptyWargearItem } from '../_constants';
import { wargearActions } from '../_actions';
import { useStoredSort } from '../_hooks/useStoredSort';

import {
  Grid, Loader, WargearCard, NewCard, Icon, Advert, PageHeader
} from '../_components';
import EditWargearOverlay from '../_components/Overlay/EditWargearOverlay';

const storageName = 'sort-armoury';
const sortOptions = [
  { label: 'Name', value: o => o.name.toLowerCase(), mode: 'asc' },
  { label: 'Name', value: o => o.name.toLowerCase(), mode: 'desc' }
];

function Armoury({ wargear, getWargearAction }) {
  const [sort, setSort] = useState(useStoredSort(sortOptions, 0, storageName));

  useEffect(() => {
    getWargearAction();
  }, [getWargearAction]);

  if (wargear.loading) {
    return <Loader />;
  }

  const orderedWargear = _.orderBy(wargear, [sort.value], [sort.mode]);

  const items = _.filter(orderedWargear, { mount: 0 });
  const mounts = _.filter(orderedWargear, { mount: 1 });
  let i = 0;

  return (
    <Fragment>
      <Advert isWide />
      <PageHeader title="My Armoury" sortOption={sort} setSort={setSort} sortOptions={sortOptions} storageName={storageName} />
      <h2><Icon name="swords" /> Items</h2>
      <Grid>
        {_.map(items, item => (
          <Fragment key={item.id}>
            <WargearCard item={item} />
            {(i++ % 4 === 0) && <Advert />}
          </Fragment>
        ))}
        <NewCard
          key="new"
          title="New Item"
          overlay={<EditWargearOverlay item={_.clone(emptyWargearItem)} />}
          small
        />
      </Grid>
      <h2><Icon name="horseshoe" /> Mounts</h2>
      <Grid>
        {_.map(mounts, item => (
          <Fragment key={item.id}>
            <WargearCard item={item} />
            {(i++ % 4 === 0) && <Advert />}
          </Fragment>
        ))}
        <NewCard
          key="new"
          title="New Mount"
          overlay={<EditWargearOverlay item={_.clone({ ...emptyWargearItem, mount: 1 })} />}
          small
        />
      </Grid>
    </Fragment>
  );
}

Armoury.propTypes = {
  wargear: PropTypes.oneOfType([
    PropTypes.arrayOf(WargearType),
    PropTypes.object
  ]).isRequired,
  getWargearAction: PropTypes.func.isRequired
};

function mapStateToProps({ wargear }) {
  return { wargear };
}

export default withRouter(connect(mapStateToProps, {
  getWargearAction: wargearActions.getWargear
})(Armoury));
