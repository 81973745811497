/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import NumberControl from './Controls/NumberControl';
import RadioButtons from './Controls/RadioButtons';
import FileUpload from './Controls/FileUpload';

import ChangePasswordForm from './ChangePasswordForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import ResetPasswordForm from './ResetPasswordForm';
import UpdateUserForm from './UpdateUserForm';

import './styles.scss';

const Form = ({ children, buttons, className }) => (
  <div className={`form__container ${className}`}>
    <div className="form__inner">
      {children}
    </div>
    {buttons && (
    <div className="form__footer">
      {buttons}
    </div>
    )}
  </div>
);

const FormRow = ({ cls, children }) => (
  <div className={`row ${cls}`}>
    {children}
  </div>
);

const FormField = ({
  name, label, children, showError, errorVerb, border
}) => (
  <div className={`field${border ? ' field--border' : ''}`}>
    {label && <label htmlFor={name}>{label}</label>}
    {children}
    {showError && (
      <span className="field-validation-error">{label ? `Please ${errorVerb} a ${label}` : 'This field is required'}</span>
    )}
  </div>
);

function onClick(e) {
  const { target } = e;
  target.setSelectionRange(0, target.value.length);
}

const TextInput = ({
  id, name, label, value, onChange, textarea, large, small, clean,
  type, placeholder, focusSelect, showError, disabled, readOnly
}) => {
  if (clean) {
    return (
      <input
        id={id || name}
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        onClick={onClick}
        placeholder={placeholder}
      />
    );
  }
  return (
    <FormField name={name} label={label} showError={showError}>
      {textarea ? (
        <textarea
          id={id || name}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className={large ? 'large' : ''}
        />
      ) : (
        <input
          id={id || name}
          name={name}
          type={type}
          value={value}
          onChange={onChange}
          className={small ? 'small' : ''}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          onClick={focusSelect ? onClick : undefined}
        />
      )}
    </FormField>
  );
};

const SelectInput = ({
  name, label, value, multiple, onChange, children, showError
}) => (
  <FormField name={name} label={label} showError={showError} errorVerb="select">
    <select id={name} name={name} value={value} onChange={onChange} multiple={multiple}>
      {children}
    </select>
  </FormField>
);

const FileInput = ({
  name, label, onChange, onSubmit, tempImageSelected
}) => (
  <FormField name={name} label={label}>
    <FileUpload name={name} onChange={onChange} onSubmit={onSubmit} />
    {tempImageSelected && <span className="field-validation-error">Please click the Upload button, or Clear the image to continue.</span>}
  </FormField>
);

const NumberInput = ({
  name, label, value, onChange, min, max, readOnly, showError, border
}) => (
  <FormField name={name} label={label} showError={showError} border={border} errorVerb="set">
    <NumberControl
      id={name}
      name={name}
      value={value}
      min={min}
      max={max}
      onChange={onChange}
      readOnly={readOnly}
    />
  </FormField>
);

const RadioInput = ({
  name, label, value, onChange, options, showError
}) => (
  <FormField name={name} label={label} showError={showError} errorVerb="select">
    <RadioButtons id={name} name={name} value={value} options={options} onChange={onChange} />
  </FormField>
);

const CheckboxListInput = ({
  label, onChange, options
}) => (
  <FormField name="" label={label}>
    <div className="checkbox-list">
      {_.map(options, option => (
        <label className="checkbox" key={option.name}>
          <input type="checkbox" name={option.name} value={option.value} checked={!!option.value} onChange={onChange} />
          <span className="checkbox__input" />
          {option.label}
        </label>
      ))}
    </div>
  </FormField>
);

const SwitchInput = ({
  onLabel, offLabel, name, value, onChange
}) => (
  <label className="switch">
    <input type="checkbox" name={name} value={value} checked={value} onChange={onChange} />
    <div className="switch__inner" />
    <span className="switch__value">{offLabel}</span>
    <span className="switch__value">{onLabel}</span>
  </label>
);

const InputRequiredProps = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired
};

Form.propTypes = {
  children: PropTypes.node.isRequired,
  buttons: PropTypes.node,
  className: PropTypes.string
};

Form.defaultProps = {
  buttons: null,
  className: ''
};

FormRow.propTypes = {
  cls: PropTypes.string,
  children: PropTypes.node.isRequired
};

FormRow.defaultProps = {
  cls: ''
};

FormField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
  showError: PropTypes.bool,
  errorVerb: PropTypes.string,
  border: PropTypes.bool
};

FormField.defaultProps = {
  label: '',
  showError: false,
  errorVerb: 'enter',
  border: false
};

TextInput.propTypes = {
  ...InputRequiredProps,
  id: PropTypes.string,
  label: PropTypes.string,
  textarea: PropTypes.bool,
  small: PropTypes.bool,
  clean: PropTypes.bool,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  showError: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  focusSelect: PropTypes.bool
};

TextInput.defaultProps = {
  id: '',
  label: '',
  textarea: false,
  small: false,
  clean: false,
  placeholder: '',
  type: 'text',
  showError: false,
  disabled: false,
  readOnly: false,
  focusSelect: false
};

SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
  showError: PropTypes.bool,
  multiple: PropTypes.bool
};

SelectInput.defaultProps = {
  label: '',
  showError: false,
  multiple: false
};

FileInput.propTypes = {
  ...InputRequiredProps,
  onSubmit: PropTypes.func.isRequired,
  label: PropTypes.string,
  tempImageSelected: PropTypes.bool
};

FileInput.defaultProps = {
  label: '',
  tempImageSelected: false
};

NumberInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  readOnly: PropTypes.bool,
  showError: PropTypes.bool,
  border: PropTypes.bool
};

NumberInput.defaultProps = {
  label: '',
  min: null,
  max: null,
  readOnly: false,
  onChange: null,
  showError: false,
  border: false
};

RadioInput.propTypes = {
  ...InputRequiredProps,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  })).isRequired,
  showError: PropTypes.bool
};

RadioInput.defaultProps = {
  label: '',
  showError: false
};

CheckboxListInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired
  })).isRequired
};

CheckboxListInput.defaultProps = {
  label: ''
};

SwitchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onLabel: PropTypes.string.isRequired,
  offLabel: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired
};

export {
  ChangePasswordForm,
  ForgotPasswordForm,
  LoginForm,
  RegisterForm,
  ResetPasswordForm,
  UpdateUserForm,

  Form,
  FormRow,
  FormField,
  TextInput,
  SelectInput,
  FileInput,
  NumberInput,
  RadioInput,
  CheckboxListInput,
  SwitchInput,

  NumberControl,
  RadioButtons,
  FileUpload
};
