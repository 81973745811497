import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CompanyType } from '../../../../_types';

import { Overlay, Form, Button } from '../../..';

class InfluenceOverlay extends Component {
  constructor(props) {
    super(props);

    const { company } = this.props;
    const totalIncrease = company.resultInfluence + company.againstTheOdds
    + (company.woundsInfluence || 0) + (company.influenceReward || 0);

    this.state = {
      timerRunning: false,
      company: { ...company, totalIncrease },
      submitting: false
    };
  }

  onSubmit = () => {
    const { onSubmit } = this.props;
    const { company } = this.state;
    this.setState({
      submitting: true
    });
    onSubmit(company.influence);
  }

  setCounterTimer = (delay) => {
    const { company } = this.state;
    window.setTimeout(() => {
      company.influence += 1;
      company.totalIncrease -= 1;
      this.setState({
        timerRunning: true,
        company
      });
      if (company.totalIncrease > 0) {
        this.setCounterTimer(0);
      }
    }, 120 + delay);
  }

  render() {
    const { result } = this.props;
    const { company, timerRunning, submitting } = this.state;
    const {
      resultInfluence, againstTheOdds, woundsInfluence, influenceReward, influence, totalIncrease
    } = company;

    if (!timerRunning) {
      this.setCounterTimer(500);
    }

    let resultText = '';
    switch (result) {
      case 0:
        resultText = 'Win';
        break;
      case 1:
        resultText = 'Draw';
        break;
      default:
        resultText = 'Loss';
        break;
    }

    return (
      <Overlay title="Influence Overview" isSmall open>
        <Form buttons={<Button text="Submit" primary onClick={this.onSubmit} loading={totalIncrease > 0 || submitting} />}>
          <div className="overlay__content__split">
            <div className="overlay__content__left">
              <p><strong>Breakdown</strong></p>
              <table className="overlay__table table--block">
                <tbody>
                  <tr>
                    <th>{resultText} result</th>
                    <td>{resultInfluence}</td>
                  </tr>
                  {!!againstTheOdds && (
                  <tr>
                    <th>Against the odds bonus</th>
                    <td>{againstTheOdds}</td>
                  </tr>
                  )}
                  {!!woundsInfluence && (
                  <tr>
                    <th>Wounds of a hero</th>
                    <td>{woundsInfluence}</td>
                  </tr>
                  )}
                  {!!influenceReward && (
                  <tr>
                    <th>Scenario Bonus</th>
                    <td>{influenceReward}</td>
                  </tr>
                  )}
                  <tr className="total">
                    <th>Total</th>
                    <td>{totalIncrease}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="overlay__content__right">
              <span className="rosette">{influence}</span>
            </div>
          </div>
        </Form>
      </Overlay>
    );
  }
}

InfluenceOverlay.propTypes = {
  company: CompanyType.isRequired,
  onSubmit: PropTypes.func.isRequired,
  result: PropTypes.number.isRequired
};

export default InfluenceOverlay;
