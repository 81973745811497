import _ from 'lodash';
import { ruleConstants } from '../_actions';

function formatItems(rules) {
  if (!rules) {
    return {};
  }

  return _.mapKeys(rules, 'id');
}

function formatItem(rule, state) {
  if (!rule) {
    return {};
  }
  const newState = { ...state };
  newState[rule.id] = rule;
  return newState;
}

export default function (state = { loading: true }, action) {
  switch (action.type) {
    case ruleConstants.GET_REQUEST:
      return { loading: true };
    case ruleConstants.GET_SUCCESS:
      return formatItems(action.rule);
    case ruleConstants.GET_FAILURE:
      return { error: action.error };

    case ruleConstants.CREATE_SUCCESS:
      return formatItem(action.ruleResponse, state);

    case ruleConstants.UPDATE_SUCCESS:
      return formatItem(action.ruleResponse, state);

    case ruleConstants.DELETE_SUCCESS:
      return _.omit(state, action.idResponse.id);

    default:
      return state;
  }
}
