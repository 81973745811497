import React from 'react'
import ReactDOM from 'react-dom'

const modalRoot = document.getElementById('root')

export default class Modal extends React.PureComponent {
  constructor (props) {
    super(props)
    this.el = document.createElement('div')
  }

  componentDidMount () {
    modalRoot.appendChild(this.el)
  }

  componentWillUnmount () {
    modalRoot.removeChild(this.el)
  }

  render () {
    return ReactDOM.createPortal(this.props.children, this.el)
  }
}
