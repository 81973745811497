import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { heroicActionActions } from '../../../_actions';
import { HeroicActionType } from '../../../_types';

import {
  Button, Overlay, Form, FormRow, TextInput
} from '../..';

class EditHeroicActionOverlay extends Component {
  constructor(props) {
    super(props);
    const { heroicAction } = this.props;
    this.state = {
      heroicAction,
      submitting: false,
      deleting: false,
      submitted: false
    };
  }

  handleInputChange = (event) => {
    const { target } = event;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState({
      [name]: value
    });
  }

  updateHeroicActionStat = (event) => {
    const { heroicAction } = this.state;
    const {
      target: {
        name, type, checked, value
      }
    } = event;
    const val = type === 'checkbox' ? checked : value;

    const nameParts = name.split('.');
    if (nameParts.length > 1) {
      heroicAction[nameParts[0]][nameParts[1]] = val;
    } else {
      heroicAction[name] = val;
    }

    this.setState({
      heroicAction
    });
  }

  deleteHeroicAction = (e) => {
    e.preventDefault();
    const { heroicAction, deleting } = this.state;
    if (deleting) {
      return;
    }
    const { deleteHeroicActionAction } = this.props;

    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure you want to delete this Heroic Action? This action cannot be undone.')) {
      this.setState({
        deleting: true
      });

      deleteHeroicActionAction(heroicAction.id);
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { heroicAction, submitting } = this.state;
    if (submitting) {
      return;
    }

    if (heroicAction.name === '') {
      this.setState({
        submitted: true
      });
      return;
    }

    this.setState({
      submitted: false,
      submitting: true
    });

    const submitHeroicAction = {
      id: heroicAction.id,
      name: heroicAction.name,
      description: heroicAction.description
    };

    const { createHeroicActionAction, updateHeroicActionAction, handleClose } = this.props;
    if (heroicAction.id) {
      updateHeroicActionAction(submitHeroicAction, handleClose);
    } else {
      createHeroicActionAction(submitHeroicAction, handleClose);
    }
  }

  render() {
    const { handleClose } = this.props;
    const {
      heroicAction, submitting, deleting, submitted
    } = this.state;
    const { name, description } = heroicAction;
    return (
      <Overlay id="edit-heroicAction" title={heroicAction.id ? 'Edit HeroicAction' : 'Create HeroicAction'} handleClose={handleClose} open>
        <div className={`edit-left ${(submitting || deleting) ? 'submitting' : ''}`}>
          <Form buttons={(
            <Fragment>
              {!!heroicAction.id && <Button text="Delete" destructive type="button" onClick={this.deleteHeroicAction} loading={deleting} />}
              <Button text="Submit" primary type="button" onClick={this.onSubmit} loading={submitting} />
            </Fragment>
          )}
          >
            {!heroicAction.id && (
            <p className="help-text">
              Heroic Actions can be created here, and then quickly selected on your warriors.
            </p>
            )}
            <FormRow>
              <TextInput name="name" label="Name" value={name} onChange={this.updateHeroicActionStat} required showError={submitted && !name} />
            </FormRow>
            <FormRow>
              <TextInput name="description" label="Description" value={description} onChange={this.updateHeroicActionStat} textarea />
            </FormRow>
          </Form>
          {(submitting || deleting) && <div className="submit-block" />}
        </div>
      </Overlay>
    );
  }
}

EditHeroicActionOverlay.propTypes = {
  heroicAction: HeroicActionType.isRequired,
  createHeroicActionAction: PropTypes.func.isRequired,
  updateHeroicActionAction: PropTypes.func.isRequired,
  deleteHeroicActionAction: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default withRouter(connect(null, {
  createHeroicActionAction: heroicActionActions.createHeroicAction,
  updateHeroicActionAction: heroicActionActions.updateHeroicAction,
  deleteHeroicActionAction: heroicActionActions.deleteHeroicAction
})(EditHeroicActionOverlay));
