import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const Loader = ({ small, grey }) => (
  <svg className={`loader ${small ? 'loader--small' : ''} ${grey ? 'loader--grey' : ''}`} viewBox="0 0 1257.7 1223.1">
    <path d="M1250.9,252.6c-0.7,3.9-1.3,7.9-2,11.8c-2.6,14.3-5.1,28.6-8.1,42.8c-2.8,13.2-5.8,26.4-9.5,39.4
      c-4.4,15.3-8.9,30.6-14.6,45.5c-7,18.4-14.7,36.6-23,54.4c-10.6,22.8-24.2,44-39.5,64c-12.7,16.7-27.3,31.7-43.5,45
      c-17.6,14.4-35.3,28.7-53.8,42.1c-12.6,9.2-26.2,17.1-39.9,24.8c-22.6,12.7-46,23.8-70.9,31.3c-17.9,5.4-36.2,9.4-54.4,13.7
      c-5.7,1.3-11.6,1.5-18.7,2.4c3.1-8.3,5.8-15.6,8.7-22.9c5.5-13.8,11-27.5,16.5-41.3c2.8-7.1,5.6-14.2,8.6-21.3
      c0.5-1.2,2.1-2.2,3.5-2.9c13.4-6.4,26.5-13.3,38.5-22.3c13.3-9.9,24.9-21.4,35.5-34.2c8-9.7,14.3-20.3,19.9-31.4
      c7.1-14.2,12-29.1,13.8-45.1c2.3-20.2,0.9-39.8-7.5-58.3c-5.2-11.4-12-22-21.2-30.8c-6.8-6.5-12.7-14-20.1-19.7
      c-10.1-7.8-20.8-15.2-32.1-21.2c-12-6.3-25-11.1-37.7-15.9c-5.4-2.1-11.3-2.9-17.1-3.8c-1.3-0.2-3.6,1.3-4.3,2.6
      c-3.2,5.9-8.9,8.8-15.1,6.2c-5.2-2.2-9.8-5.6-14.8-8.3c-18.6-10.2-37.3-20.4-56-30.6c-3.3-1.8-6.7-3.4-9.9-5.4
      c-5.7-3.7-7.1-12.8-3.6-18.8c7.6-12.8,14.9-25.6,22.2-38.5c10.3-18.1,20.5-36.3,30.7-54.4c1-1.8,1.6-4,2.8-5.6
      c6.4-8.5,12.7-9.3,22.2-3.9c23.6,13.5,47.4,26.7,71.1,40.1c4.4,2.5,7.8,5.5,8.5,11.1c0.3,1.9,2.9,4.3,5,5.1
      c11.2,4.4,22.4,8.9,33.9,12.5c17.7,5.4,35.6,10.4,53.5,14.9c15,3.8,30.1,7.1,45.3,9.9c14.1,2.6,28.5,4.2,42.7,6.2
      c11.5,1.6,23,3.3,34.5,4.9c3.9,0.5,7.9,0.8,11.8,1.1c17.7,1,35.4,2.6,53.1,2.8c11.6,0.1,23.3-1.8,34.9-2.8
      C1250.9,249.2,1250.9,250.9,1250.9,252.6z"
    />
    <path d="M245,1018.5c4.9-5.4,9.5-11,14.7-16c8.3-8.1,17.1-15.6,25.4-23.7c10.4-10.1,20.9-20.2,30.7-30.8
      c13.9-15,27.4-30.4,40.9-45.7c10.3-11.7,20.5-23.5,30.5-35.6c9.5-11.4,18.6-23.1,27.9-34.7c10.8-13.5,21.7-27,32.3-40.6
      c6.4-8.1,12.5-16.4,18.6-24.6c8.6-11.5,17.1-23.1,25.7-34.7c7.5-10.1,15.1-20.2,22.4-30.4c13.6-19.2,27-38.5,40.6-57.7
      c10.7-15.1,21.6-30,32-45.3c11.5-16.8,22.8-33.8,34-50.8c7.9-12,15.5-24.1,23.4-36.1c10.9-16.4,22.1-32.6,32.9-49.1
      c9.2-14,17.9-28.4,26.9-42.6c11.9-18.7,23.9-37.3,35.6-56.1c13.3-21.1,26.4-42.3,39.5-63.4c3.2-5.2,6.1-10.6,9.5-16.5
      c16.7,9.6,33.3,19.1,50.7,29.1c-4,6.8-7.8,13.3-11.7,19.9c-2.2,3.8-4.3,7.6-6.5,11.4c-10.1,17.5-20.2,35-30.3,52.5
      c-4.6,8-9,16.2-13.6,24.3c-4.8,8.3-9.8,16.5-14.7,24.8c-9.6,16.3-19,32.6-28.7,48.8c-8.8,14.7-17.9,29.2-26.8,43.8
      c-4.3,7.1-8.6,14.1-13,21.2c-9.7,15.4-19.4,30.8-29.1,46.2c-4.8,7.7-9.3,15.6-14.2,23.3c-9.2,14.4-18.7,28.5-27.9,42.9
      c-4.7,7.4-9,15-13.8,22.4c-9.3,14.1-18.7,28.1-28.1,42.2c-9.1,13.6-18,27.2-27.2,40.7c-7.9,11.7-15.9,23.3-24,34.9
      c-10.1,14.4-20.1,28.8-30.4,43c-10.7,14.7-21.8,29.2-32.7,43.8c-11.1,15-21.9,30.1-33.2,44.9c-9.6,12.7-19.5,25.1-29.4,37.5
      c-9.8,12.3-19.7,24.6-29.7,36.7c-7.7,9.3-15.5,18.6-23.6,27.6c-15.5,17.1-31.1,34.1-46.9,50.8c-3.4,3.5-8,5.9-12,8.8
      c-3.3,0-6.7,0-10,0c-3.3-2.4-8-4.1-9.8-7.4c-4.7-8.8-8.5-18.1-12.2-27.4c-5.2-13-10.1-26.2-15.1-39.3
      C245,1027.1,245,1022.8,245,1018.5z"
    />
    <path d="M889.9,117.6c9.1,5.3,18.2,10.5,27.4,15.8c3.3,1.9,6.7,3.4,9.9,5.4c4.9,2.9,5.7,7.3,2.8,12.5c-2.6,4.7-5,9.5-7.8,14.7
      c-16.6-9.9-33.2-19.7-50.8-30.1c4.6-6.2,9.1-12.2,13.6-18.2C886.6,117.6,888.2,117.6,889.9,117.6z"
    />
  </svg>
);

Loader.propTypes = {
  small: PropTypes.bool,
  grey: PropTypes.bool
};

Loader.defaultProps = {
  small: false,
  grey: false
};

export default Loader;
