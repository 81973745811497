import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import Analytics from 'react-router-ga';

import { CompaniesType, AuthType, DonationType } from './_types';
import {
  userActions, companyActions, wargearActions, heroicActionActions,
  profileActions, ruleActions, spellActions, alertActions
} from './_actions';

import { history } from './_helpers/history';

import AboutPage from './About';
import Armoury from './Armoury';
import Battle from './Battle';
import Companies from './Companies';
import CompanyDetails from './CompanyDetails';
import ContactPage from './Contact';
import DonationAdmin from './DonationAdmin';
import ForgotPassword from './ForgotPassword';
import Graveyard from './Graveyard';
import HeroicActions from './HeroicActions';
import Homepage from './Homepage';
import LoginPage from './Login';
import MyAccount from './MyAccount';
import PayPalCancelled from './PayPal/cancelled';
import PayPalComplete from './PayPal/complete';
import Profiles from './Profiles';
import PasswordReset from './PasswordReset';
import Retired from './Retired';
import Rules from './Rules';
import SharedCompany from './SharedCompany';
import Spells from './Spells';

import { Icon } from './_components';

import { PrivateRoute } from './_components/PrivateRoute';
import Navigation from './_components/Navigation';

import patroenButton from './_assets/patreon.png';
import paypalButton from './_assets/paypal.png';
import SupportOverlay from './_components/Overlay/SupportOverlay';

class App extends Component {
  constructor(props) {
    super(props);

    history.listen(() => {
      const { clearAlertsAction } = this.props;
      clearAlertsAction();
    });

    this.state = {
      supportOverlayOpen: false
    };
  }

  componentDidMount() {
    const {
      authentication, getCompaniesAction, getWargearAction, getHeroicActionsAction,
      getRulesAction, getSpellsAction, getProfilesAction, checkDonation
    } = this.props;
    if (authentication.loggedIn) {
      getProfilesAction();
      getWargearAction();
      getRulesAction();
      getHeroicActionsAction();
      getSpellsAction();
      getCompaniesAction();
      checkDonation();

      const modalShown = localStorage.getItem('support-modal');

      if (!modalShown || new Date() - new Date(modalShown) > (7 * 24 * 60 * 60 * 1000)) {
        window.setTimeout(() => {
          this.setState({
            supportOverlayOpen: true
          });
          localStorage.setItem('support-modal', new Date());
        }, 3000);
      }
    }
  }

  closeSupportOverlay = () => {
    this.setState({
      supportOverlayOpen: false
    });
  }

  render() {
    const { companies, alerts, donation } = this.props;
    const { supportOverlayOpen } = this.state;
    return (
      <Router history={history}>
        <Analytics id="UA-146590388-1">
          <Fragment>
            <Navigation companies={companies} />
            <div id="wrapper">
              <div id="wrapper-inner">
                <div id="main">
                  {alerts.message && (
                    <div className={`alert ${alerts.type}`}>
                      <Icon name={alerts.type === 'alert--danger' ? 'cross' : 'check'} />
                      <span>{alerts.message}</span>
                      <div className="alert__timer">
                        <div className="alert__timer__inner" />
                      </div>
                    </div>
                  )}
                  <Switch>
                    <PrivateRoute path="/company/:companyId/battle" component={Battle} />
                    <PrivateRoute path="/company/:userId/:companyId" component={SharedCompany} />
                    <PrivateRoute path="/company/:companyId" component={CompanyDetails} />
                    <PrivateRoute path="/companies" component={Companies} />
                    <PrivateRoute path="/profiles" component={Profiles} />
                    <PrivateRoute path="/armoury" component={Armoury} />
                    <PrivateRoute path="/rules" component={Rules} />
                    <PrivateRoute path="/spells" component={Spells} />
                    <PrivateRoute path="/heroics" component={HeroicActions} />
                    <PrivateRoute path="/retired" component={Retired} />
                    <PrivateRoute path="/graveyard" component={Graveyard} />
                    <PrivateRoute path="/account" component={MyAccount} />
                    <PrivateRoute path="/donation-admin" component={DonationAdmin} />
                    <Route path="/paypal-cancelled" component={PayPalCancelled} />
                    <Route path="/paypal-complete" component={PayPalComplete} />
                    <Route path="/about" component={AboutPage} />
                    <Route path="/contact" component={ContactPage} />
                    <Route path="/login" component={LoginPage} />
                    <Route path="/forgotten-password" component={ForgotPassword} />
                    <Route path="/password-reset" component={PasswordReset} />
                    <Route path="/" component={Homepage} />
                  </Switch>
                </div>
                {(!_.isEmpty(donation) && !donation.donated) && (
                  <>
                    <div className="donate-banner">
                      <span>Love the app? Help support us!</span>
                      <a href="https://www.patreon.com/bePatron?u=17808468" className="donate-link" target="_blank" rel="noopener noreferrer">
                        <img src={patroenButton} alt="Patreon" />
                      </a>
                      <form action="https://www.paypal.com/donate" method="post" target="_top" className="donate-form">
                        <input type="hidden" name="hosted_button_id" value="QZ46U9CJF7EFE" />
                        <input type="image" src={paypalButton} border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                        <img alt="" border="0" src="https://www.paypal.com/en_GB/i/scr/pixel.gif" width="1" height="1" />
                      </form>
                    </div>
                    {supportOverlayOpen && <SupportOverlay handleClose={this.closeSupportOverlay} />}
                  </>
                )}
              </div>
            </div>
          </Fragment>
        </Analytics>
      </Router>
    );
  }
}

App.propTypes = {
  companies: CompaniesType,
  donation: DonationType.isRequired,
  getCompaniesAction: PropTypes.func.isRequired,
  getWargearAction: PropTypes.func.isRequired,
  getProfilesAction: PropTypes.func.isRequired,
  getRulesAction: PropTypes.func.isRequired,
  getSpellsAction: PropTypes.func.isRequired,
  clearAlertsAction: PropTypes.func.isRequired,
  checkDonation: PropTypes.func.isRequired,
  alerts: PropTypes.objectOf(PropTypes.string),
  authentication: AuthType.isRequired
};

App.defaultProps = {
  companies: {},
  alerts: {}
};

function mapStateToProps({
  companies, alerts, authentication, donation
}) {
  return {
    companies, alerts, authentication, donation
  };
}

export default connect(mapStateToProps, {
  getCompaniesAction: companyActions.getUserCompanies,
  getWargearAction: wargearActions.getWargear,
  getProfilesAction: profileActions.getProfiles,
  getRulesAction: ruleActions.getRules,
  getSpellsAction: spellActions.getSpells,
  getHeroicActionsAction: heroicActionActions.getHeroicActions,
  clearAlertsAction: alertActions.clear,
  checkDonation: userActions.checkDonation
})(App);
