import _ from 'lodash';

export const emptySpell = {
  id: 0,
  warriorId: 0,
  mySpellsId: 0,
  name: '',
  description: '',
  type: '',
  baseCastingValue: 0,
  modifyCastingValue: 0
};

export const emptySpecialRule = {
  id: 0,
  warriorId: 0,
  wargearId: 0,
  myRuleId: 0,
  name: '',
  description: '',
  type: ''
};

export const emptyHeroicAction = {
  id: 0,
  warriorId: 0,
  myHeroicActionsId: 0,
  name: '',
  description: '',
  type: ''
};

export const emptyWargearItem = {
  id: 0,
  warriorId: 0,
  armouryId: 0,
  type: '',
  name: '',
  pointsLow: 0,
  pointsHigh: 0,
  adjustmentStat: '',
  adjustmentValue: 0,
  mount: 0,
  move: 0,
  fight: 0,
  shoot: 0,
  strength: 0,
  defence: 0,
  attacks: 0,
  wounds: 0,
  courage: 0,
  specialRules: [],
  specialAttacks: []
};

export const emptyStats = {
  move: 0,
  fight: 0,
  shoot: 0,
  strength: 0,
  defence: 0,
  attacks: 0,
  wounds: 0,
  courage: 0,
  might: 0,
  will: 0,
  fate: 0
};

export const emptyWarrior = {
  id: 0,
  companyId: 0,
  name: '',
  biography: '',
  image: '',
  rank: '',
  experience: 0,
  status: '',
  legWound: 0,
  armWound: 0,
  oldWound: 0,
  baseClass: '',
  basePoints: 0,
  totalPoints: 0,
  chosenPath: '',
  customPath: '',
  baseStats: _.clone(emptyStats),
  modifyStats: _.clone(emptyStats),
  totalStats: _.clone(emptyStats),
  wargear: [],
  specialRules: [],
  heroicActions: [],
  spells: []
};
