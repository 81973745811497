/* eslint-disable react/jsx-one-expression-per-line */
import React, { PureComponent } from 'react';

class PayPalComplete extends PureComponent {
  render() {
    return (
      <div className="text-page">
        <div className="intro">
          <div className="intro__text">
            <h3>PayPal Donation</h3>
            <h1>Complete</h1>
            <p>Thank you for your donation, it really means a lot to me and will help to support the ongoing development and hosting of the app.</p>
            <p>Currently removing adverts is a manual process, please allow me 24 hours to update your account. If you are still seeing adverts after this period please contact <a href="mailto:battlecompaniesmanager@gmail.com">battlecompaniesmanager@gmail.com</a>.</p>
          </div>
        </div>
      </div>
    );
  }
}

export default PayPalComplete;
