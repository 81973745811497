import React, { PureComponent } from 'react';
import EditSpellOverlay from '../Overlay/EditSpellOverlay';
import { SpellType } from '../../_types';

import { Card } from '..';

class SpellCard extends PureComponent {
  render() {
    const { spell } = this.props;

    return (
      <Card
        id={spell.id}
        title={spell.name}
        className="card--rule"
        overlay={<EditSpellOverlay spell={{ ...spell }} />}
        clickable
      >
        <div className="card__rules card__rules--special-rules">
          <span className="card__rules__header">
            Description
          </span>
          <div className="card__rules__inner">
            {spell.description}
          </div>
        </div>
      </Card>
    );
  }
}

SpellCard.propTypes = {
  spell: SpellType.isRequired
};

export default SpellCard;
