import React from "react"

import './styles.scss';

function WoundChart() {
  return (
    <div className="wound-chart">
      <div className="wound-chart__x-label">Defence</div>
      <div className="wound-chart__y-label">Strength</div>
      <table className="wound-chart__table">
        <thead>
          <tr>
            <th></th>
            <th>1</th>
            <th>2</th>
            <th>3</th>
            <th>4</th>
            <th>5</th>
            <th>6</th>
            <th>7</th>
            <th>8</th>
            <th>9</th>
            <th>10</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>1</th>
            <td>4</td>
            <td>5</td>
            <td>5</td>
            <td>6</td>
            <td>6</td>
            <td>6/4</td>
            <td>6/5</td>
            <td>6/6</td>
            <td>-</td>
            <td>-</td>
          </tr>
          <tr>
            <th>2</th>
            <td>4</td>
            <td>4</td>
            <td>5</td>
            <td>5</td>
            <td>6</td>
            <td>6</td>
            <td>6/4</td>
            <td>6/5</td>
            <td>6/6</td>
            <td>-</td>
          </tr>
          <tr>
            <th>3</th>
            <td>3</td>
            <td>4</td>
            <td>4</td>
            <td>5</td>
            <td>5</td>
            <td>6</td>
            <td>6</td>
            <td>6/4</td>
            <td>6/5</td>
            <td>6/6</td>
          </tr>
          <tr>
            <th>4</th>
            <td>3</td>
            <td>3</td>
            <td>4</td>
            <td>4</td>
            <td>5</td>
            <td>5</td>
            <td>6</td>
            <td>6</td>
            <td>6/4</td>
            <td>6/5</td>
          </tr>
          <tr>
            <th>5</th>
            <td>3</td>
            <td>3</td>
            <td>3</td>
            <td>4</td>
            <td>4</td>
            <td>5</td>
            <td>5</td>
            <td>6</td>
            <td>6</td>
            <td>6/4</td>
          </tr>
          <tr>
            <th>6</th>
            <td>3</td>
            <td>3</td>
            <td>3</td>
            <td>3</td>
            <td>4</td>
            <td>4</td>
            <td>5</td>
            <td>5</td>
            <td>6</td>
            <td>6</td>
          </tr>
          <tr>
            <th>7</th>
            <td>3</td>
            <td>3</td>
            <td>3</td>
            <td>3</td>
            <td>3</td>
            <td>4</td>
            <td>4</td>
            <td>5</td>
            <td>5</td>
            <td>6</td>
          </tr>
          <tr>
            <th>8</th>
            <td>3</td>
            <td>3</td>
            <td>3</td>
            <td>3</td>
            <td>3</td>
            <td>3</td>
            <td>4</td>
            <td>4</td>
            <td>5</td>
            <td>5</td>
          </tr>
          <tr>
            <th>9</th>
            <td>3</td>
            <td>3</td>
            <td>3</td>
            <td>3</td>
            <td>3</td>
            <td>3</td>
            <td>3</td>
            <td>4</td>
            <td>4</td>
            <td>5</td>
          </tr>
          <tr>
            <th>10</th>
            <td>3</td>
            <td>3</td>
            <td>3</td>
            <td>3</td>
            <td>3</td>
            <td>3</td>
            <td>3</td>
            <td>3</td>
            <td>4</td>
            <td>4</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default WoundChart