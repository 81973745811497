import _ from 'lodash';

export function calculateProfilePoints(profile) {
  // Base points
  const points = profile.basePoints;

  const wargear = _.reduce(_.map(profile.wargear, (item) => {
    if (item.remove) {
      return 0;
    }
    return parseInt(item.pointsLow, 10);
  }), (sum, n) => (sum + n), 0);

  const totalPoints = points + wargear;

  return {
    ...profile,
    totalPoints
  };
}

function calculateStatWithWargear(statName, profile) {
  const activeWargear = _.filter(profile.wargear, ({ remove }) => !remove);
  const filteredWargear = _.filter(activeWargear, { adjustmentStat: statName });
  const wargear = _.reduce(filteredWargear,
    (sum, item) => sum + parseInt(item.adjustmentValue, 10), 0);
  const baseStat = parseInt(profile.baseStats[statName.toLowerCase()], 10);
  const wargearStat = parseInt(wargear || 0, 10);

  if (statName === 'Shoot') {
    return baseStat - wargearStat;
  }
  return baseStat + wargearStat;
}

export function calculateProfileStatTotals(profile) {
  profile.subTotalStats = profile.baseStats;

  const totalStats = {
    move: calculateStatWithWargear('Move', profile),
    fight: calculateStatWithWargear('Fight', profile),
    shoot: calculateStatWithWargear('Shoot', profile),
    strength: calculateStatWithWargear('Strength', profile),
    defence: calculateStatWithWargear('Defence', profile),
    attacks: calculateStatWithWargear('Attacks', profile),
    wounds: calculateStatWithWargear('Wounds', profile),
    courage: calculateStatWithWargear('Courage', profile),
    might: calculateStatWithWargear('Might', profile),
    will: calculateStatWithWargear('Will', profile),
    fate: calculateStatWithWargear('Fate', profile)
  };

  return {
    ...profile,
    totalStats
  };
}
