import { profileService } from '../_services/profile';
import { alertActions } from './alert';

export const profileConstants = {
  GET_REQUEST: 'PROFILES_GET_REQUEST',
  GET_SUCCESS: 'PROFILES_GET_SUCCESS',
  GET_FAILURE: 'PROFILES_GET_FAILURE',

  CREATE_REQUEST: 'PROFILE_CREATE_REQUEST',
  CREATE_SUCCESS: 'PROFILE_CREATE_SUCCESS',
  CREATE_FAILURE: 'PROFILE_CREATE_FAILURE',

  UPDATE_REQUEST: 'PROFILE_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'PROFILE_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'PROFILE_UPDATE_FAILURE',

  DELETE_REQUEST: 'PROFILE_DELETE_REQUEST',
  DELETE_SUCCESS: 'PROFILE_DELETE_SUCCESS',
  DELETE_FAILURE: 'PROFILE_DELETE_FAILURE'
};

function getProfiles() {
  function request() { return { type: profileConstants.GET_REQUEST }; }
  function success(profiles) { return { type: profileConstants.GET_SUCCESS, profiles }; }
  function failure(error) { return { type: profileConstants.GET_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    profileService.getProfiles()
      .then(
        profiles => dispatch(success(profiles)),
        error => dispatch(failure(error.toString()))
      );
  };
}

function createProfile(profile, callback) {
  function request(profileResponse) {
    return { type: profileConstants.CREATE_REQUEST, profileResponse };
  }
  function success(profileResponse) {
    return { type: profileConstants.CREATE_SUCCESS, profileResponse };
  }
  function failure(error) { return { type: profileConstants.CREATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(profile));

    profileService.createProfile(profile)
      .then(
        (profileResponse) => {
          dispatch(success(profileResponse));
          if (callback) callback();
          dispatch(alertActions.success(`${profile.baseClass} created successfully`));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };
}

function updateProfile(profile, callback) {
  function request(profileResponse) {
    return { type: profileConstants.UPDATE_REQUEST, profileResponse };
  }
  function success(profileResponse) {
    return { type: profileConstants.UPDATE_SUCCESS, profileResponse };
  }
  function failure(error) { return { type: profileConstants.UPDATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(profile));

    profileService.updateProfile(profile)
      .then(
        (profileResponse) => {
          dispatch(success(profileResponse));
          if (callback) callback();
          dispatch(alertActions.success(`${profile.baseClass} updated successfully`));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };
}

function deleteProfile(id) {
  function request(idResponse) { return { type: profileConstants.DELETE_REQUEST, idResponse }; }
  function success(idResponse) { return { type: profileConstants.DELETE_SUCCESS, idResponse }; }
  function failure(idResponse, error) {
    return { type: profileConstants.DELETE_FAILURE, idResponse, error };
  }

  return (dispatch) => {
    dispatch(request(id));

    profileService.deleteProfile(id)
      .then(
        (responseId) => {
          dispatch(success(responseId));
          dispatch(alertActions.success('Profile deleted successfully'));
        },
        (error) => {
          dispatch(failure(id, error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };
}

// eslint-disable-next-line import/prefer-default-export
export const profileActions = {
  getProfiles,
  createProfile,
  updateProfile,
  deleteProfile
};
