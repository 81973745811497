/* eslint-disable react/jsx-one-expression-per-line */
import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { isHero } from '../../../../_helpers/warrior';
import { WarriorsType, RuleType, HeroicActionType, SpellType } from '../../../../_types';

import {
  WarriorCard, Overlay, Form, Button
} from '../../..';
import ExperienceForm from './ExperienceForm';

class ApplyExperience extends Component {
  constructor(props) {
    super(props);

    const { warriors, result } = this.props;

    const experienceWarriors = _.map(_.filter(warriors, warrior => (!warrior.preBattleInjury && warrior.status !== 'dead' && warrior.rank !== 'Wanderer' && warrior.rank !== 'Creature')), (warrior, index) => {
      const hero = isHero(warrior);
      const woundsExperience = hero
        ? warrior.woundsInflicted
        : Math.min(warrior.woundsInflicted, 1);
      const winExperience = hero && result === 0 ? 1 : 0;
      const participationExperience = 1;

      const experienceIncrease = this.getExperienceTotal(
        woundsExperience,
        winExperience,
        participationExperience,
        warrior.bonusExperience
      );
      const experienceTotal = warrior.experience + experienceIncrease;
      const currentLevel = Math.floor(warrior.experience / 5) + 1;
      const newLevel = Math.floor(experienceTotal / 5) + 1;
      const levelChange = newLevel - currentLevel;

      return {
        ...warrior,
        newExperience: experienceTotal,
        index,
        levelChange,
        woundsExperience,
        winExperience,
        participationExperience,
        experienceIncrease,
        introClosed: false
      };
    });

    this.state = {
      warriors: experienceWarriors
    };
  }

  getExperienceTotal = (
    woundsExperience,
    winExperience,
    participationExperience,
    bonusExperience
  ) => woundsExperience + winExperience + participationExperience + (bonusExperience || 0)

  handleInjuryResult = (name, value, warrior) => {
    const { warriors } = this.state;
    warriors[warrior.index] = { ...warrior, [name]: value };
    this.setState({
      warriors
    });
  }

  closeIntro = (skipExperienceStep) => {
    const { skipExperience } = this.props;
    this.setState({ introClosed: true });
    if (skipExperienceStep) {
      skipExperience();
    }
  }

  onSubmit = (warrior) => {
    const { updateWarrior } = this.props;
    const { warriors } = this.state;

    warriors[warrior.index].animateOut = true;
    this.setState({
      warriors
    });

    window.setTimeout(() => {
      warriors[warrior.index] = warrior;
      this.setState({
        warriors
      });
      updateWarrior(warrior);
    }, 500);
  }

  render() {
    const { rules, heroicActions, spells } = this.props;
    const { warriors, introClosed } = this.state;
    const filteredWarriors = _.filter(warriors, warrior => (warrior.experienceIncrease > 0));

    if (!introClosed) {
      return (
        <Overlay title="Allocate Experience" isSmall open>
          <Form buttons={<Button text="Continue" iconName="arrow-right" iconRight primary onClick={() => this.closeIntro(filteredWarriors.length === 0)} />}>
            <p><strong>{filteredWarriors.length}</strong> of your warriors {filteredWarriors.length !== 1 ? 'have' : 'has' } gained experience. Click Continue to allocate their experience.</p>
          </Form>
        </Overlay>
      );
    }

    return (
      <div className="card-overlay">
        {_.map(filteredWarriors, (warrior, index) => (
          <WarriorCard
            key={warrior.id}
            warrior={{ ...warrior }}
            companyId={warrior.companyId}
            overlay
            form={(
              <ExperienceForm
                warrior={{ ...warrior }}
                onSubmit={this.onSubmit}
                active={index === 0}
                rules={rules}
                heroicActions={heroicActions}
                spells={spells}
              />
            )}
          />
        ))}
      </div>
    );
  }
}

ApplyExperience.propTypes = {
  warriors: WarriorsType.isRequired,
  result: PropTypes.number.isRequired,
  updateWarrior: PropTypes.func.isRequired,
  skipExperience: PropTypes.func.isRequired,
  rules: PropTypes.oneOfType([
    PropTypes.objectOf(RuleType),
    PropTypes.objectOf(PropTypes.bool)
  ]).isRequired,
  heroicActions: PropTypes.oneOfType([
    PropTypes.objectOf(HeroicActionType),
    PropTypes.objectOf(PropTypes.bool)
  ]).isRequired,
  spells: PropTypes.oneOfType([
    PropTypes.objectOf(SpellType),
    PropTypes.objectOf(PropTypes.bool)
  ]).isRequired
};

export default ApplyExperience;
