import { authHeader } from '../_helpers/authHeader';
import { apiUrl } from '../_constants';
import { handleResponse } from '.';
import 'whatwg-fetch';

function getSpells() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${apiUrl}/spell`, requestOptions).then(handleResponse);
}

function createSpell(spell) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(spell)
  };

  return fetch(`${apiUrl}/spell/create`, requestOptions).then(handleResponse);
}

function updateSpell(spell) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(spell)
  };

  return fetch(`${apiUrl}/spell/${spell.id}`, requestOptions).then(handleResponse);
}

function deleteSpell(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };

  return fetch(`${apiUrl}/spell/${id}`, requestOptions).then(handleResponse);
}

// eslint-disable-next-line import/prefer-default-export
export const spellService = {
  getSpells,
  createSpell,
  updateSpell,
  deleteSpell
};
