import React, { PureComponent } from 'react';
import EditHeroicActionOverlay from '../Overlay/EditHeroicActionOverlay';
import { HeroicActionType } from '../../_types';

import { Card } from '..';

class HeroicActionCard extends PureComponent {
  render() {
    const { heroicAction } = this.props;

    return (
      <Card
        id={heroicAction.id}
        title={heroicAction.name}
        className="card--rule"
        overlay={<EditHeroicActionOverlay heroicAction={{ ...heroicAction }} />}
        clickable
      >
        <div className="card__rules card__rules--special-rules">
          <span className="card__rules__header">
            Description
          </span>
          <div className="card__rules__inner">
            {heroicAction.description}
          </div>
        </div>
      </Card>
    );
  }
}

HeroicActionCard.propTypes = {
  heroicAction: HeroicActionType.isRequired
};

export default HeroicActionCard;
