import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { userActions } from '../../_actions';
import {
  Button, Form, FormRow, TextInput
} from '..';

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      submitted: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { login } = this.props;
    const { email, password } = this.state;
    if (email && password) {
      login(email, password);
    }
  }

  render() {
    const { loggingIn } = this.props;
    const { email, password, submitted } = this.state;
    return (
      <form id="login-form" name="login-form" onSubmit={this.handleSubmit}>
        <Form buttons={<Button text="Log in" primary iconName="arrow-right" iconRight type="submit" loading={loggingIn} />}>
          <FormRow>
            <TextInput name="email" value={email} onChange={this.handleChange} placeholder="Email address" type="email" required showError={submitted && !email} />
          </FormRow>
          <FormRow>
            <TextInput name="password" type="password" value={password} onChange={this.handleChange} placeholder="Password" required showError={submitted && !password} />
          </FormRow>
        </Form>
        <Link style={{ marginTop: '3px', display: 'inline-block' }} to="/forgotten-password">Forgot your password?</Link>
      </form>
    );
  }
}

LoginForm.propTypes = {
  login: PropTypes.func.isRequired,
  loggingIn: PropTypes.bool
};

LoginForm.defaultProps = {
  loggingIn: false
};

function mapStateToProps({ authentication }) {
  const { loggingIn } = authentication;
  return { loggingIn };
}

export default connect(mapStateToProps, ({
  login: userActions.login
}))(LoginForm);
