/* eslint-disable react/jsx-one-expression-per-line */
import React, { Fragment, useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { HeroicActionType } from '../_types';
import { emptyHeroicAction } from '../_constants';
import { heroicActionActions } from '../_actions';
import { useStoredSort } from '../_hooks/useStoredSort';

import {
  Grid, Loader, HeroicActionCard, NewCard, Advert, PageHeader
} from '../_components';
import EditHeroicActionOverlay from '../_components/Overlay/EditHeroicActionOverlay';

const storageName = 'sort-heroic-actions';
const sortOptions = [
  { label: 'Name', value: o => o.name.toLowerCase(), mode: 'asc' },
  { label: 'Name', value: o => o.name.toLowerCase(), mode: 'desc' }
];

function HeroicActions({ heroicActions, getHeroicActionsAction }) {
  const [sort, setSort] = useState(useStoredSort(sortOptions, 0, storageName));

  useEffect(() => {
    getHeroicActionsAction();
  }, [getHeroicActionsAction]);

  if (heroicActions.loading) {
    return <Loader />;
  }

  let i = 0;

  const orderedHeroicActions = _.orderBy(heroicActions, [sort.value], [sort.mode]);

  return (
    <Fragment>
      <Advert isWide />
      <PageHeader title="My Heroic Actions" sortOption={sort} setSort={setSort} sortOptions={sortOptions} storageName={storageName} />
      <Grid>
        {_.map(orderedHeroicActions, heroicAction => (
          <Fragment key={heroicAction.id}>
            <HeroicActionCard heroicAction={heroicAction} />
            {(i++ % 4 === 0) && <Advert />}
          </Fragment>
        ))}
        <NewCard
          key="new"
          title="New Heroic Action"
          overlay={<EditHeroicActionOverlay heroicAction={_.clone(emptyHeroicAction)} />}
          small
        />
      </Grid>
    </Fragment>
  );
}

HeroicActions.propTypes = {
  heroicActions: PropTypes.oneOfType([
    PropTypes.arrayOf(HeroicActionType),
    PropTypes.object
  ]).isRequired,
  getHeroicActionsAction: PropTypes.func.isRequired
};

function mapStateToProps({ heroicActions }) {
  return { heroicActions };
}

export default withRouter(connect(mapStateToProps, {
  getHeroicActionsAction: heroicActionActions.getHeroicActions
})(HeroicActions));
