import React, { Fragment, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';

import { CompanyType } from '../_types';
import { companyActions } from '../_actions';
import { useStoredSort } from '../_hooks/useStoredSort';

import {
  WarriorList, CompanyHeader, Loader
} from '../_components';

const storageName = 'sort-warriors';
const sortOptions = [
  { label: 'Default', value: 'default', mode: '' },
  { label: 'Level', value: 'experience', mode: 'asc' },
  { label: 'Level', value: 'experience', mode: 'desc' },
  { label: 'Name', value: o => o.name.toLowerCase(), mode: 'asc' },
  { label: 'Name', value: o => o.name.toLowerCase(), mode: 'desc' },
  { label: 'Points', value: 'totalPoints', mode: 'asc' },
  { label: 'Points', value: 'totalPoints', mode: 'desc' },
  { label: 'Rank', value: 'sortRank', mode: 'asc' },
  { label: 'Rank', value: 'sortRank', mode: 'desc' }
];

function SharedCompany({ sharedCompany, match, getSharedCompany }) {
  const { userId, companyId } = match.params;

  const [sort, setSort] = useState(useStoredSort(sortOptions, 0, storageName));

  useEffect(() => {
    getSharedCompany(userId, companyId);
  }, [getSharedCompany, userId, companyId]);

  // componentDidMount() {
  //   const { history } = this.props;
  //   this.unlisten = history.listen((location) => {
  //     if (location.pathname.indexOf('/company/') > -1 && location.pathname.indexOf('/battle') === -1) {
  //       this.getWarriors(location.pathname.replace('/company/', ''));
  //     }
  //   });
  // }

  // componentWillUnmount() {
  //   this.unlisten();
  // }

  if (sharedCompany.loading) {
    return <Loader />;
  }

  return (
    <Fragment>
      <CompanyHeader companies={{ [companyId]: sharedCompany }} companyId={companyId} sortOption={sort} sortOptions={sortOptions} setSort={setSort} storageName={storageName} />
      <WarriorList warriors={sharedCompany.warriors} companyId={companyId} sortOption={sort} clickable />
    </Fragment>
  );
}

SharedCompany.propTypes = {
  sharedCompany: CompanyType.isRequired,
  getSharedCompany: PropTypes.func.isRequired,
  match: ReactRouterPropTypes.match.isRequired
  // history: ReactRouterPropTypes.history.isRequired
};

function mapStateToProps({ sharedCompany }) {
  return { sharedCompany };
}

export default withRouter(connect(mapStateToProps,
  { getSharedCompany: companyActions.getSharedCompany })(SharedCompany));
