import _ from 'lodash';
import { wargearConstants } from '../_actions';

function formatItems(items) {
  if (!items) {
    return {};
  }

  return _.mapKeys(items, 'id');
}

function formatItem(item, state) {
  if (!item) {
    return {};
  }
  const newState = { ...state };
  newState[item.id] = item;
  return newState;
}

export default function (state = { loading: true }, action) {
  switch (action.type) {
    case wargearConstants.GET_REQUEST:
      return { loading: true };
    case wargearConstants.GET_SUCCESS:
      return formatItems(action.wargear);
    case wargearConstants.GET_FAILURE:
      return { error: action.error };

    case wargearConstants.CREATE_SUCCESS:
      return formatItem(action.wargearResponse, state);

    case wargearConstants.UPDATE_SUCCESS:
      return formatItem(action.wargearResponse, state);

    case wargearConstants.DELETE_SUCCESS:
      return _.omit(state, action.idResponse.id);

    default:
      return state;
  }
}
