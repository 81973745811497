import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Overlay, Tabs } from '../..';

import WoundChart from './WoundChart';
import JumpChart from './JumpChart';
import LeapChart from './LeapChart';
import ClimbChart from './ClimbChart';
import ThrownRiderChart from './ThrownRiderChart';
import SwimChart from './SwimChart';

class ToolsOverlay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0
    }
  }

  setActiveTab = (index) => {
    this.setState({
      activeTab: index
    });
  }

  getTabs = () => {
    const tabs = [{
      name: 'Wound',
      content: (
        <WoundChart />
      )
    }, {
      name: 'Thrown Rider',
      content: (
        <ThrownRiderChart />
      )
    }, {
      name: 'Jump',
      content: (
        <JumpChart />
      )
    }, {
      name: 'Leap',
      content: (
        <LeapChart />
      )
    }, {
      name: 'Climb',
      content: (
        <ClimbChart />
      )
    }, {
      name: 'Swim',
      content: (
        <SwimChart />
      )
    }];

    return tabs
  }

  render() {
    const { handleClose } = this.props;
    const { activeTab } = this.state;

    return (
      <Overlay id="tools" title="Tools" handleClose={() => handleClose()} open>
        <Tabs
          activeTab={activeTab}
          setActiveTab={this.setActiveTab}
          tabs={this.getTabs()}
        />
      </Overlay>
    )
  }
}

ToolsOverlay.propTypes = {
  handleClose: PropTypes.func.isRequired
}

export default ToolsOverlay;