import { authHeader } from '../_helpers/authHeader';
import { apiUrl } from '../_constants';
import { handleResponse } from '.';
import 'whatwg-fetch';

function getWargear() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${apiUrl}/wargear`, requestOptions).then(handleResponse);
}

function createWargearItem(item) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(item)
  };

  return fetch(`${apiUrl}/wargear/create`, requestOptions).then(handleResponse);
}

function updateWargearItem(item) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(item)
  };

  return fetch(`${apiUrl}/wargear/${item.id}`, requestOptions).then(handleResponse);
}

function deleteWargearItem(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };

  return fetch(`${apiUrl}/wargear/${id}`, requestOptions).then(handleResponse);
}

// eslint-disable-next-line import/prefer-default-export
export const wargearService = {
  getWargear,
  createWargearItem,
  updateWargearItem,
  deleteWargearItem
};
