import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Overlay, Tabs, CharacteristicsTable } from '..';

import './styles.scss';

export class RulesItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      overlayOpen: false,
      activeTab: 0
    };
  }

  openOverlay = () => {
    this.setState({
      overlayOpen: true
    });
  }

  closeOverlay = () => {
    this.setState({
      overlayOpen: false
    });
  }

  setActiveTab = (index) => {
    this.setState({
      activeTab: index
    });
  }

  render() {
    const { item, clickable } = this.props;
    const { overlayOpen, activeTab } = this.state;
    const {
      name, description, adjustmentStat, adjustmentValue, specialAttacks, specialRules,
      mount, move, fight, shoot, strength, defence, attacks, wounds, courage
    } = item;

    const tabs = [];

    if (mount) {
      tabs.push({
        name: 'Characteristics',
        content: (
          <CharacteristicsTable
            total={{
              move,
              fight,
              shoot,
              strength,
              defence,
              attacks,
              wounds,
              courage
            }}
          />
        )
      });
    }

    if (description) {
      tabs.push({
        name: 'Rules',
        content: <p>{description}</p>
      });
    }

    if (specialAttacks && specialAttacks.length) {
      tabs.push({
        name: 'Attacks',
        content: (
          <>
            {_.map(specialAttacks, attack => (
              <p key={attack.name}>
                <strong>
                  {attack.name}
                  :
                </strong>
                {' '}
                <br />
                {attack.description}
              </p>
            ))}
          </>
        )
      });
    }

    if (specialRules && specialRules.length) {
      tabs.push({
        name: 'Rules',
        content: (
          <>
            {_.map(specialRules, rule => (
              <p key={rule.name}>
                <strong>
                  {rule.name}
                  :
                </strong>
                {' '}
                <br />
                {rule.description}
              </p>
            ))}
          </>
        )
      });
    }

    if (adjustmentStat && adjustmentValue) {
      tabs.push({
        name: 'Characteristics',
        content: (
          <p>
            <strong>
              {adjustmentStat}
              :
            </strong>
            {' '}
            {adjustmentValue > 0 ? `+${adjustmentValue}` : adjustmentValue}
          </p>
        )
      });
    }

    const canClick = clickable && tabs.length;

    return (
      <Fragment>
        {canClick ? (
          <button
            className="card__rules__item card__rules__item--clickable"
            onClick={tabs.length ? () => this.openOverlay() : () => {}}
            type="button"
          >
            {name}
          </button>
        ) : <span className="card__rules__item">{name}</span>}
        {overlayOpen && (
          <Overlay id="wargear-details" title={name} isSmall open handleClose={this.closeOverlay}>
            <div className="form__container">
              <div className="form__inner">
                <Tabs
                  activeTab={activeTab}
                  setActiveTab={this.setActiveTab}
                  tabs={tabs}
                  invertColor
                />
              </div>
            </div>
          </Overlay>
        )}
      </Fragment>
    );
  }
}

export const RulesItemType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  adjustmentStat: PropTypes.string,
  adjustmentValue: PropTypes.number,
  attackDescription: PropTypes.string,
  attackName: PropTypes.string
});

RulesItem.propTypes = {
  item: RulesItemType.isRequired,
  clickable: PropTypes.bool.isRequired
};
