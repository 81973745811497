import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { WarriorType } from '../../../_types';
import { isHero } from '../../../_helpers/warrior';

import {
  Button, Form, FormRow, CheckboxListInput, RadioInput, NumberInput
} from '../..';

const InjuriesForm = ({
  warrior, handleInputChange, updateWarriorStat, deleteWarrior,
  deleting, setActiveTab, isGraveyard, prevTab, nextTab
}) => {
  const statuses = [{ label: 'Active', value: '' }, { label: 'Injured', value: 'injured' }];
  if (isHero(warrior)) {
    statuses.push(
      { label: 'Lost in Battle', value: 'lost' }
    );
  }
  statuses.push({ label: 'Dead', value: 'dead' });

  return (
    <Form
      buttons={(
        <Fragment>
          {!isGraveyard && <Button text="Previous" secondary type="button" iconName="arrow-left" iconLeft onClick={() => setActiveTab(prevTab)} />}
          {isGraveyard && <Button text="Delete" destructive type="button" onClick={deleteWarrior} loading={deleting} />}
          <Button text="Next" primary type="button" iconName="arrow-right" iconRight onClick={() => setActiveTab(nextTab)} />
        </Fragment>
      )}
    >
      {!warrior.id && (
      <p className="help-text">
        Select any injuries that your warrior currently has. After a battle injuries are automatically applied based on your selections.
      </p>
      )}
      <FormRow>
        <RadioInput
          label="Status"
          name="status"
          onChange={handleInputChange}
          value={warrior.status}
          options={statuses}
        />
      </FormRow>
      {(!isGraveyard && isHero(warrior)) && (
        <FormRow>
          <CheckboxListInput
            label="Wounds"
            onChange={handleInputChange}
            options={[
              { label: 'Leg Wound', value: warrior.legWound, name: 'legWound' },
              { label: 'Arm Wound', value: warrior.armWound, name: 'armWound' }
            ]}
          />
        </FormRow>
      )}
      {(!isGraveyard && isHero(warrior)) && (
        <FormRow>
          <NumberInput
            label="Old Battle Wounds"
            name="oldWound"
            onChange={updateWarriorStat}
            value={warrior.oldWound}
            min={0}
          />
        </FormRow>
      )}
    </Form>
  );
};

InjuriesForm.propTypes = {
  warrior: WarriorType.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  updateWarriorStat: PropTypes.func.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  isGraveyard: PropTypes.bool.isRequired,
  deleteWarrior: PropTypes.func.isRequired,
  deleting: PropTypes.bool.isRequired,
  prevTab: PropTypes.number.isRequired,
  nextTab: PropTypes.number.isRequired
};

export default InjuriesForm;
