import React from "react"

import './styles.scss';

function ThrownRiderChart() {
  return (
    <div className="simple-chart">
      <table className="simple-chart__table">
        <thead>
          <tr>
            <th>D6</th>
            <th>Result</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>1</th>
            <td>
              <strong>Knocked Flying</strong><br/>
              The rider hits the dirt and immediately suffers on Strength 3 hit. If they survive, the model is Prone, and can do nothing else for that turn - this includes moving, shooting, making Strikes and casting Magical Powers. If the mount survives, place the rider in base contact with the mount instead of if the centre of where its base was; if there is not enough room, move the models apart a little to make it so. If Engaged in combat, they fight as a Prone model.
            </td>
          </tr>
          <tr>
            <th>2-5</th>
            <td>
              <strong>Rises from the Dust</strong><br/>
              The rider disentangles themselves from their mount but can do nothing else for that turn - this includes moving, shooting, making Strikes and casting Magical Powers.
            </td>
          </tr>
          <tr>
            <th>6</th>
            <td>
              <strong>Leaps into Action</strong><br/>
              THe rider bounds from the saddle ready to face their enemy. The Cavalry model is replaced by an infantry model and suffers no further penalty.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default ThrownRiderChart