import React, { PureComponent } from 'react';

class PayPalCancelled extends PureComponent {
  render() {
    return (
      <div className="text-page">
        <div className="intro">
          <div className="intro__text">
            <h3>PayPal Donation</h3>
            <h1>Cancelled</h1>
            <p>Your donation was cancelled and you have not been charged. </p>
          </div>
        </div>
      </div>
    );
  }
}

export default PayPalCancelled;
