import React, { Component, Fragment } from 'react';
import { CompanyType } from '../../_types';

import Icon from '../Icon';
import EditCompanyOverlay from '../Overlay/EditCompanyOverlay';

import './styles.scss';
import companyIcon from '../../_assets/company.svg';

class NewCompanyDeck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      overlayOpen: false
    };
  }

  openOverlay = () => {
    this.setState({
      overlayOpen: true
    });
  }

  closeOverlay = () => {
    this.setState({
      overlayOpen: false
    });
  }

  render() {
    const { company } = this.props;
    const { overlayOpen } = this.state;

    return (
      <Fragment>
        <button className="deck" onClick={() => this.openOverlay()} type="button">
          <div className="new-deck">
            <div className="new-deck__inner">
              <img src={companyIcon} alt="Company" />
              <span>New Company</span>
              <Icon name="plus" />
            </div>
          </div>
        </button>
        {(overlayOpen) && (
          <EditCompanyOverlay company={{ ...company }} handleClose={this.closeOverlay} />
        )}
      </Fragment>
    );
  }
}

NewCompanyDeck.propTypes = {
  company: CompanyType.isRequired
};

export default NewCompanyDeck;
