import { authHeader } from '../_helpers/authHeader';
import { apiUrl } from '../_constants';
import { handleResponse } from '.';
import 'whatwg-fetch';

function getUserCompanies() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${apiUrl}/companies`, requestOptions).then(handleResponse);
}

function createCompany(company) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(company)
  };

  return fetch(`${apiUrl}/company/create`, requestOptions).then(handleResponse);
}

function updateCompany(company) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(company)
  };

  return fetch(`${apiUrl}/company/${company.id}`, requestOptions).then(handleResponse);
}

function retireCompany(id, retire) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({ id, retire })
  };

  return fetch(`${apiUrl}/company/${id}/retire`, requestOptions).then(handleResponse);
}

function deleteCompany(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };

  return fetch(`${apiUrl}/company/${id}`, requestOptions).then(handleResponse);
}

function saveBattleResult(company, warriors) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({ company, warriors })
  };

  return fetch(`${apiUrl}/company/${company.id}/battle`, requestOptions).then(handleResponse);
}

function getSharedCompany(userId, companyId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${apiUrl}/company/shared/${userId}/${companyId}`, requestOptions).then(handleResponse);
}

// eslint-disable-next-line import/prefer-default-export
export const companyService = {
  getUserCompanies,
  createCompany,
  updateCompany,
  retireCompany,
  deleteCompany,
  saveBattleResult,
  getSharedCompany
};
