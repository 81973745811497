import _ from 'lodash';
import { profileConstants } from '../_actions';
import { calculateProfilePoints, calculateProfileStatTotals } from '../_helpers/profile';

function formatProfiles(profiles) {
  if (!profiles) {
    return {};
  }

  const fullProfiles = _.map(profiles, (profile) => {
    const profileWithPoints = calculateProfilePoints(profile);
    return calculateProfileStatTotals(profileWithPoints);
  });

  return _.mapKeys(fullProfiles, 'id');
}

function formatProfile(profile, state) {
  if (!profile) {
    return {};
  }
  const profileWithPoints = calculateProfilePoints(profile);
  const newState = { ...state };
  newState[profile.id] = calculateProfileStatTotals(profileWithPoints);
  return newState;
}

export default function (state = { loading: true }, action) {
  switch (action.type) {
    case profileConstants.GET_REQUEST:
      return { loading: true };
    case profileConstants.GET_SUCCESS:
      return formatProfiles(action.profiles);
    case profileConstants.GET_FAILURE:
      return { error: action.error };

    case profileConstants.CREATE_SUCCESS:
      return formatProfile(action.profileResponse, state);

    case profileConstants.UPDATE_SUCCESS:
      return formatProfile(action.profileResponse, state);

    case profileConstants.DELETE_SUCCESS:
      return _.omit(state, action.idResponse.id);

    default:
      return state;
  }
}
