import _ from 'lodash';
import { companyConstants } from '../_actions';

export default function (state = { loading: true }, action) {
  switch (action.type) {
    case companyConstants.GET_REQUEST:
      return { loading: true };
    case companyConstants.GET_SUCCESS:
      return _.mapKeys(action.companies, 'id');
    case companyConstants.GET_FAILURE:
      return { error: action.error };

    case companyConstants.CREATE_SUCCESS:
      return { ...state, [action.companyResponse.id]: action.companyResponse };

    case companyConstants.UPDATE_SUCCESS:
      return { ...state, [action.companyResponse.id]: action.companyResponse };

    case companyConstants.DELETE_SUCCESS:
      return _.omit(state, action.idResponse);

    case companyConstants.BATTLE_SUCCESS:
      return { ...state, [action.companyResponse.id]: action.companyResponse };

    default:
      return state;
  }
}
