import React from "react"

import './styles.scss';

function JumpChart() {
  return (
    <div className="simple-chart">
      <table className="simple-chart__table">
        <thead>
          <tr>
            <th>D6</th>
            <th>Result</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>1</th>
            <td>
              <strong>Stumbles and Falls</strong><br/>
              The model does not cross the Obstacle, but instead ends its movement for the turn.
            </td>
          </tr>
          <tr>
            <th>2-5</th>
            <td>
              <strong>Success</strong><br/>
              The model successfully clambers over the Obstacle and reaches the other side. Place your model on the other side of the Obstacle, with its base touching it. The model may move no further this Move phase.
            </td>
          </tr>
          <tr>
            <th>6</th>
            <td>
              <strong>Effortlessly Bounds Across</strong><br/>
              The model vaults across the Obstacle with ease, and may even complete its movement up to its maximum Move distance (minus the distance already used getting up to and across the Obstacle of course).
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default JumpChart