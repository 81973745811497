import { spellService } from '../_services/spell';
import { alertActions } from './alert';

export const spellConstants = {
  GET_REQUEST: 'SPELLS_GET_REQUEST',
  GET_SUCCESS: 'SPELLS_GET_SUCCESS',
  GET_FAILURE: 'SPELLS_GET_FAILURE',

  CREATE_REQUEST: 'SPELL_CREATE_REQUEST',
  CREATE_SUCCESS: 'SPELL_CREATE_SUCCESS',
  CREATE_FAILURE: 'SPELL_CREATE_FAILURE',

  UPDATE_REQUEST: 'SPELL_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'SPELL_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'SPELL_UPDATE_FAILURE',

  DELETE_REQUEST: 'SPELL_DELETE_REQUEST',
  DELETE_SUCCESS: 'SPELL_DELETE_SUCCESS',
  DELETE_FAILURE: 'SPELL_DELETE_FAILURE'
};

function getSpells() {
  function request() { return { type: spellConstants.GET_REQUEST }; }
  function success(spell) { return { type: spellConstants.GET_SUCCESS, spell }; }
  function failure(error) { return { type: spellConstants.GET_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    spellService.getSpells()
      .then(
        spells => dispatch(success(spells)),
        error => dispatch(failure(error.toString()))
      );
  };
}

function createSpell(spell, callback) {
  function request(spellResponse) {
    return { type: spellConstants.CREATE_REQUEST, spellResponse };
  }
  function success(spellResponse) {
    return { type: spellConstants.CREATE_SUCCESS, spellResponse };
  }
  function failure(error) { return { type: spellConstants.CREATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(spell));

    spellService.createSpell(spell)
      .then(
        (spellResponse) => {
          dispatch(success(spellResponse));
          if (callback) callback();
          dispatch(alertActions.success(`${spellResponse.name} created successfully`));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };
}

function updateSpell(spell, callback) {
  function request(spellResponse) {
    return { type: spellConstants.UPDATE_REQUEST, spellResponse };
  }
  function success(spellResponse) {
    return { type: spellConstants.UPDATE_SUCCESS, spellResponse };
  }
  function failure(error) { return { type: spellConstants.UPDATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(spell));

    spellService.updateSpell(spell)
      .then(
        (spellResponse) => {
          dispatch(success(spellResponse));
          if (callback) callback();
          dispatch(alertActions.success(`${spellResponse.name} updated successfully`));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };
}

function deleteSpell(id) {
  function request(idResponse) { return { type: spellConstants.DELETE_REQUEST, idResponse }; }
  function success(idResponse) { return { type: spellConstants.DELETE_SUCCESS, idResponse }; }
  function failure(idResponse, error) {
    return { type: spellConstants.DELETE_FAILURE, idResponse, error };
  }

  return (dispatch) => {
    dispatch(request(id));

    spellService.deleteSpell(id)
      .then(
        (responseId) => {
          dispatch(success(responseId));
          dispatch(alertActions.success('Spell deleted successfully'));
        },
        (error) => {
          dispatch(failure(id, error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };
}

// eslint-disable-next-line import/prefer-default-export
export const spellActions = {
  getSpells,
  createSpell,
  updateSpell,
  deleteSpell
};
