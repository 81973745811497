import { wargearService } from '../_services/wargear';
import { alertActions } from './alert';

export const wargearConstants = {
  GET_REQUEST: 'WARGEAR_GET_REQUEST',
  GET_SUCCESS: 'WARGEAR_GET_SUCCESS',
  GET_FAILURE: 'WARGEAR_GET_FAILURE',

  CREATE_REQUEST: 'WARGEAR_CREATE_REQUEST',
  CREATE_SUCCESS: 'WARGEAR_CREATE_SUCCESS',
  CREATE_FAILURE: 'WARGEAR_CREATE_FAILURE',

  UPDATE_REQUEST: 'WARGEAR_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'WARGEAR_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'WARGEAR_UPDATE_FAILURE',

  DELETE_REQUEST: 'WARGEAR_DELETE_REQUEST',
  DELETE_SUCCESS: 'WARGEAR_DELETE_SUCCESS',
  DELETE_FAILURE: 'WARGEAR_DELETE_FAILURE'
};

function getWargear() {
  function request() { return { type: wargearConstants.GET_REQUEST }; }
  function success(wargear) { return { type: wargearConstants.GET_SUCCESS, wargear }; }
  function failure(error) { return { type: wargearConstants.GET_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    wargearService.getWargear()
      .then(
        wargear => dispatch(success(wargear)),
        error => dispatch(failure(error.toString()))
      );
  };
}

function createWargearItem(item, callback) {
  function request(wargearResponse) {
    return { type: wargearConstants.CREATE_REQUEST, wargearResponse };
  }
  function success(wargearResponse) {
    return { type: wargearConstants.CREATE_SUCCESS, wargearResponse };
  }
  function failure(error) { return { type: wargearConstants.CREATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(item));

    wargearService.createWargearItem(item)
      .then(
        (wargearResponse) => {
          dispatch(success(wargearResponse));
          if (callback) callback();
          dispatch(alertActions.success(`${wargearResponse.name} created successfully`));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };
}

function updateWargearItem(item, callback) {
  function request(wargearResponse) {
    return { type: wargearConstants.UPDATE_REQUEST, wargearResponse };
  }
  function success(wargearResponse) {
    return { type: wargearConstants.UPDATE_SUCCESS, wargearResponse };
  }
  function failure(error) { return { type: wargearConstants.UPDATE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(item));

    wargearService.updateWargearItem(item)
      .then(
        (wargearResponse) => {
          dispatch(success(wargearResponse));
          if (callback) callback();
          dispatch(alertActions.success(`${wargearResponse.name} updated successfully`));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };
}

function deleteWargearItem(id) {
  function request(idResponse) { return { type: wargearConstants.DELETE_REQUEST, idResponse }; }
  function success(idResponse) { return { type: wargearConstants.DELETE_SUCCESS, idResponse }; }
  function failure(idResponse, error) {
    return { type: wargearConstants.DELETE_FAILURE, idResponse, error };
  }

  return (dispatch) => {
    dispatch(request(id));

    wargearService.deleteWargearItem(id)
      .then(
        (responseId) => {
          dispatch(success(responseId));
          dispatch(alertActions.success('Item deleted successfully'));
        },
        (error) => {
          dispatch(failure(id, error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };
}

// eslint-disable-next-line import/prefer-default-export
export const wargearActions = {
  getWargear,
  createWargearItem,
  updateWargearItem,
  deleteWargearItem
};
