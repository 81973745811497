/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { AuthType } from '../_types';
import { LoginForm, RegisterForm } from '../_components';

class LoginPage extends PureComponent {
  render() {
    const { authentication } = this.props;

    return (
      <div className="text-page">
        <div className="intro">
          <div className="intro__text">
            <h3>Battle Companies Manager</h3>
            <h1>Login / Register</h1>
            <p>
              Battle Companies Manager is is currently in beta and you may encounter some bugs.
            </p>
            <p>
              Please visit the <a href="https://www.facebook.com/Battle-Companies-Manager-112213840144097" target="_blank" rel="noopener noreferrer">Battle Companies Manager Facebook page</a> for announcements and news.
            </p>
          </div>
        </div>
        {!authentication.loggedIn && (
          <div className="text-page-forms">
            <div className="text-page-form">
              <h3>Register</h3>
              <RegisterForm />
            </div>
            <div className="text-page-form">
              <h3>Login</h3>
              <LoginForm />
            </div>
          </div>
        )}
      </div>
    );
  }
}

LoginPage.propTypes = {
  authentication: AuthType.isRequired
};

function mapStateToProps({ authentication }) {
  return { authentication };
}

export default connect(mapStateToProps)(LoginPage);
