import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { WargearType } from '../../_types';

import {
  CharacteristicsTable, MWFTable, RulesList, Card
} from '..';
import EditWarriorOverlay from '../Overlay/EditWarriorOverlay';

class ProfileCard extends Component {
  render() {
    const { profile, clickable } = this.props;
    const {
      id, baseClass, totalPoints, race, totalStats, wargear, specialRules
    } = profile;

    return (
      <Card
        id={id}
        title={baseClass}
        points={totalPoints || 0}
        tab={race.replace(/,/g, ', ') || 'Any'}
        className="card--profile"
        clickable={clickable}
        overlay={(
          <EditWarriorOverlay
            warrior={{ ...profile }}
            editable={true}
            profile
          />
        )}
      >
        <div className="card__divider" />
        <CharacteristicsTable total={totalStats} />
        <MWFTable total={totalStats} />
        <RulesList title="Wargear" rules={_.filter(wargear, ({ remove }) => !remove)} />
        <RulesList
          title="Special Rules"
          rules={_.filter(specialRules, (rule) => {
            let wargearItem;
            if (rule.wargearId) {
              wargearItem = _.find(profile.wargear, { id: rule.wargearId });
            }
            return !rule.remove && !(wargearItem && wargearItem.remove);
          })}
        />
      </Card>
    );
  }
}

ProfileCard.propTypes = {
  profile: WargearType.isRequired,
  clickable: PropTypes.bool
};

ProfileCard.defaultProps = {
  clickable: false
};

export default ProfileCard;
