import React from "react"

import './styles.scss';

function LeapChart() {
  return (
    <div className="simple-chart">
      <table className="simple-chart__table">
        <thead>
          <tr>
            <th>D6</th>
            <th>Result</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>1</th>
            <td>
              <strong>Stumbles and Falls</strong><br/>
              The attempt to leap the gap has gone wrong. The model falls to the bottom of the gap, suffering falling damage and is knocked Prone.
            </td>
          </tr>
          <tr>
            <th>2-5</th>
            <td>
              <strong>Success</strong><br/>
              The model manages to leap across to safety. Place the model on the other side of the gap, with its base touching it. The model may move no further this Move phase.
            </td>
          </tr>
          <tr>
            <th>6</th>
            <td>
              <strong>Effortlessly Bounds Across</strong><br/>
              The model leaps across without missing a step Place it on the other side; it may even complete its movement up to its maximum allowance (minus the distance it already used getting across the gap, of course)
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default LeapChart