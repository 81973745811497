/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { userActions } from '../../_actions';
import {
  Button, Form, FormRow, TextInput
} from '..';

function getParameterByName(n, u) {
  const url = u || window.location.href;
  const name = n.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

class ResetPasswordForm extends React.Component {
  constructor(props) {
    super(props);

    const token = getParameterByName('token');

    this.state = {
      token,
      password: '',
      confirmPassword: '',
      submitted: false,
      passwordReset: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { resetPassword } = this.props;
    const { token, password, confirmPassword } = this.state;

    if (password !== confirmPassword) {
      return;
    }

    const passwordObject = {
      password,
      token
    };

    resetPassword(passwordObject, () => {
      this.setState({
        passwordReset: true
      });
    });
  }

  render() {
    const { resettingPassword } = this.props;
    const {
      password, confirmPassword, submitted, passwordReset
    } = this.state;

    if (passwordReset) {
      return <p>Your password has been updated. You can now <Link to="/login">log in</Link> using your new password.</p>;
    }

    return (
      <form id="password-reset-form" name="password-reset-form" onSubmit={this.handleSubmit}>
        <Form buttons={<Button text="Submit" primary iconName="arrow-right" iconRight type="submit" loading={resettingPassword} />}>
          <FormRow>
            <TextInput name="password" value={password} onChange={this.handleChange} placeholder="Password" type="password" required showError={submitted && !password} />
          </FormRow>
          <FormRow>
            <TextInput name="confirmPassword" value={confirmPassword} onChange={this.handleChange} placeholder="Confirm Password" type="password" required showError={submitted && !confirmPassword} />
            {(submitted && password !== confirmPassword) && (
              <span className="field-validation-error">Your passwords do not match.</span>
            )}
          </FormRow>
        </Form>
      </form>
    );
  }
}

ResetPasswordForm.propTypes = {
  resettingPassword: PropTypes.bool,
  resetPassword: PropTypes.func.isRequired
};

ResetPasswordForm.defaultProps = {
  resettingPassword: false
};

function mapStateToProps({ resetPassword }) {
  const { resettingPassword } = resetPassword;
  return { resettingPassword };
}

export default connect(mapStateToProps, ({
  resetPassword: userActions.resetPassword
}))(ResetPasswordForm);
