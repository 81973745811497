import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  WarriorsType, CompanyType, RuleType, HeroicActionType, SpellType
} from '../../../_types';
import { calculateWarriorPoints, calculateStatTotals } from '../../../_helpers';
import { companyActions } from '../../../_actions';

import Result from './Result';
import ResolveInjuries from './ResolveInjuries';
import ApplyExperience from './ApplyExperience';
import InfluenceOverlay from './InfluenceOverlay';

class PostBattle extends Component {
  constructor(props) {
    super(props);

    const { company, warriors, bonus } = this.props;

    const c = _.clone(company);
    c.againstTheOdds = bonus;
    c.woundsInfluence = 0;

    const recoveredWarriors = _.mapKeys(_.map(warriors, (warrior) => {
      if (warrior.preBattleInjury && (warrior.status === 'lost' || warrior.status === 'injured')) {
        return { ...warrior, status: '' };
      }
      return warrior;
    }), 'id');

    this.state = {
      company: c,
      warriors: recoveredWarriors,
      resultRecorded: false,
      result: null,
      woundsResolved: !_.filter(warriors, { wounded: true }).length,
      experienceApplied: false
    };
  }

  recordResult = (results) => {
    const { warriors, company } = this.state;

    if (results.influenceEnabled) {
      company.influenceReward = results.influence;
    }

    if (results.levelUpEnabled) {
      const warrior = warriors[results.levelUp];
      if (warrior.rank === 'Warrior') {
        warrior.rank = 'Hero';
        warrior.modifyStats.fate += 1;
        warrior.totalStats.fate += 1;
      } else {
        warrior.bonusLevel = true;
      }
    }

    if (results.experienceEnabled) {
      _.map(results.experienceWarriors, (ew) => {
        const warrior = warriors[ew.id];
        warrior.bonusExperience = ew.bonusExperience;
      });
    }

    if (results.itemEnabled) {
      const warrior = warriors[results.item.warriorId];
      warrior.wargear.push(results.item);
    }

    switch (results.result) {
      case 0:
        company.resultInfluence = 4;
        break;
      case 1:
        company.resultInfluence = 3;
        break;
      default:
        company.resultInfluence = 2;
        break;
    }

    this.setState({
      company,
      warriors,
      result: results.result,
      resultRecorded: true
    });
  }

  recordInjury = (warrior) => {
    const { warriors, company } = this.state;
    warriors[warrior.id] = warrior;

    if (warrior.woundsInfluence) {
      company.woundsInfluence += warrior.woundsInfluence;
    }

    this.setState({
      company,
      warriors,
      woundsResolved: !_.filter(warriors, { wounded: true }).length
    });
  }

  updateWarrior = (warrior) => {
    const { warriors } = this.state;

    let editedWarrior = { ...warrior };
    editedWarrior = calculateWarriorPoints(editedWarrior);
    editedWarrior = calculateStatTotals(editedWarrior);

    warriors[editedWarrior.id] = editedWarrior;

    const resolvedWarriors = _.filter(warriors, w => (
      w.experienceIncrease != null || w.preBattleInjury || w.status === 'dead' || w.rank === 'Wanderer' || w.rank === 'Creature'
    ));

    this.setState({
      warriors,
      experienceApplied: resolvedWarriors.length === _.size(warriors)
    });
  }

  recordInfluence = (influence) => {
    const { saveBattleResultAction } = this.props;
    const { company, warriors } = this.state;

    _.map(warriors, warrior => {
      if (!warrior.owner) {
        return
      }
      const owner = warriors[warrior.owner]
      if (owner.status === 'dead') {
        warrior.status = 'dead'
      }
    })

    company.influence = influence;
    this.setState({
      company
    });

    saveBattleResultAction(company, warriors);
    window.sessionStorage.removeItem(company.id)
  }

  render() {
    const { rules, heroicActions, spells } = this.props;
    const {
      company, warriors, result, resultRecorded, woundsResolved, experienceApplied
    } = this.state;

    if (!resultRecorded) {
      return <Result warriors={warriors} onSubmit={this.recordResult} />;
    }

    if (!woundsResolved) {
      return <ResolveInjuries warriors={warriors} recordInjury={this.recordInjury} />;
    }

    if (_.size(warriors) && !experienceApplied) {
      return (
        <ApplyExperience
          warriors={warriors}
          result={result}
          updateWarrior={this.updateWarrior}
          skipExperience={() => this.setState({ experienceApplied: true })}
          rules={rules}
          heroicActions={heroicActions}
          spells={spells}
        />
      );
    }

    return <InfluenceOverlay company={company} result={result} onSubmit={this.recordInfluence} />;
  }
}

PostBattle.propTypes = {
  company: CompanyType.isRequired,
  warriors: WarriorsType.isRequired,
  bonus: PropTypes.number.isRequired,
  saveBattleResultAction: PropTypes.func.isRequired,
  rules: PropTypes.oneOfType([
    PropTypes.objectOf(RuleType),
    PropTypes.objectOf(PropTypes.bool)
  ]).isRequired,
  heroicActions: PropTypes.oneOfType([
    PropTypes.objectOf(HeroicActionType),
    PropTypes.objectOf(PropTypes.bool)
  ]).isRequired,
  spells: PropTypes.oneOfType([
    PropTypes.objectOf(SpellType),
    PropTypes.objectOf(PropTypes.bool)
  ]).isRequired
};

function mapStateToProps({ rules, heroicActions, spells }) {
  return { rules, heroicActions, spells };
}

export default connect(mapStateToProps, {
  saveBattleResultAction: companyActions.saveBattleResult
})(PostBattle);
