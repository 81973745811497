/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-restricted-globals */
import React from 'react';
import PropTypes from 'prop-types';
import { Overlay } from '../..';

import patroenButton from '../../../_assets/patreon.png';
import paypalButton from '../../../_assets/paypal.png';

function SupportOverlay({ handleClose }) {
  return (
    <Overlay id="support-us" title="Support Us" handleClose={handleClose} isSmall open>
      <h2>Please Support Battle Companies Manager</h2>
      <p>Battle Companies Manager is solely developed and hosted by Pat Gaunt, a player and fan of the game.</p>
      <p>In order create the best experience possible, I had avoided adding adverts or pay walls to the app, however, as the app has gained in popularity, the costs for hosting and maintaining it have also increased.</p>
      <p>I humbly ask you to please consider donating if you find the app useful, to support its long-term availability. As a thank you I will remove the adverts from your account (Minimum donation of £1 due to fees). You can use the buttons below to easily support BCM.</p>
      <p>Please note, removing adverts is currently a manual process, so the adverts will not disappear immediately. If you are still seeing adverts 24 hours after your donation please contact me through the Facebook group.</p>
      <p>Alternatively, purchases made through Element Games after clicking on an advert provide me with a small kick-back, so happy shopping!</p>
      <div className="support-buttons">
        <a href="https://www.patreon.com/bePatron?u=17808468" className="donate" target="_blank" rel="noopener noreferrer">
          <img src={patroenButton} alt="Patreon" />
        </a>
        <strong>Or</strong>
        <form action="https://www.paypal.com/donate" method="post" target="_top" className="donate">
          <input type="hidden" name="hosted_button_id" value="QZ46U9CJF7EFE" />
          <input type="image" src={paypalButton} border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
          <img alt="" border="0" src="https://www.paypal.com/en_GB/i/scr/pixel.gif" width="1" height="1" />
        </form>
      </div>
    </Overlay>
  );
}

SupportOverlay.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default SupportOverlay;
