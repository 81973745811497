import React, { Component, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { FormRow, TextInput } from '../Forms';
import { Accordion, Button } from '../index';

function EditNameAndDescriptionForm({
  updateItem, removeItem, name: inboundName, description: inboundDescription
}) {
  const [name, setName] = useState(inboundName);
  const [description, setDescription] = useState(inboundDescription);

  return (
    <Accordion heading={inboundName}>
      <FormRow>
        <TextInput name="name" label="Name" value={name} onChange={e => setName(e.target.value)} />
      </FormRow>
      <FormRow>
        <TextInput name="description" label="Description" value={description} onChange={e => setDescription(e.target.value)} textarea />
      </FormRow>
      <div className="form__inner-footer">
        <Button text="Remove" destructive small floatRight type="button" onClick={removeItem} />
        <Button text="Update" primary small floatRight type="submit" onClick={() => updateItem({ name, description })} />
      </div>
    </Accordion>
  );
}

class MultipleOptionFormRow extends Component {
  constructor(props) {
    super(props);
    const { items } = this.props;
    this.state = {
      items,
      name: '',
      description: ''
    };
  }

  addItem = () => {
    const { name, description } = this.state;
    if (!name) return;

    const newItem = { name, description };
    const { items } = this.state;
    const newItems = [...items, newItem];
    this.setState({
      items: newItems,
      name: '',
      description: ''
    });
    // eslint-disable-next-line react/destructuring-assignment
    this.props.onChange(newItems);
  }

  updateItem = (index, item) => {
    const { items } = this.state;
    items[index] = {
      ...items[index],
      ...item
    };
    this.setState({ items });
    // eslint-disable-next-line react/destructuring-assignment
    this.props.onChange(items);
  }

  removeItem = (index) => {
    const { items } = this.state;
    this.setState({
      items: _.filter(items, (item, itemIndex) => itemIndex !== index)
    });
    // eslint-disable-next-line react/destructuring-assignment
    this.props.onChange(items);
  }

  handleInputChange = (event) => {
    const { target: { name, value } } = event;
    this.setState({
      [name]: value
    });
  }

  render() {
    const { maxListSize, className, heading } = this.props;
    const { items, name, description } = this.state;

    return (
      <FormRow cls={`row--modify-${className}`}>
        <div className="row">
          <div className="field">
            <label>
              {heading}
              s
            </label>
            <div>
              {_.map(items, (item, index) => (
                <EditNameAndDescriptionForm
                  key={`${className}${item.name}`}
                  name={item.name}
                  description={item.description}
                  updateItem={updatedItem => this.updateItem(index, updatedItem)}
                  removeItem={() => this.removeItem(index)}
                />
              ))}
              {items.length < maxListSize && (
                <Accordion heading={`Add ${heading}`} newItem>
                  <FormRow>
                    <TextInput name="name" label="Name" value={name} onChange={this.handleInputChange} />
                  </FormRow>
                  <FormRow>
                    <TextInput
                      name="description"
                      label="Description"
                      value={description}
                      onChange={this.handleInputChange}
                      textarea
                    />
                  </FormRow>
                  <div className="form__inner-footer">
                    <Button text="Create" primary small floatRight type="button" onClick={this.addItem} />
                  </div>
                </Accordion>
              )}
            </div>
          </div>
        </div>
      </FormRow>
    );
  }
}

EditNameAndDescriptionForm.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  removeItem: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired
};


MultipleOptionFormRow.propTypes = {
  maxListSize: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  className: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default MultipleOptionFormRow;
