/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
import React, { useState } from 'react';
import { authHeader } from '../_helpers/authHeader';
import { apiUrl } from '../_constants';
import { handleResponse } from '../_services';
import 'whatwg-fetch';

import {
  TextInput, FormRow, Button
} from '../_components';

import './styles.scss';

function DonationAdmin() {
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState(null);
  const [results, setResults] = useState(null);

  const searchUsers = () => {
    setSubmitted(true);

    if (!searchTerm) {
      return;
    }

    setSubmitting(true);

    const requestOptions = {
      method: 'GET',
      headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    setError(null);

    fetch(`${apiUrl}/users/search/${searchTerm}`, requestOptions)
      .then(handleResponse)
      .then((data) => {
        setResults(data);
        setSubmitted(false);
        setSubmitting(false);
      })
      .catch((err) => {
        setError(err);
        setResults(null);
        setSubmitted(false);
        setSubmitting(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    searchUsers();
  };

  const markDonated = (id, name) => {
    if (confirm(`Set ${name} as donated?`)) {
      const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ id })
      };

      fetch(`${apiUrl}/users/set-donated`, requestOptions)
        .then(handleResponse)
        .then(() => {
          searchUsers();
        })
        .catch((err) => {
          setError(err);
          setResults(null);
        });
    }
  };

  return (
    <div>
      <form id="search-form" name="search-form" onSubmit={handleSubmit}>
        <FormRow>
          <TextInput id="search-term" name="search-term" placeholder="Search Term" value={searchTerm} onChange={({ target: { value } }) => setSearchTerm(value)} required showError={submitted && !searchTerm} />
        </FormRow>
        <Button text="Search" primary iconName="arrow-right" iconRight type="submit" loading={submitting} />
      </form>

      {results && (
        results.length ? (
          <div className="search-container">
            <table className="table table--search">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th className="text--center">Donated</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {results.map(({
                  id, name, email, donated
                }) => (
                  <tr key={email}>
                    <td>{name}</td>
                    <td>{email}</td>
                    <td className="text--center">{donated}</td>
                    <td>
                      <Button text="Set Donated" primary small onClick={() => markDonated(id, name)} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p>No results found</p>
        )
      )}

      {error && (
        <p>{error}</p>
      )}
    </div>
  );
}

export default DonationAdmin;
