import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Icon } from '../../..';

import './styles.scss';

class NumberControl extends Component {
  onClick = (e) => {
    const { target } = e;
    target.setSelectionRange(0, target.value.length);
  }

  checkValue = (e) => {
    const {
      name, onChange, min, max
    } = this.props;
    const { value } = e.target;

    let newVal = parseInt(value || 0, 10);

    if (max && newVal > max) {
      newVal = max;
    }
    if (min && newVal < min) {
      newVal = min;
    }

    if (value !== newVal) {
      onChange(name, newVal);
    }
  }

  increaseValue() {
    const {
      name, value, onChange, max
    } = this.props;
    let newVal = parseInt(value, 10) + 1;
    if (max && newVal > max) {
      newVal = max;
    }
    onChange(name, newVal);
  }

  decreaseValue() {
    const {
      name, value, onChange, min
    } = this.props;
    let newVal = parseInt(value, 10) - 1;
    if (min != null && newVal < min) {
      newVal = min;
    }
    onChange(name, newVal);
  }

  render() {
    const {
      id, name, value, readOnly, min, max, className
    } = this.props;

    if (readOnly) {
      return (
        <div className="number-control number-control--read-only">
          <span className="number-control__val">{value}</span>
        </div>
      );
    }

    return (
      <div className={`number-control ${className}`}>
        <button className="number-control__dec" type="button" onClick={() => this.decreaseValue()} disabled={min != null && value <= min}>
          <Icon name="minus" />
        </button>
        <input className="number-control__val" type="text" value={value} id={id} name={name} onChange={e => this.checkValue(e)} onClick={this.onClick} />
        <button className="number-control__inc" type="button" onClick={() => this.increaseValue()} disabled={max != null && value >= max}>
          <Icon name="plus" />
        </button>
      </div>
    );
  }
}

NumberControl.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  readOnly: PropTypes.bool,
  className: PropTypes.string
};

NumberControl.defaultProps = {
  min: null,
  max: null,
  readOnly: false,
  onChange: null,
  className: ''
};

export default NumberControl;
