/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
import React, { PureComponent, Fragment } from 'react';
import { ChangePasswordForm, UpdateUserForm } from '../_components';

class MyAccountPage extends PureComponent {
  render() {
    return (
      <Fragment>
        <h1>Account</h1>
        <div className="text-page-forms">
          <div className="text-page-form">
            <h3>Change Details</h3>
            <UpdateUserForm />
          </div>
          <div className="text-page-form">
            <h3>Change Password</h3>
            <ChangePasswordForm />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default MyAccountPage;
